import React, { Fragment } from 'react';
import CommentItem from './CommentItem';
import CommentReplies from './CommentReplies';

export default function CommentsList({
	comments,
	onCommentClick: handleCommentClick,
	onRepliedCommentClick: handleRepliedCommentClick,
}) {
	return (
		<Fragment>
			{comments.map(comment => {
				return (
					<div key={comment.id}>
						<CommentItem
							onClick={() =>
								handleCommentClick({ commentId: comment.id, isUserActive: comment.active })
							}
							comment={comment}
						/>
						{comment.replies && comment.replies.length > 0 ? (
							<CommentReplies
								commentId={comment.id}
								handleRepliedCommentClick={handleRepliedCommentClick}
								replies={comment.replies}
							/>
						) : null}
					</div>
				);
			})}
		</Fragment>
	);
}
