import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import List from '@material-ui/core/List';
import ListItem from '../../../common/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import {
	makeStyles,
	Typography,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@material-ui/core';
import {
	conversationsSelector,
	getConversations,
	getConversationsFirstPage,
} from '../../../store/chat/chat.store';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import { PullDownContent, ReleaseContent, RefreshContent } from 'react-js-pull-to-refresh';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { declineConversation } from '../../../store/message/message.store';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	avatar: {
		margin: 10,
	},
	listItemRoot: {},
	newMessage: {
		'&::after': {
			content: '""',
			position: 'absolute',
			zIndex: 0,
			top: '50%',
			right: 16,
			height: 5,
			width: 5,
			backgroundColor: '#293ded',
			borderRadius: '50%',
		},
	},
	chatListContainer: {
		width: '100%',
	},
	chatList: {
		width: '65%',
		margin: '0 auto',
		[theme.breakpoints.down('md')]: {
			width: '90%',
		},
	},
	listStyle: {
		borderRadius: '15px',
		backgroundColor: theme.mainBackgroundScreens,
		padding: 7,
		border: '2px solid ' + theme.borderContainers,
	},
}));

export default function ConversationList() {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();
	const conversations = useSelector(conversationsSelector);
	const page = useSelector(state => state.chat.page);
	const isLoading = useSelector(state => state.chat.isLoading);
	const hasMore = useSelector(state => state.chat.hasMore);

	const [actionsDialogVisible, setActionsDialogVisible] = useState(false);
	const [blockingConfirmationDialogVisible, setBlockingConfirmationDialogVisible] = useState(false);

	const loadProducer = useCallback(() => {
		dispatch(getConversations({ page: page + 1 }));
	}, [dispatch, page]);

	useEffect(() => {
		dispatch(getConversationsFirstPage());
	}, [dispatch]);

	const goToConversation = id => {
		history.push(`/conversations/${id}`, 'internal');
	};

	if (!hasMore && !conversations.length) {
		return (
			<div style={{ textAlign: 'center', color: theme.textDark }}>
				<Typography>{t('no messages')}</Typography>
			</div>
		);
	}

	const getStyle = data => {
		if (data.accepted) {
			if (data.newmessages) {
				return { fontWeight: 'bold', showCircle: true, darkBackground: false };
			}
			return { fontWeight: 400, showCircle: false, darkBackground: false };
		}
		return { fontWeight: 'bold', showCircle: false, darkBackground: true };
	};

	const onRefresh = async () => {
		return dispatch(getConversationsFirstPage());
	};

	const handleDialogClose = () => setActionsDialogVisible(false);
	const handleConvMoreClick = (event, convId) => {
		event.stopPropagation();
		setActionsDialogVisible(convId);
	};

	const handleBlockConversation = () => {
		dispatch(declineConversation(actionsDialogVisible));
		setActionsDialogVisible(false);
		setBlockingConfirmationDialogVisible(false);
	};

	return (
		<div className={classes.chatListContainer}>
			<Dialog onClose={handleDialogClose} open={!!actionsDialogVisible}>
				{actionsDialogVisible ? (
					<List>
						<ListItem
							button
							onClick={() => setBlockingConfirmationDialogVisible(true)}
							divider={true}
						>
							<ListItemText primary={t('delete conversation')} />
						</ListItem>
						<ListItem button onClick={handleDialogClose} divider={false}>
							<ListItemText primary={t('cancel')} style={{ color: 'red' }} />
						</ListItem>
					</List>
				) : (
					<div />
				)}
			</Dialog>
			<Dialog
				open={blockingConfirmationDialogVisible}
				onClose={() => {
					setBlockingConfirmationDialogVisible(false);
					setActionsDialogVisible(false);
				}}
			>
				<DialogContent>
					<DialogContentText>{t('confirm delete')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setBlockingConfirmationDialogVisible(false);
							setActionsDialogVisible(false);
						}}
						color="primary"
					>
						{t('cancel')}
					</Button>
					<Button onClick={handleBlockConversation} color="primary" autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

			<InfiniteScroll initialLoad={false} loadMore={loadProducer} hasMore={hasMore && !isLoading}>
				<PullToRefresh
					onRefresh={onRefresh}
					pullDownContent={<PullDownContent />}
					releaseContent={<ReleaseContent />}
					refreshContent={<RefreshContent />}
					pullDownThreshold={200}
					triggerHeight={'auto'}
					startInvisible={true}
				>
					<div className={classes.chatList}>
						<List className={classes.listStyle}>
							{conversations
								.filter(conversation => conversation.chatpartner)
								.map((conversation, index) => {
									const lastMessage = conversation.lastmessage
										? conversation.lastmessage.message
										: '';
									const { showCircle, fontWeight, darkBackground } = getStyle(conversation);
									return (
										<ListItem
											key={conversation.id}
											onClick={() => goToConversation(conversation.id)}
											button
											divider={index !== conversations.length - 1}
											style={{
												backgroundColor: darkBackground ? theme.headerBackground : '',
												height: 75,
											}}
										>
											<ListItemIcon>
												<Avatar
													alt="Remy Sharp"
													src={conversation.chatpartner.avatar}
													className={classes.avatar}
												/>
											</ListItemIcon>
											{showCircle && (
												<div
													style={{
														height: 10,
														width: 10,
														backgroundColor: '#2D6CE8',
														position: 'absolute',
														borderRadius: '50%',
														right: 42,
													}}
												/>
											)}
											<ListItemText
												primary={
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<Typography
															style={{
																fontWeight,
															}}
														>
															{conversation.chatpartner.nickname}
														</Typography>
														{conversation.newmessages ? (
															<div
																style={{
																	marginLeft: 8,
																	width: 10,
																	height: 10,
																	borderRadius: '50%',
																	backgroundColor: 'rgb(91, 160, 251)',
																}}
															/>
														) : null}
													</div>
												}
												secondary={lastMessage.substring(0, 100) + '...'}
												primaryTypographyProps={{ style: { color: theme.textDark } }}
												secondaryTypographyProps={{
													style: { color: theme.textLight, height: 32, overflow: 'hidden' },
												}}
											/>
											<MoreVertIcon
												style={{ marginRight: 10, color: theme.headerIcon }}
												onClick={event => handleConvMoreClick(event, conversation.id)}
											/>
											<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
										</ListItem>
									);
								})}
						</List>
					</div>
				</PullToRefresh>
			</InfiniteScroll>
			{isLoading ? <Loader /> : null}
		</div>
	);
}
