import React from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import Box from '@material-ui/core/Box';
import InlineInfo from '../../shared/components/InlineInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { ConfigCountry } from '../../../helpers/configCountry';

export default function ContactSuccessScreen({ onClose }) {
	const { t } = useTranslation();
	const text = `
    ${t('message received')}<a style="color: #A42830;" href='mailto:info@${
		ConfigCountry.domain
	}'>info@${ConfigCountry.domain}</a>
`;

	return (
		<div>
			<HeaderAppBar
				title={t('thank you')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>

			<Box p={2}>
				<InlineInfo title={t('thank you') + '!'} text={text} />
			</Box>
		</div>
	);
}
