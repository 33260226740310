import React, { useState } from 'react';
import ProfileService from '../../../services/ProfileService';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	InputBase,
} from '@material-ui/core';
import { fetchProfile } from '../../../store/profile/profile.store';
import { useDispatch } from 'react-redux';
import theme from '../../../theme';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function ProfileSlogan({ slogan, editable }) {
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [formSlogan, setFormSlogan] = useState(slogan || '');
	const { t } = useTranslation();

	const handleChange = event => {
		setFormSlogan(event.target.value);
	};

	const handleSave = () => {
		ProfileService.updateSlogan(formSlogan)
			.then(() => {
				setIsEditing(false);
			})
			.finally(() => {
				dispatch(fetchProfile());
			});
	};

	const handleCancel = () => {
		setFormSlogan(slogan || '');
		setIsEditing(false);
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				title={t('edit')}
				role="button"
			>
				{slogan && (
					<div
						dangerouslySetInnerHTML={{
							__html: slogan?.replaceAll('\n', '<br />'),
						}}
						style={{ color: theme.textLight }}
					/>
				)}
				{!slogan && editable && (
					<div style={{ color: theme.textLight }}>{t('add profile text')}</div>
				)}
				{/*<div*/}
				{/*    dangerouslySetInnerHTML={{*/}
				{/*      __html:*/}
				{/*          slogan?.replaceAll("\n", "<br />") ??*/}
				{/*          (editable ? t('add profile text') : "kk"),*/}
				{/*    }}*/}
				{/*  style={{color: theme.textLight}}*/}
				{/*/>*/}
				{editable ? (
					<div onClick={() => setIsEditing(true)}>
						<FontAwesomeIcon
							icon={faPencilAlt}
							style={{ cursor: 'pointer', marginLeft: 10, color: theme.mainBlue, fontSize: 20 }}
							color={theme.headerIcon}
						/>
					</div>
				) : null}
			</div>
			<Dialog open={isEditing} onClose={handleCancel}>
				<DialogContent>
					<DialogContentText>
						<InputBase
							multiline
							rowsMax={4}
							placeholder={t('slogan')}
							type={'textarea'}
							value={formSlogan}
							onChange={handleChange}
							autoFocus
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel}>{t('cancel')}</Button>
					<Button onClick={handleSave} variant="contained" color="primary" autoFocus>
						{t('save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ProfileSlogan;
