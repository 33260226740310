import { createAction, createReducer } from 'redux-starter-kit';
import NotificationService from '../../services/NotificationService';

export const getNotificationsRequest = createAction('GET_NOTIFICATIONS_REQUEST');
export const getNotificationsFailure = createAction('GET_NOTIFICATIONS_FAILURE');
export const getNotificationsSuccess = createAction('GET_NOTIFICATIONS_SUCCESS');

export const selectNotification = createAction('SELECT_NOTIFICATION');

export const getNotificationByIdRequest = createAction('GET_NOTIFICATION_BY_ID_REQUEST');
export const getNotificationByIdSuccess = createAction('GET_NOTIFICATION_BY_ID_SUCCESS');
export const getNotificationByIdFailure = createAction('GET_NOTIFICATION_BY_ID_FAILURE');

export const markAllNotificationsReadRequest = createAction('MARK_ALL_NOTIFICATIONS_READ_REQUEST');
export const markAllNotificationsReadSuccess = createAction('MARK_ALL_NOTIFICATIONS_READ_SUCCESS');
export const markAllNotificationsReadFailure = createAction('MARK_ALL_NOTIFICATIONS_READ_FAILURE');

export const resetNotifications = createAction('RESET_NOTIFICATIONS');

const initialState = {
	page: 0,
	notifications: [],
	isLoading: false,
	hasMore: false,
	notificationId: 0,
};

export const getNotifications =
	({ page }) =>
	async dispatch => {
		dispatch(getNotificationsRequest());

		NotificationService.getNotifications({ page })
			.then(({ data }) => {
				dispatch(
					getNotificationsSuccess({
						notifications: data.data,
						hasMore: data.to < data.total,
					}),
				);
			})
			.catch(() => {
				dispatch(getNotificationsFailure());
			});
	};

export const getNotificationById = id => dispatch => {
	dispatch(getNotificationByIdRequest());
	NotificationService.getNotificationById(id)
		.then(({ data: notification }) => {
			dispatch(
				getNotificationByIdSuccess({
					notification,
				}),
			);
		})
		.catch(() => {
			dispatch(getNotificationByIdFailure());
		});
};

export const markAllNotificationsRead = () => dispatch => {
	dispatch(markAllNotificationsReadRequest());
	NotificationService.markAllRead()
		.then(() => {
			dispatch(markAllNotificationsReadSuccess());
			dispatch(resetNotifications());
			dispatch(getNotifications({ page: 1 }));
		})
		.catch(() => {
			dispatch(markAllNotificationsReadFailure());
		});
};

const notificationReducer = createReducer(initialState, {
	// Get Notifications
	[getNotificationsRequest]: state => {
		state.isLoading = true;
	},
	[getNotificationsSuccess]: (state, { payload }) => {
		state.isLoading = false;
		state.notifications = payload.notifications;
		state.hasMore = payload.hasMore;
	},
	[getNotificationsFailure]: state => ({ ...state, isLoading: false }),

	// Get Notification Details
	[getNotificationByIdRequest]: state => {
		state.isLoading = true;
	},
	[getNotificationByIdSuccess]: (state, { payload: { notification } }) => ({
		...state,
		isLoading: false,
		notification,
	}),
	[getNotificationByIdFailure]: state => ({ ...state, isLoading: false }),

	[selectNotification]: (state, { payload }) => {
		state.notificationId = +payload;
	},
	[resetNotifications]: state => {
		state.page = 0;
		state.notifications = [];
		state.isLoading = false;
		state.hasMore = false;
		state.notificationId = 0;
	},

	[markAllNotificationsReadRequest]: state => ({ ...state, isLoading: true }),
	[markAllNotificationsReadSuccess]: state => ({ ...state, isLoading: false }),
	[markAllNotificationsReadFailure]: state => ({ ...state, isLoading: false }),
});

export const notificationsSelector = state => state.notifications.notifications;
export const notificationSelector = state => state.notifications.notification;
export const isNotificationsLoading = state => state.notifications.isLoading;

export default notificationReducer;
