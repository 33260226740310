import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CalendarIcon from '@material-ui/icons/PermContactCalendar';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import useStyles from '../../screens/style';
import NextStepButton from './NextStepButton';
import BackStepButton from './BackStepButton';
import FormErrorMessage from '../../../../common/forms/FormErrorMessage';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function AgeStep({ value, onChange, errors, handleSubmit, touched, changeStep }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<form className={classes.form} onSubmit={handleSubmit} noValidate>
			<div style={{ width: '100%' }}>
				<div style={{ fontSize: 20, textAlign: 'center', marginBottom: 20, color: theme.textDark }}>
					{t('your age')}
				</div>
				<Paper
					className={classes.root}
					elevation={0}
					style={{
						height: 48,
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
				>
					<IconButton className={classes.iconButton} aria-label="menu">
						<CalendarIcon />
					</IconButton>
					<InputBase
						type={'number'}
						name={'HEIMLICHAGE'}
						value={value}
						onChange={onChange}
						className={classes.inputWithIcon}
						placeholder={t('age')}
						inputProps={{ 'aria-label': t('age') }}
					/>
				</Paper>
				<FormErrorMessage name={'HEIMLICHAGE'} errors={errors} touched={touched} />
				<NextStepButton
					changeStep={
						typeof errors.HEIMLICHAGE === 'undefined' && value > 17 && (() => changeStep(2))
					}
				/>
				<BackStepButton changeStep={() => changeStep(0)} />
			</div>
		</form>
	);
}
