import React, { useEffect, useState } from 'react';
import { makeStyles, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { faqListSelector, fetchFAQ } from '../../../store/content/content.store';
import Loader from '../../../common/Loader';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from '../../../theme';
import DangerousHtml from '../../../common/DangerousHtml';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.mainBackgroundScreens,
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		flexBasis: '100%',
		flexShrink: 0,
		fontWeight: 'bold',
		color: theme.textDark,
	},
	answer: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.textLight,
	},
}));

export default function FAQScreen() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		dispatch(fetchFAQ());
	}, [dispatch]);

	const faq = useSelector(faqListSelector);

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			{faq.length ? (
				<>
					{faq.map((it, index) => {
						return (
							<Accordion
								className={classes.root}
								expanded={expanded === 'panel' + index}
								onChange={handleChange('panel' + index)}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon style={{ color: theme.headerIcon }} />}
									aria-controls="panel1bh-content"
									id="panel1bh-header"
								>
									<Typography className={classes.heading}>{it.question}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography className={classes.answer}>
										<DangerousHtml html={it?.answer} />
									</Typography>
								</AccordionDetails>
							</Accordion>
						);
					})}
				</>
			) : (
				<Loader />
			)}
		</>
	);
}
