import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import useStyles from '../../screens/style';
import NextStepButton from './NextStepButton';
import BackStepButton from './BackStepButton';
import MailIcon from '@material-ui/icons/Mail';
import FormErrorMessage from '../../../../common/forms/FormErrorMessage';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { settingsSelector } from '../../../../store/core/core.store';
import FormHelperText from "@material-ui/core/FormHelperText";

export default function EmailStep({
	value,
	agreement,
	onChange,
	errors,
	handleSubmit,
	touched,
	changeStep,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const settings = useSelector(settingsSelector);
	return (
		<form className={classes.form} onSubmit={handleSubmit} noValidate>
			<div style={{ width: '100%' }}>
				<div style={{ fontSize: 20, textAlign: 'center', marginBottom: 20, color: theme.textDark }}>
					{t('your email')}:
				</div>
				<Paper
					className={classes.root}
					elevation={0}
					style={{
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
				>
					<IconButton className={classes.iconButton} aria-label="menu">
						<MailIcon />
					</IconButton>
					<InputBase
						className={classes.inputWithIcon}
						placeholder={t('your email')}
						type={'mail'}
						name={'mail'}
						value={value}
						onChange={onChange}
						inputProps={{ 'aria-label': 'mail' }}
						error={!!errors.mail}
					/>
				</Paper>
				<FormHelperText error={true}>{t(errors?.mail)}</FormHelperText>
				<Paper
					className={`${classes.root} ${classes.checkboxPaper}`}
					elevation={0}
					style={{
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
				>
					<FormControlLabel
						className={classes.labelPlacementStart}
						classes={{
							label: classes.label,
						}}
						labelPlacement={'start'}
						control={
							<Checkbox
								name={'agreement'}
								onChange={onChange}
								value="checked"
								checked={agreement}
								color="green"
							/>
						}
						label={
							<Grid container>
								<Grid item xs={12} style={{ color: 'grey' }}>
									{t('i accept the')}
									<a
										className={classes.link}
										style={{ color: '#067afd' }}
										href={settings?.url?.terms}
										target="_blank"
										rel="noopener noreferrer"
									>
										{' ' + t('terms')}
									</a>
								</Grid>
								<Grid item xs={12} style={{ color: 'grey' }}>
									{t('and the')}
									<a
										className={classes.link}
										style={{ color: '#067afd' }}
										href={settings?.url?.gdpr}
										target="_blank"
										rel="noopener noreferrer"
									>
										{' '}
										{t('privacy policy')}
									</a>
								</Grid>
							</Grid>
						}
					/>
				</Paper>
				<FormErrorMessage name={'agreement'} errors={errors} touched={touched} />
				<NextStepButton text={t('get started')} />
				<BackStepButton changeStep={() => changeStep(3)} />
			</div>
		</form>
	);
}
