import React, { Fragment, useEffect, useMemo } from 'react';
import HeaderAppBar from '../../../shared/components/HeaderAppBar';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import PaymentForm from '../../../shared/components/payment/PaymentForm';
import Loader from '../../../../common/Loader';
import {
	fetchPackagePaymentTypes,
	fetchPackagesByPaymentType,
	getPackagePaymentUrl,
	packageIsLoadingSelector,
	packagePaymentTypeSelector,
	packagePaymentUrlSelector,
	packageSelector,
	resetPackagePaymentUrlSuccess,
} from '../../../../store/package/package.store';
import { openExternalLink } from '../../../../helpers';
import IcBuyPointsPackage from '../../../../assets/ic_buy_points_package.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function PackageBuyFormScreen({ packageId, paymentTypeName, rateCoins, onClose, isDirect }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const isLoading = useSelector(packageIsLoadingSelector);

	useEffect(() => {
		dispatch(fetchPackagePaymentTypes());
		dispatch(fetchPackagesByPaymentType(paymentTypeName));
		dispatch(getPackagePaymentUrl(packageId));
	}, [dispatch, paymentTypeName, packageId]);

	let paymentType = useSelector(packagePaymentTypeSelector(paymentTypeName));
	let plan = useSelector(packageSelector(Number(packageId)));
	const paymentUrl = useSelector(packagePaymentUrlSelector);

	if (!paymentType || !plan) {
		return <Loader />;
	}

	paymentType = {
		...paymentType,
		id: paymentType.name,
		primaryText: paymentType.displayname,
		image: paymentType.icon,
	};

	plan = {
		...plan,
		primaryText: `${plan.bezeichnung}`,
		secondaryText: `${plan.points} ${t('points')} - ${plan.amount} ${rateCoins}`,
		image: IcBuyPointsPackage,
	};

	const token = localStorage.getItem('token');

	const handleSubmit = () => {
		if (paymentType.id === 'debitcard') {
			openExternalLink(paymentUrl + '?token=' + token);
		} else {
			openExternalLink(paymentUrl);
		}
		dispatch(resetPackagePaymentUrlSuccess());
	};

	if(isDirect && paymentUrl) {
		handleSubmit();
		onClose();
	}

	return (
		<Fragment>
			<HeaderAppBar
				title={t('buy points now')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>
			<Box p={1} pt={2}>
				{isLoading || isDirect ? (
					<Loader />
				) : (
					<PaymentForm paymentType={paymentType} plan={plan} onSubmit={handleSubmit} />
				)}
			</Box>
		</Fragment>
	);
}
