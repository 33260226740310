import React, { useState } from 'react';
import { Box, FormLabel, Input, makeStyles, Typography } from '@material-ui/core';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import HeaderAppBar from '../../shared/components/HeaderAppBar';

import IcAnimator from '../../../assets/entertainer.png';
import { useFormik } from 'formik';
import UserService from '../../../services/UserService';
import Cloud from '../../../common/Cloud';
import PaperButton from '../../shared/components/PaperButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SubmitButton from '../../shared/components/SubmitButton';
import * as Yup from 'yup';
import FormErrorMessage from '../../../common/forms/FormErrorMessage';
import InlineInfo from '../../shared/components/InlineInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
	realname: Yup.string().label('Name').required(),
	skype: Yup.string().label('Skype').required(),
	reason: Yup.string().label('Text').required(),
	image: Yup.mixed().label('Image').required(),
});

const useStyles = makeStyles(theme => ({
	mediaBoxContainer: {
		display: 'flex',
		padding: 7,
	},
	mediaBoxItem: {
		position: 'relative',
		flex: 1,
		padding: '0 4px 0 4px',
	},
	label: {
		fontSize: '0.9rem',
	},
	labelPlacementStart: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		justifyContent: 'space-between',
	},
	captureButton: {
		position: 'absolute',
		left: '40%',
		bottom: 100,
		zIndex: 1,
	},
	mediaOptionItem: {
		position: 'relative',
	},
	mediaOptionIcon: {
		position: 'absolute',
		top: 7,
		left: 11,
		color: theme.palette.primary.user_red,
	},
	sendButton: {
		backgroundColor: '#29a646',
		'&:hover': {
			backgroundColor: 'green',
		},
	},
	inputStyle: {
		border: '1px solid grey',
		borderRadius: 5,
		paddingLeft: 5,
	},
	textDark: {
		color: theme.textDark,
	},
	textLight: {
		color: theme.textLight,
	},
}));

export function AnimatorRequestScreen({ onClose }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const [submitted, setSubmitted] = useState(false);
	const initialValues = {};

	const { handleChange, values, handleSubmit, errors, isSubmitting } = useFormik({
		validationSchema,
		initialValues,
		onSubmit: values => {
			UserService.verify(values).then(() => {
				setSubmitted(true);
			});
		},
	});

	const handleFileChange = event =>
		handleChange({ target: { value: event.target.files[0], name: 'image' } });

	return (
		<>
			<HeaderAppBar
				title={t('really entertainer')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>
			{submitted ? (
				<Box p={2}>
					<InlineInfo title={t('thank you') + '!'} text={t('thank you for request')} />
				</Box>
			) : (
				<>
					<Box p={2}>
						<Typography>
							<img
								src={IcAnimator}
								style={{
									float: 'right',
									width: 75,
									marginLeft: 15,
									marginBottom: 15,
								}}
								alt={'Animator'}
							/>
							<b className={classes.textDark}>{t('become now entertainer')}</b>
							<br />
							<span className={classes.textLight}>
								{t('to verify send this form')}
								<br />
								form...
							</span>
						</Typography>
					</Box>
					<Box p={1}>
						<form onSubmit={handleSubmit}>
							<Box mb={2}>
								<FormLabel className={classes.textDark}>Name</FormLabel>
								<Box className={classes.inputStyle}>
									<Input
										fullWidth
										placeholder={t('full name')}
										name={'realname'}
										value={values.realname}
										disableUnderline
										onChange={handleChange}
										className={classes.textLight}
									/>
								</Box>
								<FormErrorMessage name={'realname'} errors={errors} ignoreTouched={true} />
							</Box>

							<Box mb={2}>
								<FormLabel className={classes.textDark}>Skype</FormLabel>
								<Box className={classes.inputStyle}>
									<Input
										fullWidth
										placeholder={t('your skype')}
										name={'skype'}
										value={values.skype}
										disableUnderline
										onChange={handleChange}
										className={classes.textLight}
									/>
								</Box>
								<div
									style={{ fontSize: 10, marginBottom: 5, marginTop: 3 }}
									className={classes.textDark}
								>
									{t('to be entertainer skype is required')}
								</div>
								<FormErrorMessage name={'skype'} errors={errors} ignoreTouched={true} />
							</Box>

							<Box mb={2}>
								<FormLabel className={classes.textDark}>
									{t('why you want to be entertainer')}
								</FormLabel>
								<Box className={classes.inputStyle}>
									<Input
										fullWidth
										multiline
										rows={4}
										placeholder={'Text'}
										disableUnderline
										name={'reason'}
										onChange={handleChange}
										className={classes.textLight}
									/>
								</Box>
								<FormErrorMessage name={'reason'} errors={errors} ignoreTouched={true} />
							</Box>

							<Box mb={2} style={{ position: 'relative' }}>
								<FormLabel className={classes.textDark}>{t('id card')}</FormLabel>
								<Box component={Cloud} className={classes.mediaBoxContainer}>
									<PaperButton
										id={'photo'}
										name="pic"
										accept="image/*"
										label={t('add image')}
										icon={<PhotoCameraIcon />}
										onChange={event => handleFileChange(event)}
									/>
									<CheckCircleIcon
										fontSize={'small'}
										style={{
											position: 'absolute',
											top: 32,
											right: 11,
											color: 'red',
										}}
									/>
								</Box>
								<FormErrorMessage name={'image'} errors={errors} ignoreTouched={true} />
							</Box>

							<Box mb={2}>
								<SubmitButton style={classes.sendButton} disabled={isSubmitting}>
									{t('send you request')}
								</SubmitButton>
							</Box>
						</form>
					</Box>
				</>
			)}
		</>
	);
}
