import React from 'react';
import { useParams } from 'react-router-dom';
import BackNavigationIcon from '../components/BackNavigationIcon';
import HeaderAppBar from '../components/HeaderAppBar';
import { API_ENDPOINT } from '../../../services/BaseService';

export default function WebViewScreen() {
	const { title, pageName } = useParams();

	return (
		<div>
			<HeaderAppBar title={title} leftIcon={<BackNavigationIcon />} rightIcon={null} />

			<iframe
				title={title}
				src={`${API_ENDPOINT}/${pageName}?app=1`}
				frameBorder={0}
				style={{
					marginTop: '-56px',
					width: '100%',
					height: 'calc(100vh - 56px)',
					display: 'block',
				}}
			/>
		</div>
	);
}
