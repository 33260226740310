import { createAction, createReducer } from 'redux-starter-kit';
import ChatService from '../../services/ChatService';
import { blockedConversationIdsSelector } from '../message/message.store';
import NotificationService from '../../services/NotificationService';

export const getConversationsRequest = createAction('GET_CONVERSATIONS_REQUEST');
export const getConversationsFailure = createAction('GET_CONVERSATIONS_FAILURE');
export const getConversationsSuccess = createAction('GET_CONVERSATIONS_SUCCESS');

export const getConversationsFirstPageSuccess = createAction(
	'GET_CONVERSATIONS_FIRST_PAGE_SUCCESS',
);

export const setUnreadConversations = createAction('SET_UNREAD_CONVERSATIONS');
export const setUnreadNotifications = createAction('SET_UNREAD_NOTIFICATIONS');

const initialState = {
	page: 0,
	isLoading: false,
	conversations: [],
	hasMore: false,
	unreadConversations: 0,
	unreadNotifications: 0,
};

export const getConversations =
	({ page }) =>
	async dispatch => {
		dispatch(getConversationsRequest());

		ChatService.getConversations({ page })
			.then(({ data }) => {
				dispatch(
					getConversationsSuccess({
						conversations: data.data,
						page,
						hasMore: data.to < data.total,
					}),
				);
			})
			.catch(() => {
				dispatch(getConversationsFailure());
			});
	};

export const getConversationsFirstPage = () => async dispatch => {
	dispatch(getConversationsRequest());

	ChatService.getConversations({ page: 1 })
		.then(({ data }) => {
			dispatch(
				getConversationsFirstPageSuccess({
					conversations: data.data,
					page: 1,
					hasMore: data.to < data.total,
				}),
			);
		})
		.catch(() => {
			dispatch(getConversationsFailure());
		});
};

export const loadUnreadConversationsCount = () => dispatch => {
	ChatService.getUnreadConversationsCount().then(({ data }) => {
		dispatch(setUnreadConversations(data));
	});
};

export const loadUnreadNotificationsCount = () => dispatch => {
	NotificationService.getUnreadsCount().then(({ data }) => {
		dispatch(setUnreadNotifications(data.unseenmessages));
	});
};

const chatReducer = createReducer(initialState, {
	// Get Conversations
	[getConversationsRequest]: state => {
		state.isLoading = true;
	},
	[getConversationsFailure]: state => {
		state.isLoading = false;
	},
	[getConversationsSuccess]: (state, { payload }) => {
		state.isLoading = false;
		state.conversations = [...state.conversations, ...payload.conversations];
		state.page = payload.page;
		state.hasMore = payload.hasMore;
	},
	[getConversationsFirstPageSuccess]: (state, { payload }) => {
		state.isLoading = false;
		state.conversations = payload.conversations;
		state.page = payload.page;
		state.hasMore = payload.hasMore;
	},
	//set unread conversations
	[setUnreadConversations]: (state, { payload }) => {
		state.unreadConversations = payload.unreadconversations;
	},
	//set unread notifications
	[setUnreadNotifications]: (state, { payload }) => {
		state.unreadNotifications = payload;
	},
});

const getState = state => state.chat;

const blockedConversationFilter = (conversation, blockedUserIds, blockedConversationIds) => {
	return (
		!blockedUserIds.includes(conversation.sender) &&
		!blockedUserIds.includes(conversation.recipient) &&
		!blockedConversationIds.includes(conversation.id)
	);
};

export const conversationsSelector = state => {
	const blockedConversationIds = blockedConversationIdsSelector(state);
	return getState(state).conversations.filter(conversation =>
		blockedConversationFilter(conversation, state.user.blockedUserIds, blockedConversationIds),
	);
};
export const unreadConversationsSelector = state => getState(state).unreadConversations;
export const unreadNotificationsSelector = state => getState(state).unreadNotifications;

export default chatReducer;
