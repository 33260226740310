import React, {useCallback, useEffect, useRef} from 'react';
import styles from './NotificationStack.module.css';
import { useSwipeToDismiss } from 'react-swipe-to-dismiss';

const NotificationStackItem = ({ icon, title, body, action, onRemove: handleRemove }) => {
	const handleClick = useCallback(() => {
		action();
		handleRemove();
	}, [handleRemove, action]);

	const elementRef = useRef();
	useEffect(()=>{
		setTimeout(handleRemove, 10000)
	},[])
	const swipeToDismissProps = useSwipeToDismiss(elementRef, handleRemove);

	return (
		<div
			ref={elementRef}
			className={styles.NotificationStackItem}
			onClick={handleClick}
			{...swipeToDismissProps}
		>
			<div className={styles.NotificationStackItemInfo}>
				<div className={styles.NotificationStackItemTitle}>{title}</div>
				<div className={styles.NotificationStackItemBody}>{body}</div>
			</div>
			<div
				className={styles.NotificationStackItemAvatar}
				style={{ backgroundImage: `url(${icon})` }}
			/>
		</div>
	);
};

export default function NotificationStack({ notifications, removeNotification }) {
	return (
		<>
			{notifications ? (
				<div className={styles.NotificationStack}>
					{notifications.map((notification, index) => (
						<NotificationStackItem
							key={`${index}-${notification.title}`}
							{...notification}
							onRemove={() => removeNotification(notification.id)}
						/>
					))}
				</div>
			) : null}
		</>
	);
}
