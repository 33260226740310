import React from 'react';
import ToggleButtons from '../../../shared/components/ToggleButtons';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function GenderStep({ setFieldValue, values, changeStep }) {
	const { t } = useTranslation();

	return (
		<div style={{ width: '100%' }}>
			<div style={{ fontSize: 20, textAlign: 'center', color: theme.textDark }}>
				{t('choose gender')}
			</div>
			<ToggleButtons
				setValue={setFieldValue}
				value={values}
				changeStep={values && (() => changeStep(1))}
				btnOne={{ backgroundColor: '#44a4d1', marginTop: 20 }}
				btnTwo={{ backgroundColor: '#ae252c' }}
			/>
		</div>
	);
}
