import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: '8px 16px 8px 16px',
	},
	sliderRoot: {
		color: theme.textLight,
		top: 18,
	},
	sliderTitle: {
		marginBottom: '8px',
		color: theme.textDark,
	},
	valueLabel: {
		left: 'calc(-50% - 3px)',
		top: -22,
		'& *': {
			background: 'transparent',
			color: theme.textLight,
		},
	},
}));

function valuetext(value) {
	return `${value}°C`;
}

export default function RangeSlider({ min, max, value, onChange }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleChange = (event, newValue) => {
		onChange(newValue);
	};

	return (
		<div className={classes.root}>
			<Typography
				className={classes.sliderTitle}
				variant={'subtitle2'}
				id="range-slider"
				gutterBottom
			>
				{t('age limit')}
			</Typography>
			<Slider
				classes={{
					root: classes.sliderRoot,
					valueLabel: classes.valueLabel,
					markLabel: classes.thumbStyle,
				}}
				value={value}
				onChange={handleChange}
				valueLabelDisplay="on"
				aria-labelledby="range-slider"
				getAriaValueText={valuetext}
				min={min}
				max={max}
			/>
		</div>
	);
}
