import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	root: {
		width: '100%',
		height: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	progress: {
		color: theme.palette.primary.user_red,
	},
}));

export default function InlineLoader({ size = 30 }) {
	const classes = useStyle();

	return (
		<div className={classes.root}>
			<CircularProgress className={classes.progress} size={size} />
		</div>
	);
}
