import React, { Fragment, useState } from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import ReportForm from '../../report/components/ReportForm';
import { REPORT_REASON_TYPES } from '../../../constants';
import ReportService from '../../../services/ReportService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import ReportEntrySuccessScreen from './ReportEntrySuccessScreen';
import { makeStyles, Box, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	root: {
		paddingTop: 82,
		backgroundColor: theme.mainBackgroundScreens,
		height: '100%',
		overflowY: 'scroll',
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '90%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function ReportEntryScreen({ reportId, onClose }) {
	const entryId = reportId;
	const classes = useStyle();
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);

	const handleSubmit = values => {
		ReportService.reportEntry({ entryId }).then(() => handleOpen());
	};
	const { t } = useTranslation();

	return (
		<Fragment>
			<HeaderAppBar
				positionHeader={'fixed'}
				title={t('report post')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>
			<Box p={1} className={classes.root}>
				<ReportForm
					initialValues={{
						id: `ID ${entryId}`,
						subject: REPORT_REASON_TYPES[0],
					}}
					onSubmit={handleSubmit}
					withSubject={true}
					withMessage={true}
				/>
			</Box>
			<Modal
				open={open}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ReportEntrySuccessScreen onClose={onClose} />
				</Box>
			</Modal>
		</Fragment>
	);
}
