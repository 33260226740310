import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	root: {
		borderRadius: '5px',
		backgroundColor: theme.mainBackgroundScreens,
		padding: 7,
		paddingTop: 0,
		border: '2px solid' + theme.borderContainers,
	},
}));

export default function Cloud({ children, style, className, rootRef }) {
	const classes = useStyle();

	return (
		<div ref={rootRef} className={classes.root + ' ' + className} style={style}>
			{children}
		</div>
	);
}
