import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import chatBackgroundWhite from './assets/chat_background.png';
import chatBackgroundDark from './assets/chat_background_dark.png';
import Logo from './assets/logoText.png';
import LogoDark from './assets/logo_white.png';
import LogoBesecret from './assets/besecret.png';
import LogoDarkBesecret from './assets/besecret_dark.png';
import LogoSmall from './assets/logo.png';
import LogoDarkSmall from './assets/psst_white.png';
import store from './store/store';
import { ConfigCountry } from './helpers/configCountry';

const { darkMode } = store.getState()?.theme;

const theme = Number(darkMode)
	? createTheme({
			overrides: {
				MuiOutlinedInput: {
					root: {
						"& $input": {
							color: 'rgb(150,150,150)'
						},
						"& .MuiSvgIcon-root": {
							color: 'rgb(150,150,150)'
						},
					}
				},
				MuiSwitch: {
					switchBase: {
						// Controls default (unchecked) color for the thumb
						color: '#ccc',
					},
					colorSecondary: {
						'&$checked': {
							// Controls checked color for the thumb
							color: 'rgb(82,183,238)',
						},
					},
					track: {
						// Controls default (unchecked) color for the track
						opacity: 0.2,
						backgroundColor: 'rgb(82,183,238)',
						'$checked$checked + &': {
							// Controls checked color for the track
							opacity: 0.7,
							backgroundColor: 'rgb(82,183,238)',
						},
					},
				},
				MuiSvgIcon: {
					colorPrimary: {
						color: ['#625b5b', '!important'],
					},
					colorSecondary: {
						color: ['#d5d7d8', '!important'],
					},
				},
			},
			mainBlue: 'rgb(82,183,238)',
			age: '#FFF',
			headerIcon: '#FFF',
			commentCount: '#FFF',
			white: '#FFF',
			headerBackground: 'rgb(17,20,26)',
			mainBackgroundScreens: 'rgb(34,39,48)',
			feedItemText: '#FFF',
			chatBackground: chatBackgroundDark,
			blackWhite: '#FFF',
			borderContainers: '#000',
			Logo: ConfigCountry.international ? LogoDarkBesecret : LogoDark,
			smallLogo: LogoDarkSmall,
			textDark: '#FFF',
			textLight: 'rgb(150,150,150)',
			mediaButton: 'rgb(17,20,26)',
			stobernBackground: '#FFF',
			modalColor: 'rgba(255,255,255,0.3)',
			userName: '#FFF',
			headerShadow: 'rgba(255,255,255,0.10)',
			palette: {
				primary: {
					main: '#90232B',
					colorPrimary: '#ffffff',
					colorPrimaryDark: '#272d37',
					colorAccent: '#90232B',
					red: '#ab1c27',
					colorBlue: '#1582fe',
					colorLightGrey: '#8b8b8b',
					colorLightGreyText: '#ebcacc',
					login_button: '#8e232c',
					splitter_line: '#e8e8ea',
					comment_split: '#a09c9f',
					btn_txt_color_liked: '#949494',
					color_green_dark: '#FF0087',
					email_not_confirmed_color: '#f80001',
					darker_gray_text: '#767676',
					points_green: '#12710d',
					points_red: '#ef000e',
					user_red: '#A42830',
					dark_blue: '#2D6CE8',
					points_gold: '#FACE6C',
					rangeSlider: '#343841',
				},
				typography: {
					main: '#000',
					textSecondary: '#1582fe',
				},
				textSecondary: {
					main: '#1582fe',
				},
				chatBubble: {
					client: '#8ecf88',
					response: '#ffffff',
				},
				secondary: {
					main: '#272d37',
				},
				error: {
					main: red.A400,
				},
				background: {
					default: '#FFF',
					dark: '#272d37',
					lightDark: '#555963',
					communityBackground: '#EFEFEF',
					privatePageBackground: '#EFEFEF',
					partner_link_background: '#e1e1e1',
					colorProfileBackground: '#aaacb0',
					login_button_background_checked: '#CC9091',
					post_filter_button_background: '#b00000',
					post_filter_background_checked: '#b71c1c',
					login_button_background: '#CC3b40',
					login_background: '#1B212B',
				},
			},
	  })
	: createTheme({
			overrides: {
				MuiOutlinedInput: {
					root: {
						"& $input": {
							color: 'rgb(85, 85, 85)'
						},
						"& .MuiSvgIcon-root": {
							color: 'rgb(85, 85, 85)'
						},
					}
				},
				MuiSwitch: {
					switchBase: {
						// Controls default (unchecked) color for the thumb
						color: '#ccc',
					},
					colorSecondary: {
						'&$checked': {
							// Controls checked color for the thumb
							color: '#017afd',
						},
					},
					track: {
						// Controls default (unchecked) color for the track
						opacity: 0.2,
						backgroundColor: '#017afd',
						'$checked$checked + &': {
							// Controls checked color for the track
							opacity: 0.7,
							backgroundColor: '#017afd',
						},
					},
				},
			},
			mainBlue: 'rgb(82,183,238)',
			age: '#404040',
			headerIcon: '#3b3b3b',
			commentCount: '#7f7f7f',
			white: '#FFF',
			headerBackground: '#FFF',
			mainBackgroundScreens: '#FFF',
			feedItemText: '#404040',
			chatBackground: chatBackgroundWhite,
			blackWhite: '#000',
			borderContainers: '#ededed',
			Logo: ConfigCountry.international ? LogoBesecret : Logo,
			smallLogo: LogoSmall,
			textDark: '#000',
			textLight: 'rgb(85, 85, 85)',
			mediaButton: '',
			stobernBackground: 'transparent',
			modalColor: 'rgba(0,0,0,0.3)',
			userName: '#515151',
			headerShadow: 'rgba(0,0,0,0.10)',
			palette: {
				primary: {
					main: '#90232B',
					colorPrimary: '#ffffff',
					colorPrimaryDark: '#272d37',
					colorAccent: '#90232B',
					red: '#ab1c27',
					colorBlue: '#1582fe',
					colorLightGrey: '#8b8b8b',
					colorLightGreyText: '#ebcacc',
					login_button: '#8e232c',
					splitter_line: '#e8e8ea',
					comment_split: '#a09c9f',
					btn_txt_color_liked: '#949494',
					color_green_dark: '#FF0087',
					email_not_confirmed_color: '#f80001',
					darker_gray_text: '#767676',
					points_green: '#12710d',
					points_red: '#ef000e',
					user_red: '#A42830',
					dark_blue: '#2D6CE8',
					points_gold: '#FACE6C',
					rangeSlider: '#343841',
				},
				typography: {
					main: '#000',
					textSecondary: '#1582fe',
				},
				textSecondary: {
					main: '#1582fe',
				},
				chatBubble: {
					client: '#8ecf88',
					response: '#ffffff',
				},
				secondary: {
					main: '#272d37',
				},
				error: {
					main: red.A400,
				},
				background: {
					default: '#FFF',
					dark: '#272d37',
					lightDark: '#555963',
					communityBackground: '#EFEFEF',
					privatePageBackground: '#EFEFEF',
					partner_link_background: '#e1e1e1',
					colorProfileBackground: '#aaacb0',
					login_button_background_checked: '#CC9091',
					post_filter_button_background: '#b00000',
					post_filter_background_checked: '#b71c1c',
					login_button_background: '#CC3b40',
					login_background: '#1B212B',
				},
			},
	  });

export default theme;
