import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import i18n from 'i18next';
import useLocalDate from '../../../hooks/useLocalDate';
import {ConfigCountry} from "../../../helpers/configCountry";

const useStyle = makeStyles(theme => ({
	root: {
		display: 'flex',
		padding: '10px 0',
		flexDirection: 'column',
	},
	head: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	nickname: {
		color: theme.mainBlue,
		fontSize: 16,
		fontWeight: 'bold',
		cursor: 'pointer',
		padding: '3px 5px 3px 0',
	},
	commentText: {
		fontWeight: 'bold',
		color: theme.textDark,
	},
}));

export default function CommentItem({
	comment: { nickname, comment, updated_at },
	onClick: handleClick,
}) {
	const classes = useStyle();
	const updatedAt = useLocalDate({ date: updated_at, fromNow: true, lang: i18n.language });
	return (
		<Fragment>
			<div className={classes.root} onClick={handleClick}>
				<div className={classes.head}>
					<div className={classes.nickname}>{nickname}</div>
					{!ConfigCountry.international ? <div className={classes.commentText} style={{fontSize: 11, fontWeight: 'normal'}}>
						{updatedAt}
					</div> : null}
				</div>
				<div className={classes.commentText} style={{ fontSize: 13, fontWeight: 'normal' }}>
					{comment}
				</div>
			</div>
			<Divider />
		</Fragment>
	);
}
