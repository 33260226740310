import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector, isUserLoggingSelector } from '../../../store/profile/profile.store';
import Loader from '../../../common/Loader';
import { nextLocationSelector, setRouteHistory } from '../../../store/core/core.store';

function PrivateRoute({ component: Component, ...rest }) {
	const dispatch = useDispatch();
	const currentUser = useSelector(currentUserSelector);
	const isUserLogging = useSelector(isUserLoggingSelector);
	const nextLocation = useSelector(nextLocationSelector);

	const history = useHistory();
	history.listen(next => {
		dispatch(
			setRouteHistory({
				lastLocation: nextLocation,
				nextLocation: next,
			}),
		);
	});

	if (isUserLogging) {
		return (
			<Route>
				<Loader />
			</Route>
		);
	}

	const permitted = currentUser;

	return (
		<Route
			{...rest}
			render={props =>
				permitted ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/auth/guest&step=2',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
}

export default PrivateRoute;
