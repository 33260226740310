import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { resetEntries } from '../../../store/entry/entry.store';
import { useDispatch } from 'react-redux';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BackNavigationIcon({ ...restProps }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const isInternalNavigation = history.location.state === 'internal';

	const backAddFunction = () => {
		restProps.removeEntry && dispatch(resetEntries());
		history.goBack();
	};
	return restProps.tabIndex ? (
		<div
			onClick={() =>
				history.push({
					pathname: '/feed',
					state: {
						from: { ...restProps },
					},
				})
			}
			{...restProps}
		>
			<FontAwesomeIcon
				color={theme.headerIcon}
				icon={faLongArrowAltLeft}
				style={{ fontSize: 25 }}
			/>
		</div>
	) : (
		<div
			onClick={() => (isInternalNavigation ? history.goBack() : history.push('/feed'))}
			{...restProps}
		>
			<FontAwesomeIcon
				color={theme.headerIcon}
				icon={faLongArrowAltLeft}
				style={{ fontSize: 25 }}
			/>
		</div>
	);

	//     return <ArrowBackIcon color={'action'} onClick={() => backAddFunction()} {...restProps}/>;
}
