import BaseService from './BaseService';

class ContentService extends BaseService {
	constructor() {
		super('');
	}

	getFAQList() {
		return this.get('/faq');
	}

	sendContactMail({ text, image }) {
		const form = new FormData();

		image && form.append('image', image);
		form.append('message', text);

		return this.post('/contact', form);
	}
}

export default new ContentService();
