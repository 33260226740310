import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Typography } from '@material-ui/core';
import DangerousHtml from '../../../common/DangerousHtml';
import Cloud from '../../../common/Cloud';
import BottomToast from '../../../common/BottomToast';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector } from '../../../store/profile/profile.store';
import { fetchSettings, settingsSelector } from '../../../store/core/core.store';
import Loader from '../../../common/Loader';
import { copyToClipboard } from '../../../helpers';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { ConfigCountry } from '../../../helpers/configCountry';

const useStyle = makeStyles(theme => ({
	text: {
		fontSize: 14,
		color: theme.textLight,
	},
	link: {
		backgroundColor: '#e0e0e0',
		overflowWrap: 'break-word',
		padding: 2,
		fontWeight: 'bold',
		color: '#555',
	},
	copyButton: {
		color: theme.palette.primary.points_green,
		fontWeight: 'bold',
		cursor: 'pointer',
	},
	linkLabel: {
		color: theme.textDark,
		fontWeight: 'bold',
	},
}));

export default function PartnerLinkScreen() {
	const classes = useStyle();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(fetchSettings());
	}, [dispatch]);

	const settings = useSelector(settingsSelector);
	const isSettingsLoading = useSelector(state => state.core.isSettingsLoading);

	const profile = useSelector(currentUserSelector);
	const points = settings.coins_per_message;
	const partnerLink = profile.affiliatelink;

	const [copied, setCopied] = useState(false);

	const copyPartnerLink = () => {
		copyToClipboard(partnerLink);
		setCopied(true);
	};
	const firstText = points =>
		`<b>${t('your affiliate link')}</b>\\n\\n${t(
			'earn points as affiliate',
		)} <b><font color='#FF34A023'>${50}</font></b> ${t('points')}.</string>`;

	const secondText = (points, nickname) => `<b>${t('share posts')}</b>
\\n\\n${t('you can share posts')} <b><font color='#FF34A023'>${50}</font></b>
${t('earn with shared posts')}\\n<b>https://${ConfigCountry.domain}/${
		ConfigCountry.international ? 'entry' : 'beitrag'
	}/2237/${nickname}</b>\\n${t('example')} ${nickname} ${t('your id')}
`;
	return (
		<div style={{ backgroundColor: theme.mainBackgroundScreens }}>
			{isSettingsLoading ? <Loader /> : null}
			<Fragment>
				<Box p={2}>
					<Typography className={classes.text} component={'div'}>
						<DangerousHtml html={firstText(points)} />
					</Typography>

					<Box py={2}>
						<Cloud style={{ backgroundColor: theme.mainBackgroundScreens }}>
							<Box p={1}>
								<Box pb={1}>
									<div className={classes.linkLabel}>{t('your link')}</div>
								</Box>
								<div className={classes.link}>{partnerLink}</div>
								<Box pt={1} display={'flex'} justifyContent={'flex-end'}>
									<div className={classes.copyButton} onClick={copyPartnerLink}>
										{t('copy')}
									</div>
								</Box>
							</Box>
						</Cloud>
					</Box>

					<Typography className={classes.text} component={'div'}>
						<DangerousHtml html={secondText(points, profile.nickname)} />
					</Typography>
				</Box>

				<BottomToast open={copied} onClose={() => setCopied(false)}>
					{t('copied')}
				</BottomToast>
			</Fragment>
		</div>
	);
}
