import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	root: {
		backgroundColor: 'black',
		borderRadius: '50%',
	},
}));

export default function PaymentListItemIcon({ src, alt, size = 50 }) {
	const classes = useStyle();
	return <img src={src} className={classes.root} alt={alt} style={{ width: size, height: size }} />;
}
