import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/AccountCircle';
import PassIcon from '@material-ui/icons/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../store/profile/profile.store';
import { useFormik } from 'formik';
import useStyles from '../../screens/style';
import * as Yup from 'yup';
import FormErrorMessage from '../../../../common/forms/FormErrorMessage';
import FormHelperText from '@material-ui/core/FormHelperText';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function LoginStep({ changeStep }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const logInFail = useSelector(state => state.profile.logInFail);
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		mail: Yup.string().label('E-Mail').required(t('please enter email')),
		pass: Yup.string().label(t('password')).required(t('please enter password')),
	});

	const {
		handleChange,
		values,
		handleSubmit = (values, { setStatus, setErrors }) => {
			return this.props
				.onSubmit(values)
				.then(() => {
					setStatus('User was updated successfully.');
				})
				.catch(err => {
					setErrors(false);
				});
		},
		errors,
		touched,
	} = useFormik({
		validationSchema,
		initialValues: {
			mail: '',
			pass: '',
		},
		onSubmit: ({ mail, pass }) => dispatch(login(mail, pass)),
	});

	const user = useSelector(state => state.profile.user);
	const isLogging = useSelector(state => state.profile.isLogging);

	useEffect(() => {
		if (!isLogging && user) {
			history.replace('/feed');
		}
	}, [isLogging, user, history]);

	return (
		<div style={{ width: '100%' }}>
			<form onSubmit={handleSubmit}>
				<div
					style={{
						fontSize: 20,
						textAlign: 'center',
						marginBottom: 20,
						width: '100%',
						color: theme.textDark,
					}}
				>
					{t('get login')}:
				</div>
				<Paper
					className={classes.root}
					elevation={0}
					style={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
				>
					<IconButton className={classes.iconButton} aria-label="menu">
						<MenuIcon />
					</IconButton>
					<InputBase
						className={classes.inputWithIcon}
						name={'mail'}
						type={'mail'}
						value={values.mail}
						onChange={handleChange}
						placeholder={t('your email and name')}
						inputProps={{ 'aria-label': t('nickname') }}
					/>
				</Paper>
				<FormErrorMessage name={'mail'} errors={errors} touched={touched} />

				<Paper
					elevation={0}
					style={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
				>
					<IconButton className={classes.iconButton} aria-label="menu">
						<PassIcon />
					</IconButton>
					<InputBase
						className={classes.inputWithIcon}
						name={'pass'}
						type={'password'}
						value={values.pass}
						onChange={handleChange}
						placeholder={t('your password')}
						inputProps={{ 'aria-label': t('nickname') }}
					/>
				</Paper>
				<FormErrorMessage name={'pass'} errors={t(errors?.pass)} touched={touched} />

				{Object.values(errors).length > 0 ? null : logInFail ? (
					<FormHelperText error={true}>{t('wrong login')}</FormHelperText>
				) : null}

				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					style={{
						width: '100%',
						backgroundColor: '#067afd',
						color: '#FFF',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: 44,
						marginTop: 15,
						borderRadius: 30,
						textTransform: 'none',
						fontSize: 18,
					}}
				>
					{t('login')}
				</Button>
				<div
					onClick={() => changeStep(6)}
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginTop: 15,
					}}
				>
					<span
						style={{
							fontSize: 15,
							color: '#44a4d1',
							cursor: 'pointer',
						}}
					>
						{t('forget password')}
					</span>
				</div>
			</form>
		</div>
	);
}
