import { createAction, createReducer } from 'redux-starter-kit';
import { createNewMessage } from '../../utils/messageUtils';
import ChatService from '../../services/ChatService';
import { openMembershipSuggestion } from '../presents/presents.store';

export const MESSAGE_SENDER = {
	CLIENT: 'client',
	RESPONSE: 'response',
};

export const MESSAGES_TYPES = {
	TEXT: 'text',
	SNIPPET: {
		LINK: 'snippet',
	},
	CUSTOM_COMPONENT: 'component',
};
export const MESSAGE_BOX_SCROLL_DURATION = 400;

export const addUserMessage = createAction('ADD_USER_MESSAGE');
export const addMessage = createAction('ADD_MESSAGE');
export const resetMessages = createAction('RESET_MESSAGES');

export const getMessagesByConversationIdRequest = createAction(
	'GET_MESSAGES_BY_CONVERSATION_ID_REQUEST',
);
export const getMessagesByConversationIdFailure = createAction(
	'GET_MESSAGES_BY_CONVERSATION_ID_FAILURE',
);
export const getMessagesByConversationIdSuccess = createAction(
	'GET_MESSAGES_BY_CONVERSATION_ID_SUCCESS',
);

export const getOnlyFirstPageMessagesSuccess = createAction('GET_ONLY_FIRST_PAGE_MESSAGES_SUCCESS');

export const getConversationByIdRequest = createAction('GET_CONVERSATION_BY_ID_REQUEST');
export const getConversationByIdFailure = createAction('GET_CONVERSATION_BY_ID_FAILURE');
export const getConversationByIdSuccess = createAction('GET_CONVERSATION_BY_ID_SUCCESS');

export const acceptConversationsRequest = createAction('ACCEPT_CONVERSATIONS_REQUEST');
export const acceptConversationsFailure = createAction('ACCEPT_CONVERSATIONS_FAILURE');
export const acceptConversationsSuccess = createAction('ACCEPT_CONVERSATIONS_SUCCESS');

export const declineConversationRequest = createAction('DECLINE_CONVERSATION_REQUEST');
export const declineConversationFailure = createAction('DECLINE_CONVERSATION_FAILURE');
export const declineConversationSuccess = createAction('DECLINE_CONVERSATION_SUCCESS');

export const loadInitialBlockedConversationIdsRequest = createAction(
	'LOAD_INITIAL_BLOCK_CONVERSATION_IDS_REQUEST',
);
export const loadInitialBlockedConversationIdsFailure = createAction(
	'LOAD_INITIAL_BLOCK_CONVERSATION_IDS_FAILURE',
);
export const loadInitialBlockedConversationIdsSuccess = createAction(
	'LOAD_INITIAL_BLOCK_CONVERSATION_IDS_SUCCESS',
);

export const blockConversationRequest = createAction('BLOCK_CONVERSATION_REQUEST');
export const blockConversationFailure = createAction('BLOCK_CONVERSATION_FAILURE');
export const blockConversationSuccess = createAction('BLOCK_CONVERSATION_SUCCESS');

export const payMessageIdRequest = createAction('PAY_MESSAGE_ID_REQUEST');
export const payMessageRequest = createAction('PAY_MESSAGE_REQUEST');
export const payMessageFailure = createAction('PAY_MESSAGE_FAILURE');
export const payMessageSuccess = createAction('PAY_MESSAGE_SUCCESS');

const initialState = {
	page: 0,
	isLoading: false,
	isLoadingPayMessage: false,
	messages: [],
	hasMore: false,
	conversationDetails: null,
	blockedConversationIds: [],
	isBlocking: false,
	paidStatus: false,
	payMessageId: null,
};

export const handleMessageReceipt =
	({
		message,
		conversationId,
		userId,
		createdAt,
		present,
		points,
		attachment,
		is_paid,
		coins,
		pay_message,
	}) =>
	(dispatch, getState) => {
		const { conversationDetails } = getState().message;
		if (conversationDetails && conversationDetails.id === +conversationId) {
			dispatch(
				addMessage({
					message,
					conversationId,
					userId,
					createdAt,
					present,
					points,
					attachment,
					is_paid,
					coins,
					pay_message,
				}),
			);
		}
	};

export const sendMessage = message => dispatch => {
	ChatService.sendMessage(message.conversationId, { message: message.message }).catch(() => {
		// window.hideApiError();
		dispatch(openMembershipSuggestion());
	});
};

export const sendAttachment =
	({ conversationId, image }) =>
	dispatch => {
		const scheduleLatestMessagesFetching = milliseconds =>
			setTimeout(() => dispatch(getOnlyFirsPageMessages({ conversationId })), milliseconds);

		ChatService.sendAttachment(conversationId, image).then(() => {
			scheduleLatestMessagesFetching(0);
			scheduleLatestMessagesFetching(1000);
			scheduleLatestMessagesFetching(2000);
			scheduleLatestMessagesFetching(3000);
		});
	};

export const markAsSeen = conversationId => async () => {
	try {
		await ChatService.markConversationAsSeen(conversationId);
		// dispatch(addUserMessage({ ...message, createdAt: response.data.data }));
	} catch (e) {}
};

export const getMessages =
	({ conversationId, page }) =>
	async (dispatch, state) => {
		dispatch(getMessagesByConversationIdRequest());

		ChatService.getMessages({ conversationId, page })
			.then(({ data }) => {
				const currentState = state();
				let newMessages = data.data;
				const currentMessages = currentState.message.messages;
				newMessages = newMessages.filter(item => currentMessages.every(el => item.id !== el.id));
				dispatch(
					getMessagesByConversationIdSuccess({
						messages: newMessages,
						page,
						hasMore: data.to < data.total,
					}),
				);
			})
			.catch((e) => {
				console.log('error',e);
				dispatch(getMessagesByConversationIdFailure());
			});
	};

export const getOnlyFirsPageMessages =
	({ conversationId, page = 1 }) =>
	async dispatch => {
		ChatService.getMessages({ conversationId, page })
			.then(({ data }) => {
				let newMessages = data.data;
				dispatch(
					getOnlyFirstPageMessagesSuccess({
						messages: newMessages,
						page,
						hasMore: data.to < data.total,
					}),
				);
			})
			.catch(() => {
				dispatch(getMessagesByConversationIdFailure());
			});
	};

export const getConversationById = id => async dispatch => {
	dispatch(getConversationByIdRequest());

	ChatService.getConversationById(id)
		.then(({ data }) => {
			dispatch(getConversationByIdSuccess(data));
		})
		.catch(() => {
			dispatch(getConversationByIdFailure());
		});
};

export const acceptConversation = convId => async dispatch => {
	dispatch(acceptConversationsRequest());
	try {
		const res = await ChatService.acceptConversation(convId);
		dispatch(acceptConversationsSuccess());
	} catch (e) {
		console.warn(e);
		dispatch(acceptConversationsFailure());
	}
};

export const declineConversation = convId => async dispatch => {
	dispatch(declineConversationRequest());
	try {
		const res = await ChatService.declineConversation(convId);
		dispatch(blockConversation({ id: convId }));
		dispatch(declineConversationSuccess());
	} catch (e) {
		console.warn(e);
		dispatch(declineConversationFailure());
	}
};

export const blockConversation =
	({ id }) =>
	dispatch => {
		dispatch(blockConversationRequest());
		ChatService.block(id)
			.then(() => {
				dispatch(blockConversationSuccess({ id }));
			})
			.catch(() => {
				dispatch(blockConversationFailure());
			});
	};

export const loadInitialBlockedConversationIds = () => dispatch => {
	dispatch(loadInitialBlockedConversationIdsRequest());
	ChatService.getBlockedConversationIds()
		.then(ids => {
			dispatch(loadInitialBlockedConversationIdsSuccess({ ids }));
		})
		.catch(() => {
			dispatch(loadInitialBlockedConversationIdsFailure());
		});
};

export const paidMessage = messageId => dispatch => {
	dispatch(payMessageIdRequest(messageId));
	dispatch(payMessageRequest());
	ChatService.payMessage(messageId)
		.then(data => {
			dispatch(payMessageSuccess({ data, messageId }));
		})
		.catch(() => {
			dispatch(payMessageFailure());
		});
};

const messageReducer = createReducer(initialState, {
	[addUserMessage]: (state, { payload }) => {
		state.messages.push(createNewMessage(payload));
	},
	[addMessage]: (state, { payload }) => {
		state.messages.push(createNewMessage(payload));
	},
	[resetMessages]: state => {
		state.page = 0;
		state.isLoading = false;
		state.messages = [];
		state.hasMore = false;
		state.conversationDetails = null;
	},

	// Get Messages by Conversation id
	[getMessagesByConversationIdRequest]: state => {
		state.isLoading = true;
	},
	[getMessagesByConversationIdSuccess]: (state, { payload }) => {
		const reversedMessages = payload.messages.reverse();
		state.isLoading = false;
		state.messages = [...reversedMessages, ...state.messages];
		state.page = payload.page;
		state.hasMore = payload.hasMore;
	},
	[getOnlyFirstPageMessagesSuccess]: (state, { payload }) => {
		const reversedMessages = payload.messages.reverse();
		state.isLoading = false;
		state.messages = [...reversedMessages];
		state.page = payload.page;
		state.hasMore = payload.hasMore;
	},
	// Get conversation information
	[getConversationByIdRequest]: state => {
		state.isLoading = true;
	},
	[getConversationByIdSuccess]: (state, { payload }) => {
		state.conversationDetails = payload;
		state.isLoading = false;
	},
	[getConversationByIdFailure]: state => {
		state.isLoading = false;
	},
	//Accept conversation
	[acceptConversationsSuccess]: state => {
		state.conversationDetails.accepted = true;
	},
	// Decline conversation
	[declineConversationSuccess]: state => {
		state.conversationDetails.accepted = false;
	},

	// Block Conversation
	[blockConversationRequest]: state => ({ ...state, isBlocking: true }),
	[blockConversationSuccess]: (state, { payload }) => ({
		...state,
		isBlocking: false,
		blockedConversationIds: [...state.blockedConversationIds, payload.id],
	}),
	[blockConversationFailure]: state => ({ ...state, isBlocking: false }),

	// Load Initial Blocked Conversation Ids
	[loadInitialBlockedConversationIdsRequest]: state => ({ ...state }),
	[loadInitialBlockedConversationIdsSuccess]: (state, { payload }) => ({
		...state,
		blockedConversationIds: payload.ids,
	}),
	[loadInitialBlockedConversationIdsFailure]: state => ({ ...state }),

	// Load Initial Blocked Conversation Ids
	[payMessageIdRequest]: (state, { payload }) => {
		state.payMessageId = payload;
	},
	[payMessageRequest]: state => {
		state.isLoadingPayMessage = true;
	},
	[payMessageSuccess]: (state, { payload }) => {
		state.paidStatus = payload?.data.data.success;
		if (state.paidStatus) {
			const paidMessageIndex = state.messages.findIndex(val => val.id === payload.messageId);
			if (paidMessageIndex > -1) {
				const tempData = [...state.messages];
				tempData[paidMessageIndex].is_paid = 1;
				state.messages = tempData;
			}
		}
		state.isLoadingPayMessage = false;
	},
	[payMessageFailure]: state => {
		state.isLoadingPayMessage = false;
	},
});

const getState = state => state.message;

export const messagesSelector = state => getState(state).messages;
export const conversationDetailsSelector = state => getState(state).conversationDetails || {};
export const payMessageSelector = state => getState(state).paidStatus || false;
export const payMessageIdSelector = state => getState(state).payMessageId || null;
export const conversationIdSelector = state =>
	getState(state).conversationDetails && getState(state).conversationDetails.id;
export const lastMessageSelector = state =>
	getState(state).messages[getState(state).messages.length - 1];
export const conversationRecipientSelector = state =>
	getState(state).conversationDetails && getState(state).conversationDetails.recipient;
export const senderSelector = state =>
	getState(state).conversationDetails && getState(state).conversationDetails.sender;
export const conversationSenderSelector = state =>
	getState(state).conversationDetails && getState(state).conversationDetails.sender;
export const conversationOpponentSelector = id => state => {
	const sender = conversationSenderSelector(state);
	const recipient = conversationRecipientSelector(state);
	return id === (sender ? sender.uid : null) ? recipient : sender;
};

export const blockedConversationIdsSelector = state => state.message.blockedConversationIds;

export default messageReducer;
