import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

/**
 * @return {null}
 */
export default function FormErrorMessage({ name, errors, touched, ignoreTouched = false }) {
	return errors[name] && (ignoreTouched || touched[name]) ? (
		<FormHelperText error={true}>{errors[name]}</FormHelperText>
	) : null;
}
