import React from 'react';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function SuccessLoginStep({ changeStep }) {
	const { t } = useTranslation();

	return (
		<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
			<div style={{ fontSize: 14, textAlign: 'center', color: theme.textLight }}>
				{t('thanks for registration')}
				<br />
				<br />
				{t('check your email')}
			</div>
			<div
				onClick={() => {
					const token = localStorage.getItem('token');
					window.open(`/auth/guest&step=1?login=1&token=${token}`);
				}}
				style={{
					fontSize: 15,
					textAlign: 'center',
					backgroundColor: '#44a4d1',
					color: '#FFF',
					padding: '10px 0 10px 0',
					marginTop: 30,
					cursor: 'pointer',
					width: '60%',
					alignSelf: 'center',
				}}
			>
				{t('to login')}
			</div>
		</div>
	);
}
