import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import io from 'socket.io-client';
import {
	conversationIdSelector,
	conversationOpponentSelector,
	getOnlyFirsPageMessages,
	handleMessageReceipt,
} from '../store/message/message.store';
import { useDispatch, useSelector } from 'react-redux';
import { addEntryCommentReply } from '../store/entry-comments/entry-comments.store';
import usePrevious from './usePrevious';
import {
	getConversationsFirstPage,
	setUnreadConversations,
	setUnreadNotifications,
} from '../store/chat/chat.store';
import { currentUserSelector } from '../store/profile/profile.store';
import useStackNotification from './useStackNotification';

// const WEB_SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? 'https://prod-socket.heimlich.com/';
// const WEB_SOCKET_URL = 'https://prod-socket.heimlich.com/';
const WEB_SOCKET_URL = 'https://prod-socket.besecret.com/';

// const WEB_SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? 'https://prod-socket.heimlich.com/';
// const WEB_SOCKET_URL = 'http://staged-socket.heimlich.com:8880/';

const useSocketIo = (...args) => {
	const _io = io.bind(null, ...args);

	const socket = useMemo(_io, []);
	if (process.env.NODE_ENV === 'development') {
		window.socket = socket;
	}
	useEffect(() => {
		return () => {
			socket.removeAllListeners();
			socket.close();
		};
	}, [socket]);
	return [socket];
};

const useSocketConnection = topic => {
	const dispatch = useDispatch();
	const conversationId = useSelector(conversationIdSelector);
	const [temp, setTemp] = useState(null);
	const [isConnected, setConnected] = useState(false);
	const [socket] = useSocketIo(WEB_SOCKET_URL);

	const previousIsConnected = usePrevious(isConnected);

	const isNeeded = useMemo(
		() => !previousIsConnected && isConnected && conversationId,
		[isConnected, conversationId, previousIsConnected],
	);

	const getOnlyFirstPageMessages = useCallback(() => {
		if (isNeeded) {
			dispatch(getOnlyFirsPageMessages({ conversationId }));
		}
	}, [dispatch, isNeeded, conversationId]);

	useEffect(() => {
		getOnlyFirstPageMessages();
	}, [getOnlyFirstPageMessages]);

	useEffect(() => {
		socket.on('connect', () => {
			setConnected(true);
			socket.emit('authenticate', { token: localStorage.getItem('token') });
		});
		socket.on('disconnect', () => {
			setConnected(false);
			// console.log('disconnect');
		});
		socket.on(topic, data => {
			setTemp(data);
		});
		return () => {
			// console.log('removeAllListeners connected change');
			socket.removeAllListeners();
		};
	}, []);

	return [temp, { isConnected, socket }];
};

export default function useSocket() {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const [message] = useSocketConnection('message');
	const history = useHistory();
	const user = useSelector(currentUserSelector);
	const { pushNotification } = useStackNotification();

	useEffect(() => {
		if (message) {
			const { data, event } = message;

			if (event === 'conversation:unreadcount') {
				dispatch(setUnreadConversations(message.data));
			}

			if (event === 'conversation:message') {
				const conversationOpponent = conversationOpponentSelector(user.id)({
					message: {
						conversationDetails: data,
					},
				});

				if (data.sender.uid !== user.uid) {
					const notificationPayload = {
						title: conversationOpponent.nickname,
						body: data.text,
						icon: conversationOpponent.avatar,
						action: () => history.push(`/conversations/${data.conversation_id}`, 'internal'),
					};
					pushNotification(notificationPayload);
				}

				if (pathname === '/conversations') {
					dispatch(getConversationsFirstPage());
				}

				dispatch(
					handleMessageReceipt({
						message: data.text,
						conversationId: data.conversation_id,
						userId: data.sender.uid,
						createdAt: data.time || data.sender?.created_at,
						present: data.present,
						points: data.points,
						attachment: data.attachment,
						is_paid: data.is_paid,
						coins: data.coins,
						pay_message: data.pay_message,
					}),
				);
			} else if (event === 'conversation:request') {
				// TODO: Accordingly update conversations list
				const notificationPayload = {
					title: data?.conversation?.sender_user?.nickname,
					body: `${data?.conversation?.sender_user?.nickname} hat dir eine Chat-Anfrage geschickt. Wenn du die Anfrage annimmst, kannst du mit dem Nutzer chatten.`,
					icon: data?.conversation?.sender_user?.avatar,
					action: () => history.push(`/conversations/${data?.conversation_id}`, 'internal'),
				};
				pushNotification(notificationPayload);
				dispatch(setUnreadNotifications(message.data.newUnreadCount));
			} else if (event === 'conversation:accepted') {
				// TODO: Here update badge of message counts and push conversation to list in case user is showing the list
			} else if (event === 'comment:replied') {
				dispatch(addEntryCommentReply(data));
			} else if (event === 'user:registered') {
				// window.location.assign('/profile/manage');
			}
		}
	}, [pathname, message, dispatch, user.id, user.uid, history, pushNotification]);
}
