import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import InlineInfo from '../../shared/components/InlineInfo';
import { useTranslation } from 'react-i18next';

export default function ForgotPasswordSuccessScreen() {
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<Fragment>
			<HeaderAppBar
				title={t('thank you')}
				leftIcon={<BackNavigationIcon onClick={() => history.replace('/auth/login')} />}
				rightIcon={null}
			/>

			<Box p={2}>
				<InlineInfo title={t('thank you') + '!'} text={t('new password sent')} />
			</Box>
		</Fragment>
	);
}
