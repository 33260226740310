import React from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import { useHistory } from 'react-router-dom';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import Box from '@material-ui/core/Box';
import InlineInfo from '../../shared/components/InlineInfo';
import { useTranslation } from 'react-i18next';
import { ConfigCountry } from '../../../helpers/configCountry';

export default function ReportCommentSuccessScreen() {
	const history = useHistory();
	const { t } = useTranslation();
	const text = `${t('message received')} <a style="color: #A42830;" href='mailto:info@${
		ConfigCountry.domain
	}'>info@${ConfigCountry.domain}</a>`;

	return (
		<div>
			<HeaderAppBar
				title={t('thank you')}
				leftIcon={
					<BackNavigationIcon
						onClick={() => {
							history.goBack();
							history.goBack();
						}}
					/>
				}
				rightIcon={null}
			/>

			<Box p={2}>
				<InlineInfo title={t('thank you') + '!'} text={text} />
			</Box>
		</div>
	);
}
