import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import theme from '../../../theme';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1, 2),
		backgroundColor: theme.palette.background.partner_link_background,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flex: 1,
		color: '#888',
	},
	inputRoot: {
		display: 'none',
	},
	menuButton: {},
}));

export default function PaperButton({ icon, id, label, onClick: handleClick, children, ...rest }) {
	const classes = useStyles();

	return (
		<Paper
			component={'label'}
			style={{ backgroundColor: theme.mediaButton }}
			onClick={handleClick}
			elevation={0}
			className={classes.root}
			htmlFor={id}
		>
			<input className={classes.inputRoot} type="file" id={id} {...{ ...rest }} />
			{icon}
			<Typography align={'center'} component={'div'} style={{ fontSize: 14 }}>
				{label}
			</Typography>
		</Paper>
	);
}
