export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function enhanceNumberByPosition(s) {
	return +s > 0 ? `+${s}` : s;
}

export function newlinify(s) {
	return s.split('\\r\\n').join('<br />').split('\\n').join('<br />');
}

export function copyToClipboard(str) {
	const el = document.createElement('textarea');
	el.value = str;
	el.setAttribute('readonly', '');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);
	setTimeout(() => el.select(), 1);
	setTimeout(() => document.execCommand('copy'), 2);
	setTimeout(() => document.body.removeChild(el), 3);
}

export function daysBetween(d1, d2) {
	return Math.floor((new Date(d1) - new Date(d2)) / 86400000);
}

export function requestNotificationPermission(cb = () => {}) {
	// Let's check if the browser supports notifications
	if (!('Notification' in window)) {
		// console.log("This browser does not support desktop notification");
	}
	// Let's check whether notification permissions have already been granted
	else if (Notification.permission === 'granted') {
		// If it's okay let's create a notification
		cb();
	}
	// Otherwise, we need to ask the user for permission
	else if (Notification.permission !== 'denied') {
		Notification.requestPermission().then(function (permission) {
			// If the user accepts, let's create a notification
			if (permission === 'granted') {
				cb();
			}
		});
	}

	// At last, if the user has denied notifications, and you
	// want to be respectful there is no need to bother them any more.
}

export function testNotifyMe() {
	requestNotificationPermission(() => {
		new Notification('test: Hi there!');
	});
}

export const randBool = () => Boolean(Math.round(Math.random()));

export const isMobileDevice = () => {
	let check = false;
	(function (a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a,
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4),
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
};

export const openExternalLink = url => {
	setTimeout(() => {
		window.open(url, '_blank');
	})
};

const scrollToTop = () => window.scrollTo({ top: 99999999999999, behavior: 'smooth' });

export const scrollToTopForSure = () => {
	scrollToTop();
	const enhanceMilliseconds = additionalMilliseconds =>
		milliseconds.push(...additionalMilliseconds);
	let milliseconds = [5, 10, 15, 20, 25, 50, 55, 60, 65, 75, 85, 90, 100];
	enhanceMilliseconds(milliseconds.map(millisecond => millisecond * 2));
	enhanceMilliseconds(milliseconds.map(millisecond => millisecond * 3));
	enhanceMilliseconds(milliseconds.map(millisecond => millisecond + 2));
	enhanceMilliseconds(milliseconds.map(millisecond => millisecond + 3));
	milliseconds.forEach(millisecond => setTimeout(scrollToTop, millisecond));
};

export const unfocusAllInputs = () => {
	const tempInputNode = document.createElement('input');
	document.body.appendChild(tempInputNode);
	tempInputNode.focus();
	tempInputNode.remove();
};

export function jsonParser(str) {
	try {
		if (typeof str !== 'string') {
			return str;
		}
		return JSON.parse(str);
	} catch (e) {
		return null;
	}
}
