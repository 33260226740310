import { createAction, createReducer } from 'redux-starter-kit';
import MembershipService from '../../services/MembershipService';
import { path, pipe } from 'ramda';
import { findById, findByName } from '../../helpers/fp';

export const fetchMembershipPaymentTypesRequest = createAction(
	'FETCH_MEMBERSHIP_PAYMENT_TYPES_REQUEST',
);
export const fetchMembershipPaymentTypesFailure = createAction(
	'FETCH_MEMBERSHIP_PAYMENT_TYPES_FAILURE',
);
export const fetchMembershipPaymentTypesSuccess = createAction(
	'FETCH_MEMBERSHIP_PAYMENT_TYPES_SUCCESS',
);

export const fetchMembershipsByPaymentTypeRequest = createAction(
	'FETCH_MEMBERSHIPS_BY_PAYMENT_TYPE_REQUEST',
);
export const fetchMembershipsByPaymentTypeFailure = createAction(
	'FETCH_MEMBERSHIPS_BY_PAYMENT_TYPE_FAILURE',
);
export const fetchMembershipsByPaymentTypeSuccess = createAction(
	'FETCH_MEMBERSHIPS_BY_PAYMENT_TYPE_SUCCESS',
);

export const getMembershipPaymentUrlRequest = createAction('GET_MEMBERSHIP_PAYMENT_URL_REQUEST');
export const getMembershipPaymentUrlFailure = createAction('GET_MEMBERSHIP_PAYMENT_URL_FAILURE');
export const getMembershipPaymentUrlSuccess = createAction('GET_MEMBERSHIP_PAYMENT_URL_SUCCESS');

export const resetMemberships = createAction('RESET_MEMBERSHIPS');

const initialState = {
	isLoading: false,
	paymentTypes: [],
	memberships: [],
	paymentUrl: null,
};

export const fetchMembershipPaymentTypes = () => async dispatch => {
	dispatch(fetchMembershipPaymentTypesRequest());

	MembershipService.getPaymentTypes()
		.then(({ data }) => {
			dispatch(
				fetchMembershipPaymentTypesSuccess({
					paymentTypes: data,
				}),
			);
		})
		.catch(() => {
			dispatch(fetchMembershipPaymentTypesFailure());
		});
};

export const fetchMembershipsByPaymentType = paymentTypeName => async dispatch => {
	dispatch(fetchMembershipsByPaymentTypeRequest());

	MembershipService.getMemberships(paymentTypeName)
		.then(({ data }) => {
			dispatch(
				fetchMembershipsByPaymentTypeSuccess({
					memberships: data,
				}),
			);
		})
		.catch(() => {
			dispatch(fetchMembershipsByPaymentTypeFailure());
		});
};

export const getMembershipPaymentUrl = membershipId => async dispatch => {
	dispatch(getMembershipPaymentUrlRequest());

	MembershipService.getPaymentUrl(membershipId)
		.then(({ data }) => {
			dispatch(
				getMembershipPaymentUrlSuccess({
					paymentUrl: data.url,
				}),
			);
		})
		.catch(() => {
			dispatch(getMembershipPaymentUrlFailure());
		});
};

const membershipReducer = createReducer(initialState, {
	// Fetch Membership Payment Types
	[fetchMembershipPaymentTypesRequest]: state => ({ ...state, isLoading: true }),
	[fetchMembershipPaymentTypesSuccess]: (state, { payload }) => ({
		...state,
		isLoading: false,
		paymentTypes: payload.paymentTypes,
	}),
	[fetchMembershipPaymentTypesFailure]: state => ({ ...state, isLoading: false }),

	// Fetch Memberships By Payment Type
	[fetchMembershipsByPaymentTypeRequest]: state => ({ ...state, isLoading: true }),
	[fetchMembershipsByPaymentTypeSuccess]: (state, { payload }) => ({
		...state,
		isLoading: false,
		memberships: payload.memberships,
	}),
	[fetchMembershipsByPaymentTypeFailure]: state => ({ ...state, isLoading: false }),

	// Get Membership Payment Url
	[getMembershipPaymentUrlRequest]: state => ({ ...state, isLoading: true }),
	[getMembershipPaymentUrlSuccess]: (state, { payload }) => ({
		...state,
		isLoading: false,
		paymentUrl: payload.paymentUrl,
	}),
	[getMembershipPaymentUrlFailure]: state => ({ ...state, isLoading: false }),

	// Reset Memberships
	[resetMemberships]: state => ({ ...state, memberships: [] }),
});

const stateProp = key => path(['membership', key]);

export const membershipPaymentTypesSelector = stateProp('paymentTypes');
export const membershipPaymentTypeSelector = name =>
	pipe(membershipPaymentTypesSelector, findByName(name));
export const membershipsSelector = stateProp('memberships');
export const membershipIsLoadingSelector = stateProp('isLoading');
export const membershipSelector = id => pipe(membershipsSelector, findById(id));
export const membershipPaymentUrlSelector = stateProp('paymentUrl');

export default membershipReducer;
