import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import store from './store/store';
import App from './App';
import './index.css';
import theme from './theme';
import { RecoilRoot } from 'recoil';

import smoothscroll from 'smoothscroll-polyfill';
import './i18n';

smoothscroll.polyfill();

document.getElementsByTagName('HTML')[0].style.backgroundColor = theme.mainBackgroundScreens;
document.getElementsByTagName('BODY')[0].style.backgroundColor = theme.mainBackgroundScreens;
document.getElementById('root').style.backgroundColor = theme.mainBackgroundScreens;

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<Provider store={store}>
			<RecoilRoot>
				<App />
			</RecoilRoot>
		</Provider>
	</ThemeProvider>,
	document.querySelector('#root'),
);
