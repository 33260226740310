import React, { useEffect } from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import Box from '@material-ui/core/Box';
import Cloud from '../../../common/Cloud';
import { makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { faqByIdSelector, fetchFAQ } from '../../../store/content/content.store';
import { useParams } from 'react-router-dom';
import Loader from '../../../common/Loader';
import DangerousHtml from '../../../common/DangerousHtml';

const useStyle = makeStyles(theme => ({
	question: {
		fontSize: 15,
		fontWeight: '600',
		marginBottom: 20,
	},
	answer: {
		fontSize: 14,
	},
}));

export default function FAQDetailsScreen() {
	const dispatch = useDispatch();

	const { faqId } = useParams();

	useEffect(() => {
		dispatch(fetchFAQ());
	}, [dispatch]);

	const classes = useStyle();

	const faq = useSelector(faqByIdSelector(Number(faqId)));

	return (
		<div>
			<HeaderAppBar title={'FAQ'} leftIcon={<BackNavigationIcon />} rightIcon={null} />

			{faq ? (
				<Box p={2}>
					<Cloud>
						<Box p={1}>
							<Typography className={classes.question}>{faq.question}</Typography>

							<Typography className={classes.answer} component={'div'}>
								<DangerousHtml html={faq.answer} />
							</Typography>
						</Box>
					</Cloud>
				</Box>
			) : (
				<Loader />
			)}
		</div>
	);
}
