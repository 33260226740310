import React, { Fragment, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import HeaderAppBar from '../../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../../shared/components/BackNavigationIcon';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchMembershipPaymentTypes,
	fetchMembershipsByPaymentType,
	getMembershipPaymentUrl,
	membershipIsLoadingSelector,
	membershipPaymentTypeSelector,
	membershipPaymentUrlSelector,
	membershipSelector,
} from '../../../../store/membership/membership.store';
import PaymentForm from '../../../shared/components/payment/PaymentForm';
import Loader from '../../../../common/Loader';
import { openExternalLink } from '../../../../helpers';
import IcBuyPointsPackage from '../../../../assets/ic_buy_points_package.png';
import { useTranslation } from 'react-i18next';
import { currentUserSelector } from '../../../../store/profile/profile.store';

export default function MembershipBuyFormScreen() {
	const { membershipId, paymentTypeName } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();

	const isLoading = useSelector(membershipIsLoadingSelector);
	const profile = useSelector(currentUserSelector);

	const countryIds = [1, 2, 3, 21, 110, 154, 176, 201];
	const rateCoins = countryIds.includes(profile?.country) ? '$' : '$';

	useEffect(() => {
		dispatch(fetchMembershipPaymentTypes());
		dispatch(fetchMembershipsByPaymentType(paymentTypeName));
		dispatch(getMembershipPaymentUrl(membershipId));
	}, [dispatch, paymentTypeName, membershipId]);

	let paymentType = useSelector(membershipPaymentTypeSelector(paymentTypeName));
	let plan = useSelector(membershipSelector(Number(membershipId)));
	const paymentUrl = useSelector(membershipPaymentUrlSelector);

	if (!paymentType || !plan) {
		return <Loader />;
	}

	paymentType = {
		...paymentType,
		id: paymentType.name,
		primaryText: paymentType.displayname,
		image: paymentType.icon,
	};

	plan = {
		...plan,
		primaryText: `${plan.days} - Tage`,
		secondaryText: `${plan.description} - ${plan.costs} ${rateCoins}`,
		image: IcBuyPointsPackage,
	};

	const handleSubmit = () => {
		openExternalLink(paymentUrl);
		history.push('/');
	};

	return (
		<Fragment>
			<HeaderAppBar
				title={t('buy points now')}
				leftIcon={<BackNavigationIcon />}
				rightIcon={null}
			/>

			<Box p={1} pt={2}>
				{isLoading ? (
					<Loader />
				) : (
					<PaymentForm paymentType={paymentType} plan={plan} onSubmit={handleSubmit} />
				)}
			</Box>
		</Fragment>
	);
}
