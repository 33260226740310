import React, {useEffect} from 'react';
import useEntries from '../hooks/useEntries';
import EntryList from './entry/EntryList';
import Box from '@material-ui/core/Box';
import {ENTRY_TYPE} from '../../../constants';
import {searchFirstEntries} from '../../../store/entry/entry.store';
import {useDispatch} from 'react-redux';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    text: {
        textAlign: 'center',
        color: theme.white
    },
}))

export default function NewestEntries() {
    const {isLoading, hasMore, entries, loadNext} = useEntries({type: ENTRY_TYPE.NEWEST});
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        dispatch(searchFirstEntries({page: 1, type: ENTRY_TYPE.NEWEST}));
    }, []);

    return (
        <Box p={1}>
            {entries.length > 0 ?
                <EntryList isLoading={isLoading} entries={entries} hasMore={hasMore} loadMore={loadNext}/> :
                <div className={classes.text}>
                    {t('not have a feed')}
                </div>
            }
        </Box>
    );
}
