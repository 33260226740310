import React, { useState } from 'react';
import FiltersField from './FiltersField';

export default function CommunityFilters({
	nickname,
	gender,
	agefrom,
	ageto,
	country,
	zip,
	onApply,
	countries,
}) {
	const [filters, setFilters] = useState({
		nickname,
		gender,
		agefrom,
		ageto,
		country,
		zip,
	});

	const onOk = () => {
		onApply(filters);
	};

	const onChange = (field, value) => {
		setFilters(currentFilters => ({
			...currentFilters,
			[field]: value,
		}));
	};

	const onNicknameChange = value => onChange('nickname', value);

	return (
		<FiltersField
			gender={filters.gender}
			agefrom={filters.agefrom}
			ageto={filters.ageto}
			country={filters.country}
			zip={filters.zip}
			onChange={onChange}
			onOk={onOk}
			countries={countries}
			valueFilter={filters.nickname}
			onChangeFilter={onNicknameChange}
		/>
	);
}
