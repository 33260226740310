import React from 'react';
import { makeStyles } from '@material-ui/core';
import Sender from '../../../shared/components/Sender';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'fixed',
		width: '100%',
		maxWidth: 900,
		bottom: 0,
	},
}));

export default function SendMessageForm(props) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Sender {...props} />
		</div>
	);
}
