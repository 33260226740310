import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import {useDispatch, useSelector} from 'react-redux';
import {
    isEntriesLoadingSelector,
} from '../../../store/entry/entry.store';
import Loader from '../../../common/Loader';
import HeaderRight from '../../shared/components/HedaerRight';
import theme from '../../../theme';
import {fetchSettings} from "../../../store/core/core.store";
import ConfessionsEntries from "../components/ConfessionsEntries";
import {faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.mainBackgroundScreens,
        marginTop: 60,
        paddingBottom: 64,
        '& .MuiInputBase-root': {
            marginRight: 30,
        },
        '& .MuiInputBase-root:hover > *': {
            borderColor: theme.textLight,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: theme.textLight,
        },
        '& .MuiAutocomplete-hasPopupIcon *': {
            color: theme.textLight,
            borderColor: theme.textLight,
        },
        '& .MuiAutocomplete-listbox': {
            backgroundColor: theme.mainBackgroundScreens,
            border: '1px solid gray',
            borderRadius: 0,
            '& > * > *': {
                backgroundColor: theme.mainBackgroundScreens,
                color: theme.mainBlue,
            },
            '& ul': {
                backgroundColor: theme.mainBackgroundScreens,
            },
        },
    },
    icons: {
        fontSize: 16,
        marginLeft: 10,
        color: theme.mainBlue,
    },
}));

export default function ConfessionsScreen() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);

    const isEntriesLoading = useSelector(isEntriesLoadingSelector);

    return (
        <div className={classes.root}>
            <HeaderAppBar
                positionHeader={'fixed'}
                leftIcon={
                    <img
                        src={theme.smallLogo}
                        alt={'logo'}
                        className={classes.logo}
                        onClick={() => history.push('/feed')}
                        color={'action'}
                    />
                }
                rightIcon={<HeaderRight/>}
                newPost
                isLogo
                bar
            />
            {isEntriesLoading ? <Loader/> : null}
            <div style={{
                marginLeft: 30,
                marginTop: 100,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }} onClick={() => history.push('/community')}>
                <FontAwesomeIcon color={theme.blackWhite} icon={faLongArrowAltLeft} style={{fontSize: 30}}/>
                <div style={{
                    fontSize: 20,
                    color: theme.blackWhite,
                    marginLeft: 20
                }}>
                    {t('secrets_confessions')}
                </div>
            </div>

            <ConfessionsEntries/>
        </div>
    );
}
