import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	root: {
		position: 'relative',
		display: 'flex',
		flex: '1',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '40%',
		margin: '0 auto',
		[theme.breakpoints.down('md')]: {
			width: '96%',
		},
	},
	avatar: {
		margin: '15px 0 30px',
	},
	sections: {
		marginBottom: 15,
		width: 260,
	},
	slogan: {
		margin: '15px 0',
		width: 260,
		textAlign: 'center',
	},
	topLeft: {
		position: 'absolute',
		top: 20,
		left: 15,
	},
	topRight: {
		position: 'absolute',
		top: 20,
		right: 15,
	},
	section: {
		margin: '5px 0',
	},
}));

export default function UserLayout({ name, avatar, sections, slogan, topLeft, topRight }) {
	const classes = useStyle();

	return (
		<div className={classes.root}>
			<div className={classes.topLeft}>{topLeft}</div>
			<div className={classes.topRight}>{topRight}</div>
			<div className={classes.avatar}>{avatar}</div>
			<div className={classes.name}>{name}</div>
			{slogan ? <div className={classes.slogan}>{slogan}</div> : null}
			<div className={classes.sections}>
				{sections.map((section, index) => {
					return (
						<div className={classes.section} key={`section-${index}`}>
							{section}
						</div>
					);
				})}
			</div>
		</div>
	);
}
