import React, { useMemo } from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function ImagePreview({ src, onClose: handleClose }) {
	const style = useMemo(
		() => ({
			position: 'fixed',
			zIndex: 9999999999,
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundPosition: 'center center',
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundColor: 'rgb(40, 45, 54)',
			backgroundImage: `url(${src})`,
		}),
		[src],
	);

	const handleDownload = () => {
		window.location.assign(src);
	};

	const actionBarStyle = useMemo(
		() => ({
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			padding: 15,
		}),
		[],
	);

	const actionItemStyle = useMemo(
		() => ({
			cursor: 'pointer',
		}),
		[],
	);

	return (
		<div style={style}>
			<div style={actionBarStyle}>
				<div style={actionItemStyle} onClick={handleClose}>
					<ArrowBackIcon style={{ color: 'white' }} />
				</div>
				<div style={actionItemStyle} onClick={handleDownload}>
					<GetAppIcon style={{ color: 'white' }} />
				</div>
			</div>
		</div>
	);
}
