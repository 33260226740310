import { createAction, createReducer } from 'redux-starter-kit';
import UserService from '../../services/UserService';

export const searchUsersRequest = createAction('SEARCH_USERS_REQUEST');
export const searchUsersFailure = createAction('SEARCH_USERS_FAILURE');
export const searchUsersSuccess = createAction('SEARCH_USERS_SUCCESS');

export const getUserByIdRequest = createAction('GET_USER_BY_ID_REQUEST');
export const getUserByIdFailure = createAction('GET_USER_BY_ID_FAILURE');
export const getUserByIdSuccess = createAction('GET_USER_BY_ID_SUCCESS');

export const resetUsers = createAction('RESET_USERS');
export const resetUser = createAction('RESET_USER');

export const loadInitialBlockedUserIdsRequest = createAction('LOAD_INITIAL_BLOCK_USER_IDS_REQUEST');
export const loadInitialBlockedUserIdsFailure = createAction('LOAD_INITIAL_BLOCK_USER_IDS_FAILURE');
export const loadInitialBlockedUserIdsSuccess = createAction('LOAD_INITIAL_BLOCK_USER_IDS_SUCCESS');

export const blockUserRequest = createAction('BLOCK_USER_REQUEST');
export const blockUserFailure = createAction('BLOCK_USER_FAILURE');
export const blockUserSuccess = createAction('BLOCK_USER_SUCCESS');

const initialState = {
	page: 1,
	users: [],
	isLoading: false,
	hasMore: false,
	user: null,

	blockedUserIds: [],
	isBlocking: false,
};

export const loadInitialBlockedUserIds = () => dispatch => {
	dispatch(loadInitialBlockedUserIdsRequest());
	UserService.getBlockedUserIds()
		.then(ids => {
			dispatch(loadInitialBlockedUserIdsSuccess({ ids }));
		})
		.catch(() => {
			dispatch(loadInitialBlockedUserIdsFailure());
		});
};

export const searchUsers = filters => async dispatch => {
	dispatch(searchUsersRequest());

	UserService.searchUsers(filters)
		.then(({ data }) => {
			dispatch(
				searchUsersSuccess({
					users: data.data,
					hasMore: data.last_page > filters.page,
				}),
			);
		})
		.catch(() => {
			dispatch(searchUsersFailure());
		});
};

export const getUserById = id => async dispatch => {
	dispatch(getUserByIdRequest());

	UserService.getById(id)
		.then(({ data }) => {
			dispatch(
				getUserByIdSuccess({
					user: data,
				}),
			);
		})
		.catch(() => {
			dispatch(getUserByIdFailure());
		});
};

// export const getUserById = id => async dispatch => {
// 	dispatch(getUserByIdRequest());
//
// 	UserService.getById(id)
// 		.then(({ data }) => {
// 			dispatch(
// 				getUserByIdSuccess({
// 					user: data,
// 				}),
// 			);
// 		})
// 		.catch(() => {
// 			dispatch(getUserByIdFailure());
// 		});
// };

export const blockUser =
	({ id }) =>
	dispatch => {
		dispatch(blockUserRequest());
		UserService.block(id)
			.then(() => {
				dispatch(blockUserSuccess({ id }));
			})
			.catch(() => {
				dispatch(blockUserFailure());
			});
	};

const userReducer = createReducer(initialState, {
	// Search Users
	[searchUsersRequest]: state => {
		state.isLoading = true;
	},
	[searchUsersSuccess]: (state, action) => {
		state.page++;
		state.isLoading = false;
		state.users = [...state.users, ...action.payload.users];
		state.hasMore = action.payload.hasMore;
	},
	[resetUsers]: state => {
		state.page = 1;
		state.users = [];
		state.isLoading = false;
		state.hasMore = false;
	},
	[resetUser]: state => ({ ...state, user: null }),

	// Get User By Id
	[getUserByIdRequest]: state => ({ ...state, isLoading: true }),
	[getUserByIdSuccess]: (state, action) => ({
		...state,
		isLoading: false,
		user: action.payload.user,
	}),
	[getUserByIdFailure]: state => ({ ...state, isLoading: false }),

	// Block User
	[blockUserRequest]: state => ({ ...state, isBlocking: true }),
	[blockUserSuccess]: (state, { payload }) => ({
		...state,
		isBlocking: false,
		blockedUserIds: [...state.blockedUserIds, payload.id],
	}),
	[blockUserFailure]: state => ({ ...state, isBlocking: false }),

	// Load Initial Blocked User Ids
	[loadInitialBlockedUserIdsRequest]: state => ({ ...state }),
	[loadInitialBlockedUserIdsSuccess]: (state, { payload }) => ({
		...state,
		blockedUserIds: payload.ids,
	}),
	[loadInitialBlockedUserIdsFailure]: state => ({ ...state }),
});

export const usersSelector = state => state.user.users;
export const userSelector = state => state.user.user;

export const blockedUserIdsSelector = state => state.user.blockedUserIds;

export default userReducer;
