import React, { useCallback, useEffect } from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import NotificationsList from '../components/NotificationsList';
import { useDispatch } from 'react-redux';
import { markAllNotificationsRead } from '../../../store/notifications/notifications.store';
import { setUnreadNotifications } from '../../../store/chat/chat.store';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import HeaderRight from '../../shared/components/HedaerRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.mainBackgroundScreens,
	},
	logo: {
		height: 40,
	},
	headerTitle: {
		textAlign: 'center',
		fontSize: 20,
		marginTop: 30,
		marginBottom: 20,
		color: theme.headerIcon,
	},
	notificationHeader: {
		display: 'flex',
		flexDirection: 'column',
		width: '60%',
		margin: '0 auto',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignSelf: 'center',
			width: '84%',
		},
	},
	eyeIcon: {
		cursor: 'pointer',
		margin: '-15px 10px 5px 0',
		color: theme.mainBlue,
		fontSize: 40,
		alignSelf: 'flex-end',
		[theme.breakpoints.down('md')]: {
			margin: 0,
			alignSelf: 'center',
			marginTop: 10,
			width: 50,
		},
	},
}));

export default function NotificationsScreen() {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(setUnreadNotifications(0));
	}, [dispatch]);

	const handleMarkAllRead = useCallback(() => dispatch(markAllNotificationsRead()), [dispatch]);

	return (
		<div className={classes.root}>
			<HeaderAppBar
				leftIcon={
					<img
						src={theme.smallLogo}
						alt={'logo'}
						className={classes.logo}
						onClick={() => history.push('/feed')}
						color={'action'}
					/>
				}
				rightIcon={<HeaderRight />}
				newPost
				isLogo
				bar
			/>
			<div className={classes.notificationHeader}>
				<div className={classes.eyeIcon} />
				<div className={classes.headerTitle}>{t('notification')}</div>
				<FontAwesomeIcon
					icon={faEye}
					className={classes.eyeIcon}
					color={theme.headerIcon}
					onClick={handleMarkAllRead}
				/>
			</div>
			<NotificationsList />
		</div>
	);
}
