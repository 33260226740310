import React, {useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import femalePink from '../../../assets/ic_female_pink-web.png';
import maleBlue from '../../../assets/ic_male_blue-web.png';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import {countriesSelector} from "../../../store/core/core.store";
import {Box} from "@material-ui/core";
import theme from "../../../theme";

const useStyles = makeStyles(theme => ({
	user: {
		display: 'flex',
		flexDirection: 'column',
		// alignItems: 'center',
		cursor: 'pointer',
		// border: '1px solid #ededed',
		borderRadius: 5,
		// padding: '20px 0',
		position: 'relative',
		boxShadow: '2px 2px 16px 7px lightblue',
		width: 'max-content'
	},
	nickname: {
		color: theme.mainBlue,
		marginTop: '10px',
		fontWeight: '600',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '100%',
		textAlign: 'center',
	},
	media: {
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		height: 200,
		[theme.breakpoints.down('xs')]: {
			height: 150,
		},
		// maxWidth: 100,
		// borderRadius: '50%',
	},
	userDescription: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	age: {
		backgroundColor: '#c1c1c1',
		padding: '2px 5px 2px 5px',
		borderRadius: 3,
		margin: '0 5px',
		color: '#FFF',
		fontWeight: 'bold',
		fontSize: 16
	},
	gender: {
		width: 20,
		height: 20,
		marginLeft: 5,
	},
	itemList: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems:'flex-end',
		width: '100%',
		padding: '0 10px 10px 10px'
	},
	flagStyle: {
		display: 'block',
		marginRight: 5,
		border: '1px solid #FFF',
		width: 30,
		height: 20,
	},
	ageGender: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	}
}));

export default function CommunityUserItem({ nickname, gender, avatar, age, country }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const countries = useSelector(countriesSelector);

	const Flag = useMemo(() => {
		return countries?.find(e => e.id == country)?.flagicon;
	}, [country]);

	const FlagName = useMemo(() => {
		return countries?.find(e => e.id == country)?.name;
	}, [country]);

	return (
		<div className={classes.user}>
			<img alt={'avatar'} src={avatar} className={classes.media} />
			<div className={classes.itemList}>
				<img
					src={Flag}
					alt="User Country Icon"
					title={FlagName}
					className={classes.flagStyle}
				/>
				<div className={classes.userDescription}>
					<Typography className={classes.nickname} color={'primary'}>
						{nickname}
					</Typography>
					<div className={classes.ageGender}>
						{gender === 'w' && <img alt={'gender'} src={femalePink} className={classes.gender} />}
						{gender === 'm' && <img alt={'gender'} src={maleBlue} className={classes.gender} />}
						<Typography variant={'subtitle2'} className={classes.age}>
							{age}
							{t('profile age')}
						</Typography>
					</div>
				</div>
			</div>

		</div>
	);
}
