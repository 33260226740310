import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/AccountCircle';
import { useFormik } from 'formik';
import useStyles from './style';
import SubmitButton from '../../shared/components/SubmitButton';
import ProfileService from '../../../services/ProfileService';
import Loader from '../../../common/Loader';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';
import IcBgApp from '../../../assets/bg_app.png';
import { useTranslation } from 'react-i18next';

export default function ForgotPasswordScreen() {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();

	const [isResetting, setIsResetting] = useState(false);
	const requiredMessage = t('field is required');
	const validationSchema = Yup.object().shape({
		mail: Yup.string().label('E-Mail').email().required(requiredMessage),
	});
	const { handleChange, values, touched, errors, handleSubmit } = useFormik({
		initialValues: {
			mail: '',
		},
		validationSchema,
		onSubmit: ({ mail }) => {
			setIsResetting(true);
			ProfileService.resetPassword(mail).finally(() => {
				history.replace('/auth/forgot-password/success');
				setIsResetting(false);
			});
		},
	});

	return (
		<div
			style={{
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				backgroundImage: `url(${IcBgApp})`,
				height: '100%',
			}}
		>
			<Container component="main" maxWidth="xs">
				{isResetting ? <Loader /> : null}
				<Box
					display={'flex'}
					alignItems={'center'}
					style={{ color: 'white', position: 'fixed', left: 15, top: 25, cursor: 'pointer' }}
					onClick={() => history.replace('/auth/guest')}
				>
					<ArrowBackIcon style={{ marginRight: 10 }} />
					<Typography>{t('register')}</Typography>
				</Box>
				<div className={classes.paper}>
					<form className={classes.form} onSubmit={handleSubmit}>
						<Paper className={classes.root} elevation={0}>
							<IconButton className={classes.iconButton} aria-label="menu">
								<MenuIcon />
							</IconButton>
							<InputBase
								className={classes.inputWithIcon}
								name={'mail'}
								type={'mail'}
								value={values.mail}
								onChange={handleChange}
								placeholder={t('your email and name')}
								inputProps={{ 'aria-label': t('nickname') }}
							/>
						</Paper>
						<FormHelperText error={Boolean(touched.mail && errors.mail)}>
							{touched.mail && errors.mail ? errors.mail : ''}
						</FormHelperText>

						<Box color={'white'} mt={3} mb={3} textAlign={'center'}>
							{t('receive password')}
						</Box>

						<SubmitButton>{t('reset password')}</SubmitButton>
					</form>
				</div>
			</Container>
		</div>
	);
}
