import React, {useState, useMemo, useEffect} from 'react';

import ProfileService from '../../../services/ProfileService';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    makeStyles,
    Popper,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {countriesSelector} from '../../../store/core/core.store';
import {currentUserSelector, fetchProfile} from '../../../store/profile/profile.store';
import theme from '../../../theme';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import {ConfigCountry} from '../../../helpers/configCountry';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '../../community/components/Select';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        zIndex: 9999,
        '& .MuiInputBase-root': {
            marginRight: 30,
        },
        '& .MuiInputBase-root:hover > *': {
            borderColor: theme.textLight,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: theme.textLight,
        },
        '& .MuiAutocomplete-hasPopupIcon *': {
            color: theme.textLight,
            borderColor: theme.textLight,
        },
        '& .MuiAutocomplete-listbox': {
            backgroundColor: theme.mainBackgroundScreens,
            border: '1px solid gray',
            borderRadius: 0,
            '& > * > *': {
                backgroundColor: theme.mainBackgroundScreens,
                color: theme.mainBlue,
            },
            '& ul': {
                backgroundColor: theme.mainBackgroundScreens,
            },
        },
    },
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 30,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 5,
            marginBottom: 20,
        },
    },
    gridItem: {
        textAlign: 'center',
    },
    gridItemForMobile: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            margin: '10px auto',
        },
    },
    gridItemGender: {
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        height: 20,
        alignSelf: 'center',
    },
    gridItemLocation: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    rootButton: {
        alignSelf: 'flex-end',
        marginLeft: 20,
    },
    countryItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rangeStyle: {
        width: '20%',
    },
    rangeStyleForMobile: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '75%',
        },
    },
    textDark: {
        color: theme.textDark,
    },
    textLight: {
        color: theme.textLight,
        paddingLeft: 6,
    },
    icon: {
        fill: theme.textLight,
    },
    searchInput: {
        width: 300,
        color: 'red',
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
    },
    noOptions: {
        boxShadow: 'none',
        margin: 0,
        color: theme.mainBlue,
        backgroundColor: theme.textLight,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        paddingLeft: 9,
        '&[aria-selected="true"]': {
            backgroundColor: theme.mainBlue,
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.mainBlue,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
}));

function ProfileCountryAndPostalCode({country, zipcode, uid, isZipcode, isCountry}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [formCountry, setFormCountry] = useState(country);
    const [formZipCode, setFormZipCode] = useState(zipcode);
    const [langCountries, setLangCountries] = useState([]);
    const countries = useSelector(countriesSelector);
    const currentUser = useSelector(currentUserSelector);
    const {t} = useTranslation();
    const handleCountryChange = (event, values) => {
        setFormCountry(values?.id);
    };

    const handleZipCodeChange = event => {
        setFormZipCode(event.target.value);
    };

    useEffect(() => {
        if (!zipcode) {
            setFormZipCode(countries?.find(c => c.id === country).zipcodes[0]);
        }
    }, [setFormZipCode, zipcode, countries, country]);

    const handleSave = () => {
        Promise.all([
            ProfileService.updateCountry(formCountry),
            ProfileService.updateZipCode(formZipCode),
        ])
            .then(() => {
                setIsEditing(false);
            })
            .finally(() => {
                dispatch(fetchProfile());
            });
    };

    const handleCancel = () => {
        setFormCountry(country);
        setFormZipCode(zipcode);
        setIsEditing(false);
    };

    const zipCodes = useMemo(() => {
        let temp = countries?.find(c => formCountry === c.id)?.zipcodes;
        if (temp?.length) {
            return temp.reduce((acc, el) => {
                acc.push({id: el, name: el});
                return acc;
            }, []);
        }
        return [];
    }, [countries, formCountry]);

    const Flag = useMemo(() => {
        return countries?.find(e => e.id === country).flagicon;
    }, [countries, currentUser]);

    const FlagName = useMemo(() => {
        return countries?.find(e => e.id === country).name;
    }, [countries, currentUser]);

    const newLangCountries = useMemo(
        () =>
            countries?.map(elem => {
                return {...elem, name: t(elem.name)};
            }, {}),
        [countries],
    );

    useEffect(() => {
        countries && setLangCountries([...newLangCountries]);
    }, [countries]);

    const options =
        langCountries &&
        langCountries?.map(option => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            };
        });
    const CustomPopper = function (props) {
        const classes = useStyles();
        return <Popper {...props} className={classes.root} placement="bottom"/>;
    };
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <div style={{display: 'flex'}}>
                        {Flag && (
                            <img
                                src={Flag}
                                alt="User Country Icon"
                                title={FlagName}
                                style={{
                                    display: 'block',
                                    marginRight: 5,
                                    border: '1px solid #FFF',
                                    width: 30,
                                    height: 20,
                                }}
                            />
                        )}
                        <div style={{marginTop: 2, color: theme.textDark}}>{zipcode}</div>
                    </div>
                </div>
                {!isCountry &&
                !isZipcode &&
                uid === currentUser.uid && (
                    <div onClick={() => setIsEditing(true)}>
                        <FontAwesomeIcon
                            icon={faPencilAlt}
                            style={{cursor: 'pointer', marginLeft: 10, color: theme.mainBlue, fontSize: 20}}
                            color={theme.headerIcon}
                        />
                    </div>
                )
                }
            </div>
            <Dialog open={isEditing} onClose={handleCancel}>
                <DialogContent>
                    <DialogContentText>
                        <Grid container direction="column">
                            <Grid
                                item
                                xs={12}
                                direction="column"
                                alignItems="center"
                                style={{
                                    zIndex: 999999,
                                }}
                            >
                                <Autocomplete
                                    id="grouped-demo"
                                    onChange={handleCountryChange}
                                    options={options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={option => option.firstLetter}
                                    getOptionLabel={option => t(option.name)}
                                    PopperComponent={CustomPopper}
                                    noOptionsText={'Unknown country'}
                                    classes={{
                                        paper: classes.paper,
                                        option: classes.option,
                                        noOptions: classes.noOptions,
                                        popperDisablePortal: classes.popperDisablePortal,
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            className={classes.searchInput}
                                            label={t('country')}
                                            variant="outlined"
                                        />
                                    )}
                                    renderOption={(option, {selected}) => (
                                        <React.Fragment>
                                            <div className={classes.textLight}>{t(option.name)}</div>
                                        </React.Fragment>
                                    )}
                                />
                            </Grid>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{marginTop: 10}}>
                                    <label>{t('zip code area')}:</label>
                                </div>
                                <Select
                                    items={zipCodes}
                                    onSelect={handleZipCodeChange}
                                    selectedId={formZipCode}
                                />
                            </div>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{t('cancel')}</Button>
                    <Button onClick={handleSave} variant="contained" color="primary" autoFocus>
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ProfileCountryAndPostalCode;
