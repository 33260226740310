import { createAction, createReducer } from 'redux-starter-kit';
import PackageService from '../../services/PackageService';
import { path, pipe } from 'ramda';
import { findById, findByName } from '../../helpers/fp';

export const fetchPackagePaymentTypesRequest = createAction('FETCH_PACKAGE_PAYMENT_TYPES_REQUEST');
export const fetchPackagePaymentTypesFailure = createAction('FETCH_PACKAGE_PAYMENT_TYPES_FAILURE');
export const fetchPackagePaymentTypesSuccess = createAction('FETCH_PACKAGE_PAYMENT_TYPES_SUCCESS');

export const fetchPackagesByPaymentTypeRequest = createAction(
	'FETCH_PACKAGES_BY_PAYMENT_TYPE_REQUEST',
);
export const fetchPackagesByPaymentTypeFailure = createAction(
	'FETCH_PACKAGES_BY_PAYMENT_TYPE_FAILURE',
);
export const fetchPackagesByPaymentTypeSuccess = createAction(
	'FETCH_PACKAGES_BY_PAYMENT_TYPE_SUCCESS',
);

export const getPackagePaymentUrlRequest = createAction('GET_PACKAGE_PAYMENT_URL_REQUEST');
export const getPackagePaymentUrlFailure = createAction('GET_PACKAGE_PAYMENT_URL_FAILURE');
export const getPackagePaymentUrlSuccess = createAction('GET_PACKAGE_PAYMENT_URL_SUCCESS');
export const resetPackagePaymentUrlSuccess = createAction('RESET_PACKAGE_PAYMENT_URL_SUCCESS');

export const resetPackages = createAction('RESET_PACKAGES');

const initialState = {
	isLoading: false,
	paymentTypes: [],
	packages: [],
	paymentUrl: null,
};

export const fetchPackagePaymentTypes = () => async dispatch => {
	dispatch(fetchPackagePaymentTypesRequest());

	PackageService.getPaymentTypes()
		.then(({ data }) => {
			dispatch(
				fetchPackagePaymentTypesSuccess({
					paymentTypes: data,
				}),
			);
		})
		.catch(() => {
			dispatch(fetchPackagePaymentTypesFailure());
		});
};

export const fetchPackagesByPaymentType = paymentTypeName => async dispatch => {
	dispatch(fetchPackagesByPaymentTypeRequest());

	PackageService.getPackages(paymentTypeName)
		.then(({ data }) => {
			dispatch(
				fetchPackagesByPaymentTypeSuccess({
					packages: data,
				}),
			);
		})
		.catch(() => {
			dispatch(fetchPackagesByPaymentTypeFailure());
		});
};

export const getPackagePaymentUrl = packageId => async dispatch => {
	dispatch(getPackagePaymentUrlRequest());

	PackageService.getPaymentUrl(packageId)
		.then(({ data }) => {
			dispatch(
				getPackagePaymentUrlSuccess({
					paymentUrl: data.url,
				}),
			);
		})
		.catch(() => {
			dispatch(getPackagePaymentUrlFailure());
		});
};

const packageReducer = createReducer(initialState, {
	// Fetch Package Payment Types
	[fetchPackagePaymentTypesRequest]: state => ({ ...state, isLoading: true }),
	[fetchPackagePaymentTypesSuccess]: (state, { payload }) => ({
		...state,
		isLoading: false,
		paymentTypes: payload.paymentTypes,
	}),
	[fetchPackagePaymentTypesFailure]: state => ({ ...state, isLoading: false }),

	// Fetch Packages By Payment Type
	[fetchPackagesByPaymentTypeRequest]: state => ({ ...state, isLoading: true }),
	[fetchPackagesByPaymentTypeSuccess]: (state, { payload }) => ({
		...state,
		isLoading: false,
		packages: payload.packages,
	}),
	[fetchPackagesByPaymentTypeFailure]: state => ({ ...state, isLoading: false }),

	// Get Package Payment Url
	[getPackagePaymentUrlRequest]: state => ({ ...state, isLoading: true }),
	[getPackagePaymentUrlSuccess]: (state, { payload }) => ({
		...state,
		isLoading: false,
		paymentUrl: payload.paymentUrl,
	}),
	[resetPackagePaymentUrlSuccess]: (state, { payload }) => ({
		...state,
		paymentUrl: null,
	}),
	[getPackagePaymentUrlFailure]: state => ({ ...state, isLoading: false }),

	// Reset Packages
	[resetPackages]: state => ({ ...state, packages: [] }),
});

const stateProp = key => path(['package', key]);

export const packagePaymentTypesSelector = stateProp('paymentTypes');
export const packagePaymentTypeSelector = name =>
	pipe(packagePaymentTypesSelector, findByName(name));
export const packagesSelector = stateProp('packages');
export const packageIsLoadingSelector = stateProp('isLoading');
export const packageSelector = id => pipe(packagesSelector, findById(id));
export const packagePaymentUrlSelector = stateProp('paymentUrl');

export default packageReducer;
