import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import Box from '@material-ui/core/Box';
import {createAccount, loginFailureSocial, loginSocial} from '../../../store/profile/profile.store';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import IcGirl from '../../../assets/girl.png';
import GenderStep from '../components/StepTwo/GenderStep';
import AgeStep from '../components/StepTwo/AgeStep';
import NameStep from '../components/StepTwo/NameStep';
import PasswordStep from '../components/StepTwo/PasswordStep';
import EmailStep from '../components/StepTwo/EmailStep';
import AuthorizeService from '../../../services/AuthorizeService';
import BgApp from '../../../assets/step-background.png';
import AppStore from '../../../assets/apple-store.png';
import GPlay from '../../../assets/google-play.png';
import Divider from '@material-ui/core/Divider';
import LoginStep from '../components/StepTwo/LoginStep';
import ForgotPasswordStep from '../components/StepTwo/ForgotPasswordStep';
import 'react-slideshow-image/dist/styles.css';
import ThanksStep from '../components/StepTwo/ThanksStep';
import SuccessLoginStep from '../components/StepTwo/SuccessLoginStep';
import { useTranslation } from 'react-i18next';
import { settingsSelector } from '../../../store/core/core.store';
import { ConfigCountry } from '../../../helpers/configCountry';
import Language from '../components/Language';
import {GoogleLogin} from "@react-oauth/google";

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		backgroundColor: theme.mainBackgroundScreens,
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
			height: 'auto',
		},
	},
	authLeft: {
		width: '50%',
		height: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundImage: `url(${BgApp})`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		paddingRight: 60,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: 'auto',
			alignItems: 'center',
			marginTop: 20,
			paddingTop: 30,
			paddingRight: 0,
		},
	},
	leftCol: {
		// flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	leftText: {
		width: 480,
		color: '#FFF',
		fontSize: 20,
		textAlign: 'center',
		marginTop: 10,
		[theme.breakpoints.down('md')]: {
			width: '94%',
			marginBottom: 10,
			fontSize: 18,
		},
	},
	authRight: {
		width: '100%',
		height: '100%',
		display: 'flex',
		paddingTop: 60,
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			paddingLeft: 0,
			justifyContent: 'center',
		},
	},
	headText: {
		textAlign: 'center',
		fontSize: 20,
		color: theme.mainBlue,
	},
	headText1: {
		textAlign: 'center',
		fontSize: 20,
		color: theme.textDark,
	},
	headTextLight: {
		color: 'grey',
		fontStyle: 'italic',
		textAlign: 'center',
		fontSize: 18,
	},
	rightBox: {
		width: '60%',
		maxWidth: 350,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	footer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '50%',
		margin: '0 auto',
		paddingTop: 30,
		paddingBottom: 35,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	footerText: {
		color: 'gray',
		textDecoration: 'none',
	},
	selectBox: {
		background: theme.modalColor,
		borderRadius: 10,
		color: theme.white,
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 15,
		marginLeft: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	dropdown: {
		width: 'max-content',
		background: theme.modalColor,
		borderRadius: 10,
		color: theme.white,
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 5,
		marginLeft: 10,
		position: 'absolute',
		zIndex: 99,
	},
	dropdownItem: {
		paddingRight: 20,
		paddingLeft: 20,
		paddingBottom: 3,
		paddingTop: 3,
		'&:hover': {
			backgroundColor: theme.mainBlue,
		},
	},
	icons: {
		marginLeft: 10,
		marginRight: 10,
	},
}));

export default function GuestAuthByStepScreen(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { t } = useTranslation();
	const [country, setCountry] = useState('');
	const [locationName, setLocationName] = useState('');
	const settings = useSelector(settingsSelector);
	const location = useLocation();

	useEffect(() => {
		fetch('https://pro.ip-api.com/json?key=dU5KpOF4ZiQeP8K')
			.then(response => response.json())
			.then(data => {
				setLocationName(data?.countryCode?.toLocaleLowerCase());
				const countryByCode = settings?.countries?.find(e => e.code === data?.countryCode);
				setCountry(countryByCode?.id || '');
			});
	}, [settings]);

	const validationSchema = Yup.object().shape({
		HEIMLICHNICK: Yup.string()
			.label('Nickname')
			.min(4, t('minimum letters'))
			.matches(/^[a-zA-Z0-9._-]+$/, t('error special charecters'))
			.trim()
			.required(t('please enter nick')),
		mail: Yup.string().label('Email').required(t('please enter email')),
		pass: Yup.string().label('Password').required(t('please enter password')),
		confirmPass: Yup.string()
			.oneOf([Yup.ref('pass')], t('passwords not matched'))
			.required(t('please enter password again'))
			.min(6, 'Mindestens 6 Buchstaben'),
		HEIMLICHAGE: Yup.number()
			.label('Age')
			.min(18, t('too young'))
			.max(99, t('enter correct age'))
			.required(t('field is required'))
			.typeError(t('field is required')),
		HEIMLICHGENDER: Yup.string().label('Gender').nullable(true).required(t('field is required')),
		agreement: Yup.boolean().oneOf([true], t('please accept conditions')),
	});

	const {
		handleChange,
		values,
		errors,
		touched,
		handleSubmit,
		setFieldValue,
		setErrors,
		resetForm,
	} = useFormik({
		initialValues: {
			HEIMLICHNICK: '',
			mail: '',
			pass: '',
			confirmPass: '',
			HEIMLICHAGE: null,
			HEIMLICHGENDER: 'm',
			agreement: false,
		},
		validationSchema,
		onSubmit: (values, formikHelpers) => {
			const birthDate = new Date();
			birthDate.setFullYear(birthDate.getFullYear() - values.HEIMLICHAGE);
			const HEIMLICHBIRTHDAY = birthDate.toLocaleDateString('en-US');
			dispatch(
				createAccount({
					...values,
					location: locationName,
					country,
					HEIMLICHBIRTHDAY,
					agreement: undefined,
					formikHelpers,
					t,
				}),
			);
		},
	});

	const creationStatus = useSelector(state => state.profile.creationStatus);
	const socialLoginFailed = useSelector(state => state.profile.socialLoginFailed);

	const [step, setStep] = useState(8);
	const [showNickNameError, setShowNickNameError] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	useEffect(() => {
		if (creationStatus === 'DONE') {
			setStep(8);
		}
	}, [history, creationStatus]);

	useEffect(() => {
		if (Number(history?.location?.state?.from?.search[1])) {
			setStep(Number(history?.location?.state?.from?.search[1]));
		} else if (location?.pathname === '/auth/guest&login=1') {
			setStep(5);
		} else {
			setStep(0);
		}
	}, [history]);

	useEffect(() => {
		if (socialLoginFailed) {
			dispatch(loginFailureSocial(false));
			history.push('/auth/guest');
		}
	}, [socialLoginFailed]);

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const login = urlParams.get('login');
		if (login) {
			const localToken = localStorage.getItem('token');
			const tokenParam = urlParams.get('token');

			if (!localToken) {
				if (tokenParam !== 'null') {
					localStorage.setItem('token', tokenParam);
				}
			}

			changeStep(5);
		}
	}, [window.location.search]);

	const girlStyles = {
		width: window.innerWidth === 320 ? 70 : 140,
		marginTop: 20,
		marginBottom: 40,
	};

	const changeStep = newStep => {
		step === 8 && newStep === 5 && resetForm();
		setErrors({});
		setStep(newStep);
	};

	const submitNameStep = async () => {
		try {
			if (disableButton || values?.HEIMLICHNICK.length < 4 || errors.HEIMLICHNICK) {
				return;
			}
			setDisableButton(true);
			const regex = /^[a-zA-Z0-9._-]+$/;
			const found = values?.HEIMLICHNICK.match(regex);
			const {
				data: { success, error },
			} = await AuthorizeService.checkNameAvailability(values.HEIMLICHNICK);
			if (success && found) {
				!errors?.HEIMLICHNICK && changeStep(3);
				setDisableButton(false);
				return;
			}
			found && setErrors({ ...errors, HEIMLICHNICK: error });
			setShowNickNameError(true);
			setDisableButton(false);
		} catch (e) {
			setErrors({ HEIMLICHNICK: t(e?.data?.error) });
			setShowNickNameError(true);
			setDisableButton(false);
		}
	};

	const handleSteps = step => {
		switch (step) {
			case 0:
				return (
					<GenderStep
						setFieldValue={value => setFieldValue('HEIMLICHGENDER', value)}
						values={values.HEIMLICHGENDER}
						changeStep={changeStep}
					/>
				);
			case 1:
				return (
					<AgeStep
						value={values.HEIMLICHAGE}
						onChange={handleChange}
						errors={errors}
						handleSubmit={handleSubmit}
						touched={touched}
						changeStep={changeStep}
					/>
				);
			case 2:
				return (
					<NameStep
						value={values.HEIMLICHNICK.trim()}
						onChange={handleChange}
						errors={errors}
						handleSubmit={handleSubmit}
						submitNameStep={submitNameStep}
						ignoreTouched={showNickNameError}
						touched={touched}
						changeStep={changeStep}
					/>
				);
			case 3:
				return (
					<PasswordStep
						valuePass={values.pass}
						valueConfirmPass={values.confirmPass}
						onChange={handleChange}
						errors={errors}
						handleSubmit={handleSubmit}
						touched={touched}
						changeStep={changeStep}
					/>
				);
			case 4:
				return (
					<EmailStep
						value={values.mail}
						agreement={values.agreement}
						onChange={handleChange}
						errors={errors}
						handleSubmit={handleSubmit}
						touched={touched}
						changeStep={changeStep}
					/>
				);
			case 5:
				return <LoginStep changeStep={changeStep} />;
			case 6:
				return <ForgotPasswordStep changeStep={changeStep} />;
			case 7:
				return <ThanksStep changeStep={changeStep} />;
			case 8:
				return <SuccessLoginStep changeStep={changeStep} />;
			default:
				throw new Error('Unknown step');
		}
	};
	const responseGoogle = ({ credential }) => {
		if (credential) {
			const resData = { accessToken: credential, provider: 'google' };
			dispatch(loginSocial(resData));
		}
	};
	return (
		<div className={classes.root}>
			<Language />
			<div className={classes.authRight}>
				<div className={classes.rightBox}>
					<div className={classes.headText}>{t('register slogan 1.1')}</div>
					<div className={classes.headText1}>{t('register slogan 1.2')}</div>
					<div className={classes.headTextLight}>{t('register slogan 3')}</div>
					<img src={IcGirl} alt="girl" style={girlStyles} />
					{handleSteps(step || 0)}
					<Box
						style={{
							fontSize: 15,
							marginTop: theme.spacing(4),
						}}
					>
						<Divider />
						{step === 5 ? (
							<div
								style={{
									paddingTop: 20,
									textAlign: 'center',
								}}
							>
								<span style={{ color: theme.textDark }}>{t('new here')} </span>
								<span
									onClick={() => changeStep(0)}
									style={{
										fontSize: 14,
										color: '#44a4d1',
										cursor: 'pointer',
									}}
								>
									{t('register for free')}!
								</span>
							</div>
						) : step === 8 ? null : (
							<div
								style={{
									paddingTop: 20,
									textAlign: 'center',
								}}
							>
								<span style={{ color: theme.textDark }}>{t('already registered')} </span>
								<span
									onClick={() => {
										const token = localStorage.getItem('token');
										window.open(`/auth/guest&step=1?login=1&token=${token}`);
									}}
									style={{
										fontSize: 15,
										color: '#44a4d1',
										cursor: 'pointer',
									}}
								>
									{t('login here')}
								</span>
							</div>
						)}

						{!ConfigCountry.international && (
							<>
								<div
									style={{
										textAlign: 'center',
										marginTop: 30,
										color: theme.textDark,
									}}
								>
									{t('install app')}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginTop: 20,
									}}
								>
									<a
										href="https://itunes.apple.com/de/app/heimlich-com/id1308069339?ls=1&mt=8"
										target="_blank"
										rel="noopener noreferrer"
										style={{ width: '47%' }}
									>
										<img src={AppStore} alt="logo" style={{ width: '100%' }} />
									</a>
									<a
										href="https://play.google.com/store/apps/details?id=com.heimlich"
										target="_blank"
										rel="noopener noreferrer"
										style={{ width: '47%' }}
									>
										<img src={GPlay} alt="logo" style={{ width: '100%' }} />
									</a>
								</div>
							</>
						)}
					</Box>
				</div>
			</div>
		</div>
	);
}
