import React, {useEffect, useState} from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import {makeStyles, Input, Typography} from '@material-ui/core';
import Messages from '../components/messages/Messages';
import {useDispatch, useSelector} from 'react-redux';
import {
    conversationOpponentSelector,
    getConversationById,
    resetMessages,
    sendMessage,
    conversationDetailsSelector,
    conversationRecipientSelector,
} from '../../../store/message/message.store';
import {useParams, useHistory} from 'react-router-dom';
import {
    currentUserSelector,
    fetchPoints,
    pointsSelector,
} from '../../../store/profile/profile.store';
import {
    closePresentsTab,
    isPresentsShowSelector,
    openMembershipSuggestion,
    openPresentsTab,
    sendPresent,
} from '../../../store/presents/presents.store';
import PresentsDialog from '../components/presents/PresentsDialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import RedeemIcon from '@material-ui/icons/Redeem';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SendMessageForm from '../components/sender/SendMessageForm';
import MembershipSuggestionDialog from '../components/presents/MembershipSuggestionDialog';
import {sendAttachment} from '../../../store/message/message.store';
import ConversationPointSendDialog from '../components/ConversationPointSendDialog';
import Cloud from '../../../common/Cloud';
import LockIcon from '@material-ui/icons/Lock';
import HeaderRight from '../../shared/components/HedaerRight';
import theme from '../../../theme';
import Avatar from '@material-ui/core/Avatar';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 1,
    },
    avatar: {
        margin: 10,
    },
    logo: {
        height: 40,
    },
    firstHeader: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    secondHeaderStyle: {
        [theme.breakpoints.down('md')]: {
            top: '0 !important',
        },
    },
    backImage: {
        backgroundImage: `url(${theme.chatBackground})`,
        backgroundAttachment: 'fixed',
        position: 'fixed',
        width: '100%',
        height: '100%',
        opacity: 0.5,
    },
}));

export default function ConversationScreen() {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(currentUserSelector);
    const history = useHistory();
    const isPresentsDialogShow = useSelector(isPresentsShowSelector);
    const {conversationId} = useParams();
    const conversationOpponent = useSelector(conversationOpponentSelector(user ? user.uid : null));
    const nickname = conversationOpponent && conversationOpponent.nickname;
    const avatar = conversationOpponent && conversationOpponent.avatar;
    const [reload, setReload] = useState(true)


    useEffect(() => {
        dispatch(getConversationById(conversationId));
        return () => {
            dispatch(resetMessages());
        };
    }, [dispatch, conversationId]);

    useEffect(() => {
        dispatch(fetchPoints());
    }, [dispatch]);

    useEffect(() => {
        if (user.uid ||
            conversationId ||
            nickname ||
            conversationOpponent) {
            // window.location.reload(reload);
            setReload(false)
        }

    }, []);

    const points = useSelector(pointsSelector);

    const handleClose = value => {
        dispatch(closePresentsTab());
    };

    const handleMessageSubmit = value => {
        dispatch(sendMessage({message: value, conversationId, userId: user.uid}));
    };

    const handlePresentsClick = () => {
        dispatch(openPresentsTab());
    };

    const handlePresentSend = id => async () => {
        dispatch(sendPresent({present: id, recipient: conversationOpponent.uid}));
        await handleClose();
    };
    const handleCheckPoint = () => {
        if (points.sum < 10) {
            dispatch(openMembershipSuggestion());
        }
    };
    const handleImageSelect = event => {
        const image = event.target?.files?.[0] ?? null;
        if (image) {
            dispatch(sendAttachment({conversationId, image}));
        }
    };

    const cameraInputId = 'cameraInputId';

    const {accepted, blocked} = useSelector(conversationDetailsSelector);
    const recipient = useSelector(conversationRecipientSelector);

    return (
        <div>
            <div className={classes.backImage}/>
            <div className={classes.firstHeader}>
                <HeaderAppBar
                    leftIcon={
                        <img
                            src={theme.smallLogo}
                            alt={'logo'}
                            className={classes.logo}
                            onClick={() => history.push('/feed')}
                            color={'action'}
                        />
                    }
                    positionHeader={'fixed'}
                    rightIcon={<HeaderRight/>}
                    newPost
                    isLogo
                    bar
                />
            </div>
            <div style={{maxWidth: 900, margin: '0 auto'}}>
                <HeaderAppBar
                    positionHeader={'fixed'}
                    title={nickname}
                    leftIcon={<BackNavigationIcon/>}
                    rightIconUser={
                        <Avatar
                            onClick={() => history.push(`/community/${conversationOpponent.uid}`)}
                            alt="Remy Sharp"
                            src={avatar}
                            className={classes.avatar}
                        />
                    }
                    secondHeader
                    secondHeaderStyle={classes.secondHeaderStyle}
                    bgColor={theme.headerBackground}
                    headerWidth={900}
                />
                {isPresentsDialogShow && (
                    <PresentsDialog
                        onPresentSend={handlePresentSend}
                        open={isPresentsDialogShow}
                        onClose={handleClose}
                    />
                )}
                {!blocked ? (
                    <Messages
                        userId={user?.uid}
                        conversationId={conversationId}
                        nickname={nickname}
                        opponent={conversationOpponent}
                    />
                ) : (
                    <>
                        <div
                            style={{
                                backgroundImage: `url(${theme.chatBackground})`,
                                minHeight: 'calc(100vh - 1px)',
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Cloud
                                style={{
                                    display: 'flex',
                                    color: 'white',
                                    backgroundColor: '#12710d',
                                    alignItems: 'center',
                                }}
                            >
                                <LockIcon style={{marginRight: 5}}/>
                                <Typography variant="body2">{t('chat declined')}</Typography>
                            </Cloud>
                        </div>
                    </>
                )}

                {!blocked && !(recipient?.uid === user?.uid && !accepted) ? (
                    <>
                        <SendMessageForm
                            onSubmit={handleMessageSubmit}
                            userId={user.uid}
                            conversationId={conversationId}
                            placeholder={t('write message') + '...'}
                            endAdornment={
                                <>
                                    {/*<ConversationPointSendDialog*/}
                                    {/*    opponentName={nickname}*/}
                                    {/*    conversationId={conversationId}*/}
                                    {/*/>*/}
                                    <InputAdornment
                                        onClick={handlePresentsClick}
                                        position="end"
                                        style={{cursor: 'pointer'}}
                                        title={t('send present')}
                                        role="button"
                                    >
                                        <RedeemIcon/>
                                    </InputAdornment>
                                    <InputAdornment
                                        position="end"
                                        onClick={handleCheckPoint}
                                        title={t('send image')}
                                        role="button"
                                    >
                                        <label
                                            htmlFor={cameraInputId}
                                            style={{display: 'inline-block', paddingTop: 6, cursor: 'pointer'}}
                                        >
                                            <PhotoCameraIcon/>
                                            <input
                                                type="file"
                                                accept="image/*, .heic"
                                                id={points.sum > 10 ? cameraInputId : ''}
                                                onChange={handleImageSelect}
                                                style={{display: 'none'}}
                                            />
                                        </label>
                                    </InputAdornment>
                                </>
                            }
                        />
                        <MembershipSuggestionDialog/>
                    </>
                ) : null}
            </div>
        </div>
    );
}
