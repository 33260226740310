import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const [vertical, horizontal] = ['bottom', 'center'];

export default function BottomToast({ open, onClose: handleClose, children }) {
	return (
		<Snackbar
			anchorOrigin={{ vertical, horizontal }}
			key={`${vertical},${horizontal}`}
			open={open}
			onClose={handleClose}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={children}
		/>
	);
}
