function scaleDownImage(item, width = 600, cb) {
	const reader = new FileReader();

	reader.readAsDataURL(item);
	reader.name = item.name;
	reader.size = item.size;
	reader.onload = function (event) {
		const img = new Image();
		img.src = event.target.result; //result is base64-encoded Data URI
		img.name = event.target.name;
		img.size = event.target.size;
		img.onload = function (el) {
			const elem = document.createElement('canvas'); //create a canvas

			//scale the image to width and keep aspect ratio
			const scaleFactor = width / el.target.width;
			elem.width = width;
			elem.height = el.target.height * scaleFactor;

			//draw in canvas
			const ctx = elem.getContext('2d');
			ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

			//get the base64-encoded Data URI from the resize image
			cb(ctx.canvas.toDataURL('image/png', 1));
		};
	};
}

export default scaleDownImage;
