import React, { useMemo, useState } from 'react';
import CommentItem from './CommentItem';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	expand: {
		color: theme.palette.primary.darker_gray_text,
		fontSize: 14,
	},
}));

export default function CommentReplies({ commentId, replies, handleRepliedCommentClick }) {
	const classes = useStyle();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);
	const data = useMemo(() => replies.slice(0, expanded ? Infinity : 1), [expanded, replies]);
	return (
		<div style={{ paddingLeft: 30 }}>
			{data.map(repliedComment => (
				<CommentItem
					onClick={() =>
						handleRepliedCommentClick({
							commentId,
							replyId: repliedComment.id,
							isUserActive: repliedComment.active,
						})
					}
					key={repliedComment.id}
					comment={repliedComment}
				/>
			))}
			{!expanded && replies.length > 1 ? (
				<div className={classes.expand} onClick={() => setExpanded(true)}>
					{t('show previous answers')}
				</div>
			) : null}
		</div>
	);
}
