import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	userEntriesHasMoreSelector,
	userEntriesPageSelector,
	userEntriesSelector,
	isUserEntriesLoadingSelector,
	loadInitialBlockedEntryIds,
	resetUserEntries,
	searchUserEntries,
} from '../../../store/entry/entry.store';

export default function useUserEntries({ type, uid }) {
	const dispatch = useDispatch();

	const entries = useSelector(userEntriesSelector);
	const isLoading = useSelector(isUserEntriesLoadingSelector);
	const page = useSelector(userEntriesPageSelector);
	const hasMore = useSelector(userEntriesHasMoreSelector);

	useEffect(() => {
		dispatch(loadInitialBlockedEntryIds());
	}, [dispatch]);

	const loadNext = useCallback(() => {
		dispatch(searchUserEntries({ page: page + 1, type, uid }));
	}, [dispatch, page, type]);

	useEffect(() => {
		dispatch(searchUserEntries({ page: 1, type, uid }));

		return () => {
			dispatch(resetUserEntries());
		};
	}, [dispatch, type, uid]);

	return {
		page,
		isLoading,
		entries,
		hasMore,
		loadNext,
	};
}
