import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles, FormControl, InputAdornment} from '@material-ui/core';
import RangeSlider from './RangeSlider';
import Switcher from './Switcher';
import femaleIcon from '../../../assets/ic_female_pink-web.png';
import maleIcon from '../../../assets/ic_male_blue-web.png';
import Button from '@material-ui/core/Button';
import SearchField from './SearchField';
import {useTranslation} from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faGlobe, faMapMarkerAlt as faLocation} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& .MuiInputBase-root': {
            marginRight: 30,
        },
        '& .MuiInputBase-root:hover > *': {
            borderColor: theme.textLight,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: theme.textLight,
        },
        '& .MuiAutocomplete-hasPopupIcon *': {
            color: theme.textLight,
            borderColor: theme.textLight,
        },
        '& .MuiAutocomplete-listbox': {
            backgroundColor: theme.mainBackgroundScreens,
            border: '1px solid gray',
            borderRadius: 0,
            '& > * > *': {
                backgroundColor: theme.mainBackgroundScreens,
                color: theme.mainBlue,
            },
            '& ul': {
                backgroundColor: theme.mainBackgroundScreens,
            },
        },
    },
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 30,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 5,
            marginBottom: 20,
        },
    },
    gridItem: {
        textAlign: 'center',
    },
    gridItemForMobile: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            margin: '10px auto',
        },
    },
    gridItemGender: {
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        height: 20,
        alignSelf: 'center',
    },
    gridItemLocation: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            marginTop: 15
        },
    },
    rootButton: {
        alignSelf: 'flex-end',
        marginLeft: 20,
    },
    countryItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    rangeStyle: {
        width: '20%',
    },
    rangeStyleForMobile: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '75%',
        },
    },
    textDark: {
        color: theme.textDark,
    },
    textLight: {
        color: theme.textLight,
        paddingLeft: 6,
    },
    select: {
        border: 'none',
        borderBottom: 'dotted gray 1px',
        padding: '6px 0',
        fontSize: 16,
        color: '#555555',
    },
    icon: {
        fill: theme.textLight,
    },
    searchInput: {
        width: 300,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 35
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
    },
    noOptions: {
        boxShadow: 'none',
        margin: 0,
        color: theme.mainBlue,
        backgroundColor: theme.textLight,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        paddingLeft: 9,
        '&[aria-selected="true"]': {
            backgroundColor: theme.headerBackground,
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.headerBackground,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    input: {
        width: 150,
        padding: '16px',
        height: 57,
        borderRadius: 4,
        border: '#c5c5c5 solid 1px',
        appearance: 'none',
        color: theme.textLight,
        fontSize: 18,
        backgroundColor: theme.mainBackgroundScreens,
    },
    withIcon: {
        paddingLeft: '36px',
    },
    iconInputContainer: {
        width: '100%',
        position: 'relative',
    },
    icon2: {
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translate(0%, -50%)',
        cursor: 'pointer',
    },
    icon1: {
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translate(0%, -50%)',
        cursor: 'pointer',
        pointerEvents: 'none'
    },
}));

export default function FiltersField({
                                         gender,
                                         agefrom,
                                         ageto,
                                         country,
                                         zip,
                                         onChange,
                                         onOk,
                                         countries,
                                         valueFilter,
                                         onChangeFilter,
                                     }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [zipEgal, setZipEgal] = useState([{
        id: '',
        name: t('all'),
    }]);
    const [defaultCountry, setDefaultCountry] = useState(null);

    const [countryEgal, setCountryEgal] = useState([
        {
            id: '',
            name: t('all'),
        },
    ]);
    const [state, setState] = useState(
        gender === null
            ? {
                male: true,
                female: true,
            }
            : {
                male: gender === 'm',
                female: gender === 'w',
            },
    );

    const zipCodes = useMemo(() => {
        let temp;
        temp = country === 0 ? [] : countries?.find(c => country === c.id)?.zipcodes;

        if (temp?.length) {
            return temp.reduce((acc, el) => {
                acc.push({id: el, name: el});
                return acc;
            }, []);
        }
        return [];
    }, [countries, country]);

    const newLangCountries = useMemo(
        () =>
            countries?.map(elem => {
                return {...elem, name: t(elem.name)};
            }, {}),
        [countries],
    );

    useEffect(() => {
        if(countries) {
            setCountryEgal([
                {
                    id: '',
                    name: t('all'),
                },
                ...newLangCountries,
            ]);
            setDefaultCountry(country
                ? countries.find(val => +val.id === +country)
                : {
                    id: '',
                    name: t('all'),
                })
        }
    }, [countries]);

    useEffect(() => {
        setDefaultCountry(countryEgal?.find(el => el.id == country) || {
            id: '',
            name: t('all'),
        })
    }, [countryEgal]);

    useEffect(() => {
        setZipEgal(() => [...[{
            id: '',
            name: t('all'),
        }]]);
    }, [countries]);

    useEffect(() => {
        zipCodes && setZipEgal(() => [...[{
            id: '',
            name: t('all'),
        }, ...zipCodes]]);
    }, [countryEgal, zipCodes]);

    const handleCountryNewChange = (event, values) => {
        const countryId = values?.id;
        onChange('country', countryId);
        onChange('zip', zipEgal[0].id);
    };

    const handleZipCodeChange = event => {
        onChange('zip', event.target.value);
    };

    const handleAgeChange = value => {
        onChange('agefrom', value[0]);
        onChange('ageto', value[1]);
    };

    useEffect(() => {
        let gender = null;
        if (!state.female || !state.male) {
            gender = state.female ? 'w' : 'm';
        }
        onChange('gender', gender);
    }, [state]);

    const options =
        countryEgal &&
        countryEgal?.map(option => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            };
        });

    const CustomPopper = function (props) {
        const classes = useStyles();
        return <Popper {...props} className={classes.root} placement="bottom"/>;
    };

    return (
        <div className={classes.root}>
            <div className={classes.searchContainer}>
                <div className={classes.gridItem} style={{flex: 2}}>
                    <SearchField value={valueFilter} onChange={e => onChangeFilter(e)}/>
                </div>
                <div
                    className={classes.gridItem && classes.rangeStyle && classes.rangeStyleForMobile}
                    style={{flex: 2}}
                >
                    <RangeSlider min={18} max={99} value={[agefrom, ageto]} onChange={handleAgeChange}/>
                </div>
                <div className={classes.gridItem && classes.gridItemForMobile} style={{flex: 1}}>
                    <div className={classes.gridItemGender}>
                        <img className={classes.image} alt={'female'} style={{width: 20}} src={maleIcon}/>
                        <Switcher
                            onChange={() => {
                                setState(prevState => {
                                    if (prevState.female) {
                                        return {...prevState, male: !prevState.male};
                                    } else {
                                        return {male: !prevState.male, female: !prevState.female};
                                    }
                                });
                            }}
                            checked={state.male}
                        />
                    </div>
                    <div className={classes.gridItemGender}>
                        <img className={classes.image} alt={'female'} style={{width: 20}} src={femaleIcon}/>
                        <Switcher
                            onChange={() => {
                                setState(prevState => {
                                    if (prevState.male) {
                                        return {...prevState, female: !prevState.female};
                                    } else {
                                        return {male: !prevState.male, female: !prevState.female};
                                    }
                                });
                            }}
                            checked={state.female}
                        />
                    </div>
                </div>
                <div className={classes.countryItem} style={{flex: 2}}>
                    {countryEgal.length > 1 && <Autocomplete
                        id="grouped-demo"
                        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                        groupBy={option => option.firstLetter}
                        getOptionLabel={option => t(option.name)}
                        PopperComponent={CustomPopper}
                        noOptionsText={'Unknown country'}
                        onChange={handleCountryNewChange}
                        defaultValue={defaultCountry}
                        classes={{
                            paper: classes.paper,
                            option: classes.option,
                            noOptions: classes.noOptions,
                            popperDisablePortal: classes.popperDisablePortal,
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                className={classes.searchInput}
                                label={t('country')}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FontAwesomeIcon
                                                color={'rgb(180,180,180)'}
                                                icon={faGlobe}
                                                style={{fontSize: 16}}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        renderOption={option => (
                            <>
									<span style={{marginRight: '10px'}}>
										{option.name} {option.code ? (option.code) : ''}
									</span>
                                {option.flagicon ?
                                    <img src={option.flagicon} width={30} height={20} alt="flag"/>
                                    :
                                    null
                                }
                            </>
                        )}
                    />}
                    <div className={classes.gridItemLocation} style={{marginLeft: 20}}>
                        <FormControl disabled={!country}>
                            <div>
                                <div className={classes.iconInputContainer}>
                                    <select
                                        disabled={!country}
                                        className={`${classes.input} ${classes.withIcon}`}
                                        onChange={handleZipCodeChange}
                                        value={zip || zipEgal}
                                    >
                                        {zipEgal?.map(item => (
                                            <option key={`option:${item.name}`} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="zip" className={classes.icon2}>
                                        <FontAwesomeIcon
                                            color={'rgb(180,180,180)'}
                                            icon={faLocation}
                                            style={{fontSize: 16}}
                                        />
                                    </label>
                                    <label htmlFor="zip" className={classes.icon1}>
                                        <FontAwesomeIcon
                                            color={'rgb(180,180,180)'}
                                            icon={faChevronDown}
                                            style={{fontSize: 16}}
                                        />
                                    </label>
                                </div>
                            </div>

                        </FormControl>
                    </div>
                    <Button
                        className={classes.rootButton}
                        variant={'contained'}
                        color="secondary"
                        size="small"
                        onClick={() => onOk()}
                    >
                        Ok
                    </Button>
                </div>
            </div>
        </div>
    );
}
