import React from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import { useHistory } from 'react-router-dom';
import InlineInfo from '../../shared/components/InlineInfo';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

export default function ManageProfileSuccessScreen({ onClose }) {
	const { t } = useTranslation();

	return (
		<div>
			<HeaderAppBar
				title={t('thank you')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>
			<Box p={2}>
				<InlineInfo title={t('thank you') + '!'} text={t('password changed')} />
			</Box>
		</div>
	);
}
