import React from 'react';
import MyAvatar from '../../shared/components/MyAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: '50%',
		cursor: 'pointer',
	},
	colorTextSecondary: {
		color: theme.palette.primary.colorBlue,
	},
	avatarTexts: {
		position: 'absolute',
		right: 18,
		[theme.breakpoints.down('md')]: {
			top: 20,
		},
	},
}));

export default function ProfileAvatar({ avatarUploaded, avatarApproved, ...props }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const { onClick: handleClick } = props;
	return (
		<div className={classes.root}>
			<div className={classes.avatarTexts}>
				<Typography
					onClick={handleClick}
					display={'block'}
					variant="caption"
					color={'textSecondary'}
					style={{ cursor: 'pointer' }}
					classes={{
						colorTextSecondary: classes.colorTextSecondary,
					}}
				>
					{t('change image')}
				</Typography>
			</div>
			<div style={{ paddingTop: 40 }}>
				<MyAvatar {...props} size={145} styles={{ border: '5px solid #ededed' }} animationChange />
				{avatarUploaded && !avatarApproved && (
					<Typography
						paragraph
						variant="caption"
						display={'block'}
						style={{
							color: theme.textDark,
							textAlign: 'center',
							marginTop: 10,
						}}
					>
						{t('in review')}
					</Typography>
				)}
			</div>
		</div>
	);
}
