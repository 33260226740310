import BaseService from './BaseService';

class PresentsService extends BaseService {
	constructor() {
		super('/presents');
	}

	getPresents({ page }) {
		return this.get('/list', { page });
	}

	getPresentsOfCategory({ page, presentCategory }) {
		return this.get(`/categories/${presentCategory}/index`, { page });
	}

	getPresentsCategories({ page }) {
		return this.get('/categories/index', { page });
	}

	getPresentById(id) {
		return this.get(`/view/${id}`);
	}

	sendPresent({ present, recipient }) {
		return this.post(`/send`, { present, recipient });
	}
}

export default new PresentsService();
