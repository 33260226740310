import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import reducers from './reducers';

const middleware = [...getDefaultMiddleware()];

const store = configureStore({
	reducer: reducers,
	middleware: getDefaultMiddleware({
		serializableCheck: false,
	}),
	devTools: process.env.NODE_ENV !== 'production',
});

export default store;
