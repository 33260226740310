import React from 'react';
import Cloud from '../../../common/Cloud';
import { Box, Typography } from '@material-ui/core';
import theme from '../../../theme';
import useLocalDate from '../../../hooks/useLocalDate';

export default function NotificationDetails({ subject, message, created_at, updated_at }) {
	const createdAt = useLocalDate({ date: created_at });

	return (
		<Box p={2}>
			<Cloud style={{ color: theme.textDark }}>
				<Typography variant={'caption'} display={'block'}>
					{subject}
				</Typography>
				<Box py={1}>
					<Typography variant={'caption'} display={'block'}>
						{message}
					</Typography>
				</Box>
				<Typography variant={'caption'} display={'block'}>
					{createdAt}
				</Typography>
			</Cloud>
		</Box>
	);
}
