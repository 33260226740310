import { List, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import Cloud from './Cloud';
import ListItem from './ListItem';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import theme from '../theme';
const useStyle = makeStyles(theme => ({
	primary: {
		fontWeight: 'bold',
		fontSize: 14,
		color: theme.textDark,
	},
	secondary: {
		fontSize: 13,
		color: theme.textLight,
	},
}));
export default function ReferenceButton({ title, subTitle, onClick }) {
	const classes = useStyle();

	const itemTextClasses = { primary: classes.primary, secondary: classes.secondary };

	return (
		<List
			component={Cloud}
			style={{ cursor: 'pointer', backgroundColor: theme.mainBackgroundScreens }}
		>
			<ListItem
				onClick={onClick}
				style={{ padding: 0, margin: 0, paddingLeft: 5, paddingRight: 5 }}
			>
				<ListItemText
					classes={itemTextClasses}
					primary={title}
					secondary={subTitle}
					secondaryTypographyProps={{ style: { fontSize: 13 } }}
				/>
				<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
			</ListItem>
		</List>
	);
}
