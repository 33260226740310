import { createAction, createReducer } from 'redux-starter-kit';
import { path } from 'ramda';

const getBlockListRequest = createAction('GET_BLOCK_LIST_REQUEST');
const getBlockListSuccess = createAction('GET_BLOCK_LIST_SUCCESS');
const getBlockListFailure = createAction('GET_BLOCK_LIST_FAILURE');

const initialState = {
	blockedUsers: null,
	isLoading: false,
};

const blockReducer = createReducer(initialState, {
	[getBlockListRequest]: state => ({ ...state, isLoading: true }),
	[getBlockListSuccess]: state => ({ ...state, isLoading: false }),
	[getBlockListFailure]: state => ({ ...state, isLoading: false }),
});

const stateProp = key => path(['block', key]);

export const blockedUsersSelector = stateProp('blockedUsers');

export default blockReducer;
