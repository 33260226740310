import BaseService from './BaseService';

class MembershipService extends BaseService {
	constructor() {
		super('/membership');
	}

	getPaymentTypes() {
		return this.get('/paymenttypes');
	}

	getMemberships(paymentTypeName) {
		return this.get(`/${paymentTypeName}/list`);
	}

	getPaymentUrl(membershipId) {
		return this.get(`/${membershipId}/paymenturl`);
	}
}

export default new MembershipService();
