import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../theme';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
}));

export default function PresentsDialogHeader({
	onDrawerOpen: handleDrawerOpen,
	drawerOpen,
	handleClose,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<AppBar
			position={'sticky'}
			className={clsx(classes.appBar, {
				[classes.appBarShift]: drawerOpen,
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					className={clsx(classes.menuButton, drawerOpen && classes.hide)}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" noWrap>
					{t('presents')}
				</Typography>
				<div
					style={{
						position: 'absolute',
						right: 20,
						cursor: 'pointer',
					}}
				>
					<FontAwesomeIcon
						onClick={handleClose}
						icon={faTimes}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				</div>
			</Toolbar>
		</AppBar>
	);
}
