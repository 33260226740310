import React, { Fragment } from 'react';
import markdownIt from 'markdown-it';
import markdownItSup from 'markdown-it-sup';
import markdownItSanitizer from 'markdown-it-sanitizer';
import markdownItLinkAttributes from 'markdown-it-link-attributes';
import { makeStyles } from '@material-ui/core';
import MessageAttachment from './MessageAttachment';
import useLocalDate from '../../../../hooks/useLocalDate';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
	msgBubble: {
		backgroundColor: theme.palette.chatBubble.response,
		borderRadius: '5px',
		padding: '8px',
		maxWidth: '255px',
		minWidth: '115px',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	alignLeft: {
		alignItems: 'left',
	},
	alignCenter: {
		alignItems: 'center',
	},
	nickname: {
		color: theme.palette.primary.color_green_dark,
		fontWeight: 600,
	},
	presentImage: {
		height: 100,
		width: 100,
	},
	clientBubble: {
		marginLeft: 'auto',
		backgroundColor: theme.palette.chatBubble.client,
		'&::before': {
			content: '""',
			position: 'absolute',
			zIndex: 0,
			top: 0,
			right: '-15px',
			height: '20px',
			width: '20px',
			backgroundColor: theme.palette.chatBubble.client,
		},
		'&::after': {
			content: '""',
			position: 'absolute',
			zIndex: 0,
			top: 1,
			right: -16,
			height: 19,
			width: 16,
			backgroundColor: theme.borderContainers,
			borderTopLeftRadius: '20px',
		},
	},
	responseBubble: {
		'&::before': {
			content: '""',
			position: 'absolute',
			zIndex: 0,
			top: 0,
			left: '-15px',
			height: '20px',
			width: '20px',
			backgroundColor: theme.palette.chatBubble.response,
		},
		'&::after': {
			content: '""',
			position: 'absolute',
			zIndex: 0,
			top: 1,
			left: -16,
			height: 19,
			width: 16,
			backgroundColor: theme.borderContainers,
			borderTopRightRadius: '20px',
		},
	},
	messageText: {
		'& p': {
			margin: 0,
			textAlign: 'left',
		},
	},
	createdAt: {
		fontSize: '0.65rem',
	},
}));

export default function MessageBubble({
	message,
	userId,
	nickname,
	present,
	points,
	attachment,
	conversationId,
	payMessage,
	isPaid,
	price,
	messageId,
	payMessageId,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const createdAt = useLocalDate({ date: message.created_at });

	const sanitizedHTML = markdownIt()
		.use(markdownItSup)
		.use(markdownItSanitizer)
		.use(markdownItLinkAttributes, { attrs: { target: '_blank', rel: 'noopener' } })
		.render(message.message);

	const className = `
    ${classes.msgBubble} ${
		message.sender === userId ? classes.clientBubble : classes.responseBubble
	} ${present ? classes.alignCenter : classes.alignLeft}
        `;

	return (
		<div className={className}>
			{message.sender !== userId && <div className={classes.nickname}>{nickname}</div>}

			{attachment ? (
				<>
					{payMessage ? (
						<div
							className={classes.messageText}
							dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
						/>
					) : null}
					<MessageAttachment
						attachmentId={attachment}
						conversationId={conversationId}
						payMessage={payMessage}
						isPaid={isPaid}
						price={price}
						messageId={messageId}
						payMessageId={payMessageId}
					/>
				</>
			) : (
				<Fragment>
					{points ? (
						<Fragment>
							{message.sender === userId ? (
								<i>
									{t('message coin text 1')} {nickname} {points} {t('message coin text 3')}
								</i>
							) : (
								<i>
									{nickname} {t('message coin text 2')} {points} {t('message coin text 3')}
								</i>
							)}
						</Fragment>
					) : (
						<div
							className={classes.messageText}
							dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
						/>
					)}
				</Fragment>
			)}

			{present && (
				<div
					className={classes.presentImage}
					style={{
						background: `url(${message.present.filename}) no-repeat center`,
						backgroundSize: 'contain',
					}}
				/>
			)}
			<div className={classes.createdAt}>{createdAt}</div>
		</div>
	);
}
