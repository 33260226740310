import { useCallback, useState, useEffect } from 'react';

const useHigherWindowHeight = () => {
	const [windowHeight, setWindowHeight] = useState(0);
	const handleWinResize = useCallback(() => {
		if (window.innerHeight > windowHeight) {
			setWindowHeight(window.innerHeight);
		}
	}, [windowHeight]);

	useEffect(() => {
		handleWinResize();
		window.addEventListener('resize', handleWinResize);
		return () => {
			window.removeEventListener('resize', handleWinResize);
		};
	}, [handleWinResize]);

	return windowHeight;
};

export default useHigherWindowHeight;
