import { combineReducers } from 'redux';
import coreReducer from './core/core.store';
import profileReducer from './profile/profile.store';
import userReducer from './user/user.store';
import pointsReducer from './points/points.store';
import contentReducer from './content/content.store';
import notificationsReducer from './notifications/notifications.store';
import entryReducer from './entry/entry.store';
import membershipReducer from './membership/membership.store';
import packageReducer from './package/package.store';
import chatReducer from './chat/chat.store';
import messageReducer from './message/message.store';
import presentsReducer from './presents/presents.store';
import entryCommentsReducer from './entry-comments/entry-comments.store';
import blockReducer from './block/block.store';
import themeReducer from './theme/theme.store';

export default combineReducers({
	core: coreReducer,
	profile: profileReducer,
	user: userReducer,
	notifications: notificationsReducer,
	points: pointsReducer,
	content: contentReducer,
	entry: entryReducer,
	membership: membershipReducer,
	package: packageReducer,
	chat: chatReducer,
	message: messageReducer,
	presents: presentsReducer,
	entryComments: entryCommentsReducer,
	block: blockReducer,
	theme: themeReducer,
});
