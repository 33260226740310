import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import InlineInfo from '../../shared/components/InlineInfo';
import { useTranslation } from 'react-i18next';

export default function GuestAuthSuccessScreen() {
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<Fragment>
			<HeaderAppBar
				title={t('register')}
				leftIcon={<BackNavigationIcon onClick={() => history.replace('/auth/login')} />}
				rightIcon={null}
			/>

			<Box p={2}>
				<InlineInfo
					title={t('thank you') + '!'}
					text={
						'Vielen Dank für Deine Anmeldung!<br /><br />In ein paar Minuten bekommst du eine E-Mail von uns. Bitte überprüfe dein Postfach und folge den Anweisungen aus der E-Mail. Bitte prüfe ggf. auch die Spam und Junk Ordner.'
					}
				/>
			</Box>
		</Fragment>
	);
}
