import React, { Fragment } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import DangerousHtml from '../../../common/DangerousHtml';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Cloud from '../../../common/Cloud';
import SectionHeading from '../../../common/SectionHeading';
import Box from '@material-ui/core/Box';

const useStyle = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: 15,
		minHeight: 120,
	},
	titleText: {
		color: theme.textDark,
	},
	messageText: {
		fontSize: 14,
		color: theme.textLight,
	},
}));

const iconSize = 55;

export default function InlineInfo({ title, text }) {
	const classes = useStyle();

	return (
		<Box>
			<SectionHeading className={classes.titleText}>{title}</SectionHeading>
			<Cloud className={classes.root}>
				<CheckCircleOutlineIcon
					style={{ color: 'green', width: iconSize, height: iconSize, margin: '0 20px' }}
				/>
				<Typography component={'div'} className={classes.messageText}>
					<DangerousHtml html={text} />
				</Typography>
			</Cloud>
		</Box>
	);
}
