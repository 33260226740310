import React from 'react';
import { makeStyles } from '@material-ui/core';
import femalePink from '../../../assets/ic_female_pink-web.png';
import maleBlue from '../../../assets/ic_male_blue-web.png';
import EditIcon from '@material-ui/icons/Edit';
import { faMars, faPencilAlt, faVenus } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyle = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	nickname: {
		color: theme.userName,
		fontWeight: '600',
		fontSize: 20,
	},
	gender: {
		width: 25,
		height: 25,
	},
	icons: {
		fontSize: 20,
		color: theme.mainBlue,
		marginLeft: 5,
	},
}));

export default function UserName({
	nickname,
	age,
	gender,
	suffix,
	handleGenderClick: onGenderClick,
	genderChanged,
	verifiedUser,
}) {
	const classes = useStyle();
	let profilePath = window.location.pathname.split('/');

	return (
		<div className={classes.root} style={{ cursor: 'pointer' }}>
			<div className={classes.nickname}>{nickname}</div>
			<div>{age}</div>
			<div onClick={onGenderClick}>
				<FontAwesomeIcon
					icon={gender === 'w' ? faVenus : faMars}
					className={classes.icons}
					color={gender === 'w' ? '#FF00FF' : theme.mainBlue}
				/>
				{!genderChanged && profilePath[1] === 'profile' && (
					<FontAwesomeIcon
						icon={faPencilAlt}
						style={{ cursor: 'pointer', marginLeft: 10, color: theme.mainBlue, fontSize: 20 }}
						color={theme.headerIcon}
					/>
				)}
			</div>
			{verifiedUser}
			{suffix ? <div className={classes.suffix}>{suffix}</div> : null}
		</div>
	);
}
