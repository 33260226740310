import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import EntryItem from './EntryItem';
import { useSelector } from 'react-redux';
import { blockedEntryIdsSelector } from '../../../../store/entry/entry.store';
import Masonry from 'react-masonry-component';
import { isMobileDevice } from '../../../../helpers';
import theme from '../../../../theme';

const ItemsLayoutMediator = ({ children }) => {
	if (isMobileDevice()) {
		return children;
	} else {
		return (
			<Masonry enableResizableChildren={true} options={{ fitWidth: true }} style={{ overflow: 'hidden', margin: '0 auto' }}>
				{children}
			</Masonry>
		);
	}
};

export default function EntryList({ entries, isLoading, hasMore, loadMore }) {
	const blockedEntryIds = useSelector(blockedEntryIdsSelector);
	const data = useMemo(
		() => entries.filter(entry => !blockedEntryIds.includes(entry.id)),
		[entries, blockedEntryIds],
	);
	const columnItem = entry => {
		return <EntryItem key={entry.id} {...entry} />;
	};

	return (
		<InfiniteScroll
			initialLoad={false}
			loadMore={loadMore}
			hasMore={hasMore && !isLoading}
			style={{ backgroundColor: theme.mainBackgroundScreens }}
		>
			<ItemsLayoutMediator>
				{data?.map((entry, key) => (
					<div key={entry.id}>
						<div>{key % 3 === 0 && columnItem(entry)}</div>
						<div>{key % 3 === 1 && columnItem(entry)}</div>
						<div>{key % 3 === 2 && columnItem(entry)}</div>
					</div>
				))}
			</ItemsLayoutMediator>
		</InfiniteScroll>
	);
}
