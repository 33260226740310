import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	entriesHasMoreSelector,
	entriesPageSelector,
	entriesSelector,
	isEntriesLoadingSelector,
	loadInitialBlockedEntryIds,
	searchEntries,
	searchFirstEntries,
} from '../../../store/entry/entry.store';
import { nextLocationSelector } from '../../../store/core/core.store';

export default function useEntries({ type, categoryId }) {
	const dispatch = useDispatch();
	const entries = useSelector(entriesSelector);
	const nextLocation = useSelector(nextLocationSelector);
	const isLoading = useSelector(isEntriesLoadingSelector);
	const page = useSelector(entriesPageSelector);
	const hasMore = useSelector(entriesHasMoreSelector);
	const nextPathName = nextLocation?.pathname;

	useEffect(() => {
		dispatch(loadInitialBlockedEntryIds());
	}, [dispatch]);

	const loadNext = useCallback(() => {
		dispatch(searchEntries({ page: page + 1, type, categoryId }));
	}, [dispatch, page, type, categoryId]);

	useEffect(() => {
		if (
			(!nextPathName?.includes('comments') &&
				page === 0 &&
				!(nextPathName?.includes('community') && nextPathName.length > 11)) ||
			categoryId === 'NEWEST'
		) {
			dispatch(searchFirstEntries({ page: 1, type, categoryId }));
		}
	}, [dispatch, type, categoryId]);

	return {
		page,
		isLoading,
		entries,
		hasMore,
		loadNext,
	};
}
