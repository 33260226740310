import React from 'react';
import useStyles from '../../screens/style';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export default function NextStepButton({ changeStep, text }) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Button
			type="submit"
			className={classes.nextButton}
			style={{
				width: '100%',
				backgroundColor: '#067afd',
				color: '#FFF',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: 44,
				marginTop: 15,
				borderRadius: 30,
			}}
			onClick={changeStep}
		>
			{text || t('continue')}
		</Button>
	);
}
