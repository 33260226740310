import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';

import IcStarVote from '../../../assets/ic_star_vote-web.png';
import IcStarVoted from '../../../assets/ic_star_voted-web.png';

const useStyle = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
}));

export default function RateStars({ count = 5, size = 5, gap = 0, value, onChange: handleChange }) {
	const classes = useStyle();
	const items = useMemo(
		() =>
			Array(count)
				.fill(null)
				.map((_, index) => index + 1),
		[count],
	);
	const itemStyle = useMemo(
		() => ({
			width: size,
			height: size,
			margin: `0 ${gap}px`,
		}),
		[size, gap],
	);

	return (
		<div className={classes.root}>
			{items.map(star => (
				<img
					key={star}
					onClick={handleChange ? () => handleChange(star) : null}
					src={star > value ? IcStarVote : IcStarVoted}
					alt={`Rate to ${star}`}
					style={itemStyle}
				/>
			))}
		</div>
	);
}
