import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { authorizeRemoteLogin } from '../../../store/profile/profile.store';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/Loader';

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function RemoteLoginScreen() {
	const queryParams = useQuery();
	const dispatch = useDispatch();
	const user = useSelector(state => state.profile.user);
	const isLogging = useSelector(state => state.profile.isLogging);
	const history = useHistory();

	useEffect(() => {
		const localToken = localStorage.getItem('token');
		const token = queryParams.get('token');
		if(localToken){
			localStorage.setItem('token', token);
		}
		if (!token) {
			window.location.assign('/auth/guest&step=2');
		} else {
			dispatch(authorizeRemoteLogin(token));
		}
	}, [queryParams]);

	useEffect(() => {
		if (!isLogging && user) {
			const conversationId = queryParams.get('conversation_id');
			history.replace(conversationId ? `/conversations/${conversationId}` : '/feed');
		}
	}, [isLogging, user, history]);

	return (
		<>
			<Loader />
		</>
	);
}
