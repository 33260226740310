import React from 'react';
import { makeStyles } from '@material-ui/core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyle = makeStyles(theme => ({
	root: {
		borderRadius: '50%',
		display: 'block',
		position: 'relative',
		overflow: 'hidden',
		'&:hover': {
			'& $animateAvatar': {
				animation: '$slideDown 0.3s',
				display: 'flex',
			},
		},
	},
	withBorder: {
		border: '2px solid black',
	},
	img: {
		display: 'block',
		borderRadius: 'inherit',
	},
	animateAvatar: {
		position: 'absolute',
		width: '100%',
		height: 40,
		borderBottomLeftRadius: '50%',
		borderBottomRightRadius: '50%',
		backgroundColor: 'rgba(0,0,0,0.4)',
		bottom: 0,
		display: 'none',
		cursor: 'pointer',
		justifyContent: 'center',
		alignItems: 'center',
		paddingRight: 5,
		paddingBottom: 3,
	},
	'@keyframes slideDown': {
		from: { bottom: -40 },
		to: { bottom: 0 },
	},
}));

export default function UserAvatar({
	src,
	size = 200,
	withBorder = true,
	styles = false,
	animationChange = false,
	...restProps
}) {
	const classes = useStyle();
	const className = [classes.root];

	if (withBorder) {
		className.push(classes.withBorder);
	}
	return (
		<div
			{...restProps}
			className={className.join(' ')}
			style={src && { width: size, height: size, ...styles }}
		>
			{src && (
				<img
					className={classes.img}
					src={src}
					alt={'logo'}
					style={{ width: size - (styles ? 10 : 4), height: size - (styles ? 10 : 4) }}
				/>
			)}
			{animationChange && (
				<div
					className={classes.animateAvatar}
					style={{
						width: size,
					}}
				>
					<FontAwesomeIcon icon={faCamera} style={{ color: theme.white, fontSize: 20 }} />
				</div>
			)}
		</div>
	);
}
