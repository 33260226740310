import React from 'react';
import { useTranslation } from 'react-i18next';
import useLocalDate from '../../../hooks/useLocalDate';
import Box from '@material-ui/core/Box';

const CreatedDate = ({notification:{created_at, read}}) => {
	const { t } = useTranslation();
	const createdAt = useLocalDate({ date: created_at });

	let result = [createdAt];
	if (read === 0) {
		result.push(<div style={{ color: 'green', paddingLeft: 15 }}>{t('new')}</div>);
	}
	return <Box display={'flex'}>{result}</Box>;
};

export default CreatedDate;
