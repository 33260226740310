import React, {useEffect, useState} from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import theme from "../../../../theme";

const useStyle = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    image: {
        width: '100%',
        display: 'block',
    },
    text: {
        color: theme.feedItemText,
        fontWeight: 'bold',
        fontSize: 14,
        wordWrap: 'break-word'
    },
}));

export default function EntryItemMedia({id, link, message, type}) {
    const classes = useStyle();
    const { t } = useTranslation();
    const [readMoreMessage, steReadMoreMessage] = useState('')
    const [readMoreText, steReadMoreText] = useState('')

    useEffect(() => {
        message?.length > 600 && steReadMoreMessage(message.slice(0, 600))
        message?.length < 600 && steReadMoreMessage(message)
        steReadMoreText(t('read more'))
    }, [message])

    const readMore = () => {
        if (readMoreMessage.length === 600) {
            steReadMoreMessage(message)
            steReadMoreText(t('show less'))
        }else{
            steReadMoreMessage(message.slice(0, 600))
            steReadMoreText(t('read more'))
        }
    }

    return (
        <div className={classes.root}>
			{(link && type !== '2') ? <img src={link} alt={id} className={classes.image}/> : null}
			{type === '2' ?
				<iframe style={{
					height: '280px',
					width: '100%'
				}} showinfo='0' rel='0' src={`https://www.youtube.com/embed/${link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    :
				null
			}
            {message ? (
                <Box p={1}>
                    <Typography className={classes.text}>
                        {readMoreMessage}
                        &nbsp;
                        {message.length > 600 &&
                        <span
                            style={{
                                textDecoration: 'underline',
                                color: theme.mainBlue,
                                cursor: 'pointer',
                                wordWrap: 'break-word'
                            }}
                            onClick={() => readMore()}
                        >
                                 {readMoreText}
                        </span>
                        }
                    </Typography>
                </Box>
            ) : null}
        </div>
    );
}
