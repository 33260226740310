import React from 'react';
import useStyles from '../../screens/style';
import BackArrow from '../../../../assets/backward-arrow.png';

export default function BackStepButton({ changeStep }) {
	const classes = useStyles();

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'flex-end',
				marginTop: 15,
			}}
		>
			<div className={classes.iconBackContainer} onClick={changeStep}>
				<img src={BackArrow} alt="man" className={classes.iconBack} />
			</div>
		</div>
	);
}
