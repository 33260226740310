import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {settingsSelector} from '../../../store/core/core.store';
import {
    currentUserSelector,
    fetchProfile,
    isBirthdayChangedSelector,
    isRegisteredSelector,
    loginSuccess,
    updateProfileBirthday,
    updateProfileCountry,
    updateProfileGender,
    updateProfileSlogan,
    updateProfileZipCode,
} from '../../../store/profile/profile.store';
import {makeStyles} from '@material-ui/core/styles';
import {faChevronDown, faUser} from '@fortawesome/free-solid-svg-icons';
import {faBullhorn} from '@fortawesome/free-solid-svg-icons';
import {faVenusMars as faGender} from '@fortawesome/free-solid-svg-icons';
import {faMapMarkerAlt as faLocation} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import Box from '@material-ui/core/Box';
import ChangePasswordScreen from './ChangePasswordScreen';
import Modal from '@material-ui/core/Modal';
import CountryAutocomplete from '../components/CountryAutocomplete';
import EditProfileDatePicker from '../components/EditProfileDatePicker';
import moment from 'moment';
import {DeleteAccount} from '../components/DeleteAccount';
import ProfileService from '../../../services/ProfileService';
import Loader from "../../../common/Loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../../theme";
import store from "../../../store/store";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            padding: '0 25px',
            paddingTop: 50,
        },
        color: 'rgb(85, 85, 85)',
    },
    userCredentials: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 80,
        [theme.breakpoints.down('md')]: {
            marginBottom: 20,
        },
    },
    username: {
        marginBottom: 30,
    },
    changePasswordButton: {
        background: 'none',
        border: 'none',
        alignSelf: 'end',
        cursor: 'pointer',
        color: '#5e96ea',
        marginTop: 4,
        fontWeight: 'bold',
    },
    input: {
        width: 'inherit',
        padding: '16px',
        height: '100%',
        borderRadius: 4,
        border: '#c5c5c5 solid 1px',
        appearance: 'none',
        color: theme.textLight,
        backgroundColor: theme.borderContainers,
    },
    withIcon: {
        paddingLeft: '36px',
    },
    iconInputContainer: {
        width: '100%',
        position: 'relative',
    },
    confirmed: {
        position: 'absolute',
        top: '50%',
        left: '100%',
        transform: 'translate(-120%, -50%)',
        color: '#2db852',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    icon: {
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translate(0%, -50%)',
        cursor: 'pointer',
    },
    icon1: {
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translate(0%, -50%)',
        cursor: 'pointer',
        pointerEvents: 'none'
    },
    gridInputsContainer: {
        display: 'flex',
        width: '100%',
        gap: 30,
        justifyContent: 'space-between',
        marginBottom: 20,
        '& .MuiAutocomplete-root': {
            width: 'inherit',
        },
        '& .MuiFormControl-root.MuiTextField-root': {
            width: 'inherit',
        },
        '& .MuiInputBase-input': {
            fontSize: 14,
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    textAreaContainer: {
        width: '100%',
    },
    textArea: {
        width: 'inherit',
        resize: 'none',
        padding: '16px',
        borderRadius: 4,
        border: '#c5c5c5 solid 1px',
        marginBottom: 10,
        marginTop: 10,
        color: 'inherit',
        fontSize: 14,
        backgroundColor: theme.white
    },
    textAreaLabel: {
        '& label': {
            marginLeft: 10,
        },
    },
    approveButton: {
        width: '100%',
        height: 40,
        background: '#29a747',
        color: 'white',
        border: 'none',
        borderRadius: 45,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:disabled': {
            opacity: 0.6,
            cursor: 'initial',
        },
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '80%',
        backgroundColor: theme.mainBackgroundScreens,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        },
    },
    modalBack: {
        backgroundColor: theme.modalColor,
    },
    progress: {
        color: theme.white
    },
}));

const EditProfileScreen = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const settings = useSelector(settingsSelector);
    const profile = useSelector(currentUserSelector);
    const isBirthdayChanged = useSelector(isBirthdayChangedSelector);
    const isRegistered = useSelector(isRegisteredSelector);

    const [formData, setFormData] = useState({zipcode: '', gender: '', slogan: ''});
    const [openPasswordChanger, setOpenPasswordChanger] = useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [chosenCountry, setChosenCountry] = useState({});
    const [chosenBirthday, setChosenBirthday] = useState('');
    const userCountry = useMemo(
        () => settings?.countries?.find(el => el.id === profile.country),
        [profile.country, settings?.countries],
    );
    const isProfileUpdating = useSelector(state => state.profile.isProfileUpdating);
    const { darkMode } = store.getState()?.theme;

    useEffect(() => {
        ProfileService.me()
            .then(({data}) => {
                dispatch(
                    loginSuccess({
                        user: data,
                    }),
                );
            })
            .catch(e => console.log(e));
    }, []);

    useEffect(() => {
        setChosenCountry(userCountry);
    }, [profile.country, settings.countries]);

    useEffect(() => {
        setChosenBirthday(profile.birthday);
    }, [profile.birthday]);

    useEffect(() => {
        const {slogan, gender, zipcode} = profile;
        setFormData(state => ({...state, slogan, gender, zipcode}));
    }, [profile.slogan, profile.gender, profile.zipcode, profile.country, settings.countries]);

    const handleChange = e => {
        const value = {[e.target.name]: e.target.value};
        setFormData(state => ({...state, ...value}));
    };
    const {gender, zipcode, slogan} = formData;
    const country = chosenCountry?.id;
    const birthday = chosenBirthday;
    const handleApprove = () => {

        let isRequested = false;
        if (gender !== profile.gender) {
            dispatch(updateProfileGender(gender))
            isRequested = true;
        }
        if (zipcode !== profile.zipcode) {
            dispatch(updateProfileZipCode(zipcode))
            isRequested = true;
        }
        if (slogan !== profile.slogan) {
            dispatch(updateProfileSlogan(slogan))
            isRequested = true;
        }
        if (country !== userCountry?.id) {
            dispatch(updateProfileCountry(country))
            isRequested = true;
        }
        if (birthday !== profile.birthday || '') {
            dispatch(updateProfileBirthday(birthday))
            isRequested = true;
        }
        if (isRequested) {
            dispatch(fetchProfile());
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.userCredentials}>
                <label htmlFor="username">{t('username')}</label>
                <div className={`${classes.iconInputContainer} ${classes.username}`}>
                    <input
                        className={`${classes.input} ${classes.withIcon}`}
                        value={profile?.nickname}
                        disabled
                        id="username"
                    />
                    <span className={classes.icon}>
						<FontAwesomeIcon color={'rgb(180,180,180)'} icon={faUser} style={{fontSize: 16}}/>
					</span>
                </div>
                <label htmlFor="email">{t('email')}</label>
                <div className={classes.iconInputContainer}>
                    {isRegistered && <span className={classes.confirmed}>{t('confirmed')}</span>}
                    <input
                        disabled
                        className={classes.input}
                        value={profile.email || localStorage.getItem('mail')}
                        id="email"
                    />
                </div>
                <button
                    disabled={!isRegistered}
                    className={classes.changePasswordButton}
                    onClick={() => setOpenPasswordChanger(true)}
                >
                    {t('change password')}
                </button>
            </div>
            <div className={classes.gridInputsContainer}>
                <CountryAutocomplete
                    countries={settings?.countries}
                    disabled={profile.country_changed}
                    setCountry={value => {
                        setChosenCountry(value);
                        setFormData(state => ({...state, zipcode: ''}));
                    }}
                    defaultCountry={settings?.countries?.find(el => el.id === profile.country)}
                    style={{
                        border: darkMode ? '#c5c5c5 solid 1px' : '',
                        borderRadius: 4,
                        backgroundColor: profile.country_changed ? theme.borderContainers : '#FFF',
                    }}
                />
                <div className={classes.iconInputContainer}>
                    <select
                        name="zipcode"
                        id="zip"
                        className={`${classes.input} ${classes.withIcon}`}
                        style={{
                            backgroundColor: profile.zip_changed ? theme.borderContainers : '#FFF'
                        }}
                        onChange={handleChange}
                        disabled={profile.zip_changed}
                    >
                        <option selected={!formData.zipcode} disabled={formData.zipcode} value="">
                            {t('zip code')}
                        </option>
                        {chosenCountry?.zipcodes?.map(el => (
                            <option value={el} selected={el === formData.zipcode}>
                                {el}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="zip" className={classes.icon}>
                        <FontAwesomeIcon
                            color={'rgb(180,180,180)'}
                            icon={faLocation}
                            style={{fontSize: 16}}
                        />
                    </label>
                    <label htmlFor="zip" className={classes.icon1}>
                        <FontAwesomeIcon
                            color={'rgb(180,180,180)'}
                            icon={faChevronDown}
                            style={{fontSize: 16}}
                        />
                    </label>
                </div>
            </div>
            <div className={classes.gridInputsContainer}>
                <div className={classes.iconInputContainer}>
                    <input
                        className={`${classes.input} ${classes.withIcon}`}
                        style={{
                            backgroundColor: isBirthdayChanged ? theme.borderContainers : '#FFF'
                        }}
                        value={chosenBirthday ? moment(chosenBirthday).format('L') : ''}
                        disabled={isBirthdayChanged}
                        onClick={() => setOpenDatePicker(true)}
                    />
                    <span className={classes.icon}>
						<EditProfileDatePicker
                            isOpen={openDatePicker}
                            setIsOpen={setOpenDatePicker}
                            value={chosenBirthday}
                            disabled={isBirthdayChanged}
                            onApprove={value => {
                                setChosenBirthday(value);
                                setOpenDatePicker(false);
                            }}
                        />
					</span>
                    <label htmlFor="zip" className={classes.icon1}>
                        <FontAwesomeIcon
                            color={'rgb(180,180,180)'}
                            icon={faChevronDown}
                            style={{fontSize: 16}}
                        />
                    </label>
                </div>
                <div className={classes.iconInputContainer}>
                    <select
                        name="gender"
                        id="gender"
                        className={`${classes.input} ${classes.withIcon}`}
                        style={{
                            backgroundColor: profile.gender_changed ? theme.borderContainers : '#FFF'
                        }}
                        disabled={profile.gender_changed}
                        onChange={handleChange}
                    >
                        <option value="m" selected={formData.gender === 'm'}>
                            {t('male')}
                        </option>
                        <option value="w" selected={formData.gender === 'w'}>
                            {t('female')}
                        </option>
                    </select>
                    <label htmlFor="gender" className={classes.icon}>
                        <FontAwesomeIcon color={'rgb(180,180,180)'} icon={faGender} style={{fontSize: 16}}/>
                    </label>
                    <label htmlFor="zip" className={classes.icon1}>
                        <FontAwesomeIcon
                            color={'rgb(180,180,180)'}
                            icon={faChevronDown}
                            style={{fontSize: 16}}
                        />
                    </label>
                </div>
            </div>
            <div className={classes.textAreaContainer}>
                <div className={classes.textAreaLabel}>
                    <FontAwesomeIcon color={'rgb(180,180,180)'} icon={faBullhorn} style={{fontSize: 16}}/>
                    <label htmlFor="area">{t('profiltext')}</label>
                </div>
                <textarea
                    name="slogan"
                    className={classes.textArea}
                    rows={6}
                    value={formData.slogan}
                    onChange={handleChange}
                />
            </div>
            {isProfileUpdating ?
                <button className={classes.approveButton}>
                    <CircularProgress size={30} className={classes.progress}/>
                </button>
                :
                <button className={classes.approveButton} style={{
                    backgroundColor: gender !== profile.gender ||
                    zipcode !== profile.zipcode ||
                    slogan !== profile.slogan ||
                    country !== userCountry?.id ||
                    birthday !== profile.birthday ? '#29a747' : '#c5c5c5'
                }} onClick={handleApprove}>
                    <div>
                        {t('save')}
                    </div>
                </button>
            }

            {isRegistered && <DeleteAccount/>}
            <Modal
                open={openPasswordChanger}
                onClose={() => setOpenPasswordChanger(false)}
                className={classes.modalBack}
            >
                <Box className={classes.modal}>
                    <ChangePasswordScreen onClose={() => setOpenPasswordChanger(false)}/>
                </Box>
            </Modal>
        </div>
    );
};

export default EditProfileScreen;
