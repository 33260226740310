import React from 'react';
import Button from '@material-ui/core/Button';
import {
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function InfoDialog({
	open,
	title,
	text,
	children,
	onOk,
	onClose,
	onCancel,
	okText,
	cancelText,
	okColor,
	cancelColor,
}) {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent style={{ paddingTop: 20 }}>
				{title ? (
					<DialogTitle style={{ textAlign: 'center', fontSize: '14px' }}>{title}</DialogTitle>
				) : null}
				{text ? <DialogContentText>{text}</DialogContentText> : null}
				{children ? <DialogContent>{children}</DialogContent> : null}
			</DialogContent>
			<DialogActions>
				{onCancel ? (
					<Button onClick={onCancel} color={cancelColor || 'default'}>
						{cancelText || t('cancel')}
					</Button>
				) : null}
				<Button onClick={onOk} color={okColor || 'primary'} autoFocus>
					{okText || 'Ok'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
