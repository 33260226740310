import i18n from 'i18next';
import { enhanceNumberByPosition } from '../../../helpers';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import useLocalDate from '../../../hooks/useLocalDate';

const useStyle = makeStyles(theme => ({
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '3px 0',
		color: theme.textDark,
	},
	info: {
		display: 'flex',
		alignItems: 'center',
	},
	title: {
		fontSize: 16,
		fontWeight: 'bold',
		color: theme.palette.primary.darker_gray_text,
	},
	date: {
		fontWeight: 'bold',
		fontSize: 13,
		color: theme.palette.primary.darker_gray_text,
	},
	point: {
		fontWeight: 'bold',
		fontSize: 16,
	},
	positivePoint: {
		color: theme.palette.primary.points_green,
	},
	negativePoint: {
		color: theme.palette.primary.points_red,
	},
}));

const RewardItem = ({ reward }) => {
	const classes = useStyle();
	const updatedAt = useLocalDate({ date: reward.updated_at, fromNow: true, lang: i18n.language });

	return (
		<div className={classes.item} key={reward.id}>
			<div className={classes.info}>
				<span className={classes.title}>{reward.reasonString}</span>
				<span className={classes.date}>&nbsp;- {updatedAt}</span>
			</div>
			<div
				className={
					classes.point + ' ' + (+reward.points > 0 ? classes.positivePoint : classes.negativePoint)
				}
			>
				{enhanceNumberByPosition(reward.points)}
			</div>
		</div>
	);
};

export default RewardItem;
