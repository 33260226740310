import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Man from '../../../assets/man.png';
import Woman from '../../../assets/women.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		backgroundColor: 'transparent',
	},
	buttonRoot: {
		width: '100%',
		color: 'white',
		opacity: 0.3,
		textTransform: 'unset',
		fontSize: 16,
	},
	toggleContainer: {
		margin: theme.spacing(2, 0),
	},
	selected: {
		color: 'white !important',
		opacity: 1,
	},
	icon: {
		width: 22,
		marginLeft: 5,
	},
}));

export default function ToggleButtonForStep({ setValue, value }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<ToggleButtonGroup
			value={value}
			exclusive
			onChange={(_, value) => setValue(value)}
			aria-label="text alignment"
			className={classes.root}
		>
			<ToggleButton
				className={classes.buttonRoot}
				classes={{
					selected: classes.selected,
				}}
				style={{
					backgroundColor: '#40a4d0',
				}}
				value="m"
				aria-label="left aligned"
			>
				{t('i am male')}
				<img src={Man} alt="man" className={classes.icon} />
			</ToggleButton>
			<ToggleButton
				className={classes.buttonRoot}
				classes={{
					selected: classes.selected,
				}}
				style={{
					backgroundColor: '#90232B',
					marginLeft: '2%',
				}}
				value="w"
				aria-label="right aligned"
			>
				{t('i am female')}
				<img src={Woman} alt="woman" className={classes.icon} />
			</ToggleButton>
		</ToggleButtonGroup>
	);
}
