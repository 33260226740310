import React, { Fragment, useState } from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import ReportForm from '../../report/components/ReportForm';
import { REPORT_REASON_TYPES } from '../../../constants';
import ReportService from '../../../services/ReportService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';
import ReportCommentSuccessScreen from './ReportCommentSuccessScreen';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));
export default function ReportEntryCommentScreen({ commentId, replyId, onClose }) {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);
	const entityId = replyId ? replyId : commentId;
	const { t } = useTranslation();

	const handleSubmit = values => {
		const methodName = replyId ? 'reportReply' : 'reportComment';
		ReportService[methodName]({ commentId, replyId }).then(() => {
			handleOpen();
		});
	};

	return (
		<Fragment>
			<HeaderAppBar
				title={t('report')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
			/>
			<ReportForm
				initialValues={{
					id: `ID ${entityId}`,
					subject: REPORT_REASON_TYPES[0],
				}}
				onSubmit={handleSubmit}
				withSubject={true}
				withMessage={true}
			/>
			<Modal
				open={openModal}
				onClose={handleClose}
				onBackdropClick={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ReportCommentSuccessScreen onClose={onClose} />
				</Box>
			</Modal>
		</Fragment>
	);
}
