import React from 'react';
import { makeStyles } from '@material-ui/core';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const useStyle = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 'bold',
	},
	one: {
		color: theme.textDark,
		fontSize: '14px',
	},
	two: {
		color: theme.textDark,
		fontSize: '12px',
	},
}));

export default function DoubleBalance({ one, two }) {
	const classes = useStyle();
	return (
		<div className={classes.root}>
			<div>
				<div className={classes.one}>
					{one > 0 ? '+' : ''}
					{one}
				</div>
				{two ? <div className={classes.two}>+{two}</div> : null}
			</div>
			<div>
				<AccountBalanceWalletIcon
					style={{ color: '#FACE6C', display: 'block' }}
					fontSize={'large'}
				/>
			</div>
		</div>
	);
}
