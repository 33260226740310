import React, { useState } from 'react';
import InfoDialog from '../../../common/InfoDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

export default function InlineDatePicker({ value, onChange }) {
	const [dateValue, setDateValue] = useState(null);
	const [isDialog, setIsDialog] = useState(false);
	const { t } = useTranslation();

	const changeAge = date => {
		onChange(date);
		setIsDialog(false);
	};

	return (
		<div style={{ position: 'relative', overflow: 'hidden', height: 20 }}>
			<FontAwesomeIcon
				icon={faPencilAlt}
				style={{ cursor: 'pointer', marginLeft: 10, color: theme.mainBlue, fontSize: 20 }}
				color={theme.headerIcon}
				onClick={() => setIsDialog(true)}
			/>
			<InfoDialog
				title={t('change date') + ' :'}
				open={isDialog}
				onOk={() => changeAge(dateValue)}
				onClose={() => setIsDialog(false)}
				onCancel={() => setIsDialog(false)}
			>
				<input
					value={dateValue || value}
					type={'date'}
					onChange={event => setDateValue(event.target.value)}
				/>
			</InfoDialog>
		</div>
	);
}
