import { useHistory } from 'react-router-dom';
import ChatService from '../../../services/ChatService';

export default function useConversationRequest({ uid }) {
	const history = useHistory();
	return {
		startChat: async () => {
			try {
				const response = await ChatService.requestConversation(uid);
				const conversationId = response.data.id;
				history.push(`/conversations/${conversationId}`, 'internal');
			} catch (e) {
				console.error('fail: ', e);
			}
		},
	};
}
