import { createAction, createReducer } from 'redux-starter-kit';
import EntryService from '../../services/EntryService';

import { path } from 'ramda';

export const fetchEntryCommentsRequest = createAction('FETCH_ENTRY_COMMENTS_REQUEST');
export const fetchEntryCommentsFailure = createAction('FETCH_ENTRY_COMMENTS_FAILURE');
export const fetchEntryCommentsSuccess = createAction('FETCH_ENTRY_COMMENTS_SUCCESS');

export const postCommentRequest = createAction('POST_COMMENT_REQUEST');
export const postCommentFailure = createAction('POST_COMMENT_FAILURE');
export const postCommentSuccess = createAction('POST_COMMENT_SUCCESS');

export const addEntryCommentReply = createAction('ADD_ENTRY_COMMENT_REPLY');

export const addEntryComment = createAction('ADD_ENTRY_COMMENT');

export const resetEntryComments = createAction('RESET_ENTRY_COMMENTS');

const initialState = {
	comments: [],
	isLoading: false,
	page: 0,
	hasMore: false,

	isPosting: false,
};

export const fetchEntryComments =
	({ entryId, page }) =>
	dispatch => {
		dispatch(fetchEntryCommentsRequest());

		EntryService.getComments({ entryId, page })
			.then(({ data }) => {
				dispatch(
					fetchEntryCommentsSuccess({
						comments: data.data,
						hasMore: data.last_page > page,
						page,
					}),
				);
			})
			.catch(() => {
				dispatch(fetchEntryCommentsFailure());
			});
	};

export const postComment =
	({ comment, entryId, commentId }) =>
	dispatch => {
		dispatch(postCommentRequest());

		let promise = null;
		if (commentId) {
			promise = EntryService.replyToComment({ commentId, comment });
		} else {
			promise = EntryService.postComment({ entryId, comment });
		}

		promise
			.then(({ data }) => {
				dispatch(postCommentSuccess());
				if (!commentId) {
					dispatch(
						addEntryComment({
							comment: data.data,
						}),
					);
				}
			})
			.catch(() => {
				dispatch(postCommentFailure());
			});
	};

const entryCommentsReducer = createReducer(initialState, {
	// Fetch Entry Comments
	[fetchEntryCommentsRequest]: state => ({ ...state, isLoading: true }),
	[fetchEntryCommentsSuccess]: (state, { payload }) => ({
		...state,
		isLoading: false,
		comments: [...state.comments, ...payload.comments],
		page: payload.page,
		hasMore: payload.hasMore,
	}),
	[fetchEntryCommentsFailure]: state => ({ ...state, isLoading: false }),

	[postCommentRequest]: state => ({ ...state, isPosting: true }),
	[postCommentSuccess]: state => ({ ...state, isPosting: false }),
	[postCommentFailure]: state => ({ ...state, isPosting: false }),

	[addEntryComment]: (state, { payload }) => ({
		...state,
		comments: [
			{
				...payload.comment,
				replies: [],
			},
			...state.comments,
		],
	}),
	[addEntryCommentReply]: (state, { payload }) => ({
		...state,
		comments: state.comments.map(comment => {
			if (comment.id === payload.comment.id) {
				return {
					...comment,
					replies: [payload.reply, ...comment.replies],
				};
			} else {
				return comment;
			}
		}),
	}),

	[resetEntryComments]: state => ({
		...state,
		hasMore: false,
		comments: [],
		isLoading: false,
		page: 0,
	}),
});

const stateProp = key => path(['entryComments', key]);

export const entryCommentsSelector = stateProp('comments');
export const isEntryCommentsLoadingSelector = stateProp('isLoading');
export const entryCommentsPageSelector = stateProp('page');
export const hasMoreEntryCommentsSelector = stateProp('hasMore');
export const isPostingCommentSelector = stateProp('isPosting');

export default entryCommentsReducer;
