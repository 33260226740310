import React, { Fragment, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HeaderAppBar from '../../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../../shared/components/BackNavigationIcon';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchMembershipsByPaymentType,
	membershipIsLoadingSelector,
	membershipsSelector,
} from '../../../../store/membership/membership.store';
import PaymentList from '../../../shared/components/payment/PaymentList';
import Loader from '../../../../common/Loader';
import IcBuyPointsPackage from '../../../../assets/ic_buy_points_package.png';
import { useTranslation } from 'react-i18next';
import { currentUserSelector } from '../../../../store/profile/profile.store';

export default function MembershipBuyOptionScreen() {
	const history = useHistory();
	const { paymentTypeName } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(fetchMembershipsByPaymentType(paymentTypeName));
	}, [dispatch, paymentTypeName]);

	const memberships = useSelector(membershipsSelector);
	const isLoading = useSelector(membershipIsLoadingSelector);
	const profile = useSelector(currentUserSelector);

	const countryIds = [1, 2, 3, 21, 110, 154, 176, 201];
	const rateCoins = countryIds.includes(profile?.country) ? '$' : '$';

	const items = memberships.map(it => ({
		...it,
		primaryText: `${it.days} - Tage`,
		secondaryText: `${it.description} - ${it.costs} ${rateCoins}`,
		image: IcBuyPointsPackage,
	}));

	const goToForm = useCallback(
		id =>
			history.push(
				`/profile/options/membership/payment-types/${paymentTypeName}/options/${id}/form`,
			),
		[history, paymentTypeName],
	);

	return (
		<Fragment>
			<HeaderAppBar
				title={t('buy points now')}
				leftIcon={<BackNavigationIcon />}
				rightIcon={null}
			/>
			{isLoading ? <Loader /> : null}
			<Box p={1}>
				<PaymentList items={items} onClick={goToForm} />
			</Box>
		</Fragment>
	);
}
