import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoDialog from '../../../common/InfoDialog';
import { useTranslation } from 'react-i18next';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

function EditProfileDatePicker({ value, onApprove, isOpen, setIsOpen, disabled }) {
	const { t } = useTranslation();
	const [chosenValue, setChosenValue] = useState();

	useEffect(() => {
		setChosenValue(value);
	}, [value]);

	return (
		<div style={{ position: 'relative', overflow: 'hidden', height: 20 }}>
			<FontAwesomeIcon
				icon={faCalendarAlt}
				style={{ fontSize: 16, color: 'rgb(180, 180, 180)' }}
				onClick={disabled ? () => null : () => setIsOpen(true)}
			/>
			<InfoDialog
				title={t('change date') + ' :'}
				open={isOpen}
				onOk={() => onApprove(chosenValue)}
				onClose={() => setIsOpen(false)}
				onCancel={() => setIsOpen(false)}
			>
				<input
					value={chosenValue}
					type="date"
					onChange={event => setChosenValue(event.target.value)}
				/>
			</InfoDialog>
		</div>
	);
}

export default EditProfileDatePicker;
