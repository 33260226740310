import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.borderContainers,
		'&:hover, &:active, &:focus': {},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
		color: theme.textLight,
		backgroundColor: theme.borderContainers,
	},
	iconStyle: {
		color: theme.textDark,
	},
}));

export default function SearchField({ value, onChange }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<div className={classes.search}>
				<div className={classes.searchIcon}>
					<SearchIcon className={classes.iconStyle} />
				</div>
				<InputBase
					value={value}
					onChange={event => onChange(event.target.value)}
					placeholder={t('name search') + '…'}
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					inputProps={{ 'aria-label': 'search' }}
				/>
			</div>
		</div>
	);
}
