import React, { Fragment, useMemo, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import { Box, makeStyles } from '@material-ui/core';
import UserFeedEntries from './UserFeedEntries';
import { USER_ENTRY_TYPE } from '../../../constants';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tabsRoot: {
		backgroundColor: theme.mainBackgroundScreens,
		borderBottom: '2px solid #f3f6f5',
	},
	indicator: {
		backgroundColor: theme.mainBlue,
		height: 4,
		width: '100%',
	},
	tabsStyle: {
		width: 100,
		backgroundColor: 'transparent',
		flexGrow: 0,
		marginBottom: 5,
		[theme.breakpoints.down('md')]: {
			width: 'auto',
			flexGrow: 1,
		},
	},
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function TabPanel({ children, value, index, ...restProps }) {
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...restProps}
		>
			{children}
		</Typography>
	);
}

function FeedTabButton({ label, value }) {
	return (
		<div style={{ color: theme.blackWhite }}>
			<div
				style={{
					lineHeight: '17px',
					fontSize: 17,
					fontWeight: 'bold',
					marginBottom: 6,
				}}
			>
				{value}
			</div>
			<div
				style={{
					lineHeight: '16px',
					fontSize: 16,
					fontWeight: 'bold',
					textTransform: 'capitalize',
				}}
			>
				{label}
			</div>
		</div>
	);
}

export default function UserFeed({
	user: { uid, entryratings_count, comment_count, entries_count },
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	// Tabs
	const [selectedTab, setSelectedTab] = useState(0);

	const userEntryType = useMemo(
		() => [USER_ENTRY_TYPE.ENTRIES, USER_ENTRY_TYPE.COMMENTED, USER_ENTRY_TYPE.LIKES][selectedTab],
		[selectedTab],
	);

	return (
		<Box>
			<Tabs
				value={selectedTab}
				variant="fullWidth"
				textColor={'inherit'}
				onChange={(_, value) => setSelectedTab(value)}
				classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
				centered
			>
				<Tab
					className={classes.tabsStyle}
					label={<FeedTabButton value={entries_count} label={t('beichten')} />}
					{...a11yProps(0)}
				/>
				{/*<Tab*/}
				{/*	className={classes.tabsStyle}*/}
				{/*	label={<FeedTabButton value={comment_count} label={t('comments')} />}*/}
				{/*	{...a11yProps(1)}*/}
				{/*/>*/}
				<Tab
					className={classes.tabsStyle}
					label={<FeedTabButton value={entryratings_count} label={t('votes')} />}
					{...a11yProps(2)}
				/>
			</Tabs>
			<SwipeableViews axis={'x'} index={selectedTab} onChangeIndex={setSelectedTab}>
				<TabPanel value={selectedTab} index={0}>
					{selectedTab === 0 ? (
						<Fragment>
							<UserFeedEntries uid={uid} type={userEntryType} />
						</Fragment>
					) : null}
				</TabPanel>
				<TabPanel value={selectedTab} index={1}>
					{selectedTab === 1 ? (
						<Fragment>
							<UserFeedEntries uid={uid} type={userEntryType} />
						</Fragment>
					) : null}
				</TabPanel>
				<TabPanel value={selectedTab} index={2}>
					{selectedTab === 2 ? (
						<Fragment>
							<UserFeedEntries uid={uid} type={userEntryType} />
						</Fragment>
					) : null}
				</TabPanel>
			</SwipeableViews>
		</Box>
	);
}
