import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessageBubble from './MessageBubble';
import {
	conversationDetailsSelector,
	conversationRecipientSelector,
	getMessages,
	lastMessageSelector,
	markAsSeen,
	messagesSelector,
} from '../../../../store/message/message.store';
import { makeStyles } from '@material-ui/core';
import InlineLoader from '../../../../common/InlineLoader';
import ConversationRequestForm from './ConversationRequestForm';
import { scrollToTopForSure } from '../../../../helpers';
import { currentUserSelector } from '../../../../store/profile/profile.store';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

const loaderSize = 50;

const useStyles = makeStyles(theme => ({
	messagesContainer: {
		backgroundColor: 'white',
		minHeight: 'calc(100vh - 1px)', // TODO: @yeghishe.kartashyan consider this
		padding: 10,
		paddingBottom: 82,
		paddingTop: 130,
		backgroundAttachment: 'fixed',
	},
	message: {
		margin: 10,
		display: 'flex',
		wordWrap: 'break-word',
	},
	loaderContainer: {
		height: loaderSize,
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function Messages({ conversationId, userId, nickname }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const lastMessage = useSelector(lastMessageSelector);
	const messages = useSelector(messagesSelector);
	const { id, accepted } = useSelector(conversationDetailsSelector);
	const page = useSelector(state => state.message.page);
	const isLoading = useSelector(state => state.message.isLoading);
	const hasMore = useSelector(state => state.message.hasMore);
	const currentUser = useSelector(currentUserSelector);
	const recipient = useSelector(conversationRecipientSelector);
	const payMessageId = useSelector(state => state.message.payMessageId);
	const loadProducer = useCallback(() => {
		dispatch(getMessages({ conversationId, page: page + 1 }));
	}, [dispatch, conversationId, page]);

	useEffect(() => {
		if (id) {
			dispatch(markAsSeen(id));
		}
	}, [dispatch, id, lastMessage]);

	useEffect(() => {
		if (lastMessage) {
			window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
		}
	}, [lastMessage, document.body.scrollHeight]);

	const handleScroll = useCallback(() => {
		const allowScroll = window.pageYOffset === 0 && hasMore && !isLoading;
		if (allowScroll) {
			loadProducer();
		}
	}, [isLoading, hasMore, loadProducer]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [handleScroll]);

	useEffect(() => {
		dispatch(getMessages({ conversationId, page: 1 }));
	}, [dispatch, conversationId]);

	return (
		<div
			className={classes.messagesContainer}
			style={{ backgroundImage: `url(${theme.chatBackground})` }}
		>
			{isLoading ? (
				<div className={classes.loaderContainer}>
					<InlineLoader />
				</div>
			) : null}

			{accepted ? <div /> : <>{!isLoading && <ConversationRequestForm />}</>}

			<>
				{messages && !messages?.length ? (
					!messages?.length && accepted ? (
						<span style={{ fontSize: 15, color: theme.textDark }}>
							{t('send')} {nickname} {t('first message')}
						</span>
					) : null
				) : (
					<>
						{!(recipient?.uid === currentUser?.uid && !accepted) &&
							messages.map(message => (
								<div className={classes.message} key={message.id}>
									<MessageBubble
										userId={userId}
										nickname={nickname}
										message={message}
										present={message.present}
										points={message.points}
										attachment={message.attachment}
										conversationId={conversationId}
										payMessage={message.pay_message}
										isPaid={message.is_paid}
										price={message.coins}
										payMessageId={payMessageId === message.id}
										messageId={message.id}
									/>
								</div>
							))}
					</>
				)}
			</>
		</div>
	);
}
