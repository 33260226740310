import React from 'react';
import Cloud from '../../../common/Cloud';
import { ListItemText, makeStyles } from '@material-ui/core';
import ListItem from '../../../common/ListItem';
import List from '@material-ui/core/List';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../store/profile/profile.store';
import { ConfigCountry } from '../../../helpers/configCountry';

const useStyle = makeStyles(theme => ({
	root: {
		overflow: 'auto',
	},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 3,
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	title: {
		fontSize: 16,
		fontWeight: 'bold',
		color: theme.palette.primary.darker_gray_text,
	},
	point: {
		color: theme.palette.primary.darker_gray_text,
		fontSize: 13,
	},
	amount: {
		color: theme.palette.primary.points_green,
		fontSize: 20,
		fontWeight: 'bold',
	},
	primary: {
		color: theme.textDark,
	},
	secondary: {
		color: theme.textLight,
	},
}));

export default function RewardsList({ data, onClick }) {
	const classes = useStyle();
	const itemTextClasses = { primary: classes.primary, secondary: classes.secondary };
	const { t } = useTranslation();
	const rateCoins = ConfigCountry.international ? '$' : '€';

	return (
		<List component={Cloud} className={classes.root}>
			{data.map((it, index) => {
				return (
					<ListItem
						className={classes.item}
						key={it.id}
						divider={index !== data.length - 1}
						onClick={event => onClick(event, it.id)}
						style={{ cursor: 'pointer' }}
					>
						<ListItemText
							classes={itemTextClasses}
							primary={it.bezeichnung}
							secondary={
								it.missingPoint > 0
									? `${t('you still need')} ${it.missingPoint} ${t('points')}`
									: null
							}
						/>
						<div className={classes.info}>
							<div className={classes.amount}>
								{rateCoins} {parseInt(it.amount)}
							</div>
							<div className={classes.point}>
								{parseInt(it.points)} {t('points')}
							</div>
						</div>
					</ListItem>
				);
			})}
		</List>
	);
}
