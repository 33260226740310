import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import List from '@material-ui/core/List';
import Cloud from '../../../common/Cloud';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import SubmitButton from '../../shared/components/SubmitButton';
import { useFormik } from 'formik';
import { object, ref, string } from 'yup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import ProfileService from '../../../services/ProfileService';
import md5 from 'crypto-js/md5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { makeStyles } from '@material-ui/core';
import ContactSuccessScreen from '../../options/screens/ContactSuccessScreen';
import Modal from '@material-ui/core/Modal';
import ManageProfileSuccessScreen from './ManageProfileSuccessScreen';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.mainBackgroundScreens,
		height: '100%',
	},
	sendButton: {
		backgroundColor: '#29a646',
		'&:hover': {
			backgroundColor: 'green',
		},
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function ChangePasswordScreen({ onClose }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);
	const validationSchema = object().shape({
		currentPass: string().required(t('enter current password')),
		newPass: string().required(t('enter new password')),
		confirmPass: string()
			.oneOf([ref('newPass')], t('new and confirmed not matched'))
			.required(t('enter new password again')),
	});
	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		handleSubmit,
		isValid,
		isSubmitting,
		setErrors,
		setValues,
	} = useFormik({
		initialValues: {
			currentPass: '',
			newPass: '',
			confirmPass: '',
		},
		validationSchema,
		onSubmit: ({ currentPass, newPass, confirmPass }, { setSubmitting, resetForm }) =>
			_handleSubmit({
				currentPass,
				newPass,
				confirmPass,
				setSubmitting,
				resetForm,
			}),
	});

	const _handleSubmit = async ({ currentPass, newPass, resetForm }) => {
		try {
			const currentPassHash = md5(currentPass + '4500852acf9d074c0bf2c6f05556a106').toString();
			const newPassHash = (newPass || '').toString();
			ProfileService.changePassword(currentPassHash, newPassHash)
				.then(() => {
					handleOpen();
				})
				.catch(e => {
					console.log('error', e);
					setErrors({ currentPass: t('current password is wrong') });
				});
		} catch (e) {
			setValues({ currentPass: '' });
		}
	};

	return (
		<Box className={classes.root}>
			<HeaderAppBar
				title={t('change password')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>
			<Box p={1}>
				<form onSubmit={handleSubmit}>
					<List component={Cloud}>
						<FormControl fullWidth margin="dense">
							<InputLabel
								htmlFor="password-current"
								error={Boolean(touched.currentPass && errors.currentPass)}
								style={{ color: theme.mainBlue }}
							>
								{t('current password')}
							</InputLabel>
							<Input
								id="password-current"
								disableUnderline
								name="currentPass"
								type="password"
								value={values.currentPass}
								onChange={handleChange}
								onBlur={handleBlur}
								error={Boolean(touched.currentPass && errors.currentPass)}
								style={{ color: theme.textDark }}
							/>
							<FormHelperText error={Boolean(touched.currentPass && errors.currentPass)}>
								{touched.currentPass && errors.currentPass ? errors.currentPass : ''}
							</FormHelperText>
						</FormControl>
						<Divider />
						<FormControl
							fullWidth
							margin="dense"
							error={Boolean(touched.newPass && errors.newPass)}
						>
							<InputLabel
								htmlFor="password-new"
								error={Boolean(touched.newPass && errors.newPass)}
								style={{ color: theme.mainBlue }}
							>
								{t('new password')}
							</InputLabel>
							<Input
								id="password-new"
								disableUnderline
								name="newPass"
								type="password"
								value={values.newPass}
								onChange={handleChange}
								onBlur={handleBlur}
								error={Boolean(touched.newPass && errors.newPass)}
								style={{ color: theme.textDark }}
							/>
							<FormHelperText error={Boolean(touched.newPass && errors.newPass)}>
								{touched.newPass && errors.newPass ? errors.newPass : ''}
							</FormHelperText>
						</FormControl>
						<Divider />
						<FormControl
							fullWidth
							margin="dense"
							error={Boolean(touched.confirmPass && errors.confirmPass)}
						>
							<InputLabel
								htmlFor="password-confirm"
								error={Boolean(touched.confirmPass && errors.confirmPass)}
								style={{ color: theme.mainBlue }}
							>
								{t('confirm new password')}
							</InputLabel>
							<Input
								id="password-confirm"
								disableUnderline
								name="confirmPass"
								type="password"
								value={values.confirmPass}
								onChange={handleChange}
								onBlur={handleBlur}
								error={Boolean(touched.confirmPass && errors.confirmPass)}
								style={{ color: theme.textDark }}
							/>
							<FormHelperText error={Boolean(touched.confirmPass && errors.confirmPass)}>
								{touched.confirmPass && errors.confirmPass ? errors.confirmPass : ''}
							</FormHelperText>
						</FormControl>
					</List>

					<Box pt={5} pb={4}>
						<SubmitButton
							style={classes.sendButton}
							disabled={Boolean(!isValid || isSubmitting)}
							icon={<div />}
						>
							{t('save')}
						</SubmitButton>
					</Box>
				</form>
			</Box>
			<Modal
				open={openModal}
				onClose={handleClose}
				onBackdropClick={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ManageProfileSuccessScreen onClose={onClose} />
				</Box>
			</Modal>
		</Box>
	);
}
