import React, { useEffect } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function CountryAutocomplete({ countries, setCountry, defaultCountry, disabled, style }) {
	const { t } = useTranslation();

	useEffect(() => {
		setCountry(defaultCountry);
	}, [defaultCountry]);

	if (!defaultCountry) {
		return null;
	}

	const defaultProps = {
		options: countries?.map(el => ({ ...el, name: t(el.name) })),
		getOptionLabel: option => option.name,
	};

	return (
		<Autocomplete
			{...defaultProps}
			id="country"
			clearOnEscape
			disabled={disabled}
			defaultValue={{ ...defaultCountry, name: t(defaultCountry.name) }}
			autoHighlight={false}
			onChange={(event, newValue) => {
				setCountry(newValue);
			}}
			renderOption={option => (
				<>
					<span style={{ marginRight: '10px' }}>
						{option.name} ({option.code})
					</span>
					<img src={option.flagicon} width={30} height={20} alt="flag" />
				</>
			)}
			renderInput={params => (
				<TextField
					{...params}
					style={style}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<FontAwesomeIcon
									color={'rgb(180,180,180)'}
									icon={faGlobe}
									style={{ fontSize: 16 }}
								/>
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
}

export default CountryAutocomplete;
