import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export default function Select({ selectedProp, items, selectedId, onSelect, label, disabled }) {
	const selectedObj = items?.find(el => el.id == selectedId) || {
		name: selectedProp,
	};

	return (
		<div
			style={{
				display: 'flex',
				position: 'relative',
				padding: '4px 8px',
				borderBottom: 'solid gray 1px',
				justifyContent: 'space-between',
			}}
		>
			<select
				disabled={disabled}
				style={{
					position: 'absolute',
					top: '0',
					left: '0',
					height: '100%',
					opacity: 0,
					width: 100
				}}
				onChange={onSelect}
				value={selectedId}
			>
				{items?.map(item => (
					<option key={`option:${item.name}`} value={item.id}>
						{item.name}
					</option>
				))}
			</select>
			<div id="selectedOptionHidden" style={{ marginRight: 5 }}>
				{selectedObj.name}
			</div>
			<FontAwesomeIcon icon={faCaretDown} style={{ fontSize: 16 }} />
		</div>
	);
}
