import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import FeedCategories from '../components/FeedCategories';
import {useDispatch, useSelector} from 'react-redux';
import {
    categoriesSelector,
    fetchCategories,
    isEntriesLoadingSelector,
    resetEntries, searchFirstEntries,
} from '../../../store/entry/entry.store';
import FeedPeriodButtons from '../components/FeedPeriodButtons';
import NewestEntries from '../components/NewestEntries';
import MostSecretEntries from '../components/MostSecretEntries';
import Loader from '../../../common/Loader';
import {ENTRY_TYPE} from '../../../constants';
import HeaderRight from '../../shared/components/HedaerRight';
import theme from '../../../theme';
import {useTranslation} from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import {
    currentUserSelector,
    updateEmailReset,
    updateProfileEmail,
} from '../../../store/profile/profile.store';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import {faGlobe, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import FormErrorMessage from '../../../common/forms/FormErrorMessage';
import {ConfigCountry} from "../../../helpers/configCountry";
import {countriesSelector, fetchSettings} from "../../../store/core/core.store";
import Collapse from "@material-ui/core/Collapse";
import maleIcon from "../../../assets/ic_male_blue-web.png";
import Switcher from "../../community/components/Switcher";
import femaleIcon from "../../../assets/ic_female_pink-web.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.mainBackgroundScreens,
        marginTop: 60,
        paddingBottom: 64,
        '& .MuiInputBase-root': {
            marginRight: 30,
        },
        '& .MuiInputBase-root:hover > *': {
            borderColor: theme.textLight,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: theme.textLight,
        },
        '& .MuiAutocomplete-hasPopupIcon *': {
            color: theme.textLight,
            borderColor: theme.textLight,
        },
        '& .MuiAutocomplete-listbox': {
            backgroundColor: theme.mainBackgroundScreens,
            border: '1px solid gray',
            borderRadius: 0,
            '& > * > *': {
                backgroundColor: theme.mainBackgroundScreens,
                color: theme.mainBlue,
            },
            '& ul': {
                backgroundColor: theme.mainBackgroundScreens,
            },
        },
    },
    icons: {
        fontSize: 16,
        marginLeft: 10,
        color: theme.mainBlue,
    },
    searchRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingRight: '4%',
        flex: 1,
        cursor: 'pointer',
        paddingTop: '20px',

    },
    indicator: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
    },
    tabStyle: {
        backgroundColor: theme.mainBlue,
        borderRadius: 9,
        margin: '28px 50px 0',
        minHeight: 'auto',
        color: theme.white,
        padding: '4px 60px',
        [theme.breakpoints.down('md')]: {
            margin: '15px 6px 0',
            padding: '4px 15px',
            fontSize: 13,
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalItem: {
        width: '35%',
        backgroundColor: 'white',
        alignSelf: 'center',
        padding: '15px 20px 15px 20px',
        color: '#888',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
    },
    modalEmail: {
        position: 'relative',
        borderRadius: 4,
        alignItems: 'center',
        padding: '80px 20px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        width: '440px',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    gridItemForMobile: {

    },
    gridItemGender: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchInput: {
        width: 300,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 35
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
    },
    noOptions: {
        boxShadow: 'none',
        margin: 0,
        color: theme.mainBlue,
        backgroundColor: theme.textLight,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        paddingLeft: 9,
        '&[aria-selected="true"]': {
            backgroundColor: theme.headerBackground,
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.headerBackground,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    rootButton: {
        marginLeft: 20,
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 5,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    filterCountry: {
        marginLeft: '40px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
            marginTop: 10,
            marginBottom: 10
        },
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel({children, value, index, ...restProps}) {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...restProps}
        >
            {children}
        </Typography>
    );
}

export default function FeedScreen() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const validationSchema = Yup.object().shape({
        mail: Yup.string().label('Email').required(t('please enter email')),
    });
    const countries = useSelector(countriesSelector);

    const [state, setState] = useState({
            male: true,
            female: true,
        }
    );

    const [selectedCountry, setSelectedCountry] = useState('');

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);

    const {handleChange, values, errors, touched, handleSubmit, setErrors} = useFormik({
        initialValues: {
            mail: '',
        },
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(updateProfileEmail(values.mail));
        },
    });

    const isEntriesLoading = useSelector(isEntriesLoadingSelector);
    const errorEmail = useSelector(state => state.profile.errorEmail);
    const emailChanged = useSelector(state => state.profile.emailChanged);

    const [selectedPeriod, setSelectedPeriod] = useState(ENTRY_TYPE.BEST_WEEK);
    const [newEmail, setNewEmail] = useState(false);
    const profile = useSelector(currentUserSelector);
    const handleSelectedPeriodChange = useCallback(
        value => {
            selectedPeriod !== value && dispatch(resetEntries());
            return !isEntriesLoading ? setSelectedPeriod(value) : null;
        },
        [setSelectedPeriod, isEntriesLoading],
    );

    const [selectedTab, setSelectedTab] = useState(history?.location?.state?.setSelectedTabfrom?.tabIndex || 0);
    const [defaultCountry, setDefaultCountry] = useState(null);

    const [countryEgal, setCountryEgal] = useState([
        {
            id: '',
            name: t('all'),
        },
    ]);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    useEffect(() => {
        emailChanged && setNewEmail(false);
    }, [emailChanged]);

    useEffect(() => {
        setErrors({mail: errorEmail});
    }, [errorEmail]);

    useEffect(() => {
        dispatch(updateEmailReset());
    }, [errorEmail, newEmail]);

    const categories = useSelector(categoriesSelector);
    const onCategoryChange = id => history.push(`/feed/entries/category/${id}`);

    const tabHandler = index => {
        selectedTab !== index && dispatch(resetEntries());
        setSelectedTab(index);
    };

    const emailHandel = e => {
        dispatch(updateEmailReset());
        handleChange(e);
    };
    const newLangCountries = useMemo(
        () =>
            countries?.map(elem => {
                return {...elem, name: t(elem.name)};
            }, {}),
        [countries],
    );

    useEffect(() => {
        if(countries) {
            setCountryEgal([
                {
                    id: '',
                    name: t('all'),
                },
                ...newLangCountries,
            ]);
        }
        let defaultFilter = JSON.parse(window.localStorage.getItem('lastFilterFeed'));
        if(defaultFilter?.gender){
            setState(defaultFilter?.gender)
        }
        setDefaultCountry(defaultFilter?.country
            ? countries?.find(val => +val.id === +defaultFilter?.country)
            : {
                id: '',
                name: t('all'),
            })
    }, [countries]);

    const handleCountryNewChange = (event, values) => {
        const countryId = values?.id;
        setSelectedCountry(countryId);
    };
    const CustomPopper = function (props) {
        const classes = useStyles();
        return <Popper {...props} className={classes.root} placement="bottom"/>;
    };
    const options =
        countryEgal &&
        countryEgal?.map(option => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            };
        });
    const onSend = () =>{
        window.localStorage.setItem('lastFilterFeed', JSON.stringify({gender:state, country: selectedCountry}));
        if(selectedTab === 1){
            dispatch(searchFirstEntries({page: 1, type: selectedPeriod}));
        }else if(selectedTab === 0){
            dispatch(searchFirstEntries({page: 1, type: 'NEWEST'}));
        }
        setShowFilters(false)
    }

    return (
        <div className={classes.root}>
            <HeaderAppBar
                positionHeader={'fixed'}
                leftIcon={
                    <img
                        src={theme.smallLogo}
                        alt={'logo'}
                        className={classes.logo}
                        onClick={() => history.push('/feed')}
                        color={'action'}
                    />
                }
                rightIcon={<HeaderRight/>}
                newPost
                isLogo
                bar
            />
            {isEntriesLoading ? <Loader/> : null}
            <div className={classes.searchRow} onClick={() => setShowFilters(!showFilters)}>
                <div className={classes.icons}>{showFilters ? t('close filter') : t('open filter')}</div>
                <FontAwesomeIcon icon={faSearch} className={classes.icons} color={theme.headerIcon} />
            </div>

            <Collapse in={showFilters} timeout={700} disableStrictModeCompat={true}>
                <div className={classes.filterContainer}>
                    <div className={classes.gridItem}>
                        <div className={classes.gridItemGender}>
                            <img className={classes.image} alt={'female'} style={{width: 20}} src={maleIcon}/>
                            <Switcher
                                onChange={() => {
                                    setState(prevState => {
                                        if (prevState.female) {
                                            return {...prevState, male: !prevState.male};
                                        } else {
                                            return {male: !prevState.male, female: !prevState.female};
                                        }
                                    });
                                }}
                                checked={state.male}
                            />
                        </div>
                        <div className={classes.gridItemGender}>
                            <img className={classes.image} alt={'female'} style={{width: 20}} src={femaleIcon}/>
                            <Switcher
                                onChange={() => {
                                    setState(prevState => {
                                        if (prevState.male) {
                                            return {...prevState, female: !prevState.female};
                                        } else {
                                            return {male: !prevState.male, female: !prevState.female};
                                        }
                                    });
                                }}
                                checked={state.female}
                            />
                        </div>
                    </div>
                    {defaultCountry && <div className={classes.filterCountry}>
                            <Autocomplete
                                id="grouped-demo"
                                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={option => option.firstLetter}
                                getOptionLabel={option => t(option.name)}
                                PopperComponent={CustomPopper}
                                noOptionsText={'Unknown country'}
                                onChange={handleCountryNewChange}
                                defaultValue={defaultCountry}
                                classes={{
                                    paper: classes.paper,
                                    option: classes.option,
                                    noOptions: classes.noOptions,
                                    popperDisablePortal: classes.popperDisablePortal,
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        className={classes.searchInput}
                                        label={t('country')}
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon
                                                        color={'rgb(180,180,180)'}
                                                        icon={faGlobe}
                                                        style={{fontSize: 16}}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                renderOption={option => (
                                    <>
									<span style={{marginRight: '10px'}}>
										{option.name} {option.code ? (option.code) : ''}
									</span>
                                        {option.flagicon ?
                                            <img src={option.flagicon} width={30} height={20} alt="flag"/>
                                            :
                                            null
                                        }
                                    </>
                                )}
                            />
                        </div>
                    }
                    <Button
                        className={classes.rootButton}
                        variant={'contained'}
                        color="secondary"
                        size="small"
                        onClick={() => onSend()}
                    >
                        Ok
                    </Button>
                </div>
            </Collapse>


            <Tabs
                value={selectedTab}
                textColor={'inherit'}
                onChange={(_, value) => tabHandler(value)}
                classes={{indicator: classes.indicator}}
                centered
            >
                <Tab label={t('newest')} {...a11yProps(0)} className={classes.tabStyle}/>
                <Tab label={t('best')} {...a11yProps(1)} className={classes.tabStyle}/>
                {!ConfigCountry && <Tab label={t('categories')} {...a11yProps(2)} className={classes.tabStyle}/>}
            </Tabs>
            {
                ConfigCountry ?
                    <SwipeableViews axis={'x'} index={selectedTab} onChangeIndex={(i) => tabHandler(i)}>
                        <TabPanel value={selectedTab} index={0}>
                            {selectedTab === 0 ? <NewestEntries/> : null}
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            {selectedTab === 1 ? (
                                <>
                                    <FeedPeriodButtons value={selectedPeriod} onChange={handleSelectedPeriodChange}/>
                                    <MostSecretEntries type={selectedPeriod}/>
                                </>
                            ) : null}
                        </TabPanel>
                    </SwipeableViews> :
                    <SwipeableViews axis={'x'} index={selectedTab} onChangeIndex={(i) => tabHandler(i)}>
                        <TabPanel value={selectedTab} index={0}>
                            {selectedTab === 0 ? <NewestEntries/> : null}
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            {selectedTab === 1 ? (
                                <>
                                    <FeedPeriodButtons value={selectedPeriod} onChange={handleSelectedPeriodChange}/>
                                    <MostSecretEntries type={selectedPeriod}/>
                                </>
                            ) : null}
                        </TabPanel>
                        <TabPanel value={selectedTab} index={2}>
                            {selectedTab === 2 ?
                                <FeedCategories categories={categories} onClick={onCategoryChange}/> : null
                            }
                        </TabPanel>
                    </SwipeableViews>
            }
            <Modal
                open={!profile?.registered}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modal}
            >
                <>
                    {!newEmail && (
                        <div className={classes.modalItem}>
                            <>
                                <div
                                    style={{
                                        color: '#20baef',
                                        fontSize: 20,
                                    }}
                                >
                                    {t('email not confirmed')}
                                </div>
                                <br/>
                                <div>
                                    <span>{t('sent you email to')} </span>
                                    <span
                                        style={{
                                            color: '#e22b4c',
                                            fontWeight: 'bold',
                                        }}
                                    >
										E-MAIL
									</span>
                                    <br/>
                                    <span>
										{' '}
                                        {t('sent email')} {t('click to confirm')}
									</span>
                                </div>
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 18,
                                    }}
                                >
                                    <span>{t('confirm for freecoins')}</span>
                                    <span style={{color: '#20baef'}}> 20</span>
                                    <span style={{color: '#20baef'}}> {t('confirm for freecoins2')}</span>
                                </div>
                                <div>
                                    <div>{t('problem with email')}</div>
                                    <div
                                        style={{
                                            color: '#e22b4c',
                                            fontWeight: 'bold',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setNewEmail(true)}
                                    >
                                        {t('change email and resend')}
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <div style={{color: '#20baef'}}>{t('important')}</div>
                                    <ol>
                                        <li>{t('takes time')}</li>
                                        <li>{t('check also spam')}</li>
                                        <li>{t('add our email to whitelist')}</li>
                                    </ol>
                                </div>
                            </>
                        </div>
                    )}
                    {newEmail && (
                        <div className={classes.modalEmail}>
                            <div style={{fontSize: 22, color: '#000'}}>{t('new email')}</div>

                            <Paper
                                elevation={0}
                                style={{
                                    marginTop: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                    backgroundColor: 'white',
                                    color: 'white',
                                    border: '2px solid silver',
                                    borderRadius: 10,
                                    width: '100%',
                                    display: 'flex',
                                }}
                            >
                                <IconButton className={classes.iconButton} aria-label="menu">
                                    <MailIcon/>
                                </IconButton>
                                <InputBase
                                    className={classes.inputWithIcon}
                                    style={{
                                        width: '80%',
                                    }}
                                    placeholder={t('your new email')}
                                    type={'mail'}
                                    name={'mail'}
                                    value={values.mail}
                                    onChange={e => emailHandel(e)}
                                    inputProps={{'aria-label': 'mail'}}
                                    error={!!errors.mail}
                                />
                            </Paper>
                            <FormErrorMessage name={'mail'} errors={errors} touched={touched}/>
                            {/*{emailChanged &&*/}
                            {/*<div style={{*/}
                            {/*    color: 'green',*/}
                            {/*    fontSize: 20*/}
                            {/*}}>*/}
                            {/*    {t('email change')}*/}
                            {/*</div>*/}
                            {/*}*/}
                            <form className={classes.form} onSubmit={handleSubmit} noValidate>
                                <Button
                                    type="submit"
                                    className={classes.nextButton}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 44,
                                        padding: '10px 20px 10px 20px',
                                        color: '#FFF',
                                        backgroundColor: '#0f97f5',
                                        borderRadius: 10,
                                        marginTop: 15,
                                        fontSize: 18,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {t('send confirmation link')}
                                </Button>
                            </form>
                            <FontAwesomeIcon
                                onClick={() => setNewEmail(false)}
                                icon={faTimes}
                                style={{
                                    fontSize: 25,
                                    position: 'absolute',
                                    right: 20,
                                    top: 20,
                                    cursor: 'pointer',
                                }}
                                color={'black'}
                            />
                        </div>
                    )}
                </>
            </Modal>
        </div>
    );
}
