import React, { useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Cloud from '../../../common/Cloud';
import ListItem from '../../../common/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { ListItemSecondaryAction, makeStyles, Typography } from '@material-ui/core';
import DoubleBalance from '../../shared/components/DoubleBalance';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPoints, pointsSelector } from '../../../store/profile/profile.store';
import { claimReward, rewardsSelector, searchRewards } from '../../../store/points/points.store';
import RewardsList from '../components/RewardsList';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Loader from '../../../common/Loader';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	root: {
		padding: 3,
	},
	primary: {
		color: theme.textDark,
	},
}));

export default function RedeemPointsScreen() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchPoints());
	}, [dispatch]);
	const points = useSelector(pointsSelector);
	const classes = useStyle();
	let rewards = useSelector(rewardsSelector);
	const { t } = useTranslation();

	const loadProducer = useCallback(() => {
		dispatch(searchRewards());
	}, [dispatch]);

	useEffect(() => {
		loadProducer();
	}, []);

	// Claiming
	const [claimingRewardId, setClaimingRewardId] = useState(null);

	const closeClaimDialog = useCallback(() => setClaimingRewardId(null), [setClaimingRewardId]);
	const askToClaim = id => setClaimingRewardId(id);
	const claim = () => dispatch(claimReward(claimingRewardId));

	const isClaiming = useSelector(state => state.points.isClaiming);

	useEffect(() => {
		if (isClaiming) {
			closeClaimDialog();
		}
	}, [isClaiming, closeClaimDialog]);

	rewards = rewards.map(reward => ({
		...reward,
		missingPoint: Number(parseFloat(reward.points) - points.balance.earned).toFixed(1),
	}));
	const itemTextClasses = { primary: classes.primary };
	return (
		<div>
			<Box p={[2]}>
				<List component={Cloud}>
					<ListItem classes={classes}>
						<ListItemText primary={t('earned points')} classes={itemTextClasses} />
						<ListItemSecondaryAction>
							<DoubleBalance one={points?.balance?.earned} />
						</ListItemSecondaryAction>
					</ListItem>
				</List>

				<Box pt={1}>
					<Typography variant={'subtitle2'} style={{ color: theme.textDark }}>
						{t('available coupons')}
					</Typography>
				</Box>
				<Box>
					<RewardsList data={rewards} onClick={(_, id) => askToClaim(id)} />
					<Dialog open={!!claimingRewardId} onClose={() => closeClaimDialog()}>
						<DialogContent>
							<DialogContentText>{t('confirm to redeem')}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => closeClaimDialog()} color="primary">
								{t('cancel')}
							</Button>
							<Button onClick={claim} color="primary" autoFocus>
								Ok
							</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</Box>

			{isClaiming ? <Loader /> : null}
		</div>
	);
}
