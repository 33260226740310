import React, { isValidElement, useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
	faBars,
	faCoins,
	faEnvelope,
	faFileInvoiceDollar,
	faHandshake,
	faHeadset,
	faHome,
	faIdBadge,
	faMoneyBill,
	faMoon,
	faPlus,
	faQuestionCircle,
	faShieldAlt,
	faSignOutAlt,
	faSun,
	faGlobe,
	faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewPost from './NewPost';
import ListItem from '../../../common/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { useHistory } from 'react-router-dom';
import MyAvatar from './MyAvatar';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { logout } from '../../../store/profile/profile.store';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { darkModeHandler, darkModeSelector } from '../../../store/theme/theme.store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.headerBackground,
		color: theme.palette.primary.dark,
		boxShadow: '0 9px 16px 0 ' + theme.headerShadow,
		height: 64,
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
	sectionDesktop: {
		display: 'none',
		cursor: 'pointer',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		cursor: 'pointer',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	logo: {
		display: 'flex',
		justifyContent: 'center',
		height: 32,
		cursor: 'pointer',
		[theme.breakpoints.up('md')]: {
			width: 'auto',
			height: 40,
		},
		[theme.breakpoints.down('md')]: {
			'& > img': {
				height: 32,
			},
		},
	},
	backButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 32,
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			width: 'auto',
			height: 40,
			justifyContent: 'center',
			display: 'contents',
		},
	},
	list: {
		width: 250,
		height: '100%',
		backgroundColor: theme.mainBackgroundScreens,
	},
	fullList: {
		width: 'auto',
	},
	closeDrawer: {
		fontSize: 25,
		transform: 'rotate(45deg)',
	},
	drawerItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '5px 20px',
		color: theme.textLight,
	},
	drawerItemText: {
		fontSize: 15,
		marginLeft: 10,
	},
	drawerItemIcon: {
		fontSize: 20,
		color: theme.headerIcon,
	},
	drawerItemLeft: {
		width: 30,
	},
	titleHeader: {
		color: theme.textDark,
		[theme.breakpoints.down('md')]: {
			textTransform: 'uppercase',
			width: '100%',
			paddingRight: 20,
			justifyContent: 'center',
			display: 'flex',
		},
	},
}));

export default function HeaderAppBar({
	positionHeader,
	leftIcon,
	title,
	newPost,
	rightIcon,
	rightIconUser,
	changeItemFunction,
	bar,
	isLogo,
	secondHeader = false,
	bgColor,
	headerWidth,
	secondHeaderStyle,
}) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const [state, setState] = React.useState({
		right: false,
	});
	const [logOutDialog, setLogOutDialog] = useState(false);
	const onLogOut = () => dispatch(logout());

	const changeItem = changeItemFunction;
	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const darkMode = useSelector(darkModeSelector);

	const handleProfileMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleMenuClose}>Profile</MenuItem>
			<MenuItem onClick={handleMenuClose}>My account</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton aria-label="show 4 new mails" color="inherit">
					<Badge badgeContent={4} color="secondary">
						<MailIcon />
					</Badge>
				</IconButton>
				<p>Messages</p>
			</MenuItem>
			<MenuItem>
				<IconButton aria-label="show 11 new notifications" color="inherit">
					<Badge badgeContent={11} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<p>Notifications</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
		</Menu>
	);

	const toggleDrawer = (anchor, open) => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const changeRoute = (name, label) => {
		if (history.location.pathname === '/profile-edit') {
			changeItem(label, name);
		}
		history.replace({ pathname: '/profile-edit', state: { name: name, label: label } });
	};

	const changeMode = () => {
		dispatch(darkModeHandler(darkMode ? 0 : 1));
	};

	const list = anchor => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === 'top' || anchor === 'bottom',
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				<ListItem button>
					<FontAwesomeIcon icon={faPlus} className={classes.closeDrawer} color={'grey'} />
				</ListItem>

				<div className={classes.drawerItem} onClick={() => history.push('/feed')}>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faHome} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>Home</div>
				</div>

				<div className={classes.drawerItem} onClick={() => history.push('/profile')}>
					<div>
						<MyAvatar size={30} withBorder={false} animationChange />
					</div>
					<div className={classes.drawerItemText}>{t('my profile')}</div>
				</div>

				<Divider />

				<div
					className={classes.drawerItem}
					onClick={() => changeRoute(t('edit profile'), 'EditProfileScreen')}
				>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faEnvelope} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('edit profile')}</div>
				</div>

				<div
					className={classes.drawerItem}
					onClick={() => changeRoute(t('my affiliate link'), 'PartnerLinkScreen')}
				>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faHandshake} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('my affiliate link')}</div>
				</div>

				<div
					className={classes.drawerItem}
					onClick={() => changeRoute(t('my points'), 'PointsScreen')}
				>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faCoins} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('my points')}</div>
				</div>

				<div
					className={classes.drawerItem}
					onClick={() => changeRoute(t('privacy'), 'PrivacyScreen')}
				>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faShieldAlt} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('privacy')}</div>
				</div>

				<div className={classes.drawerItem} onClick={() => changeRoute(t(), 'ContactScreen')}>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faHeadset} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('contact')}</div>
				</div>

				<div className={classes.drawerItem} onClick={() => changeRoute('FAQ', 'FAQScreen')}>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faQuestionCircle} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>FAQ</div>
				</div>

				<div
					className={classes.drawerItem}
					onClick={() => changeRoute(t('become entertainer'), 'AnimatorScreen')}
				>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faIdBadge} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('real entertainer')}</div>
				</div>

				<div
					className={classes.drawerItem}
					onClick={() => changeRoute(t('buy points'), 'PackageBuyMethodScreen')}
				>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faMoneyBill} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('buy points')}</div>
				</div>

				{/*<div*/}
				{/*	className={classes.drawerItem}*/}
				{/*	onClick={() => changeRoute(t('redeem points'), 'RedeemPointsScreen')}*/}
				{/*>*/}
				{/*	<div className={classes.drawerItemLeft}>*/}
				{/*		<FontAwesomeIcon icon={faFileInvoiceDollar} className={classes.drawerItemIcon} />*/}
				{/*	</div>*/}
				{/*	<div className={classes.drawerItemText}>{t('redeem points')}</div>*/}
				{/*</div>*/}
				<div
					className={classes.drawerItem}
					onClick={() => changeRoute('select language', 'ChangeLanguage')}
				>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faGlobe} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('select language')}</div>
				</div>
				<div className={classes.drawerItem} onClick={() => changeRoute('legals', 'LegalsScreen')}>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faInfoCircle} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>{t('legals')}</div>
				</div>
				<Divider />
				<div className={classes.drawerItem} onClick={() => changeMode()}>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon
							icon={darkMode ? faSun : faMoon}
							className={classes.drawerItemIcon}
							style={{ color: darkMode ? 'gold' : '#183351' }}
						/>
					</div>
					<div className={classes.drawerItemText}>
						{darkMode ? t('light mode') : t('dark mode')}
					</div>
				</div>
				<Divider />
				<div className={classes.drawerItem} onClick={() => setLogOutDialog(true)}>
					<div className={classes.drawerItemLeft}>
						<FontAwesomeIcon icon={faSignOutAlt} className={classes.drawerItemIcon} />
					</div>
					<div className={classes.drawerItemText}>Logout</div>
				</div>
			</List>
		</div>
	);

	return (
		<>
			<AppBar
				position={positionHeader || 'sticky'}
				className={classes.root + ' ' + secondHeaderStyle}
				style={{
					top: secondHeader ? 64 : 0,
					zIndex: secondHeader ? 1100 : 1101,
					boxShadow: secondHeader && 'none',
					backgroundColor: bgColor ? bgColor : '',
					maxWidth: headerWidth,
					right: headerWidth ? 'auto' : 0,
				}}
			>
				<Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
					{newPost && (
						<div className={classes.sectionMobile}>
							<NewPost />
						</div>
					)}
					<div className={isLogo ? classes.logo : classes.backButton}>
						{width > 599 ? (
							isLogo ? (
								<img
									src={theme.Logo}
									alt={'logo'}
									className={classes.logo}
									onClick={() => history.push('/community')}
									color={'action'}
								/>
							) : (
								leftIcon
							)
						) : (
							leftIcon
						)}
					</div>
					{!isValidElement(title) && title ? (
						<Typography color={'textPrimary'} className={classes.titleHeader}>
							{title}
						</Typography>
					) : (
						title
					)}
					{rightIcon && <div className={classes.sectionDesktop}>{rightIcon}</div>}
					{!rightIcon && <div />}
					{rightIconUser && rightIconUser}

					{bar && (
						<div className={classes.sectionMobile}>
							<IconButton
								aria-label="show more"
								aria-controls={mobileMenuId}
								aria-haspopup="true"
								onClick={toggleDrawer('right', true)}
								color="inherit"
							>
								<FontAwesomeIcon icon={faBars} color={theme.mainBlue} />
							</IconButton>
						</div>
					)}
				</Toolbar>
			</AppBar>
			<React.Fragment key={'right'}>
				<Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
					{list('right')}
				</Drawer>
			</React.Fragment>
			{renderMobileMenu}
			{renderMenu}
			<Dialog open={logOutDialog} onClose={() => setLogOutDialog(false)}>
				<DialogContent>
					<DialogContentText>{t('confirm logout')}</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						justifyContent: 'center',
					}}
				>
					<Button onClick={() => setLogOutDialog(false)}>{t('cancel')}</Button>
					<Button onClick={() => onLogOut()} variant="contained" color="primary" autoFocus>
						ok
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
