import BaseService from './BaseService';
import { uniq } from 'ramda';

class UserService extends BaseService {
	constructor() {
		super('');
	}

	searchUsers(filters) {
		return this.post('/user/search', filters);
	}

	getById(id) {
		return this.get(`/user/${id}`);
	}

	getTimezone() {
		return this.get(`timezones`);
	}

	async block(userId) {
		await this.post(`/block/${userId}/add`).then(() => {
			const blockedIds = JSON.parse(localStorage.getItem('blockedUserIds')) || [];
			blockedIds.push(userId);
			localStorage.setItem('blockedUserIds', JSON.stringify(uniq(blockedIds)));
		});

		return true;
	}

	async getBlockedUserIds() {
		return JSON.parse(localStorage.getItem('blockedUserIds')) || [];
	}

	report({ userId, message }) {
		return this.post(`/user/${userId}/report`, { message });
	}

	verify({ image, reason, skype, realname }) {
		const form = new FormData();

		image && form.append('image', image);
		form.append('reason', reason);
		form.append('skype', skype);
		form.append('realname', realname);

		return this.post(`/user/verify`, form);
	}
}

export default new UserService();
