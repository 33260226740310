import { createAction, createReducer } from 'redux-starter-kit';
import PresentsService from '../../services/PresentsService';

export const getPresentsListRequest = createAction('GET_PRESENTS_LIST_REQUEST');
export const getPresentsListFailure = createAction('GET_PRESENTS_LIST_FAILURE');
export const getPresentsListSuccess = createAction('GET_PRESENTS_LIST_SUCCESS');

export const getPresentsCategoriesRequest = createAction('GET_PRESENTS_CATEGORIES_REQUEST');
export const getPresentsCategoriesFailure = createAction('GET_PRESENTS_CATEGORIES_FAILURE');
export const getPresentsCategoriesSuccess = createAction('GET_PRESENTS_CATEGORIES_SUCCESS');

export const getPresentsOfCategoryRequest = createAction('GET_PRESENTS_OF_CATEGORY_REQUEST');
export const getPresentsOfCategoryFailure = createAction('GET_PRESENTS_OF_CATEGORY_FAILURE');
export const getPresentsOfCategorySuccess = createAction('GET_PRESENTS_OF_CATEGORY_SUCCESS');

export const sendPresentRequest = createAction('SEND_PRESENT_REQUEST');
export const sendPresentFailure = createAction('SEND_PRESENT_FAILURE');
export const sendPresentSuccess = createAction('SEND_PRESENT_SUCCESS');

export const cancelMembershipSuggestion = createAction('CANCEL_MEMBERSHIP_SUGGESTION');
export const openMembershipSuggestion = createAction('OPEN_MEMBERSHIP_SUGGESTION');

export const openPresentsTab = createAction('OPEN_PRESENTS_TAB');
export const closePresentsTab = createAction('CLOSE_PRESENTS_TAB');

const initialState = {
	page: 1,
	isPresentsLoading: false,
	isCategoriesLoading: false,
	isPresentsOfCategoryLoading: false,
	show: false,
	presents: [],
	presentsCategories: null,
	showMembershipSuggestion: false,
};

export const sendPresent =
	({ present, recipient }) =>
	async dispatch => {
		dispatch(sendPresentRequest());
		try {
			const response = await PresentsService.sendPresent({ present, recipient });
			dispatch(sendPresentSuccess());
		} catch (e) {
			// if (e.data.error === "Not enough Points") {
			//     window.hideApiError();
			dispatch(sendPresentFailure());
			// }
		}
	};

export const getPresents =
	({ page = 1 }) =>
	async dispatch => {
		dispatch(getPresentsListRequest());
		try {
			const response = await PresentsService.getPresents({ page });
			dispatch(getPresentsListSuccess({ presents: response.data, page }));
		} catch (e) {
			dispatch(getPresentsListFailure());
		}
	};

export const getPresentsCategories =
	({ page = 1 }) =>
	async dispatch => {
		dispatch(getPresentsCategoriesRequest());
		try {
			const response = await PresentsService.getPresentsCategories({ page });
			dispatch(getPresentsCategoriesSuccess({ presentsCategories: response.data }));
		} catch (e) {
			dispatch(getPresentsCategoriesFailure());
		}
	};

export const getPresentsOfCategory =
	({ presentCategory, page = 1 }) =>
	async dispatch => {
		dispatch(getPresentsOfCategoryRequest());
		try {
			const response = await PresentsService.getPresentsOfCategory({ presentCategory, page });
			dispatch(getPresentsOfCategorySuccess({ presents: response.data }));
		} catch (e) {
			dispatch(getPresentsOfCategoryFailure());
		}
	};

const presentsReducer = createReducer(initialState, {
	[getPresentsListRequest]: state => {
		state.isPresentsLoading = true;
	},

	[getPresentsListFailure]: state => {
		state.isPresentsLoading = false;
	},

	[getPresentsListSuccess]: (state, { payload }) => {
		state.isPresentsLoading = false;
		state.page = payload.page;
		state.presents = payload.presents;
	},
	// get all categories
	[getPresentsCategoriesRequest]: state => {
		state.isCategoriesLoading = true;
	},

	[getPresentsCategoriesFailure]: state => {
		state.isCategoriesLoading = false;
	},

	[getPresentsCategoriesSuccess]: (state, { payload }) => {
		state.isCategoriesLoading = false;
		state.presentsCategories = payload.presentsCategories;
	},

	// get presents of category
	[getPresentsOfCategoryRequest]: state => {
		state.isPresentsOfCategoryLoading = true;
	},

	[getPresentsOfCategoryFailure]: state => {
		state.isPresentsOfCategoryLoading = false;
	},

	[getPresentsOfCategorySuccess]: (state, { payload }) => {
		state.isPresentsOfCategoryLoading = false;
		state.page = payload.page;
		state.presents = payload.presents;
	},

	[sendPresentFailure]: state => ({ ...state, showMembershipSuggestion: true }),
	[sendPresentSuccess]: state => ({ ...state, showMembershipSuggestion: false }),

	[openMembershipSuggestion]: state => ({ ...state, showMembershipSuggestion: true }),
	[cancelMembershipSuggestion]: state => ({ ...state, showMembershipSuggestion: false }),

	[openPresentsTab]: state => {
		state.show = true;
	},

	[closePresentsTab]: state => {
		state.show = false;
	},
});

const getState = state => state.presents;
export const isPresentsShowSelector = state => getState(state).show;
export const presentsSelector = state => getState(state).presents;
export const presentsCategoriesSelector = state => getState(state).presentsCategories;
export const isPresentsLoadingSelector = state =>
	getState(state).isPresentsOfCategoryLoading || getState(state).isPresentsLoading;
export const showMembershipSuggestionSelector = state => getState(state).showMembershipSuggestion;

export default presentsReducer;
