import React from 'react';
import useEntries from '../hooks/useEntries';
import EntryList from './entry/EntryList';
import Box from '@material-ui/core/Box';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    text: {
        textAlign: 'center',
        color: theme.white
    },
}))

export default function MostSecretEntries({type}) {
    const {isLoading, hasMore, entries, loadNext} = useEntries({type});
    const { t } = useTranslation();
    const classes = useStyles();


    return (
        <Box p={1}>
            {entries.length > 0 ?
                <EntryList
                    isLoading={isLoading}
                    entries={entries}
                    hasMore={hasMore}
                    loadMore={loadNext}
                    page={0}
                /> :
                <div className={classes.text}>
                    {t('not have a feed')}
                </div>
            }
        </Box>
    );
}
