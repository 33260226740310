import BaseService from './BaseService';

class ProfileService extends BaseService {
	constructor() {
		super('/profile');
	}

	me() {
		return this.get('/status');
	}

	updateBirthday(HEIMLICHBIRTHDAY) {
		return this.post('/birthday/update', {
			HEIMLICHBIRTHDAY,
		});
	}

	updateGender(gender) {
		return this.post('/gender/update', {
			gender,
		});
	}

	updateSlogan(slogan) {
		return this.post('/slogan/update', {
			slogan,
		});
	}

	updateEmail(mail) {
		return this.post('/email/update', {
			mail,
		});
	}

	updateCountry(country) {
		return this.post('/country/update', {
			country,
		});
	}

	updateZipCode(zipcode) {
		return this.post('/zipcode/update', {
			zipcode,
		});
	}

	register(mail, pass) {
		return this.post('/register', {
			mail,
			pass,
		});
	}

	changePassword(oldPass, newPass) {
		return this.post('/password/update', {
			newpass: newPass,
			oldpass: oldPass,
		});
	}

	deleteUser() {
		return this.post('/unregister');
	}

	uploadAvatar(image) {
		const formData = new FormData();
		formData.append('image', image);
		return this.post('/settings/uploadavatar', formData);
	}

	resetPassword(mail) {
		return this.post('/password/lost', {
			mail,
		});
	}

	entryLanguagesUpdate(languages) {
		return this.post('/languages/entry/update', {
			entry_languages: languages,
		});
	}

	languageUpdate(language) {
		return this.post('/languages/update', {
			language,
		});
	}

	timezoneUpdate(timezone) {
		return this.post('/timezone/update', {timezone});
	}
}

export default new ProfileService();
