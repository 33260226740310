import BaseService from './BaseService';
import { uniq } from 'ramda';

class ChatService extends BaseService {
	constructor() {
		super('');
	}

	getConversations({ page }) {
		return this.get('/conversations', { page });
	}

	acceptConversation(convId) {
		return this.post(`/conversation/${convId}/accept`);
	}

	declineConversation(convId) {
		return this.post(`/conversation/${convId}/delete`);
	}

	blockConversation(convId) {
		return this.post(`/conversation/${convId}/accept`);
	}

	getConversationById(id) {
		return this.get(`/conversation/${id}`);
	}

	getUnreadConversationsCount() {
		return this.get(`/conversations/unread`);
	}

	sendMessage(convId, body) {
		return this.post(`/conversation/${convId}`, body);
	}

	requestConversation(userId) {
		return this.get(`/conversation/withuser/${userId}`);
	}

	getMessages({ conversationId, page }) {
		return this.get(`/conversation/${conversationId}/messages`, { page });
	}

	markConversationAsSeen(conversationId) {
		return this.post(`/conversation/${conversationId}/seen`);
	}

	sendAttachment(conversationId, image) {
		const formData = new FormData();
		formData.append('image', image);
		return this.post(`/conversation/${conversationId}/attachment`, formData);
	}

	async block(userId) {
		const blockedIds = JSON.parse(localStorage.getItem('blockedConversationIds')) || [];
		blockedIds.push(userId);
		localStorage.setItem('blockedConversationIds', JSON.stringify(uniq(blockedIds)));
		return true;
	}

	async getBlockedConversationIds() {
		return JSON.parse(localStorage.getItem('blockedConversationIds')) || [];
	}

	payMessage(id) {
		return this.post(`/conversation/paymessage/${id}/pay`);
	}
}

export default new ChatService();
