import React, { Fragment, useCallback, useEffect, useState } from 'react';
import InfoDialog from '../../../common/InfoDialog';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, isUserDeletingSelector } from '../../../store/profile/profile.store';
import usePrevious from '../../../hooks/usePrevious';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		margin: '0 auto',
		display: 'block',
		padding: 20,
		background: 'none',
		border: 'none',
		color: '#5e96ea',
		cursor: 'pointer',
	},
}));

export function DeleteAccount() {
	const dispatch = useDispatch();
	const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);
	const classes = useStyles();
	const { t } = useTranslation();

	const isUserDeleting = useSelector(isUserDeletingSelector);
	const prevIsUserDeleting = usePrevious(isUserDeleting);

	useEffect(() => {
		if (prevIsUserDeleting && !isUserDeleting) {
			window.location.assign('/auth/guest');
		}
	}, [isUserDeleting, prevIsUserDeleting]);

	const handleDeleteAccount = useCallback(() => {
		dispatch(deleteUser());
	}, [dispatch]);

	return (
		<>
			<button className={classes.button} onClick={() => setDeleteAccountModalVisible(true)}>
				<strong>{t('deleteaccount')}</strong>
			</button>
			<InfoDialog
				text={t('sure to delete account')}
				open={deleteAccountModalVisible}
				onOk={handleDeleteAccount}
				onClose={() => setDeleteAccountModalVisible(false)}
				onCancel={() => setDeleteAccountModalVisible(false)}
			/>
		</>
	);
}
