import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import { useDispatch, useSelector } from 'react-redux';
import {
	entrySelector,
	getEntry,
	isEntriesLoadingSelector,
	isPostDeletingSelector,
} from '../../../store/entry/entry.store';
import Loader from '../../../common/Loader';
import MyAvatar from '../../shared/components/MyAvatar';
import EntryItem from '../components/entry/EntryItem';
import Box from '@material-ui/core/Box';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';

export default function EntryScreen() {
	const dispatch = useDispatch();
	const history = useHistory();
	const routeMatch = useRouteMatch();

	const { entryId } = useParams();
	const [dataEntry, setDataEntry] = useState({});
	const isEntriesLoading = useSelector(isEntriesLoadingSelector);
	const entry = useSelector(entrySelector);
	const isPostDeleting = useSelector(isPostDeletingSelector);

	useEffect(() => {
		dispatch(getEntry(entryId));
	}, [dispatch, entryId]);

	const leftIcon =
		routeMatch.path === '/feed/:entryId' ? (
			<BackNavigationIcon onClick={() => history.push(`/profile`)} />
		) : (
			<AddIcon onClick={() => history.push('/feed/create')} color={'action'} />
		);

	// useEffect(()=>{
	//     setDataEntry(entry)
	// }, [entry])
	//
	// useEffect(()=>{
	//     setDataEntry({})
	// }, [isPostDeleting])

	return (
		<Fragment>
			<HeaderAppBar
				leftIcon={leftIcon}
				title={<MyAvatar size={30} withBorder={false} />}
				rightIcon={
					<SettingsIcon color={'action'} onClick={() => history.push('/profile/options')} />
				}
			/>
			{isEntriesLoading ? <Loader /> : null}
			<Box p={1}>{entry ? <EntryItem key={entry.id} {...entry} /> : null}</Box>
		</Fragment>
	);
}
