import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/AccountBox';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import CalendarIcon from '@material-ui/icons/PermContactCalendar';
import ToggleButtonForStep from '../../shared/components/ToggleButtonForStep';
import { useFormik } from 'formik';
import Box from '@material-ui/core/Box';
import useStyles from './style';
import { createAccount } from '../../../store/profile/profile.store';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import FormErrorMessage from '../../../common/forms/FormErrorMessage';
import { useHistory, Link } from 'react-router-dom';
import IcBgApp from '../../../assets/bg_app.png';
import IcGirl from '../../../assets/girl.png';
import useHigherWindowHeight from '../../../hooks/useHigherWindowHeight';
import { isMobileDevice } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { ConfigCountry } from '../../../helpers/configCountry';
import Language from '../components/Language';
import { settingsSelector } from '../../../store/core/core.store';

export default function GuestAuthScreen() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { t } = useTranslation();
	const settings = useSelector(settingsSelector);
	const [country, setCountry] = useState('');
	const [providerData, setProviderData] = useState({});
	const [locationName, setLocationName] = useState('');

	const socialCredentials = useSelector(state => state.profile.socialCredentials);

	useEffect(() => {
		fetch('https://pro.ip-api.com/json?key=dU5KpOF4ZiQeP8K')
			.then(response => response?.json())
			.then(data => {
				setLocationName(data?.countryCode?.toLocaleLowerCase());
				const countryByCode = settings?.countries?.find(e => e.code === data?.countryCode);
				setCountry(countryByCode?.id || '');
			});
	}, [settings]);

	useEffect(() => {
		if (socialCredentials?.accessToken) {
			setInitialRegisterData(socialCredentials);
			setProviderData(socialCredentials);
		}
	}, [socialCredentials]);

	const validationSchema = Yup.object().shape({
		HEIMLICHNICK: Yup.string().label('Nickname').required(t('please enter nick')),
		mail: Yup.string().label('Email').required(t('please enter email')),
		pass: Yup.string().label('Password').required(t('please enter password')),
		confirmPass: Yup.string()
			.oneOf([Yup.ref('pass')], 'Das Passwort und das bestätigte Passwort stimmen nicht überein.')
			.required(t('please enter password again')),
		HEIMLICHAGE: Yup.number()
			.label('Age')
			.min(18, t('too young'))
			.max(99, t('enter correct age'))
			.required(t('field is required')),
		HEIMLICHGENDER: Yup.string().label('Gender').nullable(true).required(t('field is required')),
		agreement: Yup.string().label('Agreement').required(t('please accept conditions')),
	});
	const {
		handleChange,
		values,
		errors,
		touched,
		handleSubmit,
		setFieldValue, // For custom (non-form) components
	} = useFormik({
		initialValues: {
			HEIMLICHNICK: '',
			mail: '',
			pass: '',
			confirmPass: '',
			HEIMLICHAGE: null,
			HEIMLICHGENDER: 'm',
			agreement: '',
			birthday: null,
		},
		validationSchema,
		onSubmit: (values, formikHelpers) => {
			const birthDate = values.birthday ? new Date(values.birthday) : new Date();
			if (!values.birthday) {
				birthDate.setFullYear(birthDate.getFullYear() - values.HEIMLICHAGE);
			}
			// today.toLocaleDateString("en-US")
			const HEIMLICHBIRTHDAY = birthDate.toLocaleDateString('en-US');
			delete values.birthday;
			const data = {
				...values,
				location: locationName,
				country,
				HEIMLICHBIRTHDAY,
				agreement: undefined,
				formikHelpers,
				t,
			};
			if (providerData?.accessToken) {
				data.access_token = providerData.accessToken;
				data.provider = providerData.provider;
			}
			dispatch(createAccount(data));
		},
	});

	const setInitialRegisterData = data => {
		const name =
			data.name.replace(/ /g, '').slice(0, 9) + Math.floor(10000 + Math.random() * 90000);
		setFieldValue('HEIMLICHNICK', name);
		if (data?.email) {
			setFieldValue('mail', data.email);
		}
		if (data?.gender) {
			setFieldValue('HEIMLICHGENDER', data.gender === 'male' ? 'm' : 'w');
		}
		if (data?.birthday) {
			const ageInMilliseconds = new Date() - new Date(data?.birthday);
			const age = Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
			setFieldValue('HEIMLICHAGE', age);
			setFieldValue('birthday', data.birthday);
		}
	};

	const user = useSelector(state => state.profile.user);
	const isLogging = useSelector(state => state.profile.isLogging);

	const windowHeight = useHigherWindowHeight();

	useEffect(() => {
		if (!isLogging && user) {
			history.replace('/feed');
		}
	}, [isLogging, user, history]);

	const girlStyles = {
		width: window.innerWidth === 320 ? 70 : 140,
		marginBottom: window.innerWidth === 320 ? 0 : 10,
	};
	return (
		<div
			style={{
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				backgroundImage: `url(${IcBgApp})`,
			}}
		>
			<Container component="main" maxWidth="xs">
				<Language />
				<div className={classes.paper} style={{ height: windowHeight }}>
					<img src={IcGirl} alt="girl" style={girlStyles} />
					<div
						style={{
							color: '#cdcdcf',
							fontSize: 16.2,
							fontStyle: 'italic',
						}}
					>
						{t('register now')}...
					</div>
					<form className={classes.form} onSubmit={handleSubmit} noValidate>
						<Paper className={classes.root} elevation={0}>
							<IconButton className={classes.registerIconButton} aria-label="menu">
								<MenuIcon />
							</IconButton>
							<InputBase
								className={classes.registerInput}
								placeholder={t('nickname')}
								type={'text'}
								name={'HEIMLICHNICK'}
								value={values.HEIMLICHNICK}
								onChange={handleChange}
								inputProps={{ 'aria-label': t('nickname') }}
								error={!!errors.HEIMLICHNICK}
							/>
						</Paper>
						<FormErrorMessage name={'HEIMLICHNICK'} errors={errors} touched={touched} />
						{!socialCredentials?.email && (
							<>
								<Paper className={classes.root} elevation={0}>
									<IconButton className={classes.registerIconButton} aria-label="menu">
										<MailIcon />
									</IconButton>
									<InputBase
										disabled={socialCredentials?.email}
										className={classes.registerInput}
										placeholder={t('your email and name')}
										type={'mail'}
										name={'mail'}
										value={values.mail}
										onChange={handleChange}
										inputProps={{ 'aria-label': 'mail' }}
										error={!!errors.mail}
									/>
								</Paper>
								<FormErrorMessage name={'mail'} errors={errors} touched={touched} />
							</>
						)}
						<Paper className={classes.root} elevation={0}>
							<IconButton className={classes.registerIconButton} aria-label="menu">
								<LockIcon />
							</IconButton>
							<InputBase
								className={classes.registerInput}
								placeholder={t('password')}
								type={'password'}
								name={'pass'}
								value={values.pass}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'password' }}
								error={!!errors.pass}
							/>
						</Paper>
						<FormErrorMessage name={'pass'} errors={errors} touched={touched} />
						<Paper className={classes.root} elevation={0}>
							<IconButton className={classes.registerIconButton} aria-label="menu">
								<LockIcon />
							</IconButton>
							<InputBase
								className={classes.registerInput}
								placeholder={t('confirm password')}
								type={'password'}
								name={'confirmPass'}
								value={values.confirmPass}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'confirm password' }}
								error={!!errors.confirmPass}
							/>
						</Paper>
						<FormErrorMessage name={'confirmPass'} errors={errors} touched={touched} />
						<Paper
							className={classes.root}
							style={{
								backgroundColor: 'transparent',
								display: 'flex',
								justifyContent: 'space-between',
							}}
							elevation={0}
						>
							<ToggleButtonForStep
								setValue={value => setFieldValue('HEIMLICHGENDER', value)}
								value={values.HEIMLICHGENDER}
							/>
						</Paper>
						<FormErrorMessage name={'HEIMLICHGENDER'} errors={errors} touched={touched} />
						{!socialCredentials?.birthday && (
							<>
								<Paper className={classes.root} elevation={0}>
									<IconButton className={classes.registerIconButton} aria-label="menu">
										<CalendarIcon />
									</IconButton>
									<InputBase
										disabled={socialCredentials?.birthday}
										type={'number'}
										name={'HEIMLICHAGE'}
										value={values.HEIMLICHAGE}
										onChange={handleChange}
										className={classes.registerInput}
										placeholder={t('age')}
										inputProps={{ 'aria-label': t('age') }}
									/>
								</Paper>
								<FormErrorMessage name={'HEIMLICHAGE'} errors={errors} touched={touched} />
							</>
						)}
						<Paper className={`${classes.root} ${classes.checkboxPaper}`} elevation={0}>
							<FormControlLabel
								className={classes.labelPlacementStart}
								classes={{
									label: classes.label,
								}}
								labelPlacement={'start'}
								control={
									<Checkbox
										name={'agreement'}
										onChange={handleChange}
										value="checked"
										color="primary"
									/>
								}
								label={
									<Grid container>
										<Grid item xs={12}>
											{t('i accept the')}
											<a
												className={classes.link}
												href={`https://${ConfigCountry.linkWeb}/api/nutzungsbedingungen?app=1`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{' ' + t('conditions')}
											</a>
										</Grid>
										<Grid item xs={12}>
											{t('and the')}
											<a
												className={classes.link}
												href={`https://${ConfigCountry.linkWeb}/datenschutz`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{t('privacy policy')}
											</a>
										</Grid>
									</Grid>
								}
							/>
						</Paper>
						<FormErrorMessage name={'agreement'} errors={errors} touched={touched} />
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							{t('send registration form')}
						</Button>
						<Box
							style={{
								color: theme.palette.primary.colorPrimary,
								marginTop: theme.spacing(2),
							}}
						>
							<span>{t('already member')} </span>
							<Link
								style={{
									color: theme.palette.primary.points_green,
									fontWeight: '600',
								}}
								to="/auth/guest&step=2&login=1"
								variant="body2"
							>
								{t('login here')}
							</Link>
						</Box>
						{!isMobileDevice() ? <div style={{ height: 50 }} /> : null}
					</form>
				</div>
			</Container>
		</div>
	);
}
