import React from 'react';

import HeaderAppBar from '../../shared/components/HeaderAppBar';
import ConversationsList from '../components/ConversationsList';
import HeaderRight from '../../shared/components/HedaerRight';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.mainBackgroundScreens,
		paddingBottom: 64,
	},
	logo: {
		height: 40,
	},
	headerTitle: {
		flex: 2,
		textAlign: 'center',
		fontSize: 20,
		marginTop: 30,
		marginBottom: 20,
		color: theme.headerIcon,
	},
}));
export default function ChatScreen() {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<HeaderAppBar
				leftIcon={
					<img
						src={theme.smallLogo}
						alt={'logo'}
						className={classes.logo}
						onClick={() => history.push('/feed')}
						color={'action'}
					/>
				}
				rightIcon={<HeaderRight />}
				newPost
				isLogo
				bar
			/>
			<div className={classes.headerTitle}>{t('my messages')}</div>
			<ConversationsList />
		</div>
	);
}
