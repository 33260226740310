import React, { Fragment, useEffect } from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import NotificationDetails from '../components/NotificationDetails';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/Loader';
import {
	notificationSelector,
	getNotificationById,
	isNotificationsLoading,
} from '../../../store/notifications/notifications.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

export default function NotificationScreen({ notificationId, onClose }) {
	notificationId = Number(notificationId);
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const notification = useSelector(notificationSelector);
	const isLoading = useSelector(isNotificationsLoading);

	useEffect(() => {
		dispatch(getNotificationById(notificationId));
	}, [dispatch, notificationId]);

	return (
		<Fragment>
			<HeaderAppBar
				title={t('notification')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
			/>
			{isLoading ? <Loader /> : null}
			<NotificationDetails {...notification} />
		</Fragment>
	);
}
