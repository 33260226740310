import {
	MESSAGE_BOX_SCROLL_DURATION,
	MESSAGE_SENDER,
	MESSAGES_TYPES,
} from '../store/message/message.store';

export function createNewMessage({
	message,
	conversationId,
	userId,
	createdAt,
	present = null,
	points,
	attachment,
	is_paid,
	coins,
	pay_message,
}) {
	return {
		id: Date.now(),
		conversation_id: conversationId,
		sender: userId,
		message,
		seen: false,
		present,
		created_at: createdAt,
		points,
		attachment,
		is_paid,
		coins,
		pay_message,
	};
}

export function createLinkSnippet(link) {
	return {
		type: MESSAGES_TYPES.SNIPPET.LINK,
		componentType: 'snippet',
		title: link.title,
		link: link.link,
		target: link.target || '_blank',
		sender: MESSAGE_SENDER.RESPONSE,
		showAvatar: true,
	};
}

export function createComponentMessage(component, props, showAvatar) {
	return {
		type: MESSAGES_TYPES.CUSTOM_COMPONENT,
		component,
		props,
		sender: MESSAGE_SENDER.RESPONSE,
		showAvatar,
	};
}

function sinEaseOut(timestamp, beginning, change, duration) {
	return change * ((timestamp = timestamp / duration - 1) * timestamp * timestamp + 1) + beginning;
}

function scrollWithSlowMotion(target, scrollStart, scroll) {
	const raf = window.webkitRequestAnimationFrame || window.requestAnimationFrame;
	let start = null;
	const step = timestamp => {
		if (!start) {
			start = timestamp;
		}
		let stepScroll = sinEaseOut(timestamp - start, 1, scroll, MESSAGE_BOX_SCROLL_DURATION);
		let total = scrollStart + stepScroll;
		target.scrollTop = total;
		if (total < scrollStart + scroll) {
			raf(step);
		}
	};
	raf(step);
}

export function scrollToBottom(messagesDiv) {
	if (!messagesDiv) return;
	const screenHeight = messagesDiv.clientHeight;
	const scrollTop = messagesDiv.scrollTop;

	const scrollOffset = messagesDiv.scrollHeight - (scrollTop + screenHeight);

	scrollOffset && scrollWithSlowMotion(messagesDiv, scrollTop, scrollOffset);
}
