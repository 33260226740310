import React, { Fragment, useCallback, useEffect, useState } from 'react';
import InfoDialog from '../../../../common/InfoDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
	showMembershipSuggestionSelector,
	cancelMembershipSuggestion,
} from '../../../../store/presents/presents.store';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import PackageBuyMethodScreen from '../../../options/screens/package/PackageBuyMethodScreen';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));
export default function MembershipSuggestionDialog() {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [buyPointsSuggestionVisible, setBuyPointsSuggestionVisible] = useState(false);

	const showMembershipSuggestion = useSelector(showMembershipSuggestionSelector);

	useEffect(() => {
		setBuyPointsSuggestionVisible(showMembershipSuggestion);
	}, [showMembershipSuggestion]);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const onOk = useCallback(() => {
		handleOpen();
		dispatch(cancelMembershipSuggestion());
	}, [dispatch]);

	const onClose = useCallback(() => {
		setBuyPointsSuggestionVisible(false);
		dispatch(cancelMembershipSuggestion());
	}, [dispatch, history]);

	return (
		<>
			<InfoDialog
				text={<div style={{ marginTop: 20 }}>{t('no points')}</div>}
				onOk={onOk}
				onClose={onClose}
				onCancel={onClose}
				open={buyPointsSuggestionVisible}
				okText={t('buy points now')}
			></InfoDialog>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<PackageBuyMethodScreen onClose={() => handleClose()} header={true} />
				</Box>
			</Modal>
		</>
	);
}
