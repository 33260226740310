import React, { useMemo } from 'react';
import HeaderAppBar from '../../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../../shared/components/BackNavigationIcon';
import Box from '@material-ui/core/Box';
import Cloud from '../../../../common/Cloud';
import { Link } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';

import IcMembershipTable from '../../../../assets/membership-table.png';
import DangerousHtml from '../../../../common/DangerousHtml';
import { currentUserSelector } from '../../../../store/profile/profile.store';
import { useSelector } from 'react-redux';
import { daysBetween } from '../../../../helpers';
import { ConfigCountry } from '../../../../helpers/configCountry';

const useStyle = makeStyles(theme => ({
	headingSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	tage: {
		color: theme.palette.primary.points_green,
	},
}));

export default function MembershipScreen() {
	const classes = useStyle();

	const profile = useSelector(currentUserSelector);

	const membershipActionLabel = profile.active_membership
		? 'JETZT VERLÄNGERN'
		: 'JETZT PREMIUM WERDEN';

	const daysTillEnd = useMemo(() => {
		if (profile.active_membership) {
			return daysBetween(new Date(), new Date(profile.active_membership.end)) * -1;
		} else {
			return null;
		}
	}, [profile.active_membership]);

	return (
		<div>
			<HeaderAppBar title={'Mitgliedschaft'} leftIcon={<BackNavigationIcon />} rightIcon={null} />

			<Box p={[2]}>
				<Cloud className={classes.headingSection}>
					<div>{profile.active_membership ? 'PREMIUM' : 'FREE'}</div>
					<Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
						{profile.active_membership ? (
							<div className={classes.tage}>{daysTillEnd} Tage</div>
						) : null}
						<Link to={'/profile/options/membership/payment-types'}>{membershipActionLabel}</Link>
					</Box>
				</Cloud>

				<Box py={1}>
					<Typography variant={'subtitle2'}>Deine Vorteile</Typography>
				</Box>

				<Box pb={1}>
					<Cloud>
						<img
							src={IcMembershipTable}
							style={{ width: '100%', display: 'block' }}
							alt={'membership-plans'}
						/>
					</Cloud>
				</Box>

				<Cloud>
					<Typography>
						<DangerousHtml
							html={`Um die Beichte-App nachhaltig für dich anbieten und weiterentwickeln zu können, müssen wir uns natürlich etwas finanziell unabhängiger aufstellen. Die größten Kostentreiber sind für uns die Server, dann die Admins und Entwickler, die auch irgendwoher bezahlt werden müssen.
                    Dafür braucht <a href="https://${ConfigCountry.linkWeb}" target="_blank">${ConfigCountry.siteName}</a> eine Einnahmequelle und derzeit verdienen wir Geld entweder über bezahlte Posts sowie Werbeanzeigen oder über die Premium Mitgliedschaften. Da die Haltung des Chat ebenfalls sehr kostspielig ist, können wir ihn leider nicht ganz kostenlos für euch anbieten und erheben für die Nutzung eine angemessene monatliche Gebühr und noch einige Extras, die man mit Punkten erwerben kann.\\nBei der Zuordnung der Werbung wird lediglich nur dein Geschlecht und Alter verwendet um dir eine möglichst relevante Werbung anzeigen zu können und wir geben niemals deine Daten an Dritte weiter.\\n\\nWenn du die Werbung nicht sehen möchtest, durch Upgrade auf eine Premium-Mitgliedschaft bekommst du die heimlich Beichte-App komplett werbefrei und hast auch einen uneingeschränkten Zugang zum Chat und kannst unbegrenzt Nachrichten schreiben!`}
						/>
					</Typography>
				</Cloud>
			</Box>
		</div>
	);
}
