import React from 'react';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function ThanksStep({ changeStep }) {
	const { t } = useTranslation();
	return (
		<div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
			<div style={{ fontSize: 14, textAlign: 'center', color: theme.textLight }}>
				{t('new password sent')}
			</div>
			<div
				onClick={() => changeStep(5)}
				style={{
					fontSize: 15,
					textAlign: 'center',
					backgroundColor: '#44a4d1',
					color: '#FFF',
					padding: '10px 0 10px 0',
					marginTop: 30,
					cursor: 'pointer',
					width: '60%',
					alignSelf: 'center',
				}}
			>
				{t('to login')}
			</div>
		</div>
	);
}
