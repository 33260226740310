import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import useStyles from '../../screens/style';
import NextStepButton from './NextStepButton';
import BackStepButton from './BackStepButton';
import MenuIcon from '@material-ui/icons/AccountBox';
import FormErrorMessage from '../../../../common/forms/FormErrorMessage';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function NameStep({
	value,
	onChange,
	errors,
	handleSubmit,
	touched,
	changeStep,
	ignoreTouched,
	submitNameStep,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<form className={classes.form} onSubmit={handleSubmit} noValidate>
			<div style={{ width: '100%' }}>
				<div style={{ fontSize: 20, textAlign: 'center', marginBottom: 20, color: theme.textDark }}>
					{t('tell your name')}
				</div>
				<Paper
					className={classes.root}
					style={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
					elevation={0}
				>
					<IconButton className={classes.iconButton} aria-label="menu">
						<MenuIcon />
					</IconButton>
					<InputBase
						className={classes.inputWithIcon}
						placeholder={t('nickname')}
						type={'text'}
						name={'HEIMLICHNICK'}
						value={value}
						onChange={onChange}
						inputProps={{ 'aria-label': t('nickname') }}
						error={!!errors.HEIMLICHNICK}
						autoFocus={true}
					/>
				</Paper>
				<FormErrorMessage
					name={'HEIMLICHNICK'}
					errors={errors}
					touched={touched}
					ignoreTouched={ignoreTouched}
				/>
				<NextStepButton changeStep={submitNameStep} />
				<BackStepButton changeStep={() => changeStep(1)} />
			</div>
		</form>
	);
}
