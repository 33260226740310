import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Cloud from '../../../common/Cloud';
import { Input, makeStyles, Select } from '@material-ui/core';
import { useFormik } from 'formik';
import SubmitButton from '../../shared/components/SubmitButton';
import SectionHeading from '../../../common/SectionHeading';
import { REPORT_REASON_TYPES } from '../../../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	sendButton: {
		backgroundColor: '#29a646',
		'&:hover': {
			backgroundColor: 'green',
		},
	},
	lightText: {
		color: theme.textDark,
		backgroundColor: theme.mainBackgroundScreens,
	},
	paper: {
		background: theme.mainBackgroundScreens,
		color: theme.textDark,
	},
}));

export default function ReportForm({ initialValues, onSubmit, withSubject, withMessage }) {
	const { handleChange, values, handleSubmit } = useFormik({
		initialValues,
		onSubmit,
	});
	const classes = useStyles();
	const { t } = useTranslation();

	const subjectList = useMemo(() => REPORT_REASON_TYPES, []);

	return (
		<Box p={1}>
			<form onSubmit={handleSubmit}>
				<SectionHeading>{t('report content')}</SectionHeading>
				<Box component={Cloud} mb={2}>
					<Input
						fullWidth
						placeholder={t('description')}
						name={'id'}
						value={values.id}
						disableUnderline
						onChange={handleChange}
						className={classes.lightText}
					/>
				</Box>
				{withSubject ? (
					<Box component={Cloud} mb={2}>
						<Select
							MenuProps={{
								classes: {
									paper: classes.paper,
								},
							}}
							native
							fullWidth
							placeholder={t('description')}
							name={'subject'}
							value={values.subject}
							disableUnderline
							onChange={handleChange}
							className={classes.lightText}
						>
							{subjectList.map(subject => (
								<option key={subject} value={subject}>
									{t(subject)}
								</option>
							))}
						</Select>
					</Box>
				) : null}
				{withMessage ? (
					<Box component={Cloud} mb={2}>
						<Input
							fullWidth
							multiline
							rows={7}
							placeholder={t('description')}
							name={'message'}
							value={values.message}
							disableUnderline
							onChange={handleChange}
							className={classes.lightText}
						/>
					</Box>
				) : null}

				<SubmitButton style={classes.sendButton}>{t('sendAb')}</SubmitButton>
			</form>
		</Box>
	);
}
