export const ENTRY_ACTION = {
	DELETE_POST: 'DELETE_POST',
	REPORT_ENTRY: 'REPORT_ENTRY',
	BLOCK_ENTRY: 'BLOCK_ENTRY',
	GO_TO_PROFILE: 'GO_TO_PROFILE',
	SHARE: 'SHARE',
};

export const REPORT_REASON_TYPES = [
	'Spam',
	'hate and mobbing',
	'illegal activities',
	'suicide',
	'identity theft',
	'i just dont like it',
];

export const ENTRY_TYPE = {
	CONFESSIONS: 'CONFESSIONS',
	BEST: 'BEST',
	BEST_WEEK: 'BEST_WEEK',
	BEST_MONTH: 'BEST_MONTH',
	NEWEST: 'NEWEST',
	BY_CATEGORY: 'BY_CATEGORY',
};

export const USER_ENTRY_TYPE = {
	ENTRIES: 'ENTRIES',
	COMMENTED: 'COMMENTED',
	LIKES: 'LIKES',
};
