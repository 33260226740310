import React from 'react';
import {useHistory} from 'react-router-dom';
import Cloud from '../../../../common/Cloud';
import EntryItemHeader from './EntryItemHeader';
import EntryItemFooter from './EntryItemFooter';
import EntryItemMedia from './EntryItemMedia';
import {useDispatch, useSelector} from 'react-redux';
import {blockEntry, voteEntry, deletePostEntry} from '../../../../store/entry/entry.store';
import {copyToClipboard, isMobileDevice} from '../../../../helpers';
import {currentUserSelector} from '../../../../store/profile/profile.store';
import theme from '../../../../theme';
import {useTranslation} from 'react-i18next';
import {ConfigCountry} from '../../../../helpers/configCountry';

export default function EntryItem({
                                      id,
                                      link,
                                      desc,
                                      author,
                                      nickname,
                                      age,
                                      gender,
                                      average_points,
                                      commentsCount,
                                      voted,
                                      ownvote,
                                      chatexists,
                                      canContact,
                                      sponsored_link,
                                      uid,
                                      type
                                  }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(currentUserSelector);
    const {t} = useTranslation();
    const deletePost = () => dispatch(deletePostEntry({id}));
    const vote = rate => {
        dispatch(voteEntry({id, rate}));
    };
    const report = () => history.push(`/feed/${id}/report`);
    const block = () => dispatch(blockEntry({id}));
    const goToProfile = () => history.push(`/community/${author?.uid || uid}`);
    const shareOrCopyLink = () => {
        const url = ConfigCountry.international
            ? `https://${ConfigCountry.domain}/entry/${id}/${user?.nickname}`
            : `https://${ConfigCountry.domain}/beitrag/${id}/${user?.nickname}`;

        if (navigator.share) {
            const title = t('Hey, take a look at this confession!');
            const text = `
            ${title}
            ${t('Confession of')} ${nickname}
            ${url}
            ${t('Get more! Confess, chat and date')}
          
            `;

            navigator.share({
                title,
                text,
            });
        } else {
            copyToClipboard(url);
        }
    };

    const goToAuthor = () => {
        if ((author?.uid || uid) === user?.uid) {
            history.push(`/profile`);
            return;
        }
        (author?.uid || uid) && history.push(`/community/${author?.uid || uid}`);
        // author?.uid && history.push(`/community/${author?.uid}`)
    };

    const itemWrapperStyle = isMobileDevice() ? {} : {width: '350px'};
    const bannerWrapperStyle = {
        width: '330px',
        height: '423px',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 10px 17px 10px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.mainBackgroundScreens,
        borderRadius: 5,
        border: '2px solid' + theme.borderContainers,
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: theme.mainBlue,
    };
    const bannerItemStyle = isMobileDevice()
        ? {width: '100%'}
        : {
            backgroundColor: 'black',
            height: '330px',
            alignItems: 'center',
            display: 'flex',
            marginTop: 20,
        };
    return (
        <>
            {sponsored_link ? (
                <a
                    href={sponsored_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={bannerWrapperStyle}
                >
                    <div
                        style={{
                            width: '100%',
                            paddingRight: 14,
                            fontSize: 10,
                            textAlign: 'right',
                        }}
                    >
                        {t('sponsor')}
                    </div>
                    <div style={bannerItemStyle}>
                        <img
                            src={link}
                            alt={desc}
                            style={{display: 'block', width: '100%', borderRadius: 5}}
                        />
                    </div>
                </a>
            ) : (
                <div style={itemWrapperStyle}>
                    <Cloud
                        style={{
                            margin: 10,
                            marginBottom: 15,
                            padding: 0,
                            borderRadius: 7,
                            borderWidth: 1,
                            backgroundColor: theme.mainBackgroundScreens,
                        }}
                    >
                        <EntryItemHeader
                            author={{
                                ...author,
                                nickname,
                                age,
                                gender,
                            }}
                            uidUser={author?.uid || uid}
                            id={id}
                            onReportEntry={report}
                            onBlockEntry={block}
                            onGoToProfile={goToProfile}
                            onShare={shareOrCopyLink}
                            onGoToAuthor={goToAuthor}
                            deletePost={deletePost}
                        />
                        <EntryItemMedia link={link} message={desc} type={type}/>
                        <EntryItemFooter
                            commentsCount={commentsCount}
                            averagePrivacy={parseInt(average_points)}
                            isVoted={voted}
                            ownVote={ownvote}
                            voteEntry={vote}
                            uid={author?.uid}
                            commentsId={id}
                            chatexists={chatexists}
                            canContact={canContact}
                        />
                    </Cloud>
                </div>
            )}
        </>
    );
}
