import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { settingsSelector } from '../store/core/core.store';

const useLocalDate = ({ date, fromNow, lang }) => {
	const settings = useSelector(settingsSelector);
	if (fromNow && settings?.default_timezone) {
		return moment(date).tz('' + settings?.default_timezone, true).format('MMM DD HH:mm');
	} else {
		return moment(date).tz('' + settings?.default_timezone, true)?.format('MMM DD HH:mm');
	}
};

export default useLocalDate;
