import React, { Fragment, useState } from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import ReportForm from '../../report/components/ReportForm';
import { REPORT_REASON_TYPES } from '../../../constants';
import UserService from '../../../services/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import Box from '@material-ui/core/Box';
import { makeStyles, Modal } from '@material-ui/core';
import ReportUserSuccessScreen from './ReportUserSuccessScreen';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	root: {
		height: 46,
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '90%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function ReportUserScreen(props) {
	const { userId, nickname, onClose } = props;
	const classes = useStyle();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleSubmit = values => {
		let message = values?.message;
		UserService.report({ userId, message }).then(() => handleOpen());
	};

	return (
		<Fragment>
			<HeaderAppBar
				positionHeader={'fixed'}
				title={t('report user')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>
			<Box
				p={1}
				style={{
					paddingTop: 82,
					backgroundColor: theme.mainBackgroundScreens,
					height: '100%',
					overflowY: 'scroll',
					'&:hover': {
						color: '#d81e11',
						border: '6px solid #fff',
					},
				}}
			>
				<ReportForm
					initialValues={{
						id: `ID ${nickname}`,
						subject: REPORT_REASON_TYPES[0],
					}}
					onSubmit={handleSubmit}
					withSubject={true}
					withMessage={true}
				/>
			</Box>
			<Modal
				open={open}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ReportUserSuccessScreen onClose={onClose} />
				</Box>
			</Modal>
		</Fragment>
	);
}
