import React, { useCallback, useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import Cloud from '../../../common/Cloud';
import ListItem from '../../../common/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
	getNotifications,
	notificationsSelector,
} from '../../../store/notifications/notifications.store';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';
import Modal from '@material-ui/core/Modal';
import NotificationScreen from '../screens/NotificationScreen';
import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import CreatedDate from './CreatedDate';

const useStyles = makeStyles(theme => ({
	logo: {
		height: 40,
	},
	headerTitle: {
		textAlign: 'center',
		fontSize: 20,
		marginTop: 30,
		marginBottom: 20,
	},
	notificationHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	notificationListContainer: {
		width: '65%',
		margin: '0 auto',
		[theme.breakpoints.down('md')]: {
			width: '90%',
		},
	},
	primary: {
		fontWeight: 'bold',
		fontSize: 14,
		color: theme.textDark,
	},
	secondary: {
		fontSize: 13,
		color: theme.textLight,
	},
	emptyText: {
		color: theme.textDark,
	},
	envelopeStyle: {
		fontSize: 20,
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function NotificationsList() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const notifications = useSelector(notificationsSelector);
	const page = useSelector(state => state.notifications.page);
	const isLoading = useSelector(state => state.notifications.isLoading);
	const hasMore = useSelector(state => state.notifications.hasMore);
	const [openPost, setOpenPost] = useState(false);
	const [noteId, setNoteId] = useState(null);

	const handleOpen = () => setOpenPost(true);
	const handleClose = () => setOpenPost(false);

	const loadProducer = useCallback(() => {
		dispatch(getNotifications({ page: page + 1 }));
	}, [dispatch, page]);

	useEffect(() => {
		loadProducer();
	}, []);

	const goToNotification = id => {
		setNoteId(id);
		handleOpen();
	};

	if (!hasMore && !notifications.length) {
		return (
			<div className={classes.notificationListContainer} style={{ textAlign: 'center' }}>
				<Typography className={classes.emptyText}>{t('no notifications')}</Typography>
			</div>
		);
	}
	const itemTextClasses = { primary: classes.primary, secondary: classes.secondary };
	return (
		<div className={classes.notificationListContainer}>
			{isLoading && notifications.length===0 ? <Loader /> : <InfiniteScroll initialLoad={false} loadMore={loadProducer} hasMore={hasMore}>
				<List style={{ padding: 0 }}>
					<Cloud style={{ margin: 10, marginBottom: 15, padding: 0, borderRadius: 7 }}>
						{notifications?.map(notification => (
							<ListItem
								key={notification.id}
								onClick={() => goToNotification(notification.id)}
								button
								divider
								style={{
									paddingTop: 0,
									paddingBottom: 0,
									backgroundColor: notification.read === 0 ? 'rgba(0, 0, 0, 0.2)' : null,
									borderRadius: 5,
								}}
							>
								<ListItemIcon style={{ minWidth: 38 }}>
									<FontAwesomeIcon
										icon={notification.read === 0 ? faEnvelope : faEnvelopeOpen}
										className={classes.envelopeStyle}
										color={theme.headerIcon}
									/>
								</ListItemIcon>
								<ListItemText
									classes={itemTextClasses}
									primary={
										<Typography variant={'subtitle2'} style={{ color: theme.textDark }}>
											{notification.subject}
										</Typography>
									}
									secondary={<CreatedDate notification={notification} />}
								/>
								<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
							</ListItem>
						))}
					</Cloud>
				</List>
			</InfiniteScroll>}
			<Modal
				open={openPost}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<NotificationScreen notificationId={noteId} onClose={() => handleClose()} />
				</Box>
			</Modal>
		</div>
	);
}
