import React, {Fragment, useEffect, useMemo, useState} from 'react';
import HeaderAppBar from '../../../shared/components/HeaderAppBar';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import PaymentList from '../../../shared/components/payment/PaymentList';
import Loader from '../../../../common/Loader';
import {
	fetchPackagesByPaymentType,
	packageIsLoadingSelector,
	packagesSelector,
} from '../../../../store/package/package.store';
import IcBuyPointsPackage from '../../../../assets/ic_buy_points_package.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../theme';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import PackageBuyFormScreen from './PackageBuyFormScreen';
import { useTranslation } from 'react-i18next';
import {countriesSelector} from "../../../../store/core/core.store";
import {currentUserSelector} from "../../../../store/profile/profile.store";

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function PackageBuyOptionScreen({ paymentTypeName, data, onClose, header }) {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [payId, setPayId] = useState(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(currentUserSelector);
	const countries = useSelector(countriesSelector);

	useEffect(() => {
		dispatch(fetchPackagesByPaymentType(paymentTypeName));
	}, [dispatch, paymentTypeName]);

	const currency = useMemo(() => {
		if(countries?.length) {
			const userCountry = countries.find(val => Number(val.id) === Number(user.country));
			return userCountry.symbol || '€';
		}
		return '€';
	}, [countries, user])

	const memberships = useSelector(packagesSelector);
	const isLoading = useSelector(packageIsLoadingSelector);
	const rateCoins = currency;

	const items = memberships.map(it => ({
		...it,
		primaryText: `${it.bezeichnung}`,
		secondaryText: `${it.points} ${t('points')} - ${it.amount} ${rateCoins}`,
		image: IcBuyPointsPackage,
	}));

	const goToForm = id => {
		setPayId(id);
		setOpenModal(true);
	};

	const handleClose = () => setOpenModal(false);

	return (
		<Fragment>
			{!header && <HeaderAppBar
				title={t('buy points now')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{fontSize: 25}}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>}
			{isLoading ? <Loader /> : null}
			<Box p={1}>
				<PaymentList items={items} onClick={goToForm} />
			</Box>
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<PackageBuyFormScreen
						paymentTypeName={paymentTypeName}
						packageId={payId}
						items={items}
						rateCoins={rateCoins}
						onClose={() => handleClose()}
						isDirect
					/>
				</Box>
			</Modal>
		</Fragment>
	);
}
