import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {
	getPresents,
	getPresentsCategories,
	getPresentsOfCategory,
	isPresentsLoadingSelector,
	presentsCategoriesSelector,
	presentsSelector,
} from '../../../../store/presents/presents.store';
import CategoryList from './CategoryList';
import PresentsContainer from './PresentsContainer';
import PresentsDialogHeader from './PresentsDialogHeader';

const useStyles = makeStyles(theme => ({
	paperRoot: {
		width: '100%',
		height: '75%',
		margin: 8,
		overflow: 'hidden',
		backgroundColor: theme.mainBackgroundScreens,
	},
}));

export default function PresentsDialog({
	onClose: handleClose,
	onPresentSend: handlePresentSend,
	open,
}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const presents = useSelector(presentsSelector);
	const presentsCategories = useSelector(presentsCategoriesSelector);
	const isPresentsLoading = useSelector(isPresentsLoadingSelector);

	useEffect(() => {
		dispatch(getPresents({ page: 1 }));
		dispatch(getPresentsCategories({ page: 1 }));
	}, [dispatch]);

	const [drawerOpen, setDrawerOpen] = useState(false);

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	const handleCategoryClick = presentCategory => () => {
		dispatch(getPresentsOfCategory({ presentCategory }));
		handleDrawerClose();
	};

	const handleAllPresentsClick = () => {
		dispatch(getPresents({ page: 1 }));
		handleDrawerClose();
	};

	return (
		<Dialog
			classes={{
				paper: classes.paperRoot,
			}}
			disablePortal
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
		>
			<PresentsDialogHeader
				onDrawerOpen={handleDrawerOpen}
				drawerOpen={drawerOpen}
				handleClose={handleClose}
			/>

			<CategoryList
				onAllPresentsClick={handleAllPresentsClick}
				onCategoryClick={handleCategoryClick}
				onDrawerClose={handleDrawerClose}
				drawerOpen={drawerOpen}
				presentsCategories={presentsCategories}
			/>

			<PresentsContainer
				onPresentSend={handlePresentSend}
				presents={presents}
				isPresentsLoading={isPresentsLoading}
			/>
		</Dialog>
	);
}
