import React from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	presentsContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		flexWrap: 'wrap',
		height: '100%',
		overflow: 'scroll',
	},
	presentItem: {
		width: '48%',
		height: '45%',
		textAlign: 'center',
		border: '1px solid black',
		marginBottom: 10,
		lineHeight: '2',
	},
	presentTitle: {
		color: theme.palette.primary.color_green_dark,
		fontWeight: 600,
	},
	presentImage: {
		height: '65%',
	},
	presentPrice: {
		color: 'gray',
		height: 32,
	},
	presentFooter: {
		color: 'white',
		backgroundColor: 'black',
		position: 'relative',
		width: '100%',
	},
}));

export default function PresentsContainer({
	presents,
	onPresentSend: handlePresentSend,
	isPresentsLoading,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.presentsContainer}>
			{(isPresentsLoading ? Array.from(new Array(3)) : presents).map((present, index) =>
				present ? (
					<div key={present.id} className={classes.presentItem}>
						<div className={classes.presentTitle}>{present.description}</div>
						<div
							onClick={handlePresentSend(present.id)}
							className={classes.presentImage}
							style={{
								background: `url(${present.filename}) no-repeat center`,
								backgroundSize: 'contain',
								cursor: 'pointer',
							}}
						/>
						<div className={classes.presentPrice}>
							{t('price')}: {present.costs} {t('points')}
						</div>
						<div
							onClick={handlePresentSend(present.id)}
							className={classes.presentFooter}
							style={{ cursor: 'pointer' }}
						>
							{t('buy now')}
						</div>
					</div>
				) : (
					<Skeleton key={index} variant="rect" className={classes.presentItem} />
				),
			)}
		</div>
	);
}
