import React, { Fragment, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderAppBar from '../../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../../shared/components/BackNavigationIcon';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchMembershipPaymentTypes,
	membershipIsLoadingSelector,
	membershipPaymentTypesSelector,
} from '../../../../store/membership/membership.store';
import PaymentList from '../../../shared/components/payment/PaymentList';
import Loader from '../../../../common/Loader';
import { useTranslation } from 'react-i18next';

export default function MembershipBuyMethodScreen() {
	const history = useHistory();
	const { t } = useTranslation();

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchMembershipPaymentTypes());
	}, [dispatch]);

	const paymentTypes = useSelector(membershipPaymentTypesSelector);
	const isLoading = useSelector(membershipIsLoadingSelector);

	const items = paymentTypes.map(it => ({
		...it,
		id: it.name,
		primaryText: it.displayname,
		image: it.icon,
	}));

	const goToOptions = useCallback(
		id => history.push(`/profile/options/membership/payment-types/${id}/options`),
		[history],
	);

	return (
		<Fragment>
			<HeaderAppBar
				title={t('buy points now')}
				leftIcon={<BackNavigationIcon />}
				rightIcon={null}
			/>
			{isLoading ? <Loader /> : null}
			<Box p={1}>
				<PaymentList items={items} onClick={goToOptions} />
			</Box>
		</Fragment>
	);
}
