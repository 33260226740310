import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	currentUserSelector,
	fetchPoints,
	isBirthdayChangedSelector,
	pointsSelector,
	updateProfileBirthday,
	updateProfileGender,
	updateProfileField,
} from '../../../store/profile/profile.store';
import UserLayout from '../../shared/components/UserLayout';
import Cloud from '../../../common/Cloud';
import List from '@material-ui/core/List';
import ListItem from '../../../common/ListItem';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import { useHistory } from 'react-router-dom';
import UserName from '../../shared/components/UserName';
import InlineDatePicker from '../components/InlineDatePicker';
import UserAge from '../../shared/components/UserAge';
import Loader from '../../../common/Loader';
import ProfileAvatar from '../components/ProfileAvatar';
import CroppingPage from '../components/CropPage';
import ProfileService from '../../../services/ProfileService';
import UserFeed from '../../community/components/UserFeed';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import BottomToast from '../../../common/BottomToast';
import usePrevious from '../../../hooks/usePrevious';
import ProfileSlogan from '../components/ProfileSlogan';
import ProfileCountryAndPostalCode from '../components/ProfileCountryAndPostalCode';
import HeaderRight from '../../shared/components/HedaerRight';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCoins, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import scaleDownImage from '../../../utils/scaleDownImage';

const inputId = 'profile-avatar';
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.mainBackgroundScreens,
		paddingTop: 64,
		[theme.breakpoints.down('md')]: {
			paddingBottom: 64,
		},
	},
	indicator: {
		backgroundColor: 'transparent',
		justifyContent: 'center',
	},
	tabStyle: {
		backgroundColor: theme.mainBlue,
		borderRadius: 7,
		margin: '0 50px',
		minHeight: 'auto',
		color: theme.white,
		padding: '4px 60px',
		marginTop: 25,
	},
	logo: {
		height: 40,
	},
	profileButtons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		height: 51,
		width: 230,
		cursor: 'pointer',
	},
	buttons2: {
		display: 'flex',
		flexDirection: 'row',
		height: 51,
		width: 230,
		paddingLeft: 20,
		paddingRight: 20,
		cursor: 'pointer',
		backgroundColor: theme.mainBlue,
		color: theme.white,
		borderRadius: 40,
		border: '3px solid #f3f6f5',
		alignItems: 'center',
		marginTop: 10,
		fontSize: 16
	},
	buttons2Text:{
		width: '-webkit-fill-available',
		textAlign: 'center'
	},
	buttonsText: {
		marginLeft: 10,
	},
	editProfile: {
		backgroundColor: '#017afe',
		color: '#FFF',
		borderRadius: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	coins: {
		color: theme.mainBlue,
		borderRadius: 40,
		border: '3px solid #f3f6f5',
		display: 'flex',
		alignItems: 'center',
		marginTop: 10,
	},
	textLight: {
		color: theme.textLight,
	},
}));
export default function ProfileScreen() {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(fetchPoints());
	}, [dispatch]);

	const profile = useSelector(currentUserSelector);
	const isBirthdayChanged = useSelector(isBirthdayChangedSelector);
	const points = useSelector(pointsSelector);
	const [isAgeChanged, setIsAgeChanged] = useState(false);

	const [editDrawerState, setEditDrawerState] = useState(false);
	const handleDrawerClose = () => setEditDrawerState(false);
	const handleDrawerOpen = () => setEditDrawerState(true);

	const [isEditGenderDrawerVisible, setIsEditGenderDrawerVisible] = useState(false);
	const handleGenderChange = gender => {
		dispatch(updateProfileGender(gender));
		setIsEditGenderDrawerVisible(false);
	};

	const isProfileUpdating = useSelector(state => state.profile.isProfileUpdating);

	const currentUser = useSelector(currentUserSelector);
	const { avatar_approved: avatarApproved, avatar_uploaded: avatarUploaded } = currentUser || {};

	const updateBirthday = value => dispatch(updateProfileBirthday(value));

	const prevIsProfileUpdating = usePrevious(isProfileUpdating);
	useEffect(() => {
		if (isProfileUpdating) {
			setIsAgeChanged(true);
		}
	}, [prevIsProfileUpdating, isProfileUpdating, setIsAgeChanged]);

	const [src, setSrc] = useState('');

	const [cropPageToggle, setCropPageToggle] = useState(false);

	const handleImageSelect = event => {
		event.stopPropagation();
		event.preventDefault();

		const file = event.target.files[0];

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = e => {
			const image = new Image();
			image.src = e.target.result;
			image.onload = e => {
				if (e.target.width > 800) {
					scaleDownImage(file, 600, base64 => {
						setSrc(base64 || URL.createObjectURL(currentUser?.avatar));
						setCropPageToggle(true);
					});
				} else {
					setSrc(URL.createObjectURL(file) || URL.createObjectURL(currentUser?.avatar));
					setCropPageToggle(true);
				}
			};
		};
	};

	const handleImageEdit = () => {
		setSrc(currentUser.avatar);
		setCropPageToggle(true);
	};

	const handleCroppedImage = async (event, file) => {
		setCropPageToggle(false);
		setSrc(URL.createObjectURL(file));
		try {
			const res = await ProfileService.uploadAvatar(file);
			dispatch(
				updateProfileField({
					field: 'avatar_uploaded',
					value: true,
				}),
			);
			URL.revokeObjectURL(src);
			handleDrawerClose();
			// console.log('upload response', res);
		} catch (e) {
			URL.revokeObjectURL(src);
			// console.warn('upload error response', e);
		}
	};

	if (cropPageToggle) {
		return (
			<CroppingPage
				src={src}
				onCroppedImageReady={handleCroppedImage}
				onCancel={() => {
					setCropPageToggle(false);
					handleDrawerClose();
				}}
			/>
		);
	} else
		return (
			<>
				<HeaderAppBar
					positionHeader={'fixed'}
					leftIcon={
						<img
							src={theme.smallLogo}
							alt={'logo'}
							className={classes.logo}
							onClick={() => history.push('/feed')}
							color={'action'}
						/>
					}
					rightIcon={<HeaderRight />}
					newPost
					isLogo
					bar
				/>
				{isProfileUpdating ? <Loader /> : null}
				<div className={classes.root}>
					<UserLayout
						topLeft={
							<ProfileCountryAndPostalCode
								zipcode={profile.zipcode}
								country={profile.country}
								isZipcode={profile.zip_changed}
								isCountry={profile.country_changed}
								uid={profile.uid}
							/>
						}
						avatar={
							<ProfileAvatar
								avatarApproved={avatarApproved}
								avatarUploaded={avatarUploaded}
								onClick={handleDrawerOpen}
							/>
						}
						name={
							<UserName
								genderChanged={profile.gender_changed}
								nickname={profile.nickname}
								age={
									<UserAge
										age={profile.age}
										suffix={
											!isBirthdayChanged ? (
												<InlineDatePicker
													value={profile.birthday}
													onChange={value => updateBirthday(value)}
												/>
											) : null
										}
									/>
								}
								gender={profile.gender}
								handleGenderClick={
									!profile.gender_changed ? () => setIsEditGenderDrawerVisible(true) : null
								}
							/>
						}
						slogan={<ProfileSlogan slogan={profile.slogan} editable={true} />}
						sections={[
							<div className={classes.profileButtons}>

								<div
									className={classes.buttons2}
									onClick={() =>
										history.replace({
											pathname: '/profile-edit',
											state: { name: t('edit profile'), label: 'EditProfileScreen' },
										})
									}
								>
									<div>
										<FontAwesomeIcon
											icon={faPencilAlt}
											style={{ cursor: 'pointer', marginLeft: 10, color: '#FFF', fontSize: 20 }}
											color={theme.headerIcon}
										/>
									</div>
									<div className={classes.buttons2Text}>
										{t('edit profile')}
									</div>
								</div>
								<div
									className={classes.buttons + ' ' + classes.coins}
									onClick={() =>
										history.replace({
											pathname: '/profile-edit',
											state: { name: t('my points'), label: 'PointsScreen' },
										})
									}
								>
									<FontAwesomeIcon
										icon={faCoins}
										style={{ cursor: 'pointer', marginLeft: 10, color: 'gold', fontSize: 20 }}
										color={theme.headerIcon}
									/>
									<div className={classes.buttonsText}>
										{t('my points')}{' '}
										<span
											className={classes.buttonsText}
											style={{ color: '#479a1d', fontWeight: 'bold' }}
										>
											+{points.sum}
										</span>
									</div>
								</div>
							</div>,
						]}
					/>
					<UserFeed user={profile} />
					<Drawer anchor="bottom" open={editDrawerState} onClose={handleDrawerClose}>
						<List component={Cloud} style={{ cursor: 'pointer' }} className={classes.textLight}>
							<ListItem component={'label'} htmlFor={inputId} style={{ cursor: 'pointer' }}>
								<input
									type="file"
									id={inputId}
									onChange={handleImageSelect}
									style={{ display: 'none' }}
									accept="image/*, .heic"
								/>
								{t('select')}
							</ListItem>
							{/*<Divider/>*/}
							{/*{avatarUploaded && (*/}
							{/*    <ListItem onClick={handleImageEdit}>*/}
							{/*      Bild ändern*/}
							{/*    </ListItem>*/}
							{/*)}*/}
							<Divider />
							<ListItem onClick={handleDrawerClose}>{t('cancel')}</ListItem>
						</List>
					</Drawer>

					<Drawer
						anchor="bottom"
						open={isEditGenderDrawerVisible}
						onClose={() => setIsEditGenderDrawerVisible(false)}
					>
						<List component={Cloud} className={classes.textLight}>
							<ListItem onClick={() => handleGenderChange('m')}>{t('male')}</ListItem>
							<Divider />
							<ListItem onClick={() => handleGenderChange('w')}>{t('female')}</ListItem>
							<Divider />
							<ListItem onClick={() => setIsEditGenderDrawerVisible(false)}>{t('cancel')}</ListItem>
						</List>
					</Drawer>
					{isLoading && <Loader />}
					<BottomToast
						open={isAgeChanged}
						onClose={() => setIsAgeChanged(false)}
						className={classes.textLight}
					>
						Profil aktualisiert
					</BottomToast>
				</div>
			</>
		);
}
