import React from 'react';
import { useHistory } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/core/SvgIcon/SvgIcon';
import ListItem from '../../../common/ListItem';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	root: {
		padding: 0,
	},
}));

export default function RegisterListItem(props) {
	const classes = useStyle();

	const history = useHistory();

	return (
		<ListItem classes={classes} button onClick={() => history.push('/profile/manage')} {...props}>
			<ListItemText
				primary={'Mit Email anmelden'}
				secondary={'Du musst dich anmelden um Punkte verdienen zu konnen'}
			/>
			<KeyboardArrowRightIcon />
		</ListItem>
	);
}
