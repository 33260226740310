import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import PaymentList from '../../../shared/components/payment/PaymentList';
import Loader from '../../../../common/Loader';
import {
	fetchPackagePaymentTypes,
	packageIsLoadingSelector,
	packagePaymentTypesSelector,
} from '../../../../store/package/package.store';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import PackageBuyOptionScreen from './PackageBuyOptionScreen';
import HeaderAppBar from '../../../shared/components/HeaderAppBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function PackageBuyMethodScreen({ onClose, header }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(fetchPackagePaymentTypes());
	}, [dispatch]);
	const [openModal, setOpenModal] = useState(false);
	const [paymentTypeName, setPaymentTypeName] = useState('');
	const paymentTypes = useSelector(packagePaymentTypesSelector);
	const isLoading = useSelector(packageIsLoadingSelector);

	const handleClose = () => setOpenModal(false);
	const items = paymentTypes.map(it => ({
		...it,
		id: it.name,
		primaryText: it.displayname,
		image: it.icon,
		currency: it.currency,
	}));

	const goToOptions = id => {
		setPaymentTypeName(id);
		setOpenModal(true);
	};
	useEffect(()=>{
		if(items.length === 1){
			goToOptions(items[0].id)
		}
	}, [items])
	return (
		<Fragment>
			{header && (
				<HeaderAppBar
					positionHeader={'fixed'}
					title={t('buy points now')}
					leftIcon={
						<FontAwesomeIcon
							onClick={onClose}
							icon={faLongArrowAltLeft}
							style={{ fontSize: 25 }}
							color={theme.headerIcon}
						/>
					}
					rightIcon={null}
				/>
			)}
			{isLoading ? <Loader /> : null}
			<Box p={1} style={{ marginTop: header ? 70 : 0 }}>
				{items.length > 1 &&
				<PaymentList items={items} onClick={goToOptions}/>
				}
				{items.length === 1 && <PackageBuyOptionScreen
					header={true}
					paymentTypeName={paymentTypeName}
					data={items}
					onClose={() => handleClose()}
				/>}
			</Box>
			{items.length > 1 && <Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<PackageBuyOptionScreen
						paymentTypeName={paymentTypeName}
						data={items}
						onClose={() => handleClose()}
					/>
				</Box>
			</Modal>}
		</Fragment>
	);
}
