import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Cloud from '../../../common/Cloud';
import ListItem from '../../../common/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPoints } from '../../../store/profile/profile.store';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { ConfigCountry } from '../../../helpers/configCountry';
import { settingsSelector } from '../../../store/core/core.store';

const useStyle = makeStyles(theme => ({
	root: {
		padding: 0,
	},
	primary: {
		fontWeight: 'bold',
		fontSize: 14,
		color: theme.textDark,
	},
	secondary: {
		fontSize: 13,
		color: theme.textLight,
	},
	linkText: {
		color: theme.textLight,
		textDecoration: 'none',
	},
}));

export default function LegalsScreen() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchPoints());
	}, [dispatch]);
	const settings = useSelector(settingsSelector);

	const classes = useStyle();
	const { t } = useTranslation();

	const itemTextClasses = { primary: classes.primary, secondary: classes.secondary };
	return (
		<div>
			<Box p={[2]}>
				<List component={Cloud} style={{ backgroundColor: theme.mainBackgroundScreens }}>
					<a
						href={settings?.url?.imprint}
						target="_blank"
						rel="noopener noreferrer"
						className={classes.linkText}
					>
						<ListItem classes={classes} button divider>
							<ListItem>
								<ListItemText classes={itemTextClasses}>{t('imprint')}</ListItemText>
							</ListItem>
							<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
						</ListItem>
					</a>
					<a
						href={settings?.url?.terms}
						target="_blank"
						rel="noopener noreferrer"
						className={classes.linkText}
					>
						<ListItem classes={classes} button divider>
							<ListItem>
								<ListItemText classes={itemTextClasses}>{t('conditions')}</ListItemText>
							</ListItem>
							<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
						</ListItem>
					</a>
					<a
						href={settings?.url?.gdpr}
						target="_blank"
						rel="noopener noreferrer"
						className={classes.linkText}
					>
						<ListItem classes={classes} button divider>
							<ListItem>
								<ListItemText classes={itemTextClasses}>{t('register privacy')}</ListItemText>
							</ListItem>
							<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
						</ListItem>
					</a>
					{ConfigCountry.international && (
						<a
							href={settings?.url?.usc2577}
							target="_blank"
							rel="noopener noreferrer"
							className={classes.linkText}
						>
							<ListItem classes={classes} button>
								<ListItem>
									<ListItemText classes={itemTextClasses}>USC 2257</ListItemText>
								</ListItem>
								<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
							</ListItem>
						</a>
					)}
				</List>
			</Box>
		</div>
	);
}
