import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
	return {
		'@global': {
			body: {
				backgroundColor: theme.white,
			},
		},
		root: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			backgroundColor: theme.palette.background.lightDark,
			color: 'white',
		},
		paper: {
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(5),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		avatar: {
			margin: theme.spacing(8),
			backgroundColor: theme.palette.primary.colorAccent,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		input: {
			color: theme.palette.common.white,
		},
		inputWithIcon: {
			width: 'calc(100% - 58px)',
		},
		registerInput: {
			color: '#fff',
			width: 'calc(100% - 58px)',
		},
		labelPlacementStart: {
			width: '100%',
			marginLeft: '0',
			justifyContent: 'space-between',
		},
		checkboxPaper: {
			padding: '3px 5px 3px 15px',
		},
		link: {
			color: theme.palette.primary.points_red,
		},
		label: {
			fontSize: '0.9rem',
		},
		submit: {
			backgroundColor: theme.palette.background.login_button_background,
			height: '40px',
		},
		iconButton: {
			padding: 10,
			// color: theme.palette.background.lightDark,
		},
		registerIconButton: {
			color: '#fff',
			padding: 10,
		},
		icon: {
			// color: theme.palette.background.lightDark,
		},
		iconBack: {
			width: 15,
		},
		iconBackContainer: {
			backgroundColor: '#f8f8f9',
			width: 'fit-content',
			display: 'flex',
			borderRadius: '100%',
			padding: 6,
		},
		stepTitle: {
			color: '#cdcdcf',
			fontSize: 21,
			marginTop: 30,
			marginBottom: 30,
			textAlign: 'center',
		},
		nextButton: {
			fontSize: 18,
			color: '#FFF',
		},
	};
});
export default useStyles;
