import React from 'react';
import { Dialog, List, ListItemText } from '@material-ui/core';
import ListItem from './ListItem';

export default function SelectDialog({
	onSelect: handleSelect,
	onClose: handleClose,
	actions,
	visible,
}) {
	return (
		<Dialog onClose={handleClose} open={visible}>
			{visible ? (
				<List>
					{actions.map((action, index) => (
						<ListItem
							key={action.key}
							button
							onClick={() =>
								action.onClick ? action.onClick(action.key) : handleSelect(action.key)
							}
							divider={index !== actions.length - 1}
						>
							<ListItemText primary={action.label} />
						</ListItem>
					))}
				</List>
			) : (
				<div />
			)}
		</Dialog>
	);
}
