import React, { Fragment, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import CommentsList from '../../comment/components/CommentsList';
import CommentForm from '../../comment/components/CommentForm';
import InfiniteScroll from 'react-infinite-scroller';
import useEntryComments from '../hooks/useComments';
import Box from '@material-ui/core/Box';
import Loader from '../../../common/Loader';
import { findById } from '../../../helpers/fp';
import SelectDialog from '../../../common/SelectDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
	isPostingCommentSelector,
	postComment,
} from '../../../store/entry-comments/entry-comments.store';
import usePrevious from '../../../hooks/usePrevious';
import SectionHeading from '../../../common/SectionHeading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';
import ReportEntryCommentScreen from './ReportEntryCommentScreen';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		maxWidth: 700,
		height: '90%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function EntryCommentsScreen({ commentsId, onClose }) {
	const entryId = commentsId;
	const dispatch = useDispatch();
	const classes = useStyle();
	const { t } = useTranslation();

	const history = useHistory();
	const [replyingCommentId, setReplyingCommentId] = useState(null);
	const [activeCommentId, setActiveCommentId] = useState(null);
	const [activeReplyId, setActiveReplyId] = useState(null);
	const [selectDialogVisible, setSelectDialogVisible] = useState(false);
	const [open, setOpen] = useState(false);
	const [isClickedUserActive, setIsClickedUserActive] = useState(true);
	const scrollInf = useRef(null);
	const isPosting = useSelector(isPostingCommentSelector);
	const prevIsPosting = usePrevious(isPosting);
	useEffect(() => {
		if (prevIsPosting && !isPosting) {
			setReplyingCommentId(null);
		}
	}, [isPosting, prevIsPosting, setReplyingCommentId]);

	const handleSelectDialogClose = () => {
		setSelectDialogVisible(false);
		setActiveCommentId(null);
		setActiveReplyId(null);
	};

	const { isLoading, hasMore, comments, loadNext } = useEntryComments({ entryId });

	const replies = useMemo(
		() => comments.reduce((acc, val) => [...acc, ...val.replies], []),
		[comments],
	);

	const handleCommentClick = ({ commentId, isUserActive }) => {
		setActiveCommentId(commentId);
		setIsClickedUserActive(Boolean(isUserActive));
		setSelectDialogVisible(true);
	};

	const handleRepliedCommentClick = ({ replyId, isUserActive }) => {
		setActiveReplyId(replyId);
		setIsClickedUserActive(Boolean(isUserActive));
		setSelectDialogVisible(true);
	};

	const handleCleanReply = () => {
		setReplyingCommentId(null);
	};

	const handleSubmit = comment => {
		const actionPayload = {
			comment,
			entryId,
		};
		if (replyingCommentId) {
			actionPayload.commentId = replyingCommentId;
		}
		dispatch(postComment(actionPayload));
	};

	const targetName = useMemo(
		() => (replyingCommentId ? findById(replyingCommentId)(comments).nickname : null),
		[comments, replyingCommentId],
	);

	const reply = activeReplyId && findById(activeReplyId)(replies);
	const comment = activeCommentId && findById(activeCommentId)(comments);

	const selectCommentActions = useMemo(() => {
		if (activeReplyId) {
			return [
				{
					label: `@${reply.nickname}'s ${t('profile')}`,
					key: 'GO_TO_REPLY_PROFILE',
					onClick: () => {
						if (isClickedUserActive) {
							history.push(`/community/${reply.uid}`);
						}
					},
				},
				{
					label: t('report'),
					key: 'REPORT_REPLY',
					onClick: () => handleOpen(),
					// onClick: () => history.push(`/feed/${entryId}/reply/${reply.id}/report`),
				},
			];
		} else if (activeCommentId) {
			return [
				{
					label: `@${comment.nickname}'s ${t('profile')}`,
					key: 'GO_TO_COMMENT_PROFILE',
					onClick: () => {
						if (isClickedUserActive) {
							history.push(`/community/${comment.uid}`);
						}
					},
				},
				{
					label: t('answer'),
					key: 'REPLY_TO_COMMENT',
					onClick: () => {
						handleSelectDialogClose();
						setReplyingCommentId(activeCommentId);
					},
				},
				{
					label: t('report'),
					key: 'REPORT_COMMENT',
					onClick: () => handleOpen(),
					// onClick: () => history.push(`/feed/${entryId}/comments/${comment.id}/report`),
				},
			];
		} else {
			return [];
		}
	}, [comments, replies, activeCommentId, activeReplyId, entryId, history]);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Fragment style={{ border: 'none' }}>
			<HeaderAppBar
				positionHeader={'fixed'}
				title={t('comments')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
				rightIcon={null}
			/>
			<Loader loading={isLoading} />
			<Box p={1} style={{ height: 675, overflow: 'auto', marginTop: 65 }} ref={scrollInf}>
				{!isLoading && !comments.length ? (
					<SectionHeading>{t('no comments')}!</SectionHeading>
				) : null}
				<InfiniteScroll
					initialLoad={false}
					loadMore={loadNext}
					hasMore={hasMore && !isLoading}
					useWindow={false}
				>
					<CommentsList
						comments={comments}
						onCommentClick={handleCommentClick}
						onRepliedCommentClick={handleRepliedCommentClick}
					/>
				</InfiniteScroll>
			</Box>
			<SelectDialog
				visible={selectDialogVisible}
				actions={selectCommentActions}
				onClose={handleSelectDialogClose}
			/>
			<CommentForm
				targetUserName={targetName}
				onCleanReply={handleCleanReply}
				onSubmit={handleSubmit}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ReportEntryCommentScreen
						commentId={comment?.id}
						replyId={reply?.id}
						onClose={() => handleClose()}
					/>
				</Box>
			</Modal>
		</Fragment>
	);
}
