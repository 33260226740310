import React from 'react';
import { makeStyles } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';

const useStyle = makeStyles(theme => ({
	icon: {
		marginRight: 5,
	},
	submit: {
		backgroundColor: theme.palette.background.login_button_background,
		height: '48px',
	},
}));

export default function SubmitButton({
	children,
	type = 'submit',
	style,
	onClick,
	icon,
	iconRight,
	disabled = false,
}) {
	const classes = useStyle();

	return (
		<Button
			type={type}
			fullWidth
			variant="contained"
			color="primary"
			className={style || classes.submit}
			onClick={onClick}
			startIcon={icon ? icon : <SendIcon className={classes.icon} />}
			endIcon={iconRight}
			disabled={disabled}
		>
			{children}
		</Button>
	);
}
