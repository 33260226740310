import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RestoreIcon from '@material-ui/icons/Restore';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import getCroppedImg from '../../../utils/getCroppedImgUtil';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	cropPageContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: '#1a1a1b',
		zIndex: '1111',
	},
	cropContainer: {
		position: 'absolute',
		width: '100%',
		height: '80%',
		background: '#333',
		[theme.breakpoints.up('sm')]: {
			height: 400,
		},
	},
	cropButton: {
		flexShrink: 0,
		marginLeft: 16,
	},
	controls: {
		position: 'absolute',
		bottom: '0',
		padding: '16px 16px 16px 40px',
		// display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	sliderContainer: {
		display: 'flex',
		flex: '1',
		alignItems: 'center',
	},
	sliderLabel: {
		[theme.breakpoints.down('xs')]: {
			minWidth: 65,
		},
	},
	slider: {
		padding: '22px 0px',
		marginLeft: 16,
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			alignItems: 'center',
			margin: '0 16px',
		},
	},
}));

function CroppingPage({
	src,
	onCroppedImageReady: handleCroppedImageReady,
	onCancel: handleCancel,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		// console.log('onCropComplete');
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const handleCroppedImage = useCallback(
		async event => {
			try {
				const { objectURL: croppedImage, blob } = await getCroppedImg(
					src,
					croppedAreaPixels,
					rotation,
				);
				setCroppedImage(croppedImage);
				const file = new File([blob], 'image-01', { type: 'image/jpeg' });
				handleCroppedImageReady(event, file);
			} catch (e) {
				console.error(e);
			}
		},
		[croppedAreaPixels, rotation],
	);

	return (
		<div className={classes.cropPageContainer}>
			<div className={classes.cropContainer}>
				<Cropper
					image={src}
					crop={crop}
					rotation={rotation}
					zoom={zoom}
					showGrid={false}
					aspect={1}
					cropShape="round"
					onCropChange={setCrop}
					onRotationChange={setRotation}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
				/>
			</div>
			<div className={classes.controls}>
				<Button style={{ color: '#fff' }} onClick={handleCancel}>
					{t('cancel')}
				</Button>
				<IconButton
					onClick={() => {
						setRotation(prevState => prevState - 90);
					}}
					style={{ color: '#fff' }}
					className={classes.button}
					aria-label="add to shopping cart"
				>
					<RotateLeftIcon />
				</IconButton>
				<IconButton
					onClick={() => {
						setRotation(0);
						setZoom(1);
					}}
					style={{ color: '#fff' }}
					className={classes.button}
					aria-label="add to shopping cart"
				>
					<RestoreIcon />
				</IconButton>
				<IconButton
					onClick={() => {
						setRotation(prevState => prevState + 90);
					}}
					style={{ color: '#fff' }}
					className={classes.button}
					aria-label="add to shopping cart"
				>
					<RotateRightIcon />
				</IconButton>
				<Button style={{ color: '#fff' }} onClick={handleCroppedImage}>
					{t('ready')}
				</Button>
			</div>
		</div>
	);
}

export default React.memo(CroppingPage);
