import { createAction, createReducer } from 'redux-starter-kit';

const setThemeConfigs = createAction('SET_THEME_CONFIGS');

const initialState = {
	darkMode: Number(localStorage.getItem('darkMode')),
};

const themeReducer = createReducer(initialState, {
	[setThemeConfigs]: (state, action) => ({ ...state, darkMode: action.payload.darkMode }),
});

export const darkModeHandler = state => dispatch => {
	dispatch(setThemeConfigs({ darkMode: state }));
	localStorage.setItem('darkMode', state);
	window.location.reload();
};

export const darkModeSelector = state => state.theme.darkMode;

export default themeReducer;
