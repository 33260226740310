import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import IcAnimator from '../../../assets/entertainer.png';
import ReferenceButton from '../../../common/ReferenceButton';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { AnimatorRequestScreen } from './AnimatorRequestScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
	animatorContainer: {
		paddingTop: '82px !important',
		backgroundColor: theme.mainBackgroundScreens,
		height: '100%',
		overflowY: 'scroll',
		[theme.breakpoints.down('md')]: {
			padding: 20,
		},
	},
	dark: {
		color: theme.textDark,
	},
}));

export function AnimatorScreen({ onClose, header }) {
	const classes = useStyles();

	const [openModal, setOpenModal] = useState(false);
	const { t } = useTranslation();

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);
	return (
		<>
			{header && (
				<HeaderAppBar
					positionHeader={'fixed'}
					title={t('real entertainer')}
					leftIcon={
						<FontAwesomeIcon
							onClick={onClose}
							icon={faLongArrowAltLeft}
							style={{ fontSize: 25 }}
							color={theme.headerIcon}
						/>
					}
					rightIcon={null}
				/>
			)}
			<div>
				<Box p={1} className={classes.animatorContainer}>
					<Typography style={{ color: theme.textLight }}>
						<img
							src={IcAnimator}
							style={{
								float: 'right',
								width: 75,
								marginLeft: 15,
								marginBottom: 15,
							}}
							alt={'Animator'}
						/>
						<b className={classes.dark}>{t('what is entertainer')}</b>
						<br />
						<br />
						{t('entertainer answer')}
						<br />
						{t('entertainer answer 2')}
						<br />
						<br />
						<b className={classes.dark}>{t('why entertainer')}</b>
						<br />
						<br />
						{t('why entertainer answer')}
						<br />
						<br />
						<b className={classes.dark}>{t('who can be entertainer')}</b>
						<br />
						<br />
						{t('who can be answer')}
					</Typography>
					<br />
					<ReferenceButton
						title={t('request verification')}
						subTitle={t('to be entertainer send request')}
						onClick={handleOpen}
					/>
				</Box>
			</div>
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<AnimatorRequestScreen onClose={() => handleClose()} />
				</Box>
			</Modal>
		</>
	);
}
