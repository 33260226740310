import React, { useCallback, useState, Fragment } from 'react';
import InfoDialog from '../../../common/InfoDialog';
import { InputBase, InputAdornment, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormErrorMessage from '../../../common/forms/FormErrorMessage';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PointService from '../../../services/PointService';
import { useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import ReportUserScreen from '../../community/screens/ReportUserScreen';
import PackageBuyMethodScreen from '../../options/screens/package/PackageBuyMethodScreen';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function ConversationPointSendDialog({ conversationId, opponentName }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const [show, setShow] = useState(false);
	const [buyPointsSuggestionVisible, setBuyPointsSuggestionVisible] = useState(false);
	const [open, setOpen] = useState(false);

	const requiredMessage = t('field is required');
	const validationSchema = Yup.object().shape({
		points: Yup.number().label(t('points')).required(requiredMessage).positive().integer(),
	});

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const { handleChange, values, touched, errors, handleSubmit, resetForm } = useFormik({
		initialValues: {
			points: '',
		},
		validationSchema,
		onSubmit: ({ points }, formikHelpers) => {
			PointService.sendPoints(conversationId, points)
				.then(() => formikHelpers.resetForm())
				.catch(() => {
					setBuyPointsSuggestionVisible(true);
					// window.hideApiError();
				})
				.finally(() => setShow(false));
		},
	});

	const onOk = useCallback(() => {
		handleSubmit();
		setShow(true);
	}, [setShow, handleSubmit]);

	const onClose = useCallback(() => {
		resetForm();
		setShow(false);
	}, [setShow, resetForm]);

	const handleBuyPoints = () => {
		handleOpen();
		// history.push('/profile/options/points/payment-types');
		setBuyPointsSuggestionVisible(false);
	};

	return (
		<>
			<InfoDialog
				text={<div style={{ marginTop: 20 }}>{t('no points')}</div>}
				onOk={handleBuyPoints}
				onClose={() => setBuyPointsSuggestionVisible(false)}
				onCancel={() => setBuyPointsSuggestionVisible(false)}
				open={buyPointsSuggestionVisible}
				okText={t('buy points now')}
			/>
			<InputAdornment
				onClick={() => setShow(true)}
				position="end"
				style={{ cursor: 'pointer' }}
				title={t('send points')}
				role="button"
			>
				<AttachMoneyIcon />
			</InputAdornment>
			<InfoDialog
				text={
					<div style={{ marginTop: 20 }}>
						{t('how many points do you want')} {opponentName} {t('give a gift')}?
					</div>
				}
				open={show}
				onOk={onOk}
				onClose={onClose}
				onCancel={onClose}
				okText={t('send')}
				cancelColor={'primary'}
			>
				<InputBase
					placeholder={t('points')}
					name={'points'}
					type={'number'}
					value={values.points}
					onChange={handleChange}
					error={!!errors.points}
					fullWidth
					style={{ border: '1px solid #ccc', paddingLeft: 10, paddingRight: 10 }}
				/>
				<FormErrorMessage name={'points'} errors={errors} touched={touched} />
			</InfoDialog>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<PackageBuyMethodScreen onClose={() => handleClose()} header={true} />
				</Box>
			</Modal>
		</>
	);
}
