import React, { useCallback, useEffect, useRef } from 'react';
import UserFeedEntryItem from './UserFeedEntryItem';
import Loader from '../../../common/Loader';
import useUserEntries from '../../feed/hooks/useUserEntries';
import Box from '@material-ui/core/Box';
import EntryList from '../../feed/components/entry/EntryList';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

export default function UserFeedEntries({ uid, type }) {
	// const containerRef = useRef();
	const { isLoading, hasMore, entries, loadNext } = useUserEntries({ type, uid });
	const { t } = useTranslation();

	// const handleScroll = useCallback(() => {
	//     if ((containerRef.current.scrollTop + containerRef.current.offsetHeight) >= containerRef.current.scrollHeight
	//         && !isLoading && hasMore) {
	//         loadNext();
	//     }
	// }, [containerRef, hasMore, isLoading]);

	// useEffect(() => {
	//     containerRef.current.addEventListener('scroll', handleScroll);
	//     return () => containerRef.current.removeEventListener('scroll', handleScroll);
	// }, [containerRef, handleScroll]);
	return (
		<div>
			<Loader loading={isLoading} />
			{entries.length === 0 && !isLoading && (
				<div style={{ textAlign: 'center', marginTop: 30, color: theme.textDark }}>
					{t('no entries')}
				</div>
			)}
			<Box>
				<EntryList
					isLoading={isLoading}
					entries={entries}
					hasMore={hasMore}
					loadMore={loadNext}
					page={0}
				/>
			</Box>
		</div>
	);
}
