import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	age: {
		display: 'flex',
		backgroundColor: '#eeeeee',
		padding: '3px 7px',
		borderRadius: 7,
		margin: '0 5px',
		color: '#575757',
		fontWeight: 'bold',
	},
	suffix: {
		marginLeft: 7,
	},
}));

export default function UserAge({ age, suffix }) {
	const classes = useStyle();
	const { t } = useTranslation();

	return (
		<div className={classes.age} style={{ cursor: 'pointer' }}>
			<span>
				{age} {t('profile age')}
			</span>
			{suffix ? <div className={classes.suffix}>{suffix}</div> : <div />}
		</div>
	);
}
