import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Man from '../../../assets/man.png';
import Woman from '../../../assets/women.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		backgroundColor: 'transparent',
		display: 'flex',
		flexDirection: 'column',
	},
	buttonRoot: {
		width: '100%',
		color: 'white',
		opacity: 0.5,
		textTransform: 'unset',
		fontSize: 21,
		padding: '0 2px',
		height: 'auto',
	},
	selected: {
		color: 'white !important',
		opacity: 1,
	},
	icon: {
		width: 22,
		marginLeft: 5,
	},
	buttonText: {
		width: '90%',
	},
}));

export default function ToggleButtons({ setValue, value, changeStep, btnOne, btnTwo }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment !== null) {
			setValue(newAlignment);
		}
	};
	return (
		<ToggleButtonGroup
			value={value}
			exclusive
			onChange={handleAlignment}
			aria-label="text alignment"
			className={classes.root}
			onClick={() => changeStep(1)}
		>
			<ToggleButton
				className={classes.buttonRoot}
				classes={{
					selected: classes.selected,
				}}
				style={{
					backgroundColor: '#41a5d1',
					borderRadius: 0,
					marginTop: 50,
					...btnOne,
				}}
				value="m"
				aria-label="left aligned"
			>
				<div className={classes.buttonText}>{t('i am male')}</div>
				<img src={Man} alt="man" className={classes.icon} />
			</ToggleButton>
			<ToggleButton
				className={classes.buttonRoot}
				classes={{
					selected: classes.selected,
				}}
				style={{
					backgroundColor: '#db5b5d',
					borderRadius: 0,
					marginLeft: 0,
					marginTop: 15,
					...btnTwo,
				}}
				value="w"
				aria-label="right aligned"
			>
				<div className={classes.buttonText}>{t('i am female')}</div>
				<img src={Woman} alt="woman" className={classes.icon} />
			</ToggleButton>
		</ToggleButtonGroup>
	);
}
