import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CreatePostScreen from '../../feed/screens/CreatePostScreen';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.white,
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
	},
	addPost: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '7.5px 15px',
		marginLeft: 10,
		backgroundColor: theme.mainBlue,
		borderRadius: 15,
		color: theme.white,
	},
	noTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: 10,
		marginLeft: 10,
		backgroundColor: theme.mainBlue,
		borderRadius: 50,
		color: theme.white,
	},
	addPostText: {
		fontSize: 15,
		marginRight: 8,
	},
	addPostIcon: {
		size: 22,
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
		width: '100%',
		height: '100%',
	},
}));

export default function NewPost({ title }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [openPost, setOpenPost] = useState(false);

	const handleOpen = () => setOpenPost(true);
	const handleClose = () => setOpenPost(false);

	return (
		<div
			className={classes.toolbar}
			aria-label="shopping cart items"
			role="button"
			title={t('new entry')}
		>
			<div className={title ? classes.addPost : classes.noTitle} onClick={handleOpen}>
				{title && <div className={classes.addPostText}>{title}</div>}
				<FontAwesomeIcon icon={faPlus} className={classes.addPostIcon} color={theme.white} />
			</div>
			<Modal
				open={openPost}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<CreatePostScreen onClose={() => handleClose()} />
				</Box>
			</Modal>
		</div>
	);
}
