import React from 'react';
import Cloud from '../../../../common/Cloud';
import ListItem from '../../../../common/ListItem';
import { List, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PaymentListItemIcon from './PaymentListItemIcon';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../../store/profile/profile.store';

const useStyle = makeStyles(theme => ({
	root: {
		paddingLeft: 0,
		paddingRight: 0,
		backgroundColor: theme.mainBackgroundScreens,
	},
	primary: {
		color: theme.palette.primary.user_red,
		fontSize: 14,
		fontWeight: 'bold',
	},
	secondary: {
		fontSize: 13,
		fontWeight: 'bold',
		color: theme.textLight,
	},
	iconStyle: {
		color: theme.headerIcon,
	},
}));

const countryIds = [1, 2, 3, 21, 110, 154, 176, 201];

export default function PaymentList({ items, onClick: handleClick }) {
	const classes = useStyle();
	const profile = useSelector(currentUserSelector);

	return (
		<List component={Cloud} className={classes.root}>
			{items.map((it, index) => {
				return it?.id === 'sofort' && !countryIds.includes(profile?.country) ? null : (
					<ListItem
						key={it.id}
						onClick={() => handleClick(it.id)}
						button
						divider={index !== items.length - 1}
					>
						<ListItemIcon>
							<PaymentListItemIcon src={it.image} />
						</ListItemIcon>
						<ListItemText
							primary={it.primaryText}
							secondary={it.secondaryText}
							classes={{ primary: classes.primary, secondary: classes.secondary }}
						/>
						<ArrowForwardIosIcon className={classes.iconStyle} />
					</ListItem>
				);
			})}
		</List>
	);
}
