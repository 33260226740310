import BaseService from './BaseService';

class PackageService extends BaseService {
	constructor() {
		super('/packages');
	}

	getPaymentTypes() {
		return this.get('/paymenttypes');
	}

	getPackages(paymentTypeName) {
		return this.get(`/${paymentTypeName}`);
	}

	getPaymentUrl(packageId) {
		return this.get(`/${packageId}/paymenturl`);
	}
}

export default new PackageService();
