import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ENTRY_TYPE } from '../../../constants';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	item: {
		textTransform: 'uppercase',
		fontSize: 16,
		color: theme.mainBlue,
		margin: '0 20px',
		[theme.breakpoints.down('md')]: {
			fontSize: 11,
		},
	},
	selectedItem: {
		borderBottom: '3px solid ' + theme.mainBlue,
		fontWeight: 'bold',
		color: theme.mainBlue,
	},
}));

export default function FeedPeriodButtons({ value, onChange: handleChange }) {
	const classes = useStyle();
	const { t } = useTranslation();

	const items = [
		{
			label: t('best of week'),
			value: ENTRY_TYPE.BEST_WEEK,
		},
		{
			label: t('best of month'),
			value: ENTRY_TYPE.BEST_MONTH,
		},
		{
			label: t('best all time'),
			value: ENTRY_TYPE.BEST,
		},
	];
	return (
		<Box py={2} display={'flex'} justifyContent={'center'}>
			{items.map(it => (
				<div
					key={it.value}
					onClick={() => handleChange(it.value)}
					className={`${classes.item} ${it.value === value ? classes.selectedItem : ''}`}
					style={{ cursor: 'pointer' }}
				>
					{it.label}
				</div>
			))}
		</Box>
	);
}
