import React, { Fragment, useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import InfoDialog from '../../../../common/InfoDialog';
import RateStars from '../../../shared/components/RateStars';
import { useSelector } from 'react-redux';
import { isVotingSelector } from '../../../../store/entry/entry.store';
import usePrevious from '../../../../hooks/usePrevious';
import useConversationRequest from '../../../chat/hooks/useConversationRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../theme';
import Modal from '@material-ui/core/Modal';
import EntryCommentsScreen from '../../screens/EntryCommentsScreen';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.primary.splitter_line}`,
		height: 46,
	},
	typographySchema: {
		color: theme.palette.background.lightDark,
	},
	sendIcon: {
		marginTop: -7,
	},
	commentsCount: {
		position: 'absolute',
		left: 23,
		bottom: -9,
		fontSize: 14,
		fontWeight: 'bold',
		color: theme.commentCount,
	},
	heimlich: {
		color: '#888888',
		fontSize: 16,
		textAlign: 'center',
		marginLeft: 7,
		lineHeight: '16px',
	},
	feedChatText: {
		color: theme.mainBlue,
		fontSize: 10,
		textAlign: 'center',
		marginTop: 1
	},
	rateButton: {
		fontSize: 15,
		color: 'white',
		borderRadius: 10,
		padding: '2px 13px',
		backgroundColor: theme.mainBlue,
		alignSelf: 'center',
		cursor: 'pointer',
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		maxWidth: 700,
		height: '90%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function EntryItemFooter({
	commentsCount,
	averagePrivacy,
	isVoted,
	ownVote,
	voteEntry,
	uid,
	chatexists,
	canContact,
	commentsId,
}) {
	const classes = useStyle();
	const { t } = useTranslation();
	const [rate, setRate] = useState(null);
	const [rateDialogVisibility, setRateDialogVisibility] = useState(false);
	const [chatDialogVisibility, setChatDialogVisibility] = useState(false);
	const [open, setOpen] = useState(false);
	const handleRateDialogOpen = () => setRateDialogVisibility(true);
	const handleRateDialogCloseAndCancel = () => setRateDialogVisibility(false);
	const handleChatDialogCloseAndCancel = () => setChatDialogVisibility(false);

	const isVoting = useSelector(isVotingSelector);
	const prevIsVoting = usePrevious(isVoting);
	useEffect(() => {
		if (prevIsVoting && !isVoting) {
			setRateDialogVisibility(false);
		}
	}, [isVoting, prevIsVoting, setRateDialogVisibility]);

	const { startChat } = useConversationRequest({ uid });

	const handleStartChat = () => {
		if (chatexists) {
			startChat();
		} else {
			setChatDialogVisibility(true);
		}
	};
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	return (
		<Box p={1} className={classes.root} display={'flex'} justifyContent={'space-between'}>
			<Box display={'flex'} alignItems={'center'}>
				{canContact ? (
					<Box
						pr={1}
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
						style={{ cursor: 'pointer' }}
						title={t('write message')}
						role="button"
					>
						<FontAwesomeIcon
							onClick={() => handleStartChat()}
							icon={faPaperPlane}
							style={{ fontSize: 20, transform: 'rotate(30deg)' }}
							color={theme.mainBlue}
						/>
						<Typography className={classes.feedChatText} onClick={() => handleStartChat()}>
							Chat
						</Typography>
					</Box>
				) : null}
				{/*<Box*/}
				{/*	display={'flex'}*/}
				{/*	alignItems={'center'}*/}
				{/*	style={{ position: 'relative', cursor: 'pointer', marginLeft: 15, marginTop: -4 }}*/}
				{/*	onClick={handleOpen}*/}
				{/*>*/}
				{/*	<FontAwesomeIcon*/}
				{/*		icon={faCommentDots}*/}
				{/*		style={{ fontSize: 24 }}*/}
				{/*		color={theme.mainBlue}*/}
				{/*		title={t('comments')}*/}
				{/*		role="button"*/}
				{/*	/>*/}
				{/*	<Typography className={classes.commentsCount}>{commentsCount}</Typography>*/}
				{/*</Box>*/}
			</Box>
			<InfoDialog
				text={t('sure to chat')}
				open={chatDialogVisibility}
				onOk={startChat}
				onClose={handleChatDialogCloseAndCancel}
				onCancel={handleChatDialogCloseAndCancel}
			/>

			<Box display={'flex'}>
				{isVoted ? (
					<Box displat={'flex'} alignSelf={'center'}>
						<RateStars size={18} value={ownVote} />
					</Box>
				) : (
					<>
						<InfoDialog
							title={t('your vote') + ':'}
							open={rateDialogVisibility}
							onOk={() => voteEntry(rate)}
							onClose={handleRateDialogCloseAndCancel}
							onCancel={handleRateDialogCloseAndCancel}
						>
							<RateStars size={35} value={rate} onChange={setRate} />
						</InfoDialog>
						<div
							className={classes.rateButton}
							onClick={() => handleRateDialogOpen()}
							title={t('vote')}
							role="button"
						>
							{t('vote')}
						</div>
					</>
				)}

				<Typography className={classes.heimlich}>
					{averagePrivacy}%<br />
					{t('secret')}
				</Typography>
			</Box>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<EntryCommentsScreen commentsId={commentsId} onClose={() => handleClose()} />
				</Box>
			</Modal>
		</Box>
	);
}
