import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import theme from '../theme';

export default function SectionHeading({ children, ...restProps }) {
	return (
		<Box {...restProps} pb={1}>
			<Typography variant={'subtitle2'} style={{ color: theme.textDark }}>
				{children}
			</Typography>
		</Box>
	);
}
