import { createAction, createReducer } from 'redux-starter-kit';
import PointService from '../../services/PointService';
import { fetchPoints } from '../profile/profile.store';

export const searchEarnedRewardsRequest = createAction('SEARCH_EARNED_REWARDS_REQUEST');
export const searchEarnedRewardsFailure = createAction('SEARCH_EARNED_REWARDS_FAILURE');
export const searchEarnedRewardsSuccess = createAction('SEARCH_EARNED_REWARDS_SUCCESS');

export const searchRewardsRequest = createAction('SEARCH_REWARDS_REQUEST');
export const searchRewardsFailure = createAction('SEARCH_REWARDS_FAILURE');
export const searchRewardsSuccess = createAction('SEARCH_REWARDS_SUCCESS');

export const claimRewardsRequest = createAction('CLAIM_REWARDS_REQUEST');
export const claimRewardsFailure = createAction('CLAIM_REWARDS_FAILURE');
export const claimRewardsSuccess = createAction('CLAIM_REWARDS_SUCCESS');

export const resetEarnedRewards = createAction('RESET_EARNED_REWARDS');

const initialState = {
	page: 1,
	earnedRewards: [],
	isLoading: false,
	hasMore: false,
	rewards: [],
	isClaiming: false,
};

export const searchEarnedRewards = filters => async dispatch => {
	dispatch(searchEarnedRewardsRequest());

	PointService.getEarnedRewards(filters)
		.then(({ data }) => {
			dispatch(
				searchEarnedRewardsSuccess({
					earnedRewards: data.entries.data,
					hasMore: data.entries.last_page === filters.page,
				}),
			);
		})
		.catch(() => {
			dispatch(searchEarnedRewardsFailure());
		});
};

export const searchRewards = filters => async dispatch => {
	dispatch(searchRewardsRequest());

	PointService.getRewards(filters)
		.then(({ data }) => {
			dispatch(
				searchRewardsSuccess({
					rewards: data,
				}),
			);
		})
		.catch(() => {
			dispatch(searchRewardsFailure());
		});
};

export const claimReward = id => async dispatch => {
	dispatch(claimRewardsRequest());

	PointService.claimReward(id)
		.then(() => {
			dispatch(claimRewardsSuccess());
			dispatch(fetchPoints());
		})
		.catch(() => {
			dispatch(claimRewardsFailure());
		});
};

const pointsReducer = createReducer(initialState, {
	// Search Earned Rewards
	[searchEarnedRewardsRequest]: state => {
		state.isLoading = true;
	},
	[searchEarnedRewardsSuccess]: (state, action) => {
		state.page++;
		state.isLoading = false;
		state.earnedRewards = [...state.earnedRewards, ...action.payload.earnedRewards];
		state.hasMore = action.payload.hasMore;
	},
	[searchEarnedRewardsFailure]: state => ({ ...state, isLoading: false }),
	[resetEarnedRewards]: state => ({
		...state,
		page: 1,
		earnedRewards: [],
		hasMore: false,
		isLoading: false,
	}),

	// Search Rewards
	[searchRewardsRequest]: state => ({ ...state, isLoading: true }),
	[searchRewardsSuccess]: (state, action) => ({
		...state,
		isLoading: false,
		rewards: action.payload.rewards,
	}),
	[searchRewardsFailure]: state => ({ ...state, isLoading: false }),

	// Claim reward
	[claimRewardsRequest]: state => ({ ...state, isClaiming: true }),
	[claimRewardsSuccess]: state => ({ ...state, isClaiming: false }),
	[claimRewardsFailure]: state => ({ ...state, isClaiming: false }),
});

export const earnedRewardsSelector = state => state.points.earnedRewards;
export const rewardsSelector = state => state.points.rewards;

export default pointsReducer;
