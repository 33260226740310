import BaseService from './BaseService';

class NotificationService extends BaseService {
	constructor() {
		super('/systemmessages');
	}

	getNotifications({ page }) {
		return this.get('/all', {
			page,
		});
	}

	getNotificationById(id) {
		return this.get(`/get/${id}`);
	}

	markAllRead() {
		return this.get('/markAllRead');
	}

	getUnreadsCount() {
		return this.get(`/unseen`);
	}
}

export default new NotificationService();
