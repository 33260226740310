import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import UserLayout from '../../shared/components/UserLayout';
import UserAvatar from '../../shared/components/UserAvatar';
import ListItem from '../../../common/ListItem';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import {getUserById, userSelector, blockUser, resetUser} from '../../../store/user/user.store';
import Loader from '../../../common/Loader';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import LockIcon from '@material-ui/icons/Lock';
import UserName from '../../shared/components/UserName';
import useConversationRequest from '../../chat/hooks/useConversationRequest';
import UserFeed from '../components/UserFeed';
import UserAge from '../../shared/components/UserAge';
import {
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    List,
    ListItemText,
    Dialog,
    Box,
} from '@material-ui/core';
import InfoDialog from '../../../common/InfoDialog';
import IcAnimator from '../../../assets/entertainer.png';
import ProfileSlogan from '../../profile/components/ProfileSlogan';
import ProfileCountryAndPostalCode from '../../profile/components/ProfileCountryAndPostalCode';
import theme from '../../../theme';
import {faFlag, faSearchMinus, faSearchPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Logo from '../../../assets/logo.png';
import HeaderRight from '../../shared/components/HedaerRight';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ReportUserScreen from './ReportUserScreen';
import {AnimatorScreen} from '../../animator/screens/AnimatorScreen';
import {useTranslation} from 'react-i18next';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.mainBackgroundScreens,
        [theme.breakpoints.down('md')]: {
            paddingBottom: 64,
        },
    },
    logo: {
        height: 40,
    },
    chatButton: {
        backgroundColor: theme.mainBlue,
        color: 'white',
        borderRadius: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 51,
        fontSize: 18,
        cursor: 'pointer',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '90%',
        backgroundColor: theme.mainBackgroundScreens,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        },
    },
    modalBack: {
        backgroundColor: theme.modalColor,
    },
}));

export default function UserScreen() {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();

    const {userId} = useParams();
    const {startChat} = useConversationRequest({uid: userId});
    const [chatDialogVisibility, setChatDialogVisibility] = useState(false);
    const handleChatDialogCloseAndCancel = () => setChatDialogVisibility(false);
    const [open, setOpen] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [verifyUserModal, setVerifyUserModal] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenVerify = () => setVerifyUserModal(true);
    const handleCloseVerify = () => setVerifyUserModal(false);

    const handleOnZoomIn = () => setZoom(true);
    const handleOnZoomOut = () => setZoom(false);


    const handleStartChat = () => {
        if (user?.chatexists) {
            startChat();
        } else {
            setChatDialogVisibility(true);
        }
    };

    useEffect(() => {
        dispatch(getUserById(userId));
        return () => {
            dispatch(resetUser());
        };
    }, [dispatch, userId]);

    const [actionsDialogVisible, setActionsDialogVisible] = useState(false);
    const handleDialogClose = () => setActionsDialogVisible(false);

    const [blockingDialogVisible, setBlockingDialogVisible] = useState(false);

    const handleBlock = () => {
        dispatch(blockUser({id: userId}));
        setBlockingDialogVisible(false);
    };

    const handleAction = entryAction => {
        handleDialogClose();
        switch (entryAction) {
            case 'REPORT_USER':
                handleOpen();
                // history.push(`/community/${userId}/${user.nickname}/report`);
                break;
            case 'BLOCK_USER':
                setBlockingDialogVisible(true);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: 'REPORT_USER',
            label: t('report user'),
        },
        {
            key: 'BLOCK_USER',
            label: t('block user'),
        },
    ];

    if (!user) {
        return <Loader/>;
    }

    return (
        <>
            <HeaderAppBar
                leftIcon={
                    <img
                        src={Logo}
                        alt={'logo'}
                        className={classes.logo}
                        onClick={() => history.push('/feed')}
                        color={'action'}
                    />
                }
                rightIcon={<HeaderRight/>}
                newPost
                isLogo
                bar
            />
            <div onClick={() => history.goBack()}><FontAwesomeIcon
                icon={faLongArrowAltLeft}
                style={{fontSize: 25, marginLeft: 20, marginTop: 10}}
                color={theme.headerIcon}
            /></div>
            <div className={classes.root}>
                <Dialog open={blockingDialogVisible} onClose={() => setBlockingDialogVisible(false)}>
                    <DialogContent>
                        <DialogContentText>{t('confirm block')}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setBlockingDialogVisible(false)} color="primary">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleBlock} color="primary" autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={handleDialogClose} open={actionsDialogVisible}>
                    {actionsDialogVisible ? (
                        <List>
                            {actions.map(action => (
                                <ListItem
                                    key={action.key}
                                    button
                                    onClick={() => handleAction(action.key)}
                                    divider={true}
                                >
                                    <ListItemText primary={action.label}/>
                                </ListItem>
                            ))}
                            <ListItem button onClick={handleDialogClose} divider={false}>
                                <ListItemText primary={t('cancel')} style={{color: 'red'}}/>
                            </ListItem>
                        </List>
                    ) : (
                        <div/>
                    )}
                </Dialog>

                <InfoDialog
                    text={t('sure to chat')}
                    open={chatDialogVisibility}
                    onOk={startChat}
                    onClose={handleChatDialogCloseAndCancel}
                    onCancel={handleChatDialogCloseAndCancel}
                ></InfoDialog>
                {zoom ? <div
                    onClick={() => handleOnZoomOut()}
                    style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        zIndex: 98,
                        // backgroundColor: 'rgba(255,255,255,0.1)',
                        justifyContent: 'center'
                    }}/> : null}
                {zoom ? <div
                    // onClick={() => handleOnZoomOut()}
                    style={{
                        position: 'absolute',
                        zIndex: 99,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <div style={{
                        position: 'relative',
                        width: 300
                    }}>
                        <img
                            className={classes.img}
                            src={user.avatar}
                            alt={'logo'}
                            style={{width: 300, height: 300}}
                        />
                        <div
                            onClick={() => handleOnZoomOut()}
                            style={{
                                height: 60,
                                width: 60,
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faSearchMinus}
                                style={{cursor: 'pointer', color: '#FFF', fontSize: 25}}
                            />
                        </div>
                    </div>
                </div> : null}
                <UserLayout
                    topLeft={
                        <ProfileCountryAndPostalCode
                            zipcode={user.zipcode}
                            country={user.country}
                            uid={user?.uid}
                        />
                    }
                    avatar={
                        <div>
                            <Box
                                style={{
                                    position: 'absolute',
                                    right: '18px',
                                    display: 'flex',
                                    cursor: 'pointer',
                                }}
                                role="button"
                                title={t('report')}
                                onClick={() => setActionsDialogVisible(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faFlag}
                                    style={{cursor: 'pointer', marginLeft: 10, color: '#de3445', fontSize: 20}}
                                    color={theme.headerIcon}
                                />
                            </Box>
                            <div style={{
                                width: 'max-content',
                                position: 'relative',
                                borderRadius: 300,
                                overflow: 'hidden'
                            }}>
                                <UserAvatar src={user.avatar} size={145}/>
                                <div
                                    onClick={() => handleOnZoomIn()}
                                    style={{
                                        height: 50,
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faSearchPlus}
                                        style={{cursor: 'pointer', color: '#FFF', fontSize: 25}}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    name={
                        <UserName
                            nickname={user.nickname}
                            age={<UserAge age={user.age}/>}
                            gender={user.gender}
                            verifiedUser={
                                user.verified_animator ? (
                                    <img
                                        src={IcAnimator}
                                        alt={'Verified Animator'}
                                        style={{cursor: 'pointer', width: 38, marginLeft: 10}}
                                        onClick={() => handleOpenVerify()}
                                    />
                                ) : null
                            }
                        />
                    }
                    slogan={<ProfileSlogan slogan={user.slogan}/>}
                    sections={[
                        <div
                            className={classes.chatButton}
                            onClick={user.allowchatrequests ? handleStartChat : null}
                        >
                            <div>{user?.chatexists ? t('write message') : t('chat request')}</div>
                            <div>
                                {user.allowchatrequests ? (
                                    <ChatBubbleIcon style={{color: 'white', marginTop: 6, marginLeft: 15}}/>
                                ) : (
                                    <LockIcon style={{color: 'white', marginTop: 6, marginLeft: 15}}/>
                                )}
                            </div>
                        </div>,
                    ]}
                />
                <UserFeed user={user}/>
                <Modal
                    open={verifyUserModal}
                    onClose={handleCloseVerify}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.modalBack}
                >
                    <Box className={classes.modal}>
                        <AnimatorScreen onClose={() => handleCloseVerify()} header={true}/>
                    </Box>
                </Modal>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.modalBack}
                >
                    <Box className={classes.modal}>
                        <ReportUserScreen
                            userId={userId}
                            nickname={user?.nickname}
                            onClose={() => handleClose()}
                        />
                    </Box>
                </Modal>
            </div>
        </>
    );
}
