import React, { Fragment, useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Cloud from '../../../../common/Cloud';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
	acceptConversation,
	conversationDetailsSelector,
	senderSelector,
	declineConversation,
} from '../../../../store/message/message.store';
import { useHistory } from 'react-router-dom';
import { currentUserSelector } from '../../../../store/profile/profile.store';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function ConversationRequestForm() {
	const dispatch = useDispatch();
	const history = useHistory();
	const conversationDetails = useSelector(conversationDetailsSelector);
	const currentUser = useSelector(currentUserSelector);
	const isLoading = useSelector(state => state.message.isLoading);
	const sender = useSelector(senderSelector);
	const { t } = useTranslation();

	const handleClick = useCallback(() => {
		conversationDetails && dispatch(acceptConversation(conversationDetails.id));
	}, [conversationDetails, dispatch]);

	const handleDeclineConversation = useCallback(() => {
		conversationDetails && dispatch(declineConversation(conversationDetails.id));
		history.push(`/conversations`, 'internal');
	}, [conversationDetails, history, dispatch]);

	const renderState = useMemo(() => {
		if (isLoading) {
			return 'LOADING';
		}
		if (sender && currentUser) {
			if (sender.uid === currentUser.uid) {
				return 'PENDING_FORM';
			} else {
				return 'REQUEST_FORM';
			}
		}
		return 'LOADING';
	}, [isLoading, sender, currentUser]);

	const renderForm = useCallback(() => {
		switch (renderState) {
			case 'LOADING':
				return null;
			case 'PENDING_FORM':
				return (
					<Typography variant="body2" style={{ color: theme.textDark }}>
						{t('chat need to be accepted')}
					</Typography>
				);
			case 'REQUEST_FORM':
				return (
					<Cloud>
						<Typography variant="body2" style={{ color: theme.textDark }}>
							{sender.nickname} {t('received chat request')}.
						</Typography>
						<Box display={'flex'} mt={1}>
							<Button
								onClick={handleClick}
								style={{ marginRight: 8 }}
								fullWidth
								color={'secondary'}
								variant="contained"
							>
								{t('accept')}
							</Button>
							<Button
								onClick={handleDeclineConversation}
								fullWidth
								color={'primary'}
								variant="contained"
							>
								{t('decline')}
							</Button>
						</Box>
					</Cloud>
				);
			default:
				return null;
		}
	}, [renderState, sender, handleClick, handleDeclineConversation]);
	return (
		<Fragment>
			<Box style={{ marginTop: 64 }}>{renderForm()}</Box>
		</Fragment>
	);
}
