import BaseService from './BaseService';

class AuthorizeService extends BaseService {
	constructor() {
		super('/authorize');
	}

	authorize(mail, pass) {
		return this.post('', {
			mail,
			pass,
		});
	}

	remoteLogin(token) {
		return this.api.get('/authorizeToken', {
			...this.configs,
			params: {
				token,
			},
		});
	}

	authorizeSocial(provider, token) {
		return this.api.post(
			'/authorizeSocials',
			{
				provider,
				access_token: token,
			},
			this.configs,
		);
	}

	createAccount(data) {
		return this.api.post(
			'/createAccount',
			{
				...data,
			},
			this.configs,
		);
	}

	createRegistered(data) {
		return this.api.post(
			'/createregistered',
			{
				...data,
			},
			this.configs,
		);
	}

	checkNameAvailability(data) {
		return this.api.post('/checkusername', `username=${data}`, this.configs);
	}
}

export default new AuthorizeService();
