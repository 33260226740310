import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Cloud from '../../../common/Cloud';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RewardItem from './RewardItem';

const useStyle = makeStyles(theme => ({
	root: {
		overflow: 'auto',
		height: '38vh',
		backgroundColor: theme.mainBackgroundScreens,
	},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '3px 0',
		color: theme.textDark,
	},
}));

export default function EarnedRewardsList({ loadProducer, hasMore, isLoading, data }) {
	const classes = useStyle();
	const { t } = useTranslation();

	const scrollParentRef = useRef();
	return (
		<InfiniteScroll
			initialLoad={false}
			loadMore={loadProducer}
			hasMore={hasMore && !isLoading}
			useWindow={false}
			getScrollParent={() => scrollParentRef.current}
		>
			<Cloud className={classes.root} rootRef={scrollParentRef}>
				{data.length > 0 ? (
					data.map(reward => <RewardItem reward={reward} />)
				) : (
					<div className={classes.item}>{t('no stats')}</div>
				)}
			</Cloud>
		</InfiniteScroll>
	);
}
