import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/AccountCircle';
import { useFormik } from 'formik';
import useStyles from '../../screens/style';
import ProfileService from '../../../../services/ProfileService';
import Loader from '../../../../common/Loader';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import theme from '../../../../theme';
import Button from '@material-ui/core/Button';
import BackStepButton from './BackStepButton';
import { useTranslation } from 'react-i18next';

export default function ForgotPasswordStep({ changeStep }) {
	const classes = useStyles();
	const [isResetting, setIsResetting] = useState(false);
	const { t } = useTranslation();

	const requiredMessage = t('field is required');
	const validationSchema = Yup.object().shape({
		mail: Yup.string().label('E-Mail').email().required(requiredMessage),
	});
	const { handleChange, values, touched, errors, handleSubmit } = useFormik({
		initialValues: {
			mail: '',
		},
		validationSchema,
		onSubmit: ({ mail }) => {
			setIsResetting(true);
			ProfileService.resetPassword(mail).finally(() => {
				changeStep(7);
				setIsResetting(false);
			});
		},
	});

	return (
		<div>
			{isResetting ? <Loader /> : null}
			<div>
				<form onSubmit={handleSubmit}>
					<Paper
						className={classes.root}
						elevation={0}
						style={{
							display: 'flex',
							alignItems: 'center',
							backgroundColor: '#FFF',
							border: '2px solid silver',
							borderRadius: 10,
						}}
					>
						<IconButton className={classes.iconButton} aria-label="menu">
							<MenuIcon />
						</IconButton>
						<InputBase
							className={classes.inputWithIcon}
							name={'mail'}
							type={'mail'}
							value={values.mail}
							onChange={handleChange}
							placeholder={t('your email')}
							inputProps={{ 'aria-label': t('nickname') }}
						/>
					</Paper>
					<FormHelperText error={Boolean(touched.mail && errors.mail)}>
						{touched.mail && errors.mail ? errors.mail : ''}
					</FormHelperText>

					<Box color={theme.textDark} mt={3} mb={3} textAlign={'center'} style={{ fontSize: 14 }}>
						{t('receive password')}
					</Box>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						style={{
							width: '100%',
							backgroundColor: '#067afd',
							color: '#FFF',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: 44,
							marginTop: 15,
							borderRadius: 30,
							textTransform: 'none',
							fontSize: 18,
						}}
					>
						{t('reset password')}
					</Button>
					<div
						onClick={() => changeStep(5)}
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginTop: 15,
						}}
					>
						<BackStepButton changeStep={() => changeStep(1)} />
					</div>
				</form>
			</div>
		</div>
	);
}
