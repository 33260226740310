import React, { useState, useMemo, useCallback } from 'react';
import { API_ENDPOINT } from '../../../../services/BaseService';
import ImagePreview from '../../../../common/ImagePreview';
import LockImg from '../../../../assets/lockimg.jpg';
import InfoDialog from '../../../../common/InfoDialog';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { pointsSelector } from '../../../../store/profile/profile.store';
import {
	messagesSelector,
	paidMessage,
	payMessageSelector,
} from '../../../../store/message/message.store';
import Loader from '../../../../common/Loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, makeStyles } from '@material-ui/core';
import PackageBuyMethodScreen from '../../../options/screens/package/PackageBuyMethodScreen';
import Modal from '@material-ui/core/Modal';

const useStyle = makeStyles(theme => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	progress: {
		color: theme.mainBlue,
		position: 'absolute',
		zIndex: 99,
		top: 124,
		left: 82,
	},
	payButton: {
		position: 'absolute',
		width: 155,
		zIndex: 1,
		top: 105,
		left: 30,
		textAlign: 'center',
		backgroundColor: theme.mainBlue,
		color: 'white',
		padding: '0 10px 0 10px',
		borderRadius: 10,
		cursor: 'pointer',
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));
const size = 200;

export default function MessageAttachment({
	conversationId,
	attachmentId,
	payMessage,
	messageId,
	isPaid,
	price,
	payMessageId,
}) {
	const classes = useStyle();
	const dispatch = useDispatch();
	const [showPreview, setShowPreview] = useState(false);
	const [imageLoader, setImageLoader] = useState(false);
	const [buyPointsSuggestionVisible, setBuyPointsSuggestionVisible] = useState(false);
	const { t } = useTranslation();
	const points = useSelector(pointsSelector);
	const imageUrl =
		API_ENDPOINT +
		`conversation/${conversationId}/attachment/${attachmentId}?token=${window.localStorage.getItem(
			'token',
		)}`;
	const isLoadingPayMessage = useSelector(state => state.message.isLoadingPayMessage);
	const [open, setOpen] = useState(false);
	const style = useMemo(() => {
		return {
			width: size,
			height: size,
			// backgroundImage: `url(${isPaid ? imageUrl : Girl}&thumbnail=true)`,
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			cursor: 'pointer',
		};
	}, [imageUrl]);

	const imageHandler = () => {
		isPaid && setShowPreview(true);
		!payMessage && setShowPreview(true);
	};

	const payPhoto = () => {
		if (points.sum > price) {
			dispatch(paidMessage(messageId));
		} else {
			setBuyPointsSuggestionVisible(true);
		}
	};
	const handleBuyPoints = () => {
		handleOpen();
		// history.push('/profile/options/points/payment-types');
		setBuyPointsSuggestionVisible(false);
	};

	const isPayMessage = useMemo(() => payMessage && !isPaid, [payMessage, isPaid]);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	return (
		// style={{width: window.innerWidth < 500 ? 300 : 456}}
		<div style={{ display: 'flex' }}>
			{payMessageId && isLoadingPayMessage ? (
				<CircularProgress className={classes.progress} />
			) : isPayMessage ? (
				<div className={classes.payButton} onClick={() => payPhoto()}>
					{t('Unlock image')}
				</div>
			) : null}
			<InfoDialog
				text={<div style={{ marginTop: 20 }}>{t('no points')}</div>}
				onOk={handleBuyPoints}
				onClose={() => setBuyPointsSuggestionVisible(false)}
				onCancel={() => setBuyPointsSuggestionVisible(false)}
				open={buyPointsSuggestionVisible}
				okText={t('buy points now')}
			></InfoDialog>
			<img
				style={{
					...style,
					// display: imageLoader ? 'flex' : 'none',
					filter: isPayMessage
						? 'blur(0)'
						: payMessage && isPaid && !imageLoader
						? 'blur(0)'
						: 'blur(0)',
				}}
				onClick={imageHandler}
				src={isPayMessage ? LockImg : imageUrl}
				onLoad={() => {
					payMessage && isPaid && setImageLoader(true);
				}}
			/>
			{showPreview ? <ImagePreview src={imageUrl} onClose={() => setShowPreview(false)} /> : null}
			{/*{showPreview ? (*/}
			{/*    <ImagePreview src={imageUrl} onClose={() => setShowPreview(false)}/>*/}
			{/*) : null}*/}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<PackageBuyMethodScreen onClose={() => handleClose()} header={true} />
				</Box>
			</Modal>
		</div>
	);
}
