import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1500,
	},
	progress: {
		color: theme.mainBlue,
	},
}));

export default function Loader({ loading = true }) {
	const classes = useStyle();

	return (
		<Fragment>
			{loading ? (
				<div className={classes.root}>
					<CircularProgress className={classes.progress} />
				</div>
			) : null}
		</Fragment>
	);
}
