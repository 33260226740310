import BaseService from './BaseService';

class PointService extends BaseService {
	constructor() {
		super('/points');
	}

	getPoints() {
		return this.get('/status');
	}

	getRewards() {
		return this.get('/rewards');
	}

	claimReward(id) {
		return this.post('/rewards/claim', {
			id,
		});
	}

	getEarnedRewards({ page }) {
		return this.get(`/entries?page=${page}`);
	}

	sendPoints(conversationId, points) {
		return this.post(`/sendto/${conversationId}`, { points });
	}
}

export default new PointService();
