import { createAction, createReducer } from 'redux-starter-kit';
import CoreService from '../../services/CoreService';
import { path } from 'ramda';

export const fetchSettingsRequest = createAction('FETCH_SETTINGS_REQUEST');
export const fetchGlobalLoader = createAction('FETCH_GLOBAL_LOADER');
export const fetchSettingsFailure = createAction('FETCH_SETTINGS_FAILURE');
export const fetchSettingsSuccess = createAction('FETCH_SETTINGS_SUCCESS');
export const setRouteHistory = createAction('SET_ROUTE_HISTORY');

const initialState = {
	settings: {},
	isSettingsLoading: true,
	globalLoader: false
};

export const fetchSettings = () => async dispatch => {
	const token = localStorage.getItem('token');
	dispatch(fetchSettingsRequest());
	if (token) {
		CoreService.getSettings()
			.then(({ data }) => {
				dispatch(
					fetchSettingsSuccess({
						settings: data,
					}),
				);
			})
			.catch((error) => {
				if(error.response) {
					dispatch(fetchSettingsFailure());
					localStorage.removeItem('token');
					window.location.reload();
				}else {
					dispatch(fetchSettingsFailure());
				}
			});
	} else {
		CoreService.publicSettings()
			.then(({ data }) => {
				dispatch(
					fetchSettingsSuccess({
						settings: data,
					}),
				);
			})
			.catch(() => {
				dispatch(fetchSettingsFailure());
			});
	}
};

const coreReducer = createReducer(initialState, {
	// Fetch Settings
	[fetchSettingsRequest]: state => ({ ...state, isSettingsLoading: true }),
	[fetchGlobalLoader]: state => ({ ...state, globalLoader: false }),
	[fetchSettingsSuccess]: (state, { payload }) => ({
		...state,
		isSettingsLoading: false,
		settings: payload.settings,
		globalLoader: false
	}),
	[fetchSettingsFailure]: state => ({ ...state, isSettingsLoading: false, globalLoader: true }),

	[setRouteHistory]: (state, { payload }) => {
		return {
			...state,
			...payload,
		};
	},
});

export const settingsSelector = path(['core', 'settings']);
export const lastLocationSelector = path(['core', 'lastLocation']);
export const nextLocationSelector = path(['core', 'nextLocation']);
export const countriesSelector = path(['core', 'settings', 'countries']);

export default coreReducer;
