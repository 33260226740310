import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import CommunityUserList from '../components/CommunityUserList';
import HeaderRight from '../../shared/components/HedaerRight';
import { useHistory } from 'react-router-dom';
import theme from '../../../theme';
import { fetchSettings } from '../../../store/core/core.store';
import {useDispatch, useSelector} from 'react-redux';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";
import InputBase from "@material-ui/core/InputBase";
import FormErrorMessage from "../../../common/forms/FormErrorMessage";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {currentUserSelector, updateEmailReset, updateProfileEmail} from "../../../store/profile/profile.store";
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";

// const useStyles = makeStyles(theme => ({
// 	root: {
// 		backgroundColor: theme.mainBackgroundScreens,
// 		height: '101%',
// 	},
// 	logo: {
// 		height: 40,
// 	},
// }));
const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.mainBackgroundScreens,
		height: '101%',
	},
	icons: {
		fontSize: 16,
		marginLeft: 10,
		color: theme.mainBlue,
	},
	searchRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		paddingRight: '4%',
		flex: 1,
		cursor: 'pointer',
		paddingTop: '20px',

	},
	indicator: {
		backgroundColor: 'transparent',
		justifyContent: 'center',
	},
	tabStyle: {
		backgroundColor: theme.mainBlue,
		borderRadius: 9,
		margin: '28px 50px 0',
		minHeight: 'auto',
		color: theme.white,
		padding: '4px 60px',
		[theme.breakpoints.down('md')]: {
			margin: '15px 6px 0',
			padding: '4px 15px',
			fontSize: 13,
		},
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	modalItem: {
		width: '35%',
		backgroundColor: 'white',
		alignSelf: 'center',
		padding: '15px 20px 15px 20px',
		color: '#888',
		[theme.breakpoints.down('md')]: {
			width: '90%',
		},
	},
	modalEmail: {
		position: 'relative',
		borderRadius: 4,
		alignItems: 'center',
		padding: '80px 20px',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
		width: '440px',
		[theme.breakpoints.down('xs')]: {
			width: '90%',
		},
	},
	gridItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	gridItemForMobile: {

	},
	gridItemGender: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	searchInput: {
		width: 300,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 35
		},
	},
	paper: {
		boxShadow: 'none',
		margin: 0,
		color: '#586069',
	},
	noOptions: {
		boxShadow: 'none',
		margin: 0,
		color: theme.mainBlue,
		backgroundColor: theme.textLight,
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		padding: 8,
		paddingLeft: 9,
		'&[aria-selected="true"]': {
			backgroundColor: theme.headerBackground,
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.headerBackground,
		},
	},
	popperDisablePortal: {
		position: 'relative',
	},
	rootButton: {
		marginLeft: 20,
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 10,
		paddingBottom: 5,
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	filterCountry: {
		marginLeft: '40px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0',
			marginTop: 10,
			marginBottom: 10
		},
	}
}));

export default function CommunityScreen() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [newEmail, setNewEmail] = useState(false);
	const profile = useSelector(currentUserSelector);
	const emailChanged = useSelector(state => state.profile.emailChanged);

	const validationSchema = Yup.object().shape({
		mail: Yup.string().label('Email').required(t('please enter email')),
	});
	useEffect(() => {
		dispatch(fetchSettings());
		// localStorage.getItem('darkMode') ? localStorage.setItem('darkMode', '0') : localStorage.setItem('darkMode', '1')
	}, []);

	useEffect(() => {
		emailChanged && setNewEmail(false);
	}, [emailChanged]);

	const {handleChange, values, errors, touched, handleSubmit, setErrors} = useFormik({
		initialValues: {
			mail: '',
		},
		validationSchema,
		onSubmit: (values, formikHelpers) => {
			dispatch(updateProfileEmail(values.mail));
		},
	});

	const emailHandel = e => {
		dispatch(updateEmailReset());
		handleChange(e);
	};

	return (
		<div className={classes.root}>
			<HeaderAppBar
				leftIcon={
					<img
						src={theme.smallLogo}
						alt={'logo'}
						className={classes.logo}
						onClick={() => history.push('/feed')}
						color={'action'}
					/>
				}
				rightIcon={<HeaderRight />}
				newPost
				isLogo
				bar
			/>
			<CommunityUserList />
			<Modal
				open={!profile?.registered}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modal}
			>
				<>
					{!newEmail && (
						<div className={classes.modalItem}>
							<>
								<div
									style={{
										color: '#20baef',
										fontSize: 20,
									}}
								>
									{t('email not confirmed')}
								</div>
								<br/>
								<div>
									<span>{t('sent you email to')} </span>
									<span
										style={{
											color: '#e22b4c',
											fontWeight: 'bold',
										}}
									>
										E-MAIL
									</span>
									<br/>
									<span>
										{' '}
										{t('sent email')} {t('click to confirm')}
									</span>
								</div>
								<div
									style={{
										fontWeight: 'bold',
										fontSize: 18,
									}}
								>
									<span>{t('confirm for freecoins')}</span>
									<span style={{color: '#20baef'}}> 20</span>
									<span style={{color: '#20baef'}}> {t('confirm for freecoins2')}</span>
								</div>
								<div>
									<div>{t('problem with email')}</div>
									<div
										style={{
											color: '#e22b4c',
											fontWeight: 'bold',
											textDecoration: 'underline',
											cursor: 'pointer',
										}}
										onClick={() => setNewEmail(true)}
									>
										{t('change email and resend')}
									</div>
								</div>
								<br/>
								<div>
									<div style={{color: '#20baef'}}>{t('important')}</div>
									<ol>
										<li>{t('takes time')}</li>
										<li>{t('check also spam')}</li>
										<li>{t('add our email to whitelist')}</li>
									</ol>
								</div>
							</>
						</div>
					)}
					{newEmail && (
						<div className={classes.modalEmail}>
							<div style={{fontSize: 22, color: '#000'}}>{t('new email')}</div>

							<Paper
								elevation={0}
								style={{
									marginTop: theme.spacing(1),
									marginBottom: theme.spacing(1),
									backgroundColor: 'white',
									color: 'white',
									border: '2px solid silver',
									borderRadius: 10,
									width: '100%',
									display: 'flex',
								}}
							>
								<IconButton className={classes.iconButton} aria-label="menu">
									<MailIcon/>
								</IconButton>
								<InputBase
									className={classes.inputWithIcon}
									style={{
										width: '80%',
									}}
									placeholder={t('your new email')}
									type={'mail'}
									name={'mail'}
									value={values.mail}
									onChange={e => emailHandel(e)}
									inputProps={{'aria-label': 'mail'}}
									error={!!errors.mail}
								/>
							</Paper>
							<FormErrorMessage name={'mail'} errors={errors} touched={touched}/>
							{/*{emailChanged &&*/}
							{/*<div style={{*/}
							{/*    color: 'green',*/}
							{/*    fontSize: 20*/}
							{/*}}>*/}
							{/*    {t('email change')}*/}
							{/*</div>*/}
							{/*}*/}
							<form className={classes.form} onSubmit={handleSubmit} noValidate>
								<Button
									type="submit"
									className={classes.nextButton}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: 44,
										padding: '10px 20px 10px 20px',
										color: '#FFF',
										backgroundColor: '#0f97f5',
										borderRadius: 10,
										marginTop: 15,
										fontSize: 18,
										whiteSpace: 'nowrap',
									}}
								>
									{t('send confirmation link')}
								</Button>
							</form>
							<FontAwesomeIcon
								onClick={() => setNewEmail(false)}
								icon={faTimes}
								style={{
									fontSize: 25,
									position: 'absolute',
									right: 20,
									top: 20,
									cursor: 'pointer',
								}}
								color={'black'}
							/>
						</div>
					)}
				</>
			</Modal>
		</div>
	);
}
