import React, { Fragment, useEffect, useState } from 'react';
import SectionHeading from '../../../../common/SectionHeading';
import Cloud from '../../../../common/Cloud';
import ListItem from '../../../../common/ListItem';
import {
	Box,
	Checkbox,
	List,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Typography,
} from '@material-ui/core';
import PaymentListItemIcon from './PaymentListItemIcon';
import SubmitButton from '../SubmitButton';
import BottomToast from '../../../../common/BottomToast';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { ConfigCountry } from '../../../../helpers/configCountry';
import { useSelector } from 'react-redux';
import { settingsSelector } from '../../../../store/core/core.store';

const useStyle = makeStyles(theme => ({
	primary: {
		color: theme.palette.primary.user_red,
		fontSize: 14,
		fontWeight: 'bold',
	},
	secondary: {
		fontSize: 13,
		fontWeight: 'bold',
		color: theme.textLight,
	},
	secondIcon: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	miniText: {
		fontSize: 12,
		fontWeight: 'bold',
		color: theme.textDark,
	},
	privacyText: {
		color: theme.textLight,
	},
}));

export default function PaymentForm({ paymentType, plan, onSubmit }) {
	const [isNoContentToast, setIsNoContentToast] = useState(false);
	const [agreement, setAgreement] = useState(false);
	useEffect(() => {
		return () => {
			setAgreement(false);
		};
	}, []);

	const classes = useStyle();

	const { t } = useTranslation();
	const settings = useSelector(settingsSelector);

	const handleSubmit = () => {
		if (!agreement) {
			setIsNoContentToast(true);
			return;
		}

		onSubmit();
	};

	return (
		<Fragment>
			<SectionHeading>{t('confirm transaction')}</SectionHeading>
			<List component={Cloud} style={{ paddingLeft: 0, paddingRight: 0 }}>
				<ListItem>
					<ListItemIcon>
						<PaymentListItemIcon src={plan.image} />
					</ListItemIcon>
					<ListItemText
						primary={plan.primaryText}
						secondary={plan.secondaryText}
						classes={{ primary: classes.primary, secondary: classes.secondary }}
					/>
					<div className={classes.secondIcon}>
						<PaymentListItemIcon src={paymentType.image} size={40} />
						<Typography className={classes.miniText}>{paymentType.primaryText}</Typography>
					</div>
				</ListItem>
			</List>

			<Box py={1} display={'flex'} alignItems={'flex-start'}>
				<Checkbox
					name={'agreement'}
					checked={agreement}
					onChange={() => setAgreement(currentAgreement => !currentAgreement)}
					value="checked"
					color="primary"
					style={{ color: theme.mainBlue }}
					size={'medium'}
				/>
				<div className={classes.privacyText}>
					{t('i accept the')}
					<a
						href={settings?.url?.terms}
						target={'_blank'}
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{' '}
						{t('conditions')}{' '}
					</a>
					{t('and')}
					<a
						href={settings.url?.withdrawal}
						target={'_blank'}
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{' '}
						{t('right of withdrawal')}{' '}
					</a>
					{t('right of withdrawal after')}.
				</div>
			</Box>
			<SubmitButton icon={' '} onClick={handleSubmit} iconRight={<ArrowForwardIosIcon />}>
				{t('order now')}
			</SubmitButton>

			<BottomToast open={isNoContentToast} onClose={() => setIsNoContentToast(false)}>
				{t('please accept conditions')}!
			</BottomToast>
		</Fragment>
	);
}
