import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/AccountCircle';
import PassIcon from '@material-ui/icons/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../store/profile/profile.store';
import { useFormik } from 'formik';
import useStyles from './style';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';
import * as Yup from 'yup';
import FormErrorMessage from '../../../common/forms/FormErrorMessage';
import IcBgApp from '../../../assets/bg_app.png';
import IcGirl from '../../../assets/girl.png';
import useHigherWindowHeight from '../../../hooks/useHigherWindowHeight';
import { isMobileDevice } from '../../../helpers';
import { useTheme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';

export default function UserAuthScreen() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		mail: Yup.string().label('E-Mail').email(t('wrong email')).required(t('please enter email')),
		pass: Yup.string().label(t('password')).required('please enter password'),
	});

	const history = useHistory();
	const logInFail = useSelector(state => state.profile.logInFail);

	const {
		handleChange,
		values,
		handleSubmit = (values, { setStatus, setErrors }) => {
			return this.props
				.onSubmit(values)
				.then(() => {
					setStatus('User was updated successfully.');
				})
				.catch(err => {
					setErrors(false);
				});
		},
		errors,
		touched,
	} = useFormik({
		validationSchema,
		initialValues: {
			mail: '',
			pass: '',
		},
		onSubmit: ({ mail, pass }) => dispatch(login(mail, pass)),
	});

	const user = useSelector(state => state.profile.user);
	const isLogging = useSelector(state => state.profile.isLogging);

	useEffect(() => {
		if (!isLogging && user) {
			history.replace('/feed');
		}
	}, [isLogging, user, history]);

	const windowHeight = useHigherWindowHeight();

	return (
		<div
			style={{
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				backgroundImage: `url(${IcBgApp})`,
			}}
		>
			<Container component="main" maxWidth="xs">
				<Box
					display={'flex'}
					alignItems={'center'}
					style={{ color: 'white', position: 'fixed', left: 15, top: 25, cursor: 'pointer' }}
					onClick={() => history.replace('/auth/guest')}
				>
					<ArrowBackIcon style={{ marginRight: 10 }} />
					<Typography>{t('register')}</Typography>
				</Box>
				<div className={classes.paper} style={{ height: windowHeight }}>
					<img src={IcGirl} alt="girl" style={{ marginBottom: '50px' }} />
					<form className={classes.form} onSubmit={handleSubmit}>
						<Paper className={classes.root} elevation={0}>
							<IconButton className={classes.iconButton} aria-label="menu">
								<MenuIcon />
							</IconButton>
							<InputBase
								className={classes.inputWithIcon}
								name={'mail'}
								type={'mail'}
								value={values.mail}
								onChange={handleChange}
								placeholder={t('your email and name')}
								inputProps={{ 'aria-label': t('nickname') }}
							/>
						</Paper>
						<FormErrorMessage name={'mail'} errors={errors} touched={touched} />

						<Paper className={classes.root} elevation={0}>
							<IconButton className={classes.iconButton} aria-label="menu">
								<PassIcon />
							</IconButton>
							<InputBase
								className={classes.inputWithIcon}
								name={'pass'}
								type={'password'}
								value={values.pass}
								onChange={handleChange}
								placeholder={t('your password')}
								inputProps={{ 'aria-label': t('nickname') }}
							/>
						</Paper>
						<FormErrorMessage name={'pass'} errors={errors} touched={touched} />

						{Object.values(errors).length > 0 ? null : logInFail ? (
							<FormHelperText error={true}>{t('wrong login')}</FormHelperText>
						) : null}

						<Box mt={1} mb={3} display={'flex'} justifyContent={'flex-end'}>
							<Link style={{ color: 'white' }} to="/auth/forgot-password">
								{t('forget password')}
							</Link>
						</Box>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							login
						</Button>
						<div style={{ marginTop: 20, textAlign: 'center' }}>
							<Link
								style={{
									color: theme.palette.primary.points_green,
									fontWeight: '600',
								}}
								to="/auth/guest"
								variant="body2"
							>
								{t('new user')}
							</Link>
						</div>
						{!isMobileDevice() ? <div style={{ height: 50 }} /> : null}
					</form>
				</div>
			</Container>
		</div>
	);
}
