import BaseService from './BaseService';

class ReportService extends BaseService {
	constructor() {
		super('/report');
	}

	reportEntry({ entryId }) {
		return this.post(`/${entryId}`);
	}

	reportComment({ commentId }) {
		return this.post(`/comment/${commentId}`);
	}

	reportReply({ replyId }) {
		return this.post(`/reply/${replyId}`);
	}
}

export default new ReportService();
