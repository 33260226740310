import BaseService from './BaseService';
import {ENTRY_TYPE, USER_ENTRY_TYPE} from '../constants';
import {uniq} from 'ramda';

class EntryService extends BaseService {
    constructor() {
        super('');
    }

    getCategories() {
        return this.get('/categories');
    }

    getBestsWeek(page, filterText) {
        return this.get('/beste/woche' + filterText, {page});
    }

    getBestsMonth(page, filterText) {
        return this.get('/beste/monat' + filterText, {page});
    }

    getBestsAll(page, filterText) {
        return this.get('/beste' + filterText, {page});
    }

    getNewest(page, filterText) {
        return this.get('/neueste' + filterText, {page});
    }

    getConfessions(page, filterText) {
        return this.get('/entries/categories/2?', {page});
    }

    getEntriesByCategory(categoryId, page) {
        return this.get(`/category/${categoryId}`, {page});
    }

    searchEntries({type, page, categoryId, filterText}) {
        switch (type) {
            case ENTRY_TYPE.CONFESSIONS:
                return this.getConfessions(page, filterText);
            case ENTRY_TYPE.BEST:
                return this.getBestsAll(page, filterText);
            case ENTRY_TYPE.BEST_WEEK:
                return this.getBestsWeek(page, filterText);
            case ENTRY_TYPE.BEST_MONTH:
                return this.getBestsMonth(page, filterText);
            case ENTRY_TYPE.NEWEST:
                return this.getNewest(page, filterText);
            case ENTRY_TYPE.BY_CATEGORY:
                return this.getEntriesByCategory(categoryId, page);
            default:
        }
    }

    getUserEntries(uid, page) {
        return this.get(`/user/${uid}/entries`, {page});
    }

    getUserCommented(uid, page) {
        return this.get(`/user/${uid}/commented`, {page});
    }

    getUserLikes(uid, page) {
        return this.get(`/user/${uid}/likes`, {page});
    }

    searchUserEntries({type, page, uid}) {
        switch (type) {
            case USER_ENTRY_TYPE.ENTRIES:
                return this.getUserEntries(uid, page);
            case USER_ENTRY_TYPE.COMMENTED:
                return this.getUserCommented(uid, page);
            case USER_ENTRY_TYPE.LIKES:
                return this.getUserLikes(uid, page);
        }
    }

    deletePostEntry(entryId) {
        return this.post(`/entry/${entryId}/delete`);
    }

    vote(entryId, rate) {
        return this.post(`/upvote/${entryId}/${rate}`);
    }

    getComments({entryId, page}) {
        return this.get(`/entry/${entryId}/comments`, {page});
    }

    postComment({entryId, comment}) {
        return this.post(`/entry/${entryId}/comment`, {comment});
    }

    replyToComment({commentId, comment}) {
        return this.post(`/comment/${commentId}/reply`, {comment});
    }

    async blockEntry(entryId) {
        const blockedIds = JSON.parse(localStorage.getItem('blockedEntryIds')) || [];
        blockedIds.push(entryId);
        localStorage.setItem('blockedEntryIds', JSON.stringify(uniq(blockedIds)));
        return true;
    }

    async getBlockedEntryIds() {
        return JSON.parse(localStorage.getItem('blockedEntryIds')) || [];
    }

    getEntry(entryId) {
        return this.get(`/entry/${entryId}`);
    }
}

export default new EntryService();
