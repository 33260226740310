import React, {useEffect, useState} from 'react';
import SendIcon from '@material-ui/icons/Send';
import {makeStyles} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import {OutlinedInput} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from "react-redux";
import {settingsSelector} from "../../../store/core/core.store";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        bottom: 0,
        width: '100%',
        padding: 8,
        backgroundColor: theme.mainBackgroundScreens,
    },
    inputRoot: {
        display: 'flex',
        flex: 1,
        color: theme.textDark,
    },
    fabRoot: {
        margin: 8,
        marginRight: 0,
        backgroundColor: theme.mainBlue,
        color: 'white',
        width: 'max-content',
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10
    },
    price: {
        marginLeft: 5,
        marginRight: 5
    }
}));

export default function Sender({onSubmit: handleSubmit, placeholder = '', endAdornment, coins = true}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const settings = useSelector(settingsSelector);
    const [messagePrice, setMessagePrice] = useState(10)
    const [messageLength, setMessageLength] = useState(0)

    useEffect(() => {
        settings?.coins_per_message && setMessagePrice(settings?.coins_per_message)
    }, [settings])

    useEffect(() => {
        setMessagePrice((prevState) => (Math.floor(messageLength / 300 + 1) * 10))
    }, [messageLength])

    const handleMessageSubmit = event => {
        event.preventDefault();
        const userInput = event.target.message.value;
        if (userInput.trim()) {
            handleSubmit(userInput);
            setMessageLength(0)
        }
        event.target.message.value = '';
    };

    return (
        <form className={classes.root} onSubmit={handleMessageSubmit}>
            <OutlinedInput
                type="text"
                className={classes.inputRoot}
                name="message"
                placeholder={placeholder}
                autoFocus={false}
                autoComplete="off"
                labelWidth={0}
                endAdornment={messageLength ? null : endAdornment}
                multiline={true}
                maxRows={6}
                onKeyUp={(e) => setMessageLength(e.target.value.length)}
            />
            <Fab
                className={classes.fabRoot}
                aria-label="add"
                type={'submit'}
                size={'small'}
                title={t('send')}
                role="button"
            >
                <SendIcon/>
                {coins &&
                <>
                    {messagePrice && <div className={classes.price}>
                        {messagePrice}
                    </div>
                    }
                    <FontAwesomeIcon
                        icon={faCoins}
                        className={classes.icons}
                        style={{}}
                    />
                </>
                }
            </Fab>
        </form>
    );
}
