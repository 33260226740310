import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, Redirect, useLocation } from 'react-router-dom';
import GuestAuthScreen from './modules/auth/screens/GuestAuthScreen';
import GuestAuthByStepScreen from './modules/auth/screens/UserAuthByStepScreen';
import AuthByStepTwoScreen from './modules/auth/screens/AuthByStepTwoScreen';
import UserAuthScreen from './modules/auth/screens/UserAuthScreen';
import ForgotPasswordScreen from './modules/auth/screens/ForgotPasswordScreen';
import RemoteLoginScreen from './modules/auth/screens/RemoteLoginScreen';
import FeedScreen from './modules/feed/screens/FeedScreen';
import ConfessionsScreen from './modules/feed/screens/ConfessionsScreen';
import CreatePostScreen from './modules/feed/screens/CreatePostScreen';
import CreatePostSuccessScreen from './modules/feed/screens/CreatePostSuccessScreen';
import ProfileScreen from './modules/profile/screens/ProfileScreen';
import CommunityScreen from './modules/community/screens/CommunityScreen';
import UserScreen from './modules/community/screens/UserScreen';
import OptionsScreen from './modules/options/screens/OptionsScreen';
import MembershipScreen from './modules/options/screens/membership/MembershipScreen';
import NotificationsScreen from './modules/notifications/screens/NotificationsScreen';
import PointsScreen from './modules/options/screens/PointsScreen';
import PrivacyScreen from './modules/options/screens/PrivacyScreen';
import FAQScreen from './modules/options/screens/FAQScreen';
import FAQDetailsScreen from './modules/options/screens/FAQDetailsScreen';
import WebViewScreen from './modules/shared/screens/WebViewScreen';
import LicenseScreen from './modules/options/screens/LicenseScreen';
import NotificationScreen from './modules/notifications/screens/NotificationScreen';
import RedeemPointsScreen from './modules/options/screens/RedeemPointsScreen';
import ManageProfileScreen from './modules/profile/screens/ManageProfileScreen';
import HowItWorksScreen from './modules/options/screens/HowItWorksScreen';
import ManageProfileSuccessScreen from './modules/profile/screens/ManageProfileSuccessScreen';
import ContactScreen from './modules/options/screens/ContactScreen';
import ContactSuccessScreen from './modules/options/screens/ContactSuccessScreen';
import ChatScreen from './modules/chat/screens/ChatScreen';
import ConversationScreen from './modules/chat/screens/ConversationScreen';
import PartnerLinkScreen from './modules/options/screens/PartnerLinkScreen';
import FeedCategoryEntriesScreen from './modules/feed/screens/FeedCategoryEntriesScreen';
import MembershipBuyMethodScreen from './modules/options/screens/membership/MembershipBuyMethodScreen';
import MembershipBuyOptionScreen from './modules/options/screens/membership/MembershipBuyOptionScreen';
import MembershipBuyFormScreen from './modules/options/screens/membership/MembershipBuyFormScreen';
import PackageBuyMethodScreen from './modules/options/screens/package/PackageBuyMethodScreen';
import PackageBuyOptionScreen from './modules/options/screens/package/PackageBuyOptionScreen';
import PackageBuyFormScreen from './modules/options/screens/package/PackageBuyFormScreen';
import useSocket from './hooks/useSocket';
import EntryCommentsScreen from './modules/feed/screens/EntryCommentsScreen';
import ReportEntryCommentScreen from './modules/feed/screens/ReportEntryCommentScreen';
import ReportEntryScreen from './modules/feed/screens/ReportEntryScreen';
import ReportEntrySuccessScreen from './modules/feed/screens/ReportEntrySuccessScreen';
import ReportCommentSuccessScreen from './modules/feed/screens/ReportCommentSuccessScreen';
import ChangePasswordScreen from './modules/profile/screens/ChangePasswordScreen';
import ForgotPasswordSuccessScreen from './modules/auth/screens/ForgotPasswordSuccessScreen';
import EntryScreen from './modules/feed/screens/EntryScreen';
import ReportUserScreen from './modules/community/screens/ReportUserScreen';
import ReportUserSuccessScreen from './modules/community/screens/ReportUserSuccessScreen';
import GuestAuthSuccessScreen from './modules/auth/screens/GuestAuthSuccessScreen';
import { AnimatorScreen } from './modules/animator/screens/AnimatorScreen';
import { AnimatorRequestScreen } from './modules/animator/screens/AnimatorRequestScreen';
import ProfileEditScreen from './modules/profileEdit/srceens/ProfileEditScreen';
import PrivateNavigation from './modules/shared/components/PrivateNavigation';

export function PublicRoutes() {
	const routeMatch = useRouteMatch();
	const root = routeMatch.path;
	return (
		<Switch>
			<Route path={`${root}/guest/success`} component={GuestAuthSuccessScreen} />
			<Route path={`${root}/guest&step=1`} component={GuestAuthByStepScreen} />
			<Route path={`${root}/guest&login=1`} component={GuestAuthByStepScreen} />
			<Route path={`${root}/guest`} component={GuestAuthScreen} />
			<Route path={`${root}/guest&step=2`} component={AuthByStepTwoScreen} />
			<Route path={`${root}/guest&step=2&login=1`} component={AuthByStepTwoScreen} />
			<Route path={`${root}/login`} component={UserAuthScreen} />
			<Route path={`${root}/privacy`} component={PrivacyScreen} />
			<Route path={`${root}/forgot-password/success`} component={ForgotPasswordSuccessScreen} />
			<Route path={`${root}/forgot-password`} component={ForgotPasswordScreen} />
			<Route path={`${root}/remotelogin`} component={RemoteLoginScreen} />
		</Switch>
	);
}

export function PrivateRoutes() {
	useSocket();

	const location = useLocation();

	useEffect(() => {
		!location.pathname.includes('community') &&
			window.localStorage.getItem('lastFilter') &&
			window.localStorage.removeItem('lastFilter');
	}, [location]);

	return (
		<>
			<Switch>
				{/* Feed */}
				<Route
					path={`/feed/:entryId/reply/:replyId/report/success`}
					component={ReportCommentSuccessScreen}
				/>
				<Route path={`/feed/:entryId/reply/:replyId/report`} component={ReportEntryCommentScreen} />
				<Route
					path={`/feed/:entryId/comments/:commentId/report/success`}
					component={ReportCommentSuccessScreen}
				/>
				<Route
					path={`/feed/:entryId/comments/:commentId/report`}
					component={ReportEntryCommentScreen}
				/>
				<Route path={`/feed/:entryId/comments`} component={EntryCommentsScreen} />
				<Route path={`/feed/:entryId/report/success`} component={ReportEntrySuccessScreen} />
				<Route path={`/feed/:entryId/report`} component={ReportEntryScreen} />
				<Route path={`/feed/entries/category/:categoryId`} component={FeedCategoryEntriesScreen} />
				<Route path={`/feed/create/success`} component={CreatePostSuccessScreen} />
				<Route path={`/feed/create`} component={CreatePostScreen} />
				<Route path={`/feed/:entryId`} component={EntryScreen} />
				{/*<Route path={`/feed`} component={FeedScreen} />*/}
				<Route path={`/confessions`} component={ConfessionsScreen} />

				{/* Community */}
				<Route
					path={`/community/:userId/:nickname/report/success`}
					component={ReportUserSuccessScreen}
				/>
				<Route path={`/community/:userId/:nickname/report`} component={ReportUserScreen} />
				<Route path={`/community/:userId`} component={UserScreen} />
				<Route path={`/community`} component={CommunityScreen} />

				{/* Notifications */}
				<Route path={`/notifications/:notificationId`} component={NotificationScreen} />
				<Route path={`/notifications`} component={NotificationsScreen} />

				{/* Chat Conversations */}
				<Route path={`/conversations/:conversationId`} component={ConversationScreen} />
				<Route path={`/conversations`} component={ChatScreen} />

				{/* Profile Options */}
				<Route path={'/profile/options/license'} component={LicenseScreen} />
				<Route path={'/profile/options/faq/:faqId'} component={FAQDetailsScreen} />
				<Route path={'/profile/options/faq'} component={FAQScreen} />
				<Route path={'/profile/options/privacy'} component={PrivacyScreen} />
				<Route
					path={'/profile/options/points/payment-types/:paymentTypeName/options/:packageId/form'}
					component={PackageBuyFormScreen}
				/>
				<Route
					path={'/profile/options/points/payment-types/:paymentTypeName/options'}
					component={PackageBuyOptionScreen}
				/>
				<Route path={'/profile/options/points/payment-types'} component={PackageBuyMethodScreen} />
				<Route path={'/profile/options/points/redeem'} component={RedeemPointsScreen} />
				<Route path={'/profile/options/points'} component={PointsScreen} />
				<Route
					path={
						'/profile/options/membership/payment-types/:paymentTypeName/options/:membershipId/form'
					}
					component={MembershipBuyFormScreen}
				/>
				<Route
					path={'/profile/options/membership/payment-types/:paymentTypeName/options'}
					component={MembershipBuyOptionScreen}
				/>
				<Route
					path={'/profile/options/membership/payment-types'}
					component={MembershipBuyMethodScreen}
				/>
				<Route path={'/profile/options/membership'} component={MembershipScreen} />
				<Route path={'/profile/options/how-it-works'} component={HowItWorksScreen} />
				<Route path={'/profile/options/contact/success'} component={ContactSuccessScreen} />
				<Route path={'/profile/options/contact'} component={ContactScreen} />
				<Route path={'/profile/options/partner-link'} component={PartnerLinkScreen} />
				<Route path={'/profile/options'} component={OptionsScreen} />
				<Route path={'/profile-edit'} component={ProfileEditScreen} />

				{/* Profile */}
				{/*<Route path={'/profile/manage/success'} component={ManageProfileSuccessScreen} />*/}
				{/*<Route path={'/profile/manage'} component={ManageProfileScreen} />*/}
				<Route path={'/profile/change-password'} component={ChangePasswordScreen} />
				<Route path={'/profile'} component={ProfileScreen} />

				{/* Static Pages */}
				<Route path={'/static-page/:pageName/:title'} component={WebViewScreen} />

				{/* Animator */}
				<Route path={'/animator/request'} component={AnimatorRequestScreen} />
				<Route path={'/animator'} component={AnimatorScreen} />
				<Redirect to={'/community'} />
			</Switch>
			<PrivateNavigation />
		</>
	);
}
