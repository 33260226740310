import { createAction, createReducer } from 'redux-starter-kit';
import ContentService from '../../services/ContentService';
import { path, pipe } from 'ramda';
import { findById } from '../../helpers/fp';

export const fetchFAQRequest = createAction('FETCH_FAQ_REQUEST');
export const fetchFAQFailure = createAction('FETCH_FAQ_FAILURE');
export const fetchFAQSuccess = createAction('FETCH_FAQ_SUCCESS');

const initialState = {
	faq: [],
};

export const fetchFAQ = () => async (dispatch, getState) => {
	const { content } = getState();
	if (content.faq.length) return;

	dispatch(fetchFAQRequest());
	ContentService.getFAQList()
		.then(({ data }) => {
			dispatch(
				fetchFAQSuccess({
					faq: data.entries,
				}),
			);
		})
		.catch(() => {
			dispatch(fetchFAQFailure());
		});
};

const contentReducer = createReducer(initialState, {
	// Fetch FAq
	[fetchFAQSuccess]: (state, action) => ({ faq: action.payload.faq }),
});

const stateProp = key => path(['content', key]);

export const faqListSelector = stateProp('faq');
export const faqByIdSelector = id => pipe(faqListSelector, findById(id));

export default contentReducer;
