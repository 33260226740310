import React, { useState } from 'react';
import MediaForm from '../../shared/components/MediaForm';
import BottomToast from '../../../common/BottomToast';
import ContentService from '../../../services/ContentService';
import Loader from '../../../common/Loader';
import { Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ContactSuccessScreen from './ContactSuccessScreen';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function ContactScreen() {
	const classes = useStyles();

	const [isSending, setIsSending] = useState(false);
	const [message, setMessage] = useState('');
	const [mediaType, setMediaType] = useState(null);
	const [mediaFile, setMediaFile] = useState(null);
	const [isNoContentToast, setIsNoContentToast] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const { t } = useTranslation();

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => {
		setMessage('');
		setOpenModal(false);
	};

	function handleFileChange(type, event) {
		setMediaType(type);
		if (event.target.files.length > 0) {
			setMediaFile(event.target.files[0]);
		} else {
			setMediaFile(null);
		}
	}

	const handleSubmit = () => {
		if (!message) {
			setIsNoContentToast(true);
			return;
		}

		const data = {
			text: message,
		};
		if (mediaFile) {
			data.image = mediaFile;
		}
		setIsSending(true);
		ContentService.sendContactMail(data)
			.then(({ data }) => {
				if (data.success) {
					handleOpen();
				}
			})
			.finally(() => setIsSending(false));
	};

	const handleTextChange = event => {
		event.persist();
		setMessage(event.target.value);
	};

	return (
		<>
			{isSending ? <Loader /> : null}
			<MediaForm
				onTextChange={handleTextChange}
				onFileChange={handleFileChange}
				onSubmit={handleSubmit}
				mediaType={mediaType}
				flow={'contact'}
				imageCrop
				message={message}
			/>
			<BottomToast open={isNoContentToast} onClose={() => setIsNoContentToast(false)}>
				{t('describe problem')}
			</BottomToast>
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ContactSuccessScreen onClose={() => handleClose()} />
				</Box>
			</Modal>
		</>
	);
}
