import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState, selector } from 'recoil';
import { notificationsState } from '../state';

const getUniqId = () =>
	Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);

const notificationsReversedState = selector({
	key: 'notificationsReversedState',
	get: ({ get }) =>
		get(notificationsState)
			?.map(it => it)
			.reverse(),
});

export default function useStackNotification() {
	const notifications = useRecoilValue(notificationsReversedState);

	const setNotifications = useSetRecoilState(notificationsState);

	const removeNotification = useCallback(
		id => {
			setNotifications(prevNotifications =>
				prevNotifications.filter(notification => notification.id !== id),
			);
		},
		[setNotifications],
	);

	const pushNotification = useCallback(
		({ title, body, icon, action }) => {
			const id = getUniqId();
			setNotifications(prevNotifications => [
				...(prevNotifications ?? []),
				{
					id,
					title,
					body,
					icon,
					action,
				},
			]);
			setTimeout(() => removeNotification(id), 5000);
		},
		[setNotifications],
	);

	return {
		notifications,
		pushNotification,
		removeNotification,
	};
}
