import React from 'react';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import Cloud from '../../../common/Cloud';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import RegisterListItem from '../../shared/components/RegisterListItem';
import DangerousHtml from '../../../common/DangerousHtml';

const useStyle = makeStyles(theme => ({
	title: {
		fontSize: 15,
		fontWeight: '600',
		marginBottom: 20,
	},
	text: {
		fontSize: 14,
	},
}));

const text = `
Deinbeichtstuhl ist nicht nur eine einfache Beichte-Seite, auch du als User kommst auf deine Kosten, weil wir deinen Spaß mit tollen Preisen und Gutscheinen honorieren.
Es ist egal was du in der App tust, ob du was beichtest, Kommentare schreibst oder Beichten bewertest, mit fast jeder deiner Aktivität kannst du Punkte sammeln und diese später in Gutscheine einlösen.
Die Punkte sind also in Deinbeichtstuhl-App so was wie dein virtuelles Beichte-Kapital.\\nDu bekommst auch einen eigenen Partner-Link, womit du die Beichte-App auf allen möglichen sozialen Netzwerken oder Seiten teilen kannst. Für jede Installation, die über deinen Link ausgeführt wird,
verdienst du Punkte, auch in der Teilen-Funktion wird deine ID nach der Registrierung miteinbezogen, so dass du jede Beichte teilen und dadurch ebenfalls Punkte verdienen kannst. \\n\\nBitte beachte nur, dass das System etwas Zeit für die Punktevergabe braucht,
so dass dir die Punkte erst nach ca. 1-24 Stunden gutgeschrieben werden können.
Um Punkte sammeln zu können muss du dich mit Email und Passwort anmelden, damit du auf unserem Server verifiziert wirst und unser System deine Punkte speichern und verwalten kann. Selbstverständlich bleibst du aber weiterhin anonym für alle Nutzer und kein Anderer wird deine Email sehen.
Wenn du in Deinbeichtstuhl-App nicht per Email registriert bist, verlierst du einfach nur Punkte und Punkte sind hier ein reines Kapital!\\n\\nFühre also die zwei kleinen Schritte durch, indem du dich mit deiner Email und einem Passwort registrierst, es lohnt sich bestimmt!
`;

export default function HowItWorksScreen() {
	const classes = useStyle();

	return (
		<div>
			<HeaderAppBar title={'Optionen'} leftIcon={<BackNavigationIcon />} rightIcon={null} />

			<Box p={2}>
				<Cloud>
					<Box p={1}>
						<Typography className={classes.title}>
							Was sind Punkte und wie funktioniert das?
						</Typography>

						<Typography className={classes.text} component={'div'}>
							<DangerousHtml html={text} />
						</Typography>
						<Box pt={1}>
							<Divider />
							<RegisterListItem />
						</Box>
					</Box>
				</Cloud>
			</Box>
		</div>
	);
}
