import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	entryCommentsPageSelector,
	entryCommentsSelector,
	fetchEntryComments,
	hasMoreEntryCommentsSelector,
	isEntryCommentsLoadingSelector,
	resetEntryComments,
} from '../../../store/entry-comments/entry-comments.store';

export default function useEntryComments({ entryId }) {
	const dispatch = useDispatch();

	const comments = useSelector(entryCommentsSelector);
	const isLoading = useSelector(isEntryCommentsLoadingSelector);
	const page = useSelector(entryCommentsPageSelector);
	const hasMore = useSelector(hasMoreEntryCommentsSelector);

	const loadNext = useCallback(() => {
		dispatch(fetchEntryComments({ page: page + 1, entryId }));
	}, [dispatch, page, entryId]);

	useEffect(() => {
		dispatch(fetchEntryComments({ page: 1, entryId }));

		return () => {
			dispatch(resetEntryComments());
		};
	}, [dispatch, entryId]);

	return {
		page,
		isLoading,
		comments,
		hasMore,

		loadNext,
	};
}
