import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Sender from '../../shared/components/Sender';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	root: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		right: 0,
		backgroundColor: theme.mainBackgroundScreens,
	},
	targetName: {
		color: theme.palette.primary.colorLightGrey,
		fontSize: 13,
	},
	cleanReply: {
		color: theme.palette.primary.user_red,
		fontWeight: 'bold',
		fontSize: 13,
	},
}));

export default function CommentForm({
	targetUserName,
	onSubmit: handleSubmit,
	onCleanReply: handleCleanReply,
}) {
	const classes = useStyle();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			{targetUserName ? (
				<Box p={1} display={'flex'} justifyContent={'space-between'} className={classes.replyInfo}>
					<Typography className={classes.targetName}>@{targetUserName}</Typography>
					<Typography className={classes.cleanReply} onClick={handleCleanReply}>
						{t('cancel')}
					</Typography>
				</Box>
			) : null}
			<Sender placeholder={t('post comment') + '...'} onSubmit={handleSubmit} coins={false}/>
		</div>
	);
}
