import BaseService from './BaseService';

class ProfileSettingsService extends BaseService {
	constructor() {
		super('/profile/settings');
	}

	toggleHideComments() {
		return this.post('/togglehidecomments');
	}

	toggleHideLikes() {
		return this.post('/togglehidelikes');
	}

	toggleAllowChatRequests() {
		return this.post('/toggleallowchatrequests');
	}

	toggleAllowMailNotifications() {
		return this.post('/toggleallowemails');
	}

	toggleAdultContent() {
		return this.post('/togglehideadultcontent');
	}
}

export default new ProfileSettingsService();
