import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { ListItemText, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DangerousHtml from '../../../common/DangerousHtml';
import SectionHeading from '../../../common/SectionHeading';
import Cloud from '../../../common/Cloud';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '../../../common/ListItem';
import InputBase from '@material-ui/core/InputBase';
import { useFormik } from 'formik';
import {
	currentUserSelector,
	isRegisteredSelector,
	login,
	register,
} from '../../../store/profile/profile.store';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/Loader';
import * as Yup from 'yup';
import FormErrorMessage from '../../../common/forms/FormErrorMessage';
import SubmitButton from '../../shared/components/SubmitButton';
import { DeleteAccount } from '../components/DeleteAccount';
import Modal from '@material-ui/core/Modal';
import ChangePasswordScreen from './ChangePasswordScreen';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function ManageProfileScreen() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const reg_description = `${t('you stay anonym')}\\n${t('email registration text')}`;
	const validationSchema = Yup.object().shape({
		mail: Yup.string().label('E-Mail').email(t('wrong email')).required(t('please enter email')),
		pass: Yup.string().label(t('password')).required(t('please enter password')),
	});

	const isRegistered = useSelector(isRegisteredSelector);
	const isRegistering = useSelector(state => state.profile.isRegistering);
	const profile = useSelector(currentUserSelector);

	const [openModal, setOpenModal] = useState(false);

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	const { handleChange, values, errors, touched, handleSubmit } = useFormik({
		initialValues: {
			mail: '',
			pass: '',
		},
		validationSchema,
		onSubmit: ({ mail, pass }) => {
			if (!isRegistered) {
				dispatch(register(mail, pass));
			} else {
				dispatch(login(mail, pass));
			}
		},
	});

	return (
		<div>
			{isRegistering ? <Loader /> : null}

			<Box p={1}>
				<SectionHeading py={1}>{t('login details')}</SectionHeading>
				<form onSubmit={handleSubmit}>
					<List
						component={Cloud}
						style={{ backgroundColor: theme.mainBackgroundScreens, color: theme.textDark }}
					>
						<ListItem style={{ padding: 0 }}>
							<Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} flex={1}>
								<Typography>Email</Typography>
								<Typography style={{ color: theme.textLight, fontSize: 12 }}>
									{t('visible to you')}
								</Typography>
							</Box>
							{isRegistered ? (
								<Box display={'flex'} alignItems={'flex-end'} flexDirection={'column'} flex={1}>
									<Typography>{profile.mail || localStorage.getItem('mail')}</Typography>
									<Typography style={{ color: 'green', fontSize: 12 }}>{t('confirmed')}</Typography>
								</Box>
							) : (
								<InputBase
									placeholder={t('your email and name')}
									name={'mail'}
									type={'mail'}
									value={values.mail}
									onChange={handleChange}
									error={!!errors.mail}
								/>
							)}
						</ListItem>
						<FormErrorMessage name={'mail'} errors={errors} touched={touched} />
						<Divider style={{ margin: '5px 0' }} />
						<ListItem
							style={{
								padding: 0,
								flexDirection: 'column',
								alignItems: 'stretch',
							}}
						>
							<Box display={'flex'}>
								<ListItemText primary={t('password')} />
								{isRegistered ? (
									<Box display={'flex'} alignItems={'flex-end'} flexDirection={'column'}>
										<div>********</div>
										{isRegistered ? (
											<Box
												display={'flex'}
												justifyContent={'flex-end'}
												fontWeight={600}
												fontSize={11}
											>
												<span onClick={handleOpen} style={{ cursor: 'pointer' }}>
													{t('change password')}
												</span>
											</Box>
										) : null}
									</Box>
								) : (
									<InputBase
										placeholder={'Passwort eingeben'}
										name={'pass'}
										type={'password'}
										value={values.pass}
										onChange={handleChange}
										error={!!errors.pass}
									/>
								)}
							</Box>
						</ListItem>
						<FormErrorMessage name={'pass'} errors={errors} touched={touched} />
					</List>

					{!isRegistered ? (
						<Box pt={5} pb={4}>
							<SubmitButton>{t('register')}</SubmitButton>
						</Box>
					) : null}
				</form>

				{/*{!isRegistered ? (*/}
				{/*    <Box pb={2}>*/}
				{/*        <span>{t('already member')} </span>*/}
				{/*        <Link style={{*/}
				{/*            color: theme.palette.primary.points_green,*/}
				{/*            fontWeight: '600',*/}
				{/*        }} to="/auth/login" variant="body2">HIER EINLOGGEN</Link>*/}
				{/*    </Box>*/}
				{/*) : <br/>}*/}

				<Typography component={'div'}>
					<DangerousHtml
						style={{ textAlign: 'center', fontSize: 14, color: '#555' }}
						html={reg_description}
					/>
				</Typography>

				{isRegistered ? (
					<Box pt={2}>
						<DeleteAccount />
					</Box>
				) : null}
			</Box>
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ChangePasswordScreen onClose={() => handleClose()} />
				</Box>
			</Modal>
		</div>
	);
}
