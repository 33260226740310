import React, { Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '../../../../common/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		position: 'absolute',
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
}));

export default function CategoryList({
	onAllPresentsClick: handleAllPresentsClick,
	onCategoryClick: handleCategoryClick,
	drawerOpen,
	onDrawerClose: handleDrawerClose,
	presentsCategories,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="left"
			open={drawerOpen}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.drawerHeader}>
				<Typography variant={`subtitle1`}>{t('category')}</Typography>
				<IconButton onClick={handleDrawerClose}>
					{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				</IconButton>
			</div>
			<Divider />
			<List>
				<ListItem button onClick={handleAllPresentsClick}>
					<ListItemText primary={t('all categories')} />
				</ListItem>
				<Divider />
				{presentsCategories
					? presentsCategories.data.map(category => (
							<Fragment key={category.id}>
								<ListItem button onClick={handleCategoryClick(category.id)}>
									<ListItemText primary={`${category.description} (${category.presents_count})`} />
								</ListItem>
								<Divider />
							</Fragment>
					  ))
					: 'Loading...'}
			</List>
		</Drawer>
	);
}
