import React from 'react';

import MatListItem from '@material-ui/core/ListItem';

export default function ListItem({ children, ...props }) {
	return (
		<MatListItem ContainerComponent={'div'} {...props}>
			{children}
		</MatListItem>
	);
}
