import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import useStyles from '../../screens/style';
import NextStepButton from './NextStepButton';
import BackStepButton from './BackStepButton';
import LockIcon from '@material-ui/icons/Lock';
import FormErrorMessage from '../../../../common/forms/FormErrorMessage';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';

export default function PasswordStep({
	valuePass,
	valueConfirmPass,
	onChange,
	errors,
	handleSubmit,
	touched,
	changeStep,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<form className={classes.form} onSubmit={handleSubmit} noValidate>
			<div style={{ width: '100%' }}>
				<div style={{ fontSize: 20, textAlign: 'center', marginBottom: 20, color: theme.textDark }}>
					{t('choose password')}
				</div>
				<Paper
					className={classes.root}
					elevation={0}
					style={{
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
				>
					<IconButton className={classes.iconButton} aria-label="menu">
						<LockIcon />
					</IconButton>
					<InputBase
						className={classes.inputWithIcon}
						placeholder={t('password')}
						type={'password'}
						name={'pass'}
						value={valuePass}
						onChange={onChange}
						inputProps={{ 'aria-label': 'password' }}
						error={!!errors.pass}
					/>
				</Paper>
				<FormErrorMessage name={'pass'} errors={errors} touched={touched} />
				<Paper
					className={classes.root}
					elevation={0}
					style={{
						backgroundColor: '#FFF',
						border: '2px solid silver',
						borderRadius: 10,
					}}
				>
					<IconButton className={classes.iconButton} aria-label="menu">
						<LockIcon />
					</IconButton>
					<InputBase
						className={classes.inputWithIcon}
						placeholder={t('confirm password')}
						type={'password'}
						name={'confirmPass'}
						value={valueConfirmPass}
						onChange={onChange}
						inputProps={{ 'aria-label': 'confirm password' }}
						error={!!errors.confirmPass}
					/>
				</Paper>
				<FormErrorMessage name={'confirmPass'} errors={errors} touched={touched} />
				<NextStepButton
					changeStep={
						valuePass?.length > 5 &&
						valueConfirmPass?.length > 5 &&
						valuePass === valueConfirmPass &&
						(() => changeStep(4))
					}
				/>
				<BackStepButton changeStep={() => changeStep(2)} />
			</div>
		</form>
	);
}
